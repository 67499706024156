import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PaginationType } from '@/apiConfigs/forms';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import { insuranceAnalyticsKeys } from '@/constants';
import Button from '@/designComponents/Button';
import { ButtonTab } from '@/designComponents/ButtonTab';
import { ComponentsProps } from '@/designComponents/DropdownButton';
import { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { RootState } from '@/store';
import { getAllForms } from '@/store/forms/functions';
import { toCapitalCase } from '@/utils/conversion';
import { debounce } from '@/utils/debounce';

const Insurance = ({ searchTerm, setSearchTerm }: ComponentsProps) => {
  const [cardKey, setCardKey] = useState<string>('home-insurance');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formsData, pagination, analytics, loading } = useSelector(
    (state: RootState) => state.forms
  );
  const [paginationParams, setPaginationParams] = useState<PaginationType>({
    page: 1,
    limit: 10,
    search: '',
  });
  const getForms = async (params: PaginationType) => {
    await dispatch(getAllForms(params));
  };
  useEffect(() => {
    getForms({ filter: { type: cardKey } });
  }, []);

  const fetchForms = (myParams: PaginationType) => {
    const noEmptyParams = Object.entries(myParams).reduce((acc, val) => {
      if (!val[1]) {
        return acc;
      }
      acc = { ...acc, [val[0]]: val[1] };
      return acc;
    }, {});

    getForms(noEmptyParams);
  };

  const delayedFetch = useCallback(debounce(fetchForms, 300), []);

  useEffect(() => {
    if (searchTerm && cardKey) {
      setPaginationParams({ page: 1, limit: 10 });
      delayedFetch({
        search: searchTerm,
        page: 1,
        limit: 10,
        filter: {
          type: cardKey,
        },
      });
    }
  }, [searchTerm, cardKey]);
  const updateParamsAndFetchDocuments = (updatedPagination: PaginationType) => {
    setPaginationParams(updatedPagination);
    delayedFetch(updatedPagination);
  };
  const onDocumentsTypeChange = (key: string) => {
    setSearchTerm('');
    setCardKey(key);
    const updatedPagination = {
      ...paginationParams,
      page: 1,
      filter: { type: key },
    };
    updateParamsAndFetchDocuments(updatedPagination);
  };
  const tableData: TableDataType = {
    headings: [
      { key: 'name', label: 'Full Name' },
      { key: 'email', label: 'Email' },
      { key: 'phone', label: 'Phone' },
      { key: 'ownership', label: 'OwnershipType' },
      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(formsData)
      ? formsData?.map((form) => {
          return {
            id: form._id,
            name: form.firstName + '' + form.lastName,
            email: form.email,
            phone: form.phone,
            ownership: toCapitalCase(form.ownershipType),
            actions: (
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="gray"
                  radius="normal"
                  onClick={() => navigate(form._id)}
                >
                  View
                </Button>
              </div>
            ),
          };
        })
      : [],
  };

  const handleCurrentPage = (page: number) => {
    const updatedPagination = {
      ...paginationParams,
      page: page,
    };
    updateParamsAndFetchDocuments(updatedPagination);
  };

  const cardData = Object.keys(analytics).length
    ? insuranceAnalyticsKeys.map((ak) => {
        return {
          title: toCapitalCase(ak),
          count: analytics[ak]?.value,
          key:
            ak === 'home-insurance'
              ? 'home-insurance'
              : ak === 'public-liability-insurance'
                ? 'public-liability-insurance'
                : ak === 'employers-liability-insurance'
                  ? 'employers-liability-insurance'
                  : ak === 'professional-indemnity'
                    ? 'professional-indemnity'
                    : null,
        };
      })
    : [];
  const handleLimitChange = (limit: number) => {
    setPaginationParams((prev) => ({ ...prev, page: 1, limit }));
  };
  const handleNextPage = () => {
    if (
      paginationParams.page &&
      formsData?.pagination?.totalPages &&
      paginationParams?.page < pagination.totalPages
    ) {
      handleCurrentPage(paginationParams.page + 1);
    }
  };
  const handleLastPage = () => {
    if (
      formsData?.pagination?.totalPages &&
      paginationParams.page !== formsData?.pagination.totalPages
    ) {
      handleCurrentPage(formsData?.pagination.totalPages);
    }
  };
  const formsTabs = (
    <ButtonTab
      items={cardData}
      activeIndex={cardData.findIndex((x) => x.key === cardKey)}
      setActiveIndex={(index) => onDocumentsTypeChange(cardData[index].key)}
    />
  );

  useHeaderTitle('Insurance');
  return (
    <div>
      <Table
        tableData={tableData}
        customRow={formsTabs}
        noData="No data to display."
        showCheckBox={false}
        maxWidthForCardView="768px"
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
        isLoading={loading}
      />
      <Pagination
        activePage={paginationParams.page || 1}
        onCurrentPageChange={handleCurrentPage}
        totalPages={formsData?.pagination?.totalPages || 1}
        limit={paginationParams.limit || 10}
        onLimitChange={handleLimitChange}
        onNext={handleNextPage}
        onLast={handleLastPage}
      />
    </div>
  );
};

export default Insurance;
