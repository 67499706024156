import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 99999999;
`;

export const ModalContainer = styled.div<{ halfWidth: boolean }>`
  background: white;
  width: ${({ halfWidth }) => (halfWidth ? '50%' : '100%')};
  margin: 20px 50px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eaeaea;
  h2 {
    margin: 0;
    font-size: 18px;
  }
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  outline: none;
`;

export const Body = styled.div`
  padding: 16px;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.dark.neutral_20};
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.dark.neutral_10};
    border-radius: 5px;
    width: 10px;
    height: 24px;
  }
`;

export const ModalContentWrapper = styled.div`
  padding: 20px;
  min-height: 400px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;
