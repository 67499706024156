import { ConfigType } from '@/utils/requestAPI';

import { PaginationType } from './documents';

const hashTagApi = {
  getAllHashTags: (params?: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/hash-tag',
      params,
    };
  },
};

export { hashTagApi };
