import styled from 'styled-components';

export const CardTabWrapper = styled.div<{
  $isActive: boolean;
  $enableBorder: boolean;
}>`
  flex: 1;
  border: ${({ $enableBorder, theme }) =>
    $enableBorder ? ` 1px solid ${theme.colors.blue_d3} ` : ''};
  border-radius: 5px;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.blue_f4 : ''};
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  cursor: ${({ $enableBorder }) => ($enableBorder ? 'default' : 'pointer')};
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .main-content {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
