import { useState } from 'react';
import { useTheme } from 'styled-components';

import DeleteModal from '@/components/DeleteModal';
import BreadCrumb from '@/designComponents/BreadCrumb';
import Button from '@/designComponents/Button';
import Modal from '@/designComponents/Modal';
import CreateDocuments from '@/pages/Documents/CreateDocument';

import { CrumbsWrapper } from '../../pages/Users/Details/style';

type Props = {
  breadCrumbData: Array<{ label: string; path: string }>;
  deleteButtonText?: string;
  editButtonText?: string;
  isNewLetter?: boolean;
  hideButtons?: boolean;
  onDeleteComplete?: () => void;
  isDeleting?: boolean;
  deletingUserName?: string;
};

const CrumbsWithButton = ({
  breadCrumbData,
  deleteButtonText,
  editButtonText,
  isNewLetter = false,
  hideButtons = false,
  onDeleteComplete,
  isDeleting = false,
  deletingUserName,
}: Props) => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  return (
    <CrumbsWrapper>
      <BreadCrumb breadCrumb={breadCrumbData} />
      {!isNewLetter &&
        (!hideButtons ? (
          <div className="btn">
            <Button
              onClick={() => setIsDeleteModalOpen(true)}
              radius="md"
              variant="ghost"
              style={{
                border: `1px solid ${theme.colors.colorDanger}`,
                color: theme.colors.light.white,
                fontWeight: theme.fontWeights.medium,
                fontSize: theme.fontSize.normal,
                fontFamily: theme.fontFamily.roboto,
                backgroundColor: theme.colors.colorDanger,
              }}
            >
              {deleteButtonText}
            </Button>
            {editButtonText ? (
              <Button
                onClick={() => setIsModalOpen(true)}
                radius="md"
                variant="ghost"
                style={{
                  border: `1px solid ${theme.colors.brandColorBlue}`,
                  color: theme.colors.light.white,
                  fontWeight: theme.fontWeights.medium,
                  fontSize: theme.fontSize.normal,
                  fontFamily: theme.fontFamily.roboto,
                  backgroundColor: theme.colors.brandColorBlue,
                }}
              >
                {editButtonText}
              </Button>
            ) : null}
          </div>
        ) : (
          ''
        ))}

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Edit Category Document"
      >
        <CreateDocuments />
      </Modal>

      <DeleteModal
        title="housebuild.com says"
        onClose={() => setIsDeleteModalOpen(false)}
        isOpen={isDeleteModalOpen}
        deleteText={`Are you sure you want to delete ${deletingUserName} ?`}
        onConfirmDelete={onDeleteComplete}
        confirmBtnLoading={isDeleting}
      />
    </CrumbsWrapper>
  );
};

export default CrumbsWithButton;
