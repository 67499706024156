import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { breakpointsDeviceWise } from '@/theme/breakpoints';
import { getColorValue, NestedColorKeys } from '@/theme/colors';
import { FontFamilyType } from '@/theme/fontFamily';
import { FontSizeType } from '@/theme/fontSizes';
import { FontWeightType } from '@/theme/fontWeights';
type TypographyFontWeight = keyof FontWeightType;
type TypographyFontFamily = keyof FontFamilyType;
type TypographySize = keyof FontSizeType;

export interface ITypographyComponent {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
}

export interface ITypography {
  size?: TypographySize | TypographySize[];
  fontFamily?: TypographyFontFamily;
  fontWeight?: TypographyFontWeight;
  color?: NestedColorKeys;
  onClick?: () => void;
}

export const StyledTypography = styled.div<ITypography>`
  color: ${({ color }) => getColorValue(color)};
  font-size: ${({ size, theme }) =>
    Array.isArray(size)
      ? `${theme.fontSize[size[0]]}`
      : `${theme.fontSize[size || 'normal']}`};
  font-weight: ${({ fontWeight, theme }) =>
    theme.fontWeights[fontWeight || 'normal']};
  font-family: ${({ fontFamily, theme }) =>
    theme.fontFamily[fontFamily || 'roboto']};
  margin: 0;
  padding: 0;
  line-height: ${({ size }) => (size === 'normal' ? '21px' : '25px')};

  @media (min-width: ${breakpointsDeviceWise.tablet}) {
    font-size: ${({ theme, size }) =>
      Array.isArray(size)
        ? `${theme.fontSize[size[1]]}`
        : `${theme.fontSize[size || 'normal']}`};
  }

  @media (min-width: ${breakpointsDeviceWise.laptop}) {
    font-size: ${({ theme, size }) =>
      Array.isArray(size)
        ? `${theme.fontSize[size[2] || size[1]]}` // Use small size if medium size is not provided.
        : `${theme.fontSize[size || 'normal']}`};
  }

  @media (min-width: ${breakpointsDeviceWise.xlLaptop}) {
    font-size: ${({ theme, size }) =>
      Array.isArray(size)
        ? `${theme.fontSize[size[3] || size[2]]}` // Use medium size if large size is not provided.
        : `${theme.fontSize[size || 'normal']}`};
  }
`;
