import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { DASHBOARD_ROUTE } from '../../routes/routePaths';

export interface ICheckAuthProps {
  children: React.ReactNode;
  redirectTo?: string;
}
const CheckAuthElement = ({
  redirectTo = DASHBOARD_ROUTE,
  children,
}: ICheckAuthProps) => {
  const hasUser = useSelector((state) => !!state?.auth?.user?._id);

  if (hasUser) {
    return <Navigate to={redirectTo} replace />;
  }
  return <>{children || <Outlet />}</>;
};

export default CheckAuthElement;
