import { useCallback, useEffect, useState } from 'react';
// import { FiTrash } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import tags, { TagsPaginationType } from '@/apiConfigs/tag';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import Pagination from '@/components/Pagination';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Table, { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import usePaginationStore from '@/storeZustand/paginationStore';
import { debounce } from '@/utils/debounce';
import requestAPI from '@/utils/requestAPI';

import { SearchInputWrapperStyled } from '../Documents/style';
import AddTag from './CreateTag';
const Tags = () => {
  const navigate = useNavigate();
  const [productTags, setProductTags] = useState([]);
  const [openCerateTagModal, setOpenCreateTagModal] = useState(false);
  const [isTagsFetching, setIsTagsFetching] = useState(false);
  const { setLimit, getLimit } = usePaginationStore();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    limit: getLimit('tags'),
    search: '',
  });
  const [pagination, setPagination] = useState({ totalPages: 1 });
  useHeaderTitle('Tags');
  const getAllTags = async (params?: TagsPaginationType) => {
    try {
      setIsTagsFetching(true);
      const data = await requestAPI(tags.getAllTags(params));
      setProductTags(data.data);
      setPagination({
        totalPages: data.pagination.totalPages,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsTagsFetching(false);
    }
  };
  const delayedFetch = useCallback(debounce(getAllTags, 300), []);
  useEffect(() => {
    getAllTags(paginationParams);
  }, [paginationParams.page, paginationParams.limit]);
  const tableData: TableDataType = {
    headings: [
      { key: 'title', label: 'Title' },
      { key: 'subCategory', label: 'Total Sub Category' },
      { key: 'productsCount', label: 'Total Products' },
      { key: 'processType', label: 'Process Type' },
      { key: 'createdAt', label: 'Created At' },
      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(productTags)
      ? productTags.map((productTag) => ({
          id: productTag._id,
          title: productTag.title,
          subCategory: Array.isArray(productTag?.subCategory)
            ? productTag.subCategory.length
            : 0,
          productsCount: Array.isArray(productTag?.products)
            ? productTag.products.length
            : 0,
          createdAt: new Date(productTag?.createdAt).toLocaleDateString(),
          processType: productTag?.isGeneratedAutomatically
            ? 'Automated'
            : 'Manual',
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                onClick={() => navigate(productTag?._id)}
                style={{ marginLeft: '10px' }}
              >
                View
              </Button>
            </div>
          ),
        }))
      : [],
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPaginationParams((prevParams) => {
      const updatedParams = { ...prevParams, search: value, page: 1 };
      delayedFetch(updatedParams);
      return updatedParams;
    });
  };
  const handleCurrentPage = (page: number) => {
    setPaginationParams((prevParams) => ({ ...prevParams, page }));
  };
  const handleLimitChange = (limit: number) => {
    setLimit('tags', limit);
    setPaginationParams((prevParams) => ({
      ...prevParams,
      limit,
      page: 1,
    }));
  };
  return (
    <div>
      <div style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchInputWrapperStyled>
            <Input
              value={paginationParams.search}
              onChange={handleInputChange}
              placeholder="Search"
              iconLeft={<SearchSVG />}
            />
          </SearchInputWrapperStyled>
          <Button radius="md" onClick={() => setOpenCreateTagModal(true)}>
            New Tag
          </Button>
          {openCerateTagModal && (
            <AddTag
              isOpenModal={openCerateTagModal}
              onClose={() => setOpenCreateTagModal(false)}
              fetchAllTags={getAllTags}
            />
          )}
        </div>
      </div>
      <Table
        tableData={tableData}
        noData="No data to display."
        showCheckBox={false}
        maxWidthForCardView="768px"
        headingBgColor="#F0F0F0"
        showMenuIcon={false}
        evenRowBgColor="#FAFAFA"
        isLoading={isTagsFetching}
      />
      <Pagination
        activePage={paginationParams.page || 1}
        onCurrentPageChange={handleCurrentPage}
        totalPages={pagination?.totalPages || 1}
        limit={getLimit('tags') || 10}
        onLimitChange={handleLimitChange}
      />
    </div>
  );
};
export default Tags;
