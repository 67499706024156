import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { LOGIN_ROUTE } from '@/routes/routePaths';

export interface IProtectedComponentProps {
  children: React.ReactNode;
  allowed?: string[];
  extraInfo?: any;
}

const ProtectedComponent: React.FC<IProtectedComponentProps> = ({
  children,
}) => {
  const hasUser = useSelector((state) => !!state?.auth?.user?._id);

  if (!hasUser) {
    return <Navigate to={LOGIN_ROUTE} replace />;
  }

  return <>{children || <Outlet />}</>;
};

export default ProtectedComponent;
