import { createAsyncThunk } from '@reduxjs/toolkit';

import forms, { PaginationType } from '@/apiConfigs/forms';
import requestAPI from '@/utils/requestAPI';

// Define async thunk for fetching forms
export const getAllForms = createAsyncThunk(
  'forms/getAllForms',
  async (params: PaginationType) => {
    const data = await requestAPI(forms.getAllForms(params));
    return data;
  }
);
