import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import * as yup from 'yup';

import videos from '@/apiConfigs/videos';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import MultiSelect from '@/designComponents/MultiSelect';
import Typography from '@/designComponents/Typography';
import UploadFile from '@/designComponents/UploadFile';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  addHowToVideosCategory,
  getAllHowToVideosCategory,
  getSingleHowToVideosCategory,
} from '@/store/videos/HowToVideosCategory/functions';
import { getAllHowToVideosCategoryClass } from '@/store/videos/HowToVideosCategoryClass/functions';
import requestAPI from '@/utils/requestAPI';
import { uploadFilesToS3OnlyKeys } from '@/utils/s3Upload';

import { HowToVideosFormWrapper, InfoRowStyled } from '../HowToVideos/style';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
};

type InitialValueType = {
  name: string;
  position: number;
  contentSectionIds: string[];
  icon: File | null | string;
};

const howToVideosSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .typeError('Name should be string'),
  position: yup
    .number()
    .required('Duration is required')
    .typeError('Duration should be number'),
  icon: yup
    .string()
    .required('Icon is required')
    .typeError('Icon should be string'),
  contentSectionIds: yup
    .array()
    .of(yup.string())
    .required('Content Section IDs are required')
    .min(1, 'At least one content section ID is required'),
});
const AddHowToVideosCategory = ({ setIsModalOpen }: Props) => {
  const theme = useTheme();
  const { videoId } = useParams();

  const [initialValues, setInitialValues] = useState<InitialValueType>({
    name: '',
    position: 1,
    contentSectionIds: [],
    icon: null,
  });

  const [loading, setLoading] = useState(false);
  const formikRef = useRef<FormikProps<InitialValueType>>(null);

  const vdosCatClass = useAppSelector(
    (state) => state.howToVideosCategoryClass.videos
  );

  const dispatch = useAppDispatch();

  const { singleVideo } = useAppSelector((state) => state.howToVideosCategory);

  useEffect(() => {
    if (videoId) {
      dispatch(getSingleHowToVideosCategory(videoId || ''));
    }
  }, [dispatch, videoId]);

  useEffect(() => {
    if (videoId) {
      setInitialValues({
        contentSectionIds: singleVideo.contentSection.map((video) => video),
        icon: singleVideo.icon,
        name: singleVideo.name,
        position: Number(singleVideo.position),
      });
    }
  }, [videoId, singleVideo]);

  const handleFormSubmit = async (values: InitialValueType) => {
    try {
      setLoading(true);
      let iconUrl: string[] | undefined = [];
      if (values.icon) {
        iconUrl = await uploadFilesToS3OnlyKeys([values.icon]);
      }

      await dispatch(
        addHowToVideosCategory({
          contentSection: values.contentSectionIds,
          icon: iconUrl?.[0] || '',
          name: values.name,
          position: String(values.position),
        })
      );
      setLoading(false);
      setIsModalOpen(false);
      dispatch(getAllHowToVideosCategory({}));
    } catch (error: any) {
      setLoading(false);
      setIsModalOpen(false);
      console.error(error);
      toast.error(error.message);
    }
  };
  const handleUpdate = async (values: InitialValueType) => {
    try {
      if (typeof values.icon !== 'string') {
        setLoading(true);
        let iconUrl: string[] | undefined = [];
        if (values.icon) {
          iconUrl = await uploadFilesToS3OnlyKeys([values.icon]);
        }

        await requestAPI(
          videos.updateHowToVideoCategory(
            {
              contentSection: values.contentSectionIds,
              icon: iconUrl?.[0] || '',
              name: values.name,
              position: String(values.position),
            },
            videoId
          )
        );
        setLoading(false);
        dispatch(getSingleHowToVideosCategory(videoId));
        setIsModalOpen(false);
        toast.success('Video Category Updated Successfully');
      } else {
        setLoading(true);

        await requestAPI(
          videos.updateHowToVideoCategory(
            {
              contentSection: values.contentSectionIds,
              icon: values.icon || '',
              name: values.name,
              position: String(values.position),
            },
            videoId
          )
        );
        setLoading(false);
        dispatch(getSingleHowToVideosCategory(videoId));
        setIsModalOpen(false);
        toast.success('Video Category Updated Successfully');
      }
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    dispatch(getAllHowToVideosCategoryClass({}));
  }, [dispatch]);
  return (
    <>
      <ModalContentWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={videoId ? handleUpdate : handleFormSubmit}
          enableReinitialize
          innerRef={formikRef}
          validationSchema={howToVideosSchema}
        >
          {(formikProps: FormikProps<InitialValueType>) => {
            const { setFieldValue, values, touched, errors } = formikProps;

            return (
              <Form>
                <HowToVideosFormWrapper>
                  <InfoRowStyled>
                    <Typography className="text">
                      Position <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="position"
                      type="number"
                      value={values.position}
                      onChange={(e) =>
                        setFieldValue('position', e.target.value)
                      }
                    />
                    {errors.position && touched.position && (
                      <div style={{ color: 'red' }}>{errors.position}</div>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="text">
                      Name <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                    />
                    {errors.name && touched.name && (
                      <div style={{ color: 'red' }}>{errors.name}</div>
                    )}
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography className="text">
                      Content section ids{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <MultiSelect
                      name="contentSectionIds"
                      options={
                        vdosCatClass?.data.map((video) => ({
                          label: video?.name || '',
                          value: video?._id || '',
                        })) || []
                      }
                      formikProps={formikProps}
                    />
                    {errors.contentSectionIds &&
                      touched.contentSectionIds &&
                      typeof errors.contentSectionIds === 'string' && (
                        <div style={{ color: 'red' }}>
                          {errors.contentSectionIds}
                        </div>
                      )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="text">Icon </Typography>
                    <UploadFile
                      name="icon"
                      onChange={(value) => setFieldValue('icon', value)}
                      apiUrls={videoId && singleVideo && [singleVideo.icon]}
                      title=""
                      variant="previewImage"
                      onRemove={() => {}}
                    />
                    {/* {errors.icon && touched.icon && (
                      <div style={{ color: 'red' }}>{errors.icon}</div>
                    )} */}
                  </InfoRowStyled>
                </HowToVideosFormWrapper>
              </Form>
            );
          }}
        </Formik>
      </ModalContentWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '20px',
          padding: '0 20px 20px',
        }}
      >
        <Button
          radius="md"
          variant="ghost"
          style={{
            border: `1px solid ${theme.colors.dark.neutral_250}`,
            borderRadius: '10px',
            color: theme.colors.text,
            fontFamily: theme.fontFamily.roboto,
            fontWeight: theme.fontWeights.medium,
          }}
          onClick={() => {
            if (setIsModalOpen) {
              setIsModalOpen(false);
            }
          }}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          radius="normal"
          isLoading={loading}
          onClick={() => formikRef.current?.handleSubmit()}
          style={{ backgroundColor: theme.colors.brandColorBlue }}
        >
          {videoId
            ? 'Edit How to Video Category'
            : 'Create How to video category'}
        </Button>
      </div>
    </>
  );
};

export default AddHowToVideosCategory;
