export const dropDownOption: Array<{
  value: string | number;
  label: string;
}> = [
  {
    label: 'Master Dashboard',
    value: 'master',
  },
  {
    label: 'Hello',
    value: 'hello',
  },
];

export const badgeData: Array<{ title: string; value: string }> = [
  {
    title: 'Go Green',
    value: 'go-green',
  },
  {
    title: 'Building Guides',
    value: 'building-guide',
  },
  {
    title: 'Building System',
    value: 'building-system',
  },
  {
    title: 'Download Center',
    value: 'download-center',
  },
];

export const searchData: Array<{
  badgeType: string;
  data: Array<{ title: string; category: string }>;
}> = [
  {
    badgeType: 'go-green',
    data: [
      {
        title: 'Retrofitting',
        category: 'All Categories',
      },
      {
        title: 'Renewable Details',
        category: 'All Resources',
      },
      {
        title: 'Introduction',
        category: 'NZEB Resources',
      },
      {
        title: 'Passive House Details',
        category: 'NZEB Subcategories',
      },
      {
        title: 'FAQ’S - Typing Costing',
        category: 'Trade Gropings',
      },
    ],
  },
  {
    badgeType: 'building-guide',
    data: [
      {
        title: 'Foundation Types',
        category: 'Architecture',
      },
      {
        title: 'Roofing Solutions',
        category: 'Design & Materials',
      },
      {
        title: 'Insulation Best Practices',
        category: 'Energy Saving',
      },
      {
        title: 'Window and Door Efficiency',
        category: 'Installation',
      },
      {
        title: 'Landscaping for Energy Conservation',
        category: 'Exterior Design',
      },
    ],
  },
  {
    badgeType: 'building-system',
    data: [
      {
        title: 'HVAC Innovations',
        category: 'Climate Control',
      },
      {
        title: 'Smart Home Systems',
        category: 'Technology',
      },
      {
        title: 'Solar Panel Integration',
        category: 'Renewable Energy',
      },
      {
        title: 'Rainwater Harvesting',
        category: 'Water Management',
      },
      {
        title: 'Sustainable Urban Development',
        category: 'City Planning',
      },
    ],
  },
  {
    badgeType: 'download-center',
    data: [
      {
        title: 'Blueprints for Energy Efficient Homes',
        category: 'Architectural Plans',
      },
      {
        title: 'DIY Solar Panel Installation Guide',
        category: 'Renewable Energy Resources',
      },
      {
        title: 'Eco-Friendly Material Suppliers',
        category: 'Vendor Lists',
      },
      {
        title: 'Green Certification Process',
        category: 'Regulatory Guidelines',
      },
      {
        title: 'Sustainable Gardening eBook',
        category: 'Lifestyle',
      },
    ],
  },
];
