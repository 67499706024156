import { createSlice } from '@reduxjs/toolkit';

import { getCount } from './functions';

const initialState: any = {
  dashboard: [],

  error: null,
  loading: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCount.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch users. Try again later.';
      })
      .addCase(getCount.fulfilled, (state, action) => {
        console.log({ action }, ':: ACTION ');

        state.loading = false;
        state.error = null;
        state.dashboard = action.payload;
      });
  },
});

export const dashboardReducer = dashboardSlice.reducer;
