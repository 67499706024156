import { useState } from 'react';

import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import { ButtonTab } from '@/designComponents/ButtonTab';
import Input from '@/designComponents/Input';
import { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';

import { data } from './data';

type Props = {};

const InteractiveSpreadSheet = (_props: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [cardKey, setCardKey] = useState<string>('users-interactive');
  const [searchTerm, setSearchTerm] = useState('');

  const cardData = [
    {
      title: 'Users Interactive',
      key: 'users-interactive',
      count: 40,
    },
    {
      title: 'SpreadSheets',
      key: 'spreadsheets',
      count: 10,
    },
  ];

  const dataArray = data();

  const lastIndex = currentPage * 10;
  const firstIndex = lastIndex - 10;
  const currentRecords = dataArray.slice(firstIndex, lastIndex);

  const tableData: TableDataType = {
    headings: [
      { key: 'id', label: 'Id' },
      { key: 'user', label: 'User' },
      { key: 'projectTitle', label: 'Project Title' },
      { key: 'spreadsheet', label: 'Spreadsheet Type' },
      { key: 'date', label: 'Created At' },
    ],
    body: currentRecords,
  };

  const handleCurrentPage = (page: number) => {
    setCurrentPage(page);
  };
  const handleNextPage = (page: number) => {
    setCurrentPage(page);
  };
  const handleLastPage = (page: number) => {
    setCurrentPage(page);
  };
  const handleClick = (key: string) => {
    setCardKey(key);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
  };
  useHeaderTitle('Interactive spreadsheet');

  const interactiveTabs = (
    <ButtonTab
      items={cardData}
      activeIndex={cardData.findIndex((x) => x.key === cardKey)}
      setActiveIndex={(index) => handleClick(cardData[index].key)}
    />
  );
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <Input
          value={searchTerm}
          onChange={handleInputChange}
          placeholder="Search Interactive"
          iconLeft={<SearchSVG />}
        />
      </div>
      <Table
        tableData={tableData}
        customRow={interactiveTabs}
        noData="No data to display."
        showCheckBox={false}
        maxWidthForCardView="768px"
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
      />

      <Pagination
        activePage={currentPage}
        onCurrentPageChange={handleCurrentPage}
        onLast={handleLastPage}
        onNext={handleNextPage}
        totalPages={Math.ceil(data.length / 10)}
      />
    </div>
  );
};

export default InteractiveSpreadSheet;
