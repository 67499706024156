import axios from 'axios';

import configs from '@/configs';
import { localStorageKeys } from '@/constants';
import { AuthType } from '@/types/auth';

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: configs.apiURL,
});

const accessToken = localStorage.getItem(localStorageKeys.accessToken);
if (accessToken) {
  axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;
}

export const clearLocalStorageData = () => {
  localStorage.removeItem(localStorageKeys.user);
  localStorage.removeItem(localStorageKeys.accessToken);
};

export const clearAxiosConfig = () => {
  delete axiosInstance.defaults.headers.Authorization;
  delete axiosInstance.defaults.headers.common.Authorization;
};

// Add response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      // Check if the error message indicates an expired token
      if (
        error.response.data?.message === 'Invalid token' ||
        error.response.data?.message === 'Token expired'
      ) {
        alert('Token Expired, logging out...');
        clearAxiosConfig();
        clearLocalStorageData();
        window.location.href = '/';
      }
    }

    return Promise.reject(error);
  }
);

export const setAuthorizationHeader = (token: string) => {
  axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
  localStorage.setItem(localStorageKeys.accessToken, token);
};

export const setRegionHeader = (region?: string) => {
  console.log('regions set', region);
  axiosInstance.defaults.headers.Region = region;
  axiosInstance.defaults.headers.common.Region = region;
};

export const setAxiosInstanceBaseURL = (baseURL: string) => {
  axiosInstance.defaults.baseURL = baseURL;
};

export const setUsersToLocalStorage = (user: AuthType) => {
  localStorage.setItem(localStorageKeys.user, JSON.stringify(user));
};

export const getAxiosInstance = () => {
  return axiosInstance;
};

export default axiosInstance;
