import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import * as yup from 'yup';

import videos from '@/apiConfigs/videos';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import Typography from '@/designComponents/Typography';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  addHowToVideosCategoryClass,
  getAllHowToVideosCategoryClass,
  getSingleHowToVideosCategoryClass,
} from '@/store/videos/HowToVideosCategoryClass/functions';
import requestAPI from '@/utils/requestAPI';

import { HowToVideosFormWrapper, InfoRowStyled } from '../HowToVideos/style';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
};

type InitialValueType = {
  name: string;
};

const howToVideosCategoryClassSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .typeError('Name should be string'),
});
const AddHowToVideosCategoryClass = ({ setIsModalOpen }: Props) => {
  const theme = useTheme();
  const { videoId } = useParams();

  const formikRef = useRef<FormikProps<InitialValueType>>(null);
  const [initialValues, setInitialValues] = useState<InitialValueType>({
    name: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { singleVideo } = useAppSelector(
    (state) => state.howToVideosCategoryClass
  );

  useEffect(() => {
    if (videoId) {
      dispatch(getSingleHowToVideosCategoryClass(videoId || ''));
    }
  }, [dispatch, videoId]);

  useEffect(() => {
    if (videoId && singleVideo) {
      setInitialValues({
        name: singleVideo.name,
      });
    }
  }, [videoId, singleVideo]);

  const handleFormSubmit = async (values: InitialValueType) => {
    setIsLoading(true);
    await dispatch(
      addHowToVideosCategoryClass({
        name: values.name,
      })
    );
    setIsLoading(false);
    setIsModalOpen(false);
    await dispatch(getAllHowToVideosCategoryClass({}));
  };
  const handleUpdate = async (values: InitialValueType) => {
    try {
      setIsLoading(true);
      await requestAPI(
        videos.updateHowToVideoCategoryClass(
          {
            name: values.name,
          },
          videoId
        )
      );
      setIsLoading(false);
      setIsModalOpen(false);
      toast.success('Video Content Section Updated Successfully');
      await dispatch(getSingleHowToVideosCategoryClass(videoId));
    } catch (error: any) {
      console.error(error.message);
      toast.error(error.message);
    }
  };

  return (
    <>
      <ModalContentWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={videoId ? handleUpdate : handleFormSubmit}
          innerRef={formikRef}
          validationSchema={howToVideosCategoryClassSchema}
          enableReinitialize
        >
          {(formikProps: FormikProps<InitialValueType>) => {
            const { setFieldValue, values, touched, errors } = formikProps;

            return (
              <Form>
                <HowToVideosFormWrapper>
                  <InfoRowStyled>
                    <Typography className="text">
                      Name <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                    />
                    {errors.name && touched.name && (
                      <div style={{ color: 'red' }}>{errors.name}</div>
                    )}
                  </InfoRowStyled>
                </HowToVideosFormWrapper>
              </Form>
            );
          }}
        </Formik>
      </ModalContentWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '20px',
          padding: '0 20px 20px',
        }}
      >
        <Button
          radius="md"
          variant="ghost"
          style={{
            border: `1px solid ${theme.colors.dark.neutral_250}`,
            borderRadius: '10px',
            color: theme.colors.text,
            fontFamily: theme.fontFamily.roboto,
            fontWeight: theme.fontWeights.medium,
          }}
          onClick={() => {
            if (setIsModalOpen) {
              setIsModalOpen(false);
            }
          }}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          radius="normal"
          isLoading={isLoading}
          onClick={() => formikRef.current?.handleSubmit()}
          style={{ backgroundColor: theme.colors.brandColorBlue }}
        >
          {videoId
            ? 'Edit Video Content Section'
            : 'Create Video Content Section'}
        </Button>
      </div>
    </>
  );
};

export default AddHowToVideosCategoryClass;
