/* eslint-disable */

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { DRAG_DROP_PASTE } from '@lexical/rich-text';
import { isMimeType, mediaFileReader } from '@lexical/utils';
import { COMMAND_PRIORITY_LOW } from 'lexical';
import { useEffect } from 'react';

import { uploadFilesToS3, uploadFilesToS3OnlyKeys } from '../../utils/s3Upload';
import { INSERT_IMAGE_COMMAND } from '../ImagesPlugin';

const ACCEPTABLE_IMAGE_TYPES = [
  'image/',
  'image/heic',
  'image/heif',
  'image/gif',
  'image/webp',
];

export default function DragDropPaste(): null {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerCommand(
      DRAG_DROP_PASTE,
      (files) => {
        (async () => {
          const result = await uploadFilesToS3(files);

          if (Array.isArray(result)) {
            for (const file of result) {
              if (isMimeType(file.file, ACCEPTABLE_IMAGE_TYPES)) {
                editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                  altText: file.file.name,
                  src: file.key,
                });
              }
            }
          }
        })();
        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor]);
  return null;
}
