import { CouponsStatsData } from '.';
import { PromoCodeChartsData } from './PromoCode/PromoCodeCharts';

export const transformCouponsChartData = (
  backendData: Array<CouponsStatsData>
): PromoCodeChartsData[] => {
  const defaultMonthsData = [
    { name: 'Jan', PromoCodeUsed: 0 },
    { name: 'Feb', PromoCodeUsed: 0 },
    { name: 'Mar', PromoCodeUsed: 0 },
    { name: 'Apr', PromoCodeUsed: 0 },
    { name: 'May', PromoCodeUsed: 0 },
    { name: 'Jun', PromoCodeUsed: 0 },
    { name: 'July', PromoCodeUsed: 0 },
    { name: 'Aug', PromoCodeUsed: 0 },
    { name: 'Sep', PromoCodeUsed: 0 },
    { name: 'Oct', PromoCodeUsed: 0 },
    { name: 'Nov', PromoCodeUsed: 0 },
    { name: 'Dec', PromoCodeUsed: 0 },
  ];

  const currentYear = new Date().getFullYear();

  return defaultMonthsData.map((month) => {
    const monthData = backendData.find(
      (item) => item.month === month.name && item.year === currentYear
    );

    return {
      name: month.name,
      PromoCodeUsed: monthData ? monthData.count : 0,
    };
  });
};

export const calculateCouponsStats = (
  backendData: Array<CouponsStatsData>
): {
  chartData: PromoCodeChartsData[];
  stats: {
    increase: string;
    decrease: string;
  };
} => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.toLocaleString('en-US', { month: 'short' });
  const previousMonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - 1)
  ).toLocaleString('en-US', { month: 'short' });

  const currentMonthData =
    backendData.find(
      (item) => item.month === currentMonth && item.year === currentYear
    )?.count || 0;

  const previousMonthData =
    backendData.find(
      (item) => item.month === previousMonth && item.year === currentYear
    )?.count || 0;

  const increasePercent = previousMonthData
    ? ((currentMonthData - previousMonthData) / previousMonthData) * 100
    : 0;

  return {
    chartData: transformCouponsChartData(backendData),
    stats: {
      increase: Math.max(increasePercent, 0).toFixed(0),
      decrease: Math.abs(Math.min(increasePercent, 0)).toFixed(0),
    },
  };
};
