import theme from '@/theme';

export default function Divider() {
  return (
    <hr
      style={{
        backgroundColor: theme.colors.dark.neutral_50,
        border: 'none',
        height: '1px',
      }}
    />
  );
}
