import { FieldArray, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import products from '@/apiConfigs/products';
import suppliers from '@/apiConfigs/suppliers';
import CountrySelection from '@/components/CountrySelection';
import TextEditor from '@/components/TextEditor';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import Switch from '@/designComponents/Switch';
import Typography from '@/designComponents/Typography';
import UploadFile from '@/designComponents/UploadFile';
import {
  AddButton,
  FlexEndContainer,
  RegionContainer,
  RemoveButton,
} from '@/pages/BuildingJourney/style';
import { useAppDispatch } from '@/store';
import { Products } from '@/store/products';
import {
  getAllProducts,
  getSingleProductDetail,
} from '@/store/products/functions';
import requestAPI from '@/utils/requestAPI';
import { uploadFilesToS3OnlyKeys } from '@/utils/s3Upload';

import { InfoRowStyled, NewDocumentWrapper } from '../style';
import ProductValidationSchema from './validation';

type Props = {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  singleProduct?: Products['products'];
  productId?: string;
};

export interface InitialValueType {
  title: string;
  description: string;
  fullDescription?: string;
  specification?: Specification;
  price: number;
  vat: number;
  supplierId: {
    label: string;
    value: string;
  };
  publishedAt: string;
  externalId?: string;
  externalUrl?: string;
  stock: number;
  isDeliverable: boolean;
  productCategoryId?: string;
  productSubcategoryId?: string;
  featured?: boolean;
  goGreen?: boolean;
  archived?: boolean;
  benefits?: string;
  region?: string[];
  photos: File[] | string[];
  referralLink: string;
  video: string;
  type: string;
  prevId?: string;
}

export interface Specification {
  content: string;
  contentType: string;
}

const CreateProduct = ({ setIsModalOpen, singleProduct, productId }: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [initialValue, setInitialValue] = useState<InitialValueType>({
    title: '',
    description: '',
    specification: { contentType: 'HTML', content: '' },
    price: 0,
    vat: 0,
    supplierId: {
      label: '',
      value: '',
    },
    publishedAt: new Date().toISOString(),
    stock: 1,
    isDeliverable: false,
    region: [],
    photos: [],
    referralLink: '',
    video: '',
    type: 'SHOWROOM',
    featured: false,

    // status: 'completed',
  });

  const formikRef = useRef<FormikProps<InitialValueType>>(null);

  console.log('single product in create product', singleProduct);

  const getAllSuppliers = async (inputValue: string) => {
    try {
      const res = await requestAPI(
        suppliers.getSuppliers({
          limit: 100,
          search: inputValue,
        })
      );
      return res?.data.map((option) => ({
        label: option.name,
        value: option._id,
      }));
    } catch (error) {
      console.error(error.message);
      toast.error('Failed to fetch suppliers');
    }
  };

  const loadProfessionalOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    getAllSuppliers(inputValue).then((options) => {
      callback(options);
    });
  };

  useEffect(() => {
    if (productId && singleProduct) {
      setInitialValue({
        description: singleProduct.description,
        isDeliverable: singleProduct.isDeliverable,
        photos: singleProduct.photos.map((img) => img),
        price: singleProduct.price,
        publishedAt: singleProduct.publishedAt,
        referralLink: singleProduct.referralLink,
        stock: singleProduct.stock,
        supplierId: {
          label: singleProduct.supplierId.name,
          value: singleProduct.supplierId._id,
        },
        title: singleProduct.title,
        type: singleProduct.type,
        vat: singleProduct.vat,
        video: singleProduct.video,
        benefits: singleProduct.benefits,
        externalId: singleProduct.externalId,
        region: singleProduct.region,
        featured: singleProduct.featured || false,
        specification: {
          content: '',
          contentType: 'HTML',
        },
      });
    }
  }, [productId, singleProduct]);

  const handleAddProduct = async (values: InitialValueType) => {
    try {
      setLoading(true);
      const newPhotos = values.photos.filter(
        (photo): photo is File => photo instanceof File
      );
      const imgUrls = await uploadFilesToS3OnlyKeys(newPhotos);
      const existingPhotos = values.photos.filter(
        (photo): photo is string => typeof photo === 'string'
      );

      const allPhotos = [...existingPhotos, ...imgUrls];

      await requestAPI(
        products.addproduct({
          description: values.description,
          isDeliverable: values.isDeliverable,
          photos: allPhotos,
          price: values.price,
          publishedAt: new Date().toISOString(),
          region: values.region,
          specification: {
            contentType: 'HTML',
            content: values.specification?.content || '',
          },
          stock: values.stock,
          referralLink: values.referralLink,
          video: values.video,
          type: values.type || 'SHOWROOM',
          featured: values.featured,
          title: values.title,
          supplierId: values.supplierId.value,
          vat: values.vat,
        })
      );

      setLoading(false);
      dispatch(getAllProducts({}));
      setIsModalOpen(false);
      toast.success('Product Created Successfully');
    } catch (error: any) {
      setLoading(false);
      setApiError(error.message);
      console.error(error.message);
      toast.error(error.message);
    }
  };

  const handleUpdateProduct = async (values: InitialValueType) => {
    try {
      setLoading(true);
      const newPhotos =
        values?.photos.filter(
          (photo): photo is File => photo instanceof File
        ) || [];
      const existingPhotos =
        values?.photos.filter(
          (photo): photo is string => typeof photo === 'string'
        ) || [];

      const allPhotos =
        newPhotos.length > 0
          ? [...existingPhotos, ...(await uploadFilesToS3OnlyKeys(newPhotos))]
          : existingPhotos;

      await requestAPI(
        products.updateProduct(
          {
            description: values.description,
            isDeliverable: values.isDeliverable,
            photos: allPhotos,
            price: values.price,
            publishedAt: new Date().toISOString(),
            region: values.region,
            specification: {
              contentType: 'HTML',
              content: values.specification?.content || '',
            },
            stock: values.stock,
            referralLink: values.referralLink,
            video: values.video,
            type: values.type || 'SHOWROOM',
            title: values.title,
            featured: values.featured,

            supplierId: values.supplierId.value,
            vat: values.vat,
          },
          productId
        )
      );

      setLoading(false);
      dispatch(getSingleProductDetail(productId));
      setIsModalOpen(false);
      toast.success('Product Updated Successfully');
    } catch (error: any) {
      setLoading(false);
      setApiError(error.message);
      console.error(error.message);
      toast.error(error.message);
    }
  };

  return (
    <>
      <ModalContentWrapper>
        <Formik
          initialValues={initialValue}
          innerRef={formikRef}
          onSubmit={productId ? handleUpdateProduct : handleAddProduct}
          enableReinitialize
          validationSchema={ProductValidationSchema}
        >
          {({ values, setFieldValue, errors, touched }) => {
            console.log('errors', errors);

            return (
              <Form>
                <NewDocumentWrapper>
                  <FlexEndContainer>
                    <FieldArray name="region">
                      {({ push, remove }) => (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              gap: '10px',
                              flexWrap: 'wrap',
                            }}
                          >
                            {values?.region?.map((other, idx) => (
                              <RegionContainer key={idx}>
                                <CountrySelection
                                  selectedCountry={other}
                                  setSelectedCountry={async (val) => {
                                    setFieldValue(`region[${idx}]`, val);
                                  }}
                                />
                                <RemoveButton
                                  type="button"
                                  onClick={() => remove(idx)}
                                  style={{
                                    marginBottom: '10px',
                                  }}
                                >
                                  Remove
                                </RemoveButton>
                              </RegionContainer>
                            ))}
                          </div>
                          <AddButton
                            type="button"
                            isDisabled={
                              values.region.includes('ie') &&
                              values.region.includes('uk')
                            }
                            onClick={() => push('ie')}
                          >
                            Add Region
                          </AddButton>
                        </div>
                      )}
                    </FieldArray>
                    {typeof errors.region === 'string' &&
                      errors.region &&
                      touched.region && (
                        <div style={{ color: 'red' }}>{errors.region}</div>
                      )}
                  </FlexEndContainer>
                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Product Title<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="title"
                      value={values.title}
                      onChange={(e) => setFieldValue('title', e.target.value)}
                    />
                    {errors.title && touched.title && (
                      <div style={{ color: 'red' }}>{errors.title}</div>
                    )}
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Specification<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="specification.content"
                      value={values.specification.content}
                      onChange={(e) =>
                        setFieldValue('specification.content', e.target.value)
                      }
                    />

                    {errors.specification &&
                      typeof errors.specification === 'object' &&
                      (errors.specification.content ||
                        errors.specification.contentType) &&
                      touched.specification && (
                        <div style={{ color: 'red' }}>
                          {errors.specification.content ||
                            errors.specification.contentType}
                        </div>
                      )}
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Price (Amount)<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="price.amount"
                      type="number"
                      value={values.price}
                      onChange={(e) => setFieldValue('price', e.target.value)}
                    />
                    {errors.price && touched.price && (
                      <div style={{ color: 'red' }}>{errors.price}</div>
                    )}
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      VAT<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="vat"
                      type="number"
                      value={values.vat}
                      onChange={(e) =>
                        setFieldValue('vat', parseFloat(e.target.value))
                      }
                    />
                    {errors.vat && touched.vat && (
                      <div style={{ color: 'red' }}>{errors.vat}</div>
                    )}
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Supplier <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      value={values.supplierId}
                      loadOptions={loadProfessionalOptions}
                      onChange={(selected) => {
                        setFieldValue('supplierId', selected);
                      }}
                    />
                    {typeof errors.supplierId === 'string' &&
                      errors.supplierId &&
                      touched.supplierId && (
                        <div style={{ color: 'red' }}>{errors.supplierId}</div>
                      )}
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Stock<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="stock"
                      type="number"
                      value={values.stock}
                      onChange={(e) =>
                        setFieldValue('stock', parseInt(e.target.value, 10))
                      }
                    />
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Is Featured
                    </Typography>
                    <Switch
                      checked={values.featured}
                      onChange={(checked) => setFieldValue('featured', checked)}
                      label={values.featured ? 'Yes' : 'No'}
                    />
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Is Deliverable
                    </Typography>
                    <Input
                      name="isDeliverable"
                      type="checkbox"
                      checked={values.isDeliverable}
                      onChange={(e) =>
                        setFieldValue('isDeliverable', e.target.checked)
                      }
                    />
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Benefits
                    </Typography>
                    <Input
                      name="benefits"
                      value={values.benefits}
                      onChange={(e) =>
                        setFieldValue('benefits', e.target.value)
                      }
                    />
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Referral Link
                    </Typography>
                    <Input
                      name="referralLink"
                      value={values.referralLink}
                      onChange={(e) =>
                        setFieldValue('referralLink', e.target.value)
                      }
                    />
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Video
                    </Typography>
                    <Input
                      name="video"
                      value={values.video}
                      onChange={(e) => setFieldValue('video', e.target.value)}
                    />
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Type<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="type"
                      value={values.type}
                      onChange={(e) => setFieldValue('type', e.target.value)}
                    />
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      External ID
                    </Typography>
                    <Input
                      name="externalId"
                      value={values.externalId}
                      onChange={(e) =>
                        setFieldValue('externalId', e.target.value)
                      }
                    />
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography className="text">
                      Photos <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <UploadFile
                      name="photos"
                      title=""
                      enableMultipleFile
                      variant="previewImage"
                      onChange={(file) => {
                        if (Array.isArray(file)) {
                          // Prevent duplication by filtering out existing files
                          const newFiles = file.filter(
                            (newFile) =>
                              !values.photos.some(
                                (existingFile) =>
                                  existingFile instanceof File &&
                                  existingFile.name === newFile.name
                              )
                          );
                          setFieldValue('photos', [
                            ...values.photos,
                            ...newFiles,
                          ]);
                        }
                      }}
                      apiUrls={values.photos.filter(
                        (photo): photo is string => typeof photo === 'string'
                      )}
                      onRemove={(idx) => {
                        if (idx !== undefined) {
                          setFieldValue(
                            'photos',
                            values.photos.filter((_, i) => i !== idx)
                          );
                        }
                      }}
                    />
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Description<span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextEditor
                      initialHtml={values.description}
                      setEditorState={({ html }) =>
                        setFieldValue('description', html)
                      }
                    />
                    {errors.description && touched.description && (
                      <div style={{ color: 'red' }}>{errors.description}</div>
                    )}
                  </InfoRowStyled>
                </NewDocumentWrapper>
                {apiError && (
                  <div style={{ color: 'red', marginTop: '10px' }}>
                    {apiError}
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </ModalContentWrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          radius="md"
          variant="ghost"
          style={{
            border: `1px solid ${theme.colors.dark.neutral_250}`,
            borderRadius: '10px',
            color: theme.colors.text,
            fontFamily: theme.fontFamily.roboto,
            fontWeight: theme.fontWeights.medium,
          }}
          onClick={() => {
            if (setIsModalOpen) {
              setIsModalOpen(false);
            }
          }}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          isLoading={loading}
          radius="normal"
          onClick={() => formikRef.current?.handleSubmit()}
          style={{ backgroundColor: theme.colors.brandColorBlue }}
        >
          Save
          {/* {docId ? 'Update Construction Detail Category' : 'Save'} */}
        </Button>
      </div>
    </>
  );
};

export default CreateProduct;
