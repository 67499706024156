import styled from 'styled-components';

export const DeleteContainer = styled.div`
  max-width: 500px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: auto;

  .delete-header {
    display: flex;
    justify-content: space-between;
    padding: 11px 30px;
    background-color: ${({ theme }) => theme.colors.dark.neutral_25};
  }

  .body {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-direction: column;

    & .btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
    }
  }
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  outline: none;
`;
