import { TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useEffect, useState } from 'react';

import configs from '@/configs';
import requestAPI from '@/utils/requestAPI';

import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import TextInput from '../../ui/TextInput';

type Props = {
  onClose: () => void;
  editor: any;
  isOpen: boolean;
  selectedText: string;
  documentType: string;
};

const CustomLinkModal = ({
  onClose,
  editor,
  isOpen,
  selectedText,
  // documentType,
}: Props) => {
  const [url, setUrl] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [activeTab, setActiveTab] = useState<'documents' | 'download_center'>(
    'documents'
  );

  const apiURL = configs.apiURL;
  const debounceDelay = 300;

  useEffect(() => {
    setQuery('');
    setSearchResults([]);
  }, [selectedText]);

  const documentSearch = async (querys: string) => {
    setLoading(true);
    try {
      const response = await requestAPI({
        method: 'get',
        url: `${apiURL}/typesense/search/documents`,
        params: { query: querys },
      });
      setSearchResults(response);
    } catch (error) {
      console.error('Error fetching document search results:', error);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const searchAPI = async (search: string) => {
    setLoading(true);
    try {
      const response = await requestAPI({
        method: 'post',
        url: `${apiURL}/search`,
        data: { search, type: 'download_center' },
      });
      setSearchResults(response);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (query) {
        if (activeTab === 'documents') {
          documentSearch(query);
        } else {
          searchAPI(query);
        }
      } else {
        setSearchResults([]);
      }
    }, debounceDelay);

    return () => clearTimeout(timeout);
  }, [query, activeTab]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (url) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
    }
    setUrl('');
    onClose();
  };

  const handleResultClick = (result: any) => {
    const prefix = activeTab === 'documents' ? '/' : '/download-center-item';
    const slug = activeTab === 'documents' ? result?.fullSlug : result?.slug;
    setUrl(`${prefix}${slug}`);
    setQuery(result?.actualTitle);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal onClose={onClose} title="Add link">
      <form onSubmit={handleSubmit}>
        <div>
          <p
            style={{
              fontSize: '16px',
              marginBottom: '10px',
              fontWeight: '600',
            }}
          >
            Selected text
          </p>
          <TextInput
            label=""
            onChange={(value) => setUrl(value)}
            value={selectedText}
          />
          <p
            style={{
              fontSize: '16px',
              marginBottom: '10px',
              fontWeight: '600',
            }}
          >
            Search
          </p>
          <TextInput
            label=""
            onChange={(value) => setQuery(value)}
            value={query}
            placeholder="Enter Title"
          />
          {/* {searchResults.length > 0 && ( */}
          <div style={{ display: 'flex', marginTop: '10px' }}>
            <button
              type="button"
              onClick={() => setActiveTab('documents')}
              style={{
                flex: 1,
                padding: '10px',
                backgroundColor: activeTab === 'documents' ? '#ddd' : '#fff',
                border: '1px solid #ddd',
                cursor: 'pointer',
              }}
            >
              Documents
            </button>
            <button
              type="button"
              onClick={() => setActiveTab('download_center')}
              style={{
                flex: 1,
                padding: '10px',
                backgroundColor:
                  activeTab === 'download_center' ? '#ddd' : '#fff',
                border: '1px solid #ddd',
                cursor: 'pointer',
              }}
            >
              Download Center
            </button>
          </div>
          {/* )} */}
          {query && (
            <div
              style={{
                maxHeight: '150px',
                overflowY: 'auto',
                marginTop: '10px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                backgroundColor: 'white',
              }}
            >
              {loading ? (
                <div>Loading...</div>
              ) : (
                searchResults?.map((result, index) => {
                  const match = result?.fullSlug?.match(/documents\/(\d+)\//);
                  const documentNumber = match ? match[1] : '';

                  const matchDownloadCenterNumber =
                    result?.slug?.match(/\/(\d+)\//);
                  const downloadCenterNumber = matchDownloadCenterNumber
                    ? matchDownloadCenterNumber[1]
                    : '';

                  return (
                    <div
                      key={index}
                      style={{
                        padding: '8px',
                        cursor: 'pointer',
                        backgroundColor:
                          query === result.actualTitle ? '#f0f0f0' : 'white',
                      }}
                      onClick={() => handleResultClick(result)}
                    >
                      {activeTab === 'documents' ? (
                        <p>
                          {result?.actualTitle?.slice(0, 30)}... -{' '}
                          {documentNumber} - {result?.parent?.slice(0, 30)}... -{' '}
                          {result?.level1Title?.slice(0, 30)}...
                        </p>
                      ) : (
                        <p>
                          {result?.actualTitle?.slice(0, 100)} -{' '}
                          {downloadCenterNumber}
                        </p>
                      )}
                    </div>
                  );
                })
              )}
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          <Button
            onClick={() => {
              onClose();
            }}
            style={{
              borderRadius: '5px',
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            style={{
              borderRadius: '5px',
            }}
          >
            Apply
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CustomLinkModal;
