import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import tags from '@/apiConfigs/tag';
import DeleteModal from '@/components/DeleteModal';
import Button from '@/designComponents/Button';
import Loader from '@/designComponents/Loader';
import Typography from '@/designComponents/Typography';
import theme from '@/theme';
import requestAPI from '@/utils/requestAPI';

import AddTag from '../CreateTag';
import { AdvertiseDetailsWrapper } from './style';

type TagsDetailsType = {
  title: string;
  isGeneratedAutomatically?: boolean;
  category: {
    title: string;
  };
  subCategory: [
    {
      _id: string;
      title: string;
    },
  ];
  products: [
    {
      _id: string;
      title: string;
      photos: string[];
    },
  ];
};

const TagDetails = () => {
  const { tagId } = useParams();
  const [tag, setTag] = useState<Partial<TagsDetailsType>>({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const navigate = useNavigate();

  const getSingleTag = async (id: string) => {
    try {
      setIsFetchingData(true);
      const data = await requestAPI(tags.getSingleTag(id));
      setTag(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetchingData(false);
    }
  };

  useEffect(() => {
    if (tagId) {
      getSingleTag(tagId);
    }
  }, [tagId]);

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await requestAPI(tags.deleteTag(tagId || ''));
      toast.success(`${tag.title} is deleted successfully`);
      navigate('/tags');
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <AdvertiseDetailsWrapper>
      {!isFetchingData ? (
        <div className="main-content">
          <div className="heading">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                fontFamily="jost"
                fontWeight="medium"
                size="heading"
                color="dark.neutral_60"
              >
                Tags Details
              </Typography>
              {tag.isGeneratedAutomatically ? (
                <Button
                  variant="danger"
                  radius="normal"
                  onClick={() => setIsDeleteModalOpen(true)}
                >
                  Delete Tag
                </Button>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    gap: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="danger"
                    radius="normal"
                    onClick={() => setIsDeleteModalOpen(true)}
                  >
                    Delete Tag
                  </Button>
                  <Button
                    variant="primary"
                    radius="normal"
                    onClick={() => setIsEditModalOpen(true)}
                  >
                    Edit Tag
                  </Button>
                </div>
              )}

              <AddTag
                isOpenModal={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
              />

              {isDeleteModalOpen && (
                <DeleteModal
                  title="housebuild.com says"
                  onClose={() => setIsDeleteModalOpen(false)}
                  isOpen={isDeleteModalOpen}
                  deleteText={`Are you sure you want to delete ${tag?.title} ?`}
                  onConfirmDelete={handleDelete}
                  confirmBtnLoading={isDeleting}
                />
              )}
            </div>

            <hr
              style={{
                backgroundColor: theme.colors.dark.neutral_50,
                border: 'none',
                height: '1px',
              }}
            />
          </div>
          <div className="main-text">
            <div className="text">
              <Typography className="text-value">Tag Title</Typography>
              <Typography className="text-value">{tag?.title}</Typography>
            </div>

            <div className="text">
              <Typography className="text-value">Category Title</Typography>
              <Typography className="text-value">
                {tag?.category?.title}
              </Typography>
            </div>

            <div>
              <Typography
                size="h5"
                fontWeight="bold"
                style={{ marginBottom: '10px' }}
              >
                Sub Categories
              </Typography>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '20px' }}
              >
                {tag?.subCategory?.map((sub) => {
                  return (
                    <div key={sub._id}>
                      {' '}
                      <li>{sub.title}</li>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <Typography
                size="h5"
                fontWeight="bold"
                style={{ marginBottom: '10px' }}
              >
                Products
              </Typography>
              <div
                style={{
                  display: 'flex',
                  gap: '20px',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {tag.products?.map((product) => {
                  return (
                    <div
                      key={product?._id}
                      style={{
                        width: 130,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={product.photos[0]}
                        alt={`${product.title}`}
                        width={120}
                        style={{
                          objectFit: 'cover',
                          borderRadius: 5,
                        }}
                        height={100}
                      />
                      <Typography>
                        {product.title.substring(0, 30) + '...'}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '500px',
            width: '100%',
          }}
        >
          <Loader
            style={{
              height: 60,
              width: 60,
            }}
          />
        </div>
      )}
    </AdvertiseDetailsWrapper>
  );
};

export default TagDetails;
