import { ConfigType } from '@/utils/requestAPI';

export type PaginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
  filter?: {
    type: string;
  };
};

const forms = {
  getAllForms: (params?: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/form',
      params,
    };
  },

  getSingleForm: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/form/${id}`,
    };
  },
};

export default forms;
