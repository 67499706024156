import React from 'react';

import PrivateLayout from '@/layout/PrivateLayout';
import PublicLayout from '@/layout/PublicLayout';
import {
  BuildingJourney,
  Dashboard,
  Documents,
  Ecommerce,
  IdeasAndStories,
  Login,
  Professionals,
  Showrooms,
  Users,
} from '@/pages';
import Advirtisement from '@/pages/Advertisement';
import Advertise from '@/pages/Advertisement/Advertise';
import AdvertisementDetails from '@/pages/Advertisement/Advertise/Details';
import AdvertiseDetails from '@/pages/Advertisement/Details';
import BuildingJourneyDetails from '@/pages/BuildingJourney/BuildingJourneyDetails';
import BuildingStepsDetailsPage from '@/pages/BuildingJourney/BuildingStep/Details';
import UpdatePassword from '@/pages/ChangePassword';
import CouponsMainPage from '@/pages/Coupons';
import CouponsDetailPage from '@/pages/Coupons/Details';
import DocumentDetails from '@/pages/Documents/Details';
import Reposition from '@/pages/Documents/Reposition';
import DownloadCenter from '@/pages/DownloadCenter';
import DownloadCenterDetails from '@/pages/DownloadCenter/Details';
import FolderDetails from '@/pages/DownloadCenter/DownloadGroup/Details';
import ResourcesDetails from '@/pages/DownloadCenter/DownloadResource/Details';
import ProductView from '@/pages/Ecommerce/Details';
import ForgotPassword from '@/pages/ForgotPassword';
import Forms from '@/pages/Forms';
import FormDetails from '@/pages/Forms/Detail';
import InteractiveSpreadSheet from '@/pages/Interactive';
import Jobs from '@/pages/Jobs';
import JobsDetails from '@/pages/Jobs/Details';
import ProfessionalDetails from '@/pages/Professionals/Details';
import Profile from '@/pages/Profile';
import Settings from '@/pages/Setting';
import AdminUsers from '@/pages/Setup/AdminUsers';
import Cities from '@/pages/Setup/Cities';
import ExternalProductMapper from '@/pages/Setup/ExternalProductMapper';
import IdeaCategories from '@/pages/Setup/IdeaCategories';
import VideoCategories from '@/pages/Setup/VideoCategories';
import ShowroomsDetail from '@/pages/Showrooms/Details';
import Stories from '@/pages/Stories';
import StoryDetailPage from '@/pages/Stories/Details';
import Tags from '@/pages/Tags';
import TagDetails from '@/pages/Tags/Details';
import UsersDetails from '@/pages/Users/Details';
import AccountDeleteion from '@/pages/Users/Details/AccountDeletionRequest';
import FreeUser from '@/pages/Users/Details/FreeUser';
import NewLetterUser from '@/pages/Users/Details/NewsLetterUserDetails';
import { default as ProfessionalUsers } from '@/pages/Users/Details/ProfessionalUsers';
import SubscribedUser from '@/pages/Users/Details/SubscribedUser';
import AnalyticUser from '@/pages/Users/Details/UserAnalytic';
import Videos from '@/pages/Videos';
import HowToVideosDetails from '@/pages/Videos/HowToVideos/Details';
import HowToVideosCategoryDetails from '@/pages/Videos/HowToVideosCategory/Details';
import HowToVideosCategoryClassDetails from '@/pages/Videos/HowToVideosCategoryClass/Details';

import {
  ADVERTISE_DETAILS_ROUTE,
  ADVERTISE_ROUTE,
  ADVERTISEMENT_DETAILS_ROUTE,
  ADVERTISEMENT_ROUTE,
  ANALYTIC_USER_DETAILS_ROUTE,
  BUILDING_JOURNEY_DETAILS_ROUTE,
  BUILDING_ROUTE,
  BUILDING_STEP_ROUTE,
  COUPONS_DETAILS_ROUTE,
  COUPONS_ROUTE,
  DASHBOARD_ROUTE,
  DELETE_USER_DETAILS_ROUTE,
  DOCUMENT_DETAILS_ROUTE,
  DOCUMENTS_ROUTE,
  DOWNLOAD_CENTER_DETAIL_ROUTE,
  DOWNLOAD_CENTER_FOLDER_DETAIL_ROUTE,
  DOWNLOAD_CENTER_FOLDER_ROUTE,
  DOWNLOAD_CENTER_RESOURCE_DETAIL_ROUTE,
  DOWNLOAD_CENTER_RESOURCE_ROUTE,
  DOWNLOAD_CENTER_ROUTE,
  DOWNLOAD_CENTER_TYPE_ROUTE,
  ECOMMERCE_DETAILS_ROUTE,
  ECOMMERCE_ROUTE,
  EXTERNAL_PRODUCT_MAPPER_ROUTE,
  FEATURED_VIDEOS_CATEGORY_CLASS_ROUTE,
  FEATURED_VIDEOS_CATEGORY_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  FORMS_DETAILS_ROUTE,
  FORMS_ROUTE,
  FREE_USER_DETAILS_ROUTE,
  HOW_TO_VIDEOS_CATEGORY_ROUTE,
  HOW_TO_VIDEOS_CLASS_ROUTE,
  HOW_TO_VIDEOS_DETAILS_ROUTE,
  IDEAS_ROUTE,
  INTERACTIVE_ROUTE,
  JOBS_DETAILS_ROUTE,
  JOBS_ROUTE,
  LOGIN_ROUTE,
  NEWSLETTER_USER_DETAILS_ROUTE,
  PROFESSIONAL_DETAILS,
  PROFFESIONAL_ROUTE,
  PROFFESIONAL_USER_DETAILS_ROUTE,
  PROFILE_ROUTE,
  REPOSITION_ROUTE,
  SETTING_ROUTE,
  SETUP_ADMIN_USERS_ROUTE,
  SETUP_CITIES_ROUTE,
  SETUP_HASHTAGS_ROUTE,
  SETUP_IDEA_CATEGORIES_ROUTE,
  SETUP_VIDEO_CATEGORIES_ROUTE,
  SHOWROOM_DETAILS_ROUTE,
  SHOWROOM_ROUTE,
  STORIES_DETAILS_ROUTE,
  STORIES_ROUTE,
  SUBSCRIBED_USER_DETAILS_ROUTE,
  TAGS_DETAILS_ROUTE,
  TAGS_ROUTE,
  UPDATE_PASSWORD_ROUTE,
  USER_DETAILS_ROUTE,
  // USER_DETAILS_ROUTE,
  USERS_ROUTE,
  VIDEOS_ROUTE,
} from './routePaths';

const routes: IRoutes[] = [
  {
    id: 100,
    path: LOGIN_ROUTE,
    component: Login,
    layout: PublicLayout,
    isProtected: false,
    redirectToOnAuth: DASHBOARD_ROUTE,
  },
  {
    id: 101,
    path: UPDATE_PASSWORD_ROUTE,
    component: UpdatePassword,
    layout: PublicLayout,
    isProtected: false,
  },
  {
    id: 200,
    path: DASHBOARD_ROUTE,
    component: Dashboard,
    layout: PrivateLayout,
    isProtected: true,
  },
  {
    id: 300,
    path: DOCUMENTS_ROUTE,
    component: Documents,
    layout: PrivateLayout,
    isProtected: true,
    children: [
      {
        id: 301,
        path: DOCUMENT_DETAILS_ROUTE,
        component: DocumentDetails,
      },
      {
        id: 302,
        path: REPOSITION_ROUTE,
        component: Reposition,
      },
    ],
  },
  {
    id: 400,
    path: VIDEOS_ROUTE,
    component: Videos,
    layout: PrivateLayout,
    isProtected: true,
    children: [
      {
        id: 401,
        path: HOW_TO_VIDEOS_CATEGORY_ROUTE,
        component: Videos,
        children: [
          {
            id: 4011,
            path: HOW_TO_VIDEOS_DETAILS_ROUTE,
            component: HowToVideosCategoryDetails,
          },
        ],
      },
      {
        id: 402,
        path: FEATURED_VIDEOS_CATEGORY_ROUTE,
        component: Videos,
      },
      {
        id: 403,
        path: HOW_TO_VIDEOS_CLASS_ROUTE,
        component: Videos,
        children: [
          {
            id: 4031,
            path: HOW_TO_VIDEOS_DETAILS_ROUTE,
            component: HowToVideosCategoryClassDetails,
          },
        ],
      },
      {
        id: 404,
        path: FEATURED_VIDEOS_CATEGORY_CLASS_ROUTE,
        component: Videos,
      },
      {
        id: 405,
        path: HOW_TO_VIDEOS_DETAILS_ROUTE,
        component: HowToVideosDetails,
      },
    ],
  },

  {
    id: 500,
    path: USERS_ROUTE,
    component: Users,
    layout: PrivateLayout,
    isProtected: true,
    children: [
      {
        id: 501,
        path: PROFFESIONAL_USER_DETAILS_ROUTE,
        component: ProfessionalUsers,
      },
      {
        id: 502,
        path: DELETE_USER_DETAILS_ROUTE,
        component: AccountDeleteion,
      },
      {
        id: 503,
        path: NEWSLETTER_USER_DETAILS_ROUTE,
        component: NewLetterUser,
      },
      {
        id: 504,
        path: ANALYTIC_USER_DETAILS_ROUTE,
        component: AnalyticUser,
      },
      {
        id: 505,
        path: SUBSCRIBED_USER_DETAILS_ROUTE,
        component: SubscribedUser,
      },
      {
        id: 506,
        path: FREE_USER_DETAILS_ROUTE,
        component: FreeUser,
      },
      {
        id: 507,
        path: USER_DETAILS_ROUTE,
        component: UsersDetails,
      },
    ],
  },
  {
    id: 600,
    path: ADVERTISEMENT_ROUTE,
    component: Advirtisement,
    layout: PrivateLayout,
    isProtected: true,
    children: [
      {
        id: 601,
        path: ADVERTISE_DETAILS_ROUTE,
        component: AdvertiseDetails,
      },
      {
        id: 602,
        path: ADVERTISE_ROUTE,
        component: Advertise,
        children: [
          {
            id: 6002,
            path: ADVERTISEMENT_DETAILS_ROUTE,
            component: AdvertisementDetails,
          },
        ],
      },
    ],
  },
  {
    id: 700,
    path: BUILDING_ROUTE,
    component: BuildingJourney,
    layout: PrivateLayout,
    isProtected: true,
    children: [
      {
        id: 7001,
        path: BUILDING_JOURNEY_DETAILS_ROUTE,
        component: BuildingJourneyDetails,
      },
      {
        id: 7002,
        path: BUILDING_STEP_ROUTE,
        component: BuildingJourney,
        children: [
          {
            id: 70021,
            path: BUILDING_JOURNEY_DETAILS_ROUTE,
            component: BuildingStepsDetailsPage,
          },
        ],
      },
    ],
  },
  {
    id: 800,
    path: IDEAS_ROUTE,
    component: IdeasAndStories,
    layout: PrivateLayout,
    isProtected: true,
  },
  {
    id: 900,
    path: SETTING_ROUTE,
    component: Settings,
    layout: PrivateLayout,
    isProtected: true,
  },
  {
    id: 900,
    path: ECOMMERCE_ROUTE,
    component: Ecommerce,
    layout: PrivateLayout,
    isProtected: true,
    children: [
      {
        id: 901,
        path: ECOMMERCE_DETAILS_ROUTE,
        component: ProductView,
      },
    ],
  },
  {
    id: 900,
    path: INTERACTIVE_ROUTE,
    component: InteractiveSpreadSheet,
    layout: PrivateLayout,
    isProtected: true,
  },
  {
    id: 900,
    path: PROFFESIONAL_ROUTE,
    component: Professionals,
    layout: PrivateLayout,
    isProtected: true,
    children: [
      {
        id: 901,
        path: PROFESSIONAL_DETAILS,
        component: ProfessionalDetails,
      },
    ],
  },
  {
    id: 900,
    path: SHOWROOM_ROUTE,
    component: Showrooms,
    layout: PrivateLayout,
    isProtected: true,
    children: [
      {
        id: 901,
        path: SHOWROOM_DETAILS_ROUTE,
        component: ShowroomsDetail,
      },
    ],
  },
  {
    id: 1000,
    path: FORGOT_PASSWORD_ROUTE,
    component: ForgotPassword,
    layout: PublicLayout,
    isProtected: true,
  },
  {
    id: 1100,
    path: DOWNLOAD_CENTER_ROUTE,
    component: DownloadCenter,
    layout: PrivateLayout,
    isProtected: true,
    children: [
      {
        id: 1101,
        path: DOWNLOAD_CENTER_DETAIL_ROUTE,
        component: DownloadCenterDetails,
      },
      {
        id: 1102,
        path: DOWNLOAD_CENTER_FOLDER_ROUTE,
        component: DownloadCenter,
        children: [
          {
            id: 11021,
            path: DOWNLOAD_CENTER_FOLDER_DETAIL_ROUTE,
            component: FolderDetails,
          },
        ],
      },
      {
        id: 1103,
        path: DOWNLOAD_CENTER_RESOURCE_ROUTE,
        component: DownloadCenter,
        children: [
          {
            id: 11031,
            path: DOWNLOAD_CENTER_RESOURCE_DETAIL_ROUTE,
            component: ResourcesDetails,
          },
        ],
      },
      {
        id: 1104,
        path: DOWNLOAD_CENTER_TYPE_ROUTE,
        component: DownloadCenter,
      },
      {
        id: 1105,
        path: DOWNLOAD_CENTER_FOLDER_DETAIL_ROUTE,
        component: FolderDetails,
      },
    ],
  },
  {
    id: 1200,
    path: SETUP_CITIES_ROUTE,
    component: Cities,
    layout: PrivateLayout,
    isProtected: true,
  },
  {
    id: 1201,
    path: SETUP_ADMIN_USERS_ROUTE,
    component: AdminUsers,
    layout: PrivateLayout,
    isProtected: true,
  },
  {
    id: 1202,
    path: SETUP_HASHTAGS_ROUTE,
    component: Cities,
    layout: PrivateLayout,
    isProtected: true,
  },
  {
    id: 1203,
    path: SETUP_IDEA_CATEGORIES_ROUTE,
    component: IdeaCategories,
    layout: PrivateLayout,
    isProtected: true,
  },
  {
    id: 1204,
    path: SETUP_VIDEO_CATEGORIES_ROUTE,
    component: VideoCategories,
    layout: PrivateLayout,
    isProtected: true,
  },
  {
    id: 1205,
    path: PROFILE_ROUTE,
    component: Profile,
    layout: PrivateLayout,
    isProtected: true,
  },
  {
    id: 1206,
    path: STORIES_ROUTE,
    component: Stories,
    layout: PrivateLayout,
    children: [
      {
        id: 12061,
        path: STORIES_DETAILS_ROUTE,
        component: StoryDetailPage,
      },
    ],
  },
  {
    id: 1207,
    path: FORMS_ROUTE,
    component: Forms,
    layout: PrivateLayout,
    children: [
      {
        id: 12071,
        path: FORMS_DETAILS_ROUTE,
        component: FormDetails,
      },
    ],
  },

  {
    id: 1208,
    path: JOBS_ROUTE,
    component: Jobs,
    layout: PrivateLayout,
    children: [
      {
        id: 12081,
        path: JOBS_DETAILS_ROUTE,
        component: JobsDetails,
      },
    ],
  },
  {
    id: 1209,
    path: EXTERNAL_PRODUCT_MAPPER_ROUTE,
    component: ExternalProductMapper,
    layout: PrivateLayout,
  },
  {
    id: 1210,
    path: TAGS_ROUTE,
    component: Tags,
    layout: PrivateLayout,
    children: [
      {
        id: 12101,
        path: TAGS_DETAILS_ROUTE,
        component: TagDetails,
      },
    ],
  },
  {
    id: 1301,
    path: COUPONS_ROUTE,
    component: CouponsMainPage,
    layout: PrivateLayout,
    children: [
      {
        id: 13101,
        path: COUPONS_DETAILS_ROUTE,
        component: CouponsDetailPage,
      },
    ],
  },
];
export default routes;

export interface IRoutes {
  id: number;
  path: string;
  component: React.FC;
  layout: React.FC<React.PropsWithChildren<{ extraInfo?: any }>>;
  isProtected?: boolean;
  redirectToOnAuth?: string;
  role?: string[];
  restrictTo?: string[];
  allowTo?: string[];
  children?: Array<IChildren>;
  extraInfo?: any;
}

export interface IChildren {
  id: number;
  path: string;
  component: React.FC;
  children?: Array<IChildren>;
}
