import styled from 'styled-components';

import IrelandFlagSVG from '@/assets/Ireland.svg';
import UKFlagSVG from '@/assets/united-kingdom.svg';
import Select from '@/designComponents/Select';

type Props = {
  selectedCountry: string;
  setSelectedCountry: (value: string) => void;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  padding: 0 4px;
`;

const FlagImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid #ddd;
  object-fit: cover;
`;

const CountrySelection = ({ selectedCountry, setSelectedCountry }: Props) => {
  const dropDownOption = [
    { label: 'UK', value: 'uk', logo: UKFlagSVG },
    { label: 'Ireland', value: 'ie', logo: IrelandFlagSVG },
  ];

  const handleCountryChange = (value: string | number) => {
    setSelectedCountry(value as string);
  };

  return (
    <Container>
      <FlagImg
        src={
          dropDownOption.find((item) => item.value === selectedCountry)?.logo
        }
        alt="country-flag"
      />
      <Select
        options={dropDownOption}
        name="country"
        placeholder="Select Country"
        selected={selectedCountry}
        onSelect={handleCountryChange}
        isHideBorder
        isReduceIconGap
      />
    </Container>
  );
};

export default CountrySelection;
