/* eslint-disable */

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $insertNodes,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
  LexicalEditor,
} from 'lexical';
import React, { useCallback, useEffect, useState } from 'react';

import SelectInput from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';

import Button from '../../ui/Button';
import TextInput from '../../ui/TextInput';
import { $createProductNode } from '../../nodes/DeepTagNode';
import requestAPI from '@/utils/requestAPI';
import products from '@/apiConfigs/products';
import AsyncSelect from 'react-select/async';
type ProductType = 'SHOWROOM' | 'CONSTRUCTION' | 'BUILDING_MATERIAL';

type ProductDetails = {
  type: ProductType;
  keyword: string;
  productId: string;
};

type Option = {
  value: string | number;
  label: string;
};

export type InsertProductCommandPayload = Readonly<ProductDetails>;

export const INSERT_PRODUCT_COMMAND: LexicalCommand<InsertProductCommandPayload> =
  createCommand('INSERT_PRODUCT_COMMAND');

const productTypes: Option[] = [
  { label: 'BUILDING_MATERIAL', value: 'BUILDING_MATERIAL' },
  { label: 'CONSTRUCTION', value: 'CONSTRUCTION' },
  { label: 'SHOWROOM', value: 'SHOWROOM' },
];

export function InsertProductDialog({
  activeEditor,
  onClose,
  initialKeyword,
}: {
  activeEditor: LexicalEditor;
  onClose: () => void;
  initialKeyword: string;
}): JSX.Element {
  const [selectedType, setSelectedType] = useState<ProductType | ''>(
    'BUILDING_MATERIAL'
  );
  const [keyword, setKeyword] = useState(initialKeyword);
  // const [selectedProductId, setSelectedProductId] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [product, setProduct] = useState([]);

  const handleTypeChange = async (value: ProductType) => {
    setSelectedType(value as ProductType);
    setSelectedProduct(null);
    const response = await requestAPI(products.getAllProduct({ type: value }));
    console.log({ response });

    setProduct(response.products.data);
  };

  const handleProductChange = async (selectedOption) => {
    setSelectedProduct(selectedOption);
  };

  const loadProductOptions = (text: string, cb) => {
    // if (!selectedType) return [];

    cb(
      product?.map((p) => ({
        label: p.title,
        value: p._id,
      }))
    );
    // .filter((option) =>
    //   option.label.toLowerCase().includes(inputValue.toLowerCase())
    // );
  };

  const onClick = () => {
    activeEditor.dispatchCommand(INSERT_PRODUCT_COMMAND, {
      type: selectedType,
      keyword,
      productId: selectedProduct.value,
    });
    onClose();
  };

  useEffect(() => {
    if (selectedType) {
      handleTypeChange(selectedType);
    }
  }, [selectedType]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
      <div>
        <Typography>
          Type<span style={{ color: 'red' }}>*</span>
        </Typography>
        <SelectInput
          options={productTypes}
          placeholder="Select Product Type"
          onSelect={handleTypeChange}
          selected={selectedType}
        />
      </div>
      {/* <div>
        <Typography>
          Keyword<span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextInput
          label=""
          onChange={(e) => setKeyword(e)}
          value={keyword}
          placeholder="Enter keyword"
        />
      </div> */}
      <div>
        <Typography>
          Product<span style={{ color: 'red' }}>*</span>
        </Typography>
        <AsyncSelect
          cacheOptions
          defaultOptions={product?.map((p) => ({
            label: p?.title,
            value: p?._id,
          }))}
          loadOptions={loadProductOptions}
          onChange={handleProductChange}
          value={selectedProduct}
          isDisabled={!selectedType}
          placeholder="Select Product"
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={onClick}
          disabled={!selectedType || !keyword || !selectedProduct}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export function ProductPlugin(): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedText, setSelectedText] = useState('');

  useEffect(() => {
    return editor.registerCommand<InsertProductCommandPayload>(
      INSERT_PRODUCT_COMMAND,
      (payload) => {
        editor.update(() => {
          const productNode = $createProductNode(payload);
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            selection.removeText();
            selection.insertNodes([productNode]);
          } else {
            $insertNodes([productNode]);
          }
        });
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  const handleOpenDialog = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const selectedText = selection.getTextContent();
      if (selectedText.trim()) {
        setSelectedText(selectedText.trim());
        setShowDialog(true);
      }
    }
  }, [editor]);

  return (
    <>
      {/* <Button onClick={handleOpenDialog}>Insert Product Tag</Button>
      {showDialog && (
        <InsertProductDialog
          activeEditor={editor}
          onClose={() => setShowDialog(false)}
          initialKeyword={selectedText}
        />
      )} */}
    </>
  );
}
