import 'react-datepicker/dist/react-datepicker.css';

import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as Yup from 'yup';

import advertise from '@/apiConfigs/ads';
import CountrySelection from '@/components/CountrySelection';
import Button from '@/designComponents/Button';
import { ComponentsProps } from '@/designComponents/DropdownButton';
import Input from '@/designComponents/Input';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import UploadFile from '@/designComponents/UploadFile';
import { WrapperStyled } from '@/pages/Documents/CreateDocumentGroup/style';
import requestAPI from '@/utils/requestAPI';
import { uploadFilesToS3OnlyKeys } from '@/utils/s3Upload';

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.dark.neutral_20};
  padding: 10px 20px;
  border-radius: 5px;
`;

interface AdsValues {
  name?: string;
  advertiser?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  url?: string;
  contentType?: string;
  status?: string;
  region?: string;
  type?: string;
  content?: File | null | string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  advertiser: Yup.string().required('Advertiser Id is required'),
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date().required('End Date is required'),
  content: Yup.string().required('Content is required'),
  contentType: Yup.string().required('Content Type is required'),
  // type: Yup.string().required('Type is required'),
});

type Props = {
  onClose: () => void;
};

const CreateAds = ({ setIsModalOpen, onClose }: Props & ComponentsProps) => {
  const { adsId } = useParams<{ adsId: string }>();
  const [loading, setLoading] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const [apiError, setApiError] = useState('');
  const [ads, setAds] = useState<AdsValues>({});

  const [initialValues, setInitialValues] = useState<AdsValues>({
    name: '',
    advertiser: '',
    startDate: null,
    endDate: null,
    status: '',
    content: null,
    contentType: '',
    type: '',
    url: '',
    region: 'ie',
  });

  const getSingleAd = async (id: string) => {
    try {
      const data = await requestAPI(advertise.getSingleAds(id));
      setAds(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (adsId) {
      getSingleAd(adsId);
    }
  }, [adsId]);

  const getAllAdvertisers = async () => {
    const data = await requestAPI(advertise.getAllAdvertiser({}));
    setAdvertisers(data.data);
  };

  useEffect(() => {
    getAllAdvertisers();
  }, []);

  useEffect(() => {
    if (adsId && ads) {
      setInitialValues({
        name: ads.name,
        advertiser: ads.advertiser?._id,
        startDate: ads.startDate,
        endDate: ads.endDate,
        status: ads.status,
        content: ads.content,
        contentType: ads.contentType,
        type: ads.type,
        url: ads.url,
        region: ads?.region?.[0] || 'ie',
      });
    }
  }, [adsId, ads]);

  const handleAdsAdd = async (values: AdsValues) => {
    setLoading(true);

    let iconUrl = '';
    if (values.content) {
      const uploadedFiles = await uploadFilesToS3OnlyKeys([values.content]);
      if (uploadedFiles.length > 0) {
        iconUrl = uploadedFiles[0];
      }
    }

    try {
      await requestAPI(
        advertise.addAds({
          name: values.name,
          advertiser: values.advertiser,
          startDate: values.startDate,
          endDate: values.endDate,
          url: values.url,
          contentType: values.contentType,
          region: [values.region],

          status: 'active',
          type: values.type,
          content: iconUrl,
        })
      );
      setLoading(false);
      setIsModalOpen(false);
      toast.success('Ad added successfully');
    } catch (e: any) {
      setApiError(e?.message);
      toast.error(e.message);
      setLoading(false);
    }
  };

  console.log(apiError, 'api error');

  const handleUpdateAd = async (val: AdsValues) => {
    setLoading(true);
    try {
      let iconUrl = val.content;
      if (val.content instanceof File) {
        const uploadedFiles = await uploadFilesToS3OnlyKeys([val.content]);
        if (uploadedFiles.length > 0) {
          iconUrl = uploadedFiles[0];
        }
      }
      await requestAPI(
        advertise.updateAds(adsId, {
          name: val.name,
          advertiser: val.advertiser,
          startDate: val.startDate,
          endDate: val.endDate,
          url: val.url,
          contentType: val.contentType,
          region: [val.region],
          status: 'active',
          type: val.type,
          content: iconUrl as string,
        })
      );
      setLoading(false);
      onClose();
      toast.success('Ad Updated successfully');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <ModalContentWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={adsId ? handleUpdateAd : handleAdsAdd}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, touched, errors }) => {
            return (
              <Form>
                <WrapperStyled>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      maxWidth: '200px',
                    }}
                  >
                    <CountrySelection
                      selectedCountry={values.region}
                      setSelectedCountry={(selected) => {
                        setFieldValue('region', selected);
                      }}
                    />
                  </div>
                  <div className="container">
                    <Typography fontWeight="medium">Name</Typography>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                    />
                    {errors.name && touched.name ? (
                      <div className="error">{errors.name}</div>
                    ) : null}
                  </div>
                  <div className="container">
                    <Typography fontWeight="medium">Advertiser</Typography>
                    <Select
                      name="advertiser"
                      placeholder="Select advertiser"
                      options={[
                        ...(advertisers?.map((advertiser: any) => ({
                          label: advertiser?.fullName,
                          value: advertiser?._id,
                        })) || []),
                      ]}
                      selected={values.advertiser}
                      onSelect={(value) => setFieldValue('advertiser', value)}
                    />
                    {errors.advertiser && touched.advertiser ? (
                      <div className="error">{errors.advertiser}</div>
                    ) : null}
                  </div>
                  <div className="container">
                    <Typography fontWeight="medium">Start Date</Typography>
                    <StyledDatePicker
                      selected={values.startDate}
                      onChange={(date) => setFieldValue('startDate', date)}
                      dateFormat="yyyy/MM/dd"
                      className="input"
                    />
                    {errors.startDate && touched.startDate ? (
                      <div className="error">{errors.startDate}</div>
                    ) : null}
                  </div>
                  <div className="container">
                    <Typography fontWeight="medium">End Date</Typography>
                    <StyledDatePicker
                      selected={values.endDate}
                      onChange={(date) => setFieldValue('endDate', date)}
                      dateFormat="yyyy/MM/dd"
                      className="input"
                    />
                    {errors.endDate && touched.endDate ? (
                      <div className="error">{errors.endDate}</div>
                    ) : null}
                  </div>
                  <div className="container">
                    <Typography fontWeight="medium">Url</Typography>
                    <Input
                      name="url"
                      value={values.url}
                      onChange={(e) => setFieldValue('url', e.target.value)}
                    />
                    {errors.content && touched.content ? (
                      <div className="error">{errors.content}</div>
                    ) : null}
                  </div>
                  <div className="container">
                    <Typography fontWeight="medium">Content Type</Typography>
                    <Select
                      name="contentType"
                      placeholder="Select content type"
                      options={[
                        { label: 'GIF', value: 'gif' },
                        { label: 'Image', value: 'image' },
                        { label: 'Video', value: 'video' },
                      ]}
                      selected={values.contentType}
                      onSelect={(value) => setFieldValue('contentType', value)}
                    />
                    {errors.contentType && touched.contentType ? (
                      <div className="error">{errors.contentType}</div>
                    ) : null}
                  </div>
                  <div className="container">
                    <Typography fontWeight="medium">Type</Typography>
                    <Select
                      name="type"
                      placeholder="Select type"
                      options={[
                        { label: 'Billboard', value: 'BILLBOARD' },
                        { label: 'Leader Board', value: 'LEADER_BOARD' },
                        { label: 'MPU', value: 'MPU' },
                        { label: 'Half Page', value: 'HALF_PAGE' },
                      ]}
                      selected={values.type}
                      onSelect={(value) => setFieldValue('type', value)}
                    />
                    {errors.type && touched.type ? (
                      <div className="error">{errors.type}</div>
                    ) : null}
                  </div>
                  <div className="container">
                    <Typography fontWeight="medium">Upload Image</Typography>
                    <UploadFile
                      title=""
                      name="content"
                      onChange={(file) => setFieldValue('content', file)}
                      variant="previewImage"
                      apiUrls={
                        values.content && typeof values.content === 'string'
                          ? [values.content]
                          : []
                      }
                      onRemove={() => {
                        setFieldValue('content', null);
                      }}
                    />
                  </div>
                  {apiError && (
                    <div style={{ color: 'red', marginTop: '10px' }}>
                      {apiError}
                    </div>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="submit" isLoading={loading}>
                      {adsId ? 'Update' : 'Create'}
                    </Button>
                  </div>
                </WrapperStyled>
              </Form>
            );
          }}
        </Formik>
      </ModalContentWrapper>
    </>
  );
};

export default CreateAds;
