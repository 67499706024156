export const LOGIN_ROUTE = '/login';
export const DASHBOARD_ROUTE = '/';
export const DOCUMENTS_ROUTE = '/documents';
export const VIDEOS_ROUTE = '/videos';
export const USERS_ROUTE = '/users';
export const ADVERTISEMENT_ROUTE = '/ads';
export const ADVERTISE_DETAILS_ROUTE = ':adsId';
export const ADVERTISE_ROUTE = 'advertiser';
export const ADVERTISEMENT_DETAILS_ROUTE = ':advertiseId';
export const BUILDING_ROUTE = '/building-journey';
export const BUILDING_JOURNEY_DETAILS_ROUTE = ':journeyId';
export const BUILDING_STEP_ROUTE = 'building-steps';
export const IDEAS_ROUTE = '/ideas';
export const SETTING_ROUTE = '/setting';
export const ECOMMERCE_ROUTE = '/ecommerce';
export const INTERACTIVE_ROUTE = '/interactive';
export const PROFFESIONAL_ROUTE = '/professions';
export const PROFESSIONAL_DETAILS = ':professionalId';
export const SHOWROOM_ROUTE = '/showrooms';
export const SHOWROOM_DETAILS_ROUTE = ':showroomId';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const DOCUMENT_DETAILS_ROUTE = ':documentId';

export const USER_DETAILS_ROUTE = ':userId';
export const ECOMMERCE_DETAILS_ROUTE = ':productId';

export const PROFFESIONAL_USER_DETAILS_ROUTE = 'professional/:userId';
export const DELETE_USER_DETAILS_ROUTE = 'deleteUser/:userId';
export const NEWSLETTER_USER_DETAILS_ROUTE = 'newsLetter/:userId';
export const ANALYTIC_USER_DETAILS_ROUTE = 'analytic/:userId';
export const SUBSCRIBED_USER_DETAILS_ROUTE = 'subscribe/:userId';
export const FREE_USER_DETAILS_ROUTE = 'free/:userId';

export const UPDATE_PASSWORD_ROUTE = '/setPassword';

export const DOWNLOAD_CENTER_ROUTE = '/download';
export const DOWNLOAD_CENTER_FOLDER_ROUTE = 'folders';
export const DOWNLOAD_CENTER_FOLDER_DETAIL_ROUTE = ':folderId';
export const DOWNLOAD_CENTER_RESOURCE_ROUTE = 'resource';
export const DOWNLOAD_CENTER_RESOURCE_DETAIL_ROUTE = ':resourceId';
export const DOWNLOAD_CENTER_TYPE_ROUTE = 'type';
export const DOWNLOAD_CENTER_DETAIL_ROUTE = ':downloadId';
export const REPOSITION_ROUTE = 'reposition';

export const HOW_TO_VIDEOS_ROUTE = 'how-to-videos';
export const HOW_TO_VIDEOS_CATEGORY_ROUTE = 'videos-category';
export const FEATURED_VIDEOS_CATEGORY_ROUTE = 'featured-videos-category';
export const HOW_TO_VIDEOS_CLASS_ROUTE = 'how-to-videos-class';
export const FEATURED_VIDEOS_CATEGORY_CLASS_ROUTE =
  'featured-videos-category-class';
export const HOW_TO_VIDEOS_DETAILS_ROUTE = ':videoId';

export const SETUP_CITIES_ROUTE = '/setup/cities';
export const SETUP_ADMIN_USERS_ROUTE = '/setup/admin-users';
export const SETUP_HASHTAGS_ROUTE = '/setup/hashtags';
export const SETUP_IDEA_CATEGORIES_ROUTE = '/setup/idea-categories';
export const SETUP_TRADE_TYPES_ROUTE = '/setup/trade-types';
export const SETUP_VIDEO_CATEGORIES_ROUTE = '/setup/video-categories';

export const PROFILE_ROUTE = '/profile';

export const STORIES_ROUTE = '/stories';
export const STORIES_DETAILS_ROUTE = ':storyId';

export const FORMS_ROUTE = '/forms';
export const FORMS_DETAILS_ROUTE = ':formId';

export const JOBS_ROUTE = '/jobs';
export const JOBS_DETAILS_ROUTE = ':jobId';

export const EXTERNAL_PRODUCT_MAPPER_ROUTE = '/setup/product-mapper';

export const TAGS_ROUTE = '/tags';
export const TAGS_DETAILS_ROUTE = ':tagId';

export const COUPONS_ROUTE = '/coupons';
export const COUPONS_DETAILS_ROUTE = ':couponId';
