import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as PDFSVG } from '@/assets/icons/pdf-icon.svg';
import DeleteModal from '@/components/DeleteModal';
import BreadCrumb from '@/designComponents/BreadCrumb';
import Button from '@/designComponents/Button';
import Loader from '@/designComponents/Loader';
import Typography from '@/designComponents/Typography';
import { useAppDispatch, useAppSelector } from '@/store';
import { getSingleDocument } from '@/store/documents/functions';
import { toCapitalCase } from '@/utils/conversion';
import { humanReadableDateWithTime } from '@/utils/date';

import { regionMapping } from '..';
import CreateDocuments from '../CreateDocument';
import { DocumentDetailsWrapper } from './style';

const DocumentDetails = () => {
  const theme = useTheme();
  const { documentId } = useParams();
  const dispatch = useAppDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const singleDoc = useAppSelector((state) => state.documents);
  const divRef = useRef<HTMLDivElement>(null);

  const breadCrumbsData = [
    {
      label: 'Documents',
      path: '/',
    },
    {
      label: toCapitalCase(singleDoc?.singleDocument?.title),
      path: '/',
    },
  ] as Array<{ label: string; path: string }>;

  useEffect(() => {
    if (documentId) {
      dispatch(getSingleDocument(documentId));
    }
  }, [dispatch, documentId]);
  useEffect(() => {
    const wrapImagesWithParent = () => {
      if (divRef.current) {
        const imgTags = divRef.current.getElementsByTagName('img');
        Array.from(imgTags).forEach((img) => {
          img.style.maxWidth = '300px';
          img.style.maxHeight = '300px';
          img.style.marginTop = '30px';
        });
      }
    };

    wrapImagesWithParent();
  }, [singleDoc?.singleDocument?.body?.content]);

  return (
    <DocumentDetailsWrapper>
      <div className="crumb-btn">
        <BreadCrumb breadCrumb={breadCrumbsData} />
        {/* <div className="btn">
          <Button
            onClick={() => setIsDeleteModalOpen(true)}
            radius="md"
            variant="ghost"
            style={{
              border: `1px solid ${theme.colors.colorDanger}`,
              color: theme.colors.light.white,
              fontWeight: theme.fontWeights.medium,
              fontSize: theme.fontSize.normal,
              fontFamily: theme.fontFamily.roboto,
              backgroundColor: theme.colors.colorDanger,
            }}
          >
            Delete Document
          </Button>

          <Button radius="md" onClick={() => setIsEditModalOpen(true)}>
            Edit Document
          </Button>

          <CreateDocuments
            isOpenModal={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            documentId={documentId}
          />
        </div> */}
      </div>
      {!singleDoc.loading ? (
        <div className="main-content">
          <div className="heading">
            <Typography
              fontFamily="jost"
              fontWeight="medium"
              size="heading"
              color="dark.neutral_60"
            >
              Document Details
            </Typography>
            <hr
              style={{
                backgroundColor: theme.colors.dark.neutral_50,
                border: 'none',
                height: '1px',
              }}
            />
          </div>
          <div className="main-text">
            <div className="text">
              <Typography className="text-value">ID</Typography>
              <Typography className="text-value">
                {singleDoc?.singleDocument?._id}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Name/ title</Typography>
              <Typography className="text-value">
                {toCapitalCase(singleDoc?.singleDocument?.title)}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Type</Typography>
              <Typography className="text-value">
                {toCapitalCase(singleDoc?.singleDocument?.type)}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Hash Tags</Typography>
              <div
                style={{ display: 'flex', gap: '15px', alignItems: 'center' }}
              >
                {singleDoc?.singleDocument?.hashTags?.map((tag) => (
                  <li key={tag?._id}>{tag.tag}</li>
                ))}
              </div>
            </div>
            <div className="text">
              <Typography className="text-value">Region</Typography>
              <Typography className="text-value">
                {singleDoc?.singleDocument.region
                  ?.map((region: string) => regionMapping[region] || region)
                  .join(', ')}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Slug</Typography>
              <Typography className="text-value">
                {singleDoc?.singleDocument?.slug}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Full Slug</Typography>
              <Typography className="text-value">
                {singleDoc?.singleDocument?.fullSlug}
              </Typography>
            </div>
            {/* <div className="text">
            <Typography className="text-value">Jurisdictions</Typography>
            <Typography className="text-value">Republic of Ireland</Typography>
          </div> */}
            <div className="text">
              <Typography className="text-value">Created At</Typography>
              <Typography
                className="text-value"
                style={{ textDecoration: 'underline' }}
              >
                {humanReadableDateWithTime(
                  singleDoc?.singleDocument?.createdAt
                )}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Update At</Typography>
              <Typography
                className="text-value"
                style={{ textDecoration: 'underline' }}
              >
                {humanReadableDateWithTime(
                  singleDoc?.singleDocument?.updatedAt
                )}
              </Typography>
            </div>
            <div className="text" style={{ alignItems: 'start' }}>
              <Typography className="text-value">Content</Typography>
              {singleDoc?.singleDocument?.body?.contentType === 'image' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '2px solid gray',
                    borderRadius: '20px',
                    width: '120px',
                    padding: '5px',
                  }}
                >
                  <img
                    src={singleDoc?.singleDocument?.body?.content}
                    alt="content image"
                    height={100}
                    width={100}
                  />
                </div>
              ) : singleDoc?.singleDocument?.body?.contentType === 'pdf' ? (
                <PDFSVG width={110} height={110} />
              ) : (
                <div
                  className="text-value"
                  ref={divRef}
                  dangerouslySetInnerHTML={{
                    __html: singleDoc?.singleDocument?.body?.content,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '400px',
            width: '100%',
          }}
        >
          <Loader
            style={{
              height: 60,
              width: 60,
            }}
          />
        </div>
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          title="housebuild.com says"
          onClose={() => setIsDeleteModalOpen(false)}
          isOpen={isDeleteModalOpen}
          deleteText="Are you sure you want to delete Passive House Details ?"
        />
      )}
    </DocumentDetailsWrapper>
  );
};

export default DocumentDetails;
