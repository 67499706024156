import { ConfigType } from '@/utils/requestAPI';

export type paginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
};

export type AdminData = {
  firstName: string;
  lastName: string;
  email: string;
};

const adminsApi = {
  AddAdmin: (data: AdminData): ConfigType => {
    return {
      method: 'post',
      url: '/admin',
      data,
    };
  },

  getAdmins: (params?: paginationType): ConfigType => {
    return {
      method: 'get',
      url: '/admin',
      params,
    };
  },

  deleteAdmin: (id: string): ConfigType => {
    return {
      method: 'delete',
      url: `/admin/${id}`,
    };
  },
};

export default adminsApi;
