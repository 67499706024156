import Typography from '@/designComponents/Typography';
import { toCapitalCase } from '@/utils/conversion';
import { humanReadableDate } from '@/utils/date';

import DataTable from './DataTable';
import Tag from './Tag';

type Props = {
  price?: string;
  subscriptionName?: string;
  planName?: string;
  status?: string;
  hasCouponCodeUsed?: boolean;
  planExpiry?: string;
  isPlanManual?: boolean;
};
const Subscription = ({
  subscriptionName,
  status,
  planName,
  hasCouponCodeUsed = false,
  planExpiry,
  isPlanManual,
}: Props) => {
  return (
    <DataTable
      data={{
        ['Subscription Name']: (
          <div
            style={{
              display: 'flex',
              gap: '9px',
            }}
          >
            <Typography>
              {toCapitalCase(subscriptionName) || 'Basic'}
            </Typography>
            {status && (
              <Tag
                title={status === 'active' ? 'Active' : 'In Active'}
                variant={status === 'active' ? 'active' : 'danger'}
              />
            )}
          </div>
        ),
        ['Payment Interval']: toCapitalCase(planName),
        ['Coupon Code Used']: hasCouponCodeUsed ? 'Yes' : 'No',
        ['Subscription Expiry Date']: humanReadableDate(planExpiry),
        ['Subscription Creation Method']: isPlanManual ? 'Manual' : 'N/A',
      }}
    />
  );
};

export default Subscription;
