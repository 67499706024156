import styled from 'styled-components';

import { scrollCss } from '@/styles/reUsableStyles';

export const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - 65px);
  width: 250px;
  position: sticky;
  top: 65px;

  .menu-container {
    padding: 10px 0px;
    z-index: 3000;
    overflow-y: auto;
    background-color: ${({ theme }) => theme.colors.light.white};
    transition: all 0.3s;
    border-right: 1px solid #e0e0e0;
    bottom: 0px;
    width: 230px;
    height: 100%;
    ${scrollCss}
  }
`;

export const SidebarItem = styled.div<{ $active: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  gap: 18px;
  padding: 5px 10px;
  margin: 2.5px 5px;
  border-radius: 4px;
  height: 40px;
  position: relative;

  transition: all 0.4s ease;
  & svg.icon {
    height: 20px;
    width: 25px;
    margin: 0 !important;
    flex-shrink: 0;
    fill: ${({ $active, theme }) =>
      $active ? theme.colors.primary : theme.colors.dark.black};
  }

  & .label {
    font-size: 14px;
    line-height: 24px;
    display: block;
    color: ${({ $active, theme }) =>
      $active ? theme.colors.primary : theme.colors.dark.black};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.dark.neutral_50};
    /* background-color: ${({ theme, $active }) =>
      $active ? theme.colors.primary : theme.colors.dark.black}; */
    & svg.icon {
      /* transform: scale(1.05); */
      fill: ${({ $active, theme }) =>
        $active ? theme.colors.primary : theme.colors.lightBlue};
    }
    .label {
      color: ${({ $active, theme }) =>
        $active ? theme.colors.primary : theme.colors.lightBlue};
    }
  }

  .caret-icon {
    position: absolute;
    right: 20px;
  }
`;

export const MenuDropdownContent = styled.div`
  background-color: ${({ theme }) => theme.colors.dark.neutral_10};
  padding: 10px 0;
  .sidebar-item {
    padding-left: 45px;
  }
`;
