import { createSlice } from '@reduxjs/toolkit';

import {
  addProduct,
  getAllProducts,
  getSingleProductDetail,
} from './functions';

type Showroom = {
  bottomBanner: BottomBanner;
  slug: string;
  title: string;
  logo: string;
  description: string;
  images: string[];
};

type BottomBanner = {
  slug: string;
  title: string;
  subtitle: string;
  image1: string;
  image2: string;
};

type ProductCategoryId = {
  _id: string;
  prevId: string;
  title: string;
  productTypeId: number;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

type SupplierId = {
  showroom: Showroom;
  _id: string;
  prevId: string;
  name: string;
  website: string;
  avatar: string;
  regions: any[];
  isInbound: boolean;
  hasShowroomPage: boolean;
  userId: string;
  createdAt: string;
  updatedAt: string;
  slug: string;
  __v: number;
};
type ProductSubcategoryId = {
  _id: string;
  prevId: string;
  title: string;
  productCategoryId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
export type Products = {
  products: {
    _id: string;
    title: string;
    slug: string;
    stock: number;
    vat: number;
    description: any;
    video: string;
    fullDescription: string;
    specifications: any;
    price: number;
    supplierId: SupplierId;
    publishedAt: any;
    externalId: string;
    referralLink: string;
    externalUrl: string;
    isDeliverable: boolean;
    productCategoryId: ProductCategoryId;
    productSubcategoryId: ProductSubcategoryId;
    featured: boolean;
    goGreen: boolean;
    archived: boolean;
    benefits: any;
    region: string[];
    photos: string[];
    buildingStage: any[];
    type: string;
    prevId: string;
    productDocument: any[];
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  analytics: {
    showroomCount: number;
    constructionCount: number;
    buildingMaterialCount: number;
  };
};

type InitialState = {
  products: Products['products'][] | null;
  singleProduct: Products['products'] | null;
  analytics: Products['analytics'] | null;
  error: string | null;
  loading: boolean;
  pagination: {
    total: number;
    limit: number;
    currentPage: number;
    totalPages: number;
  };
};

const initialState: InitialState = {
  products: null,
  singleProduct: null,
  analytics: null,
  error: null,
  loading: false,
  pagination: {
    total: 0,
    limit: 11,
    currentPage: 0,
    totalPages: 1,
  },
};

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(addProduct.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ||
          'Failed to add group document. Try again later.';
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.products = action.payload;
      });

    builder
      .addCase(getAllProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(getAllProducts.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch documents. Try again later.';
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.loading = false;

        state.products = action.payload.products.data;
        state.pagination = action.payload.products.pagination;
        state.analytics = action.payload.analytics;
      });
    builder.addCase(getSingleProductDetail.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getSingleProductDetail.fulfilled,
      (state: typeof initialState, action) => {
        state.loading = false;
        state.error = null;
        state.singleProduct = action.payload;
      }
    );
    builder.addCase(getSingleProductDetail.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to fetch single product. Try again later.';
    });
  },
});

export const productReducer = productSlice.reducer;
