// store/functions.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import documents, {
  DocumentGroupParamsType,
  DocumentGroupType,
  DocumentType,
  PaginationType,
} from '@/apiConfigs/documents';
import requestAPI from '@/utils/requestAPI';

export const addDocument = createAsyncThunk(
  'documents/addDocument',
  async (document: DocumentType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(documents.addDocument(document));
      toast.success('Document created successfully');
      return response;
    } catch (error: any) {
      console.error(error.message, 'error from catch');
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
export const getAllDocument = createAsyncThunk(
  'documents/getAllDocument',
  async (params?: PaginationType) => {
    const response = await requestAPI(documents.getAllDocument(params));

    return response;
  }
);

export const getSingleDocument = createAsyncThunk(
  'documents/getSingleDocument',
  async (documentId: string) => {
    const response = await requestAPI(documents.getSingleDocument(documentId));

    return response;
  },
  {
    condition: () => true, // Always make a new request
    dispatchConditionRejection: true,
  }
);
export const getSingleDocumentParent = createAsyncThunk(
  'documents/getSingleDocumentParent',
  async (documentId: string) => {
    const response = await requestAPI(
      documents.getSingleDocumentParent(documentId)
    );

    return response;
  },
  {
    condition: () => true, // Always make a new request
    dispatchConditionRejection: true,
  }
);

export const updateDocument = createAsyncThunk(
  'documents/updateDocument',
  async (
    { documentId, data }: { documentId: string; data: DocumentType },
    { rejectWithValue }
  ) => {
    try {
      const response = await requestAPI(
        documents.updateDocument(documentId, data)
      );
      toast.success('Document updated successfully');
      return response;
    } catch (error: any) {
      console.error(error.message, 'error from catch');
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
export const deleteDocument = createAsyncThunk(
  'documents/deleteDocument',
  async (documentId: string, { rejectWithValue }) => {
    try {
      const response = await requestAPI(documents.deleteDocument(documentId));
      toast.success('Document deleted successfully');
      return response;
    } catch (error: any) {
      console.error(
        error.message,
        'error from delete document store function catch'
      );
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const addGroupDocument = createAsyncThunk(
  'documents/addDocumentGroup',
  async (document: DocumentGroupType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(documents.addDocumentGroup(document));
      toast.success('Document group added successfully');
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

export const getAllGroupDocument = createAsyncThunk(
  'documents/getAllDocumentGroup',
  async (params?: DocumentGroupParamsType) => {
    const response = await requestAPI(documents.getAllDocumentGroup(params));

    return response;
  },
  {
    condition: () => true, // Always make a new request
    dispatchConditionRejection: true,
  }
);

export const getAllDocumentWithAnalytics = createAsyncThunk(
  'documents/getAllDocumentWithAnalytics',
  async (params?: PaginationType) => {
    const response = await requestAPI(
      documents.getAllDocumentWithAnalytics(params)
    );
    console.log('getAllDocumentWithAnalytics :::', response);

    return response;
  },
  {
    condition: () => true, // Always make a new request
    dispatchConditionRejection: true,
  }
);
