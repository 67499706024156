import { createAsyncThunk } from '@reduxjs/toolkit';

import buildingJourney, { PaginationType } from '@/apiConfigs/buildingJourney';
import requestAPI from '@/utils/requestAPI';

export const addBuildingJourney = createAsyncThunk(
  'buildingJourney/addDocument',
  async (buildingJourneys: any, { rejectWithValue }) => {
    try {
      const response = await requestAPI(
        buildingJourney.addBuildingJourney(buildingJourneys)
      );

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

export const getAllBuildngJourney = createAsyncThunk(
  'buildingJourney/getAllBuildngJourney',
  async (pagination: PaginationType) => {
    const response = await requestAPI(
      buildingJourney.getBuildingJourney(pagination)
    );

    return response;
  }
);

export const addBuildingStep = createAsyncThunk(
  'buildingSteps/addBuildingStep',
  async (buildingSteps: any, { rejectWithValue }) => {
    try {
      const response = await requestAPI(
        buildingJourney.addBuildingStep(buildingSteps)
      );

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);
export const getAllBuildngSteps = createAsyncThunk(
  'buildingSteps/getAllBuildngSteps',
  async (params?: PaginationType) => {
    const response = await requestAPI(buildingJourney.getBuildingSteps(params));

    return response;
  }
);
