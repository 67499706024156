import { ConfigType } from '@/utils/requestAPI';

export type JourneyType = {
  name?: string;
  position?: number;
  buildingSteps?: string[];
};

export type StepType = {
  name: string;
  position: number;
  icon: File | string | null;
  region: Array<{
    name: string;
    tabs: string[];
    finance: Array<{ title: string; subtitle: string; link: string }>;
    documents: string[];
    recommendedProfessionals: string[];
    productSubCategory: string[];
    buildingStage: string[];
    buildingSystems: string[];
  }>;
};

export type PaginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
};

const buildingJourney = {
  addBuildingJourney: (data: JourneyType): ConfigType => {
    return {
      method: 'post',
      url: '/building-journey',
      data,
    };
  },
  updateBuildingJourney: (data: JourneyType, id: string): ConfigType => {
    return {
      method: 'put',
      url: `/building-journey/${id}`,
      data,
    };
  },
  getBuildingJourney: (params?: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/building-journey',
      params,
    };
  },
  getSingleBuildingJourney: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/building-journeyby/${id}`,
    };
  },
  getSingleBuildingJourneyStep: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/building-step/${id}`,
    };
  },
  addBuildingStep: (data: StepType): ConfigType => {
    return {
      method: 'post',
      url: '/building-step',
      data,
    };
  },
  updateBuildingStep: (data: StepType, id: string): ConfigType => {
    return {
      method: 'put',
      url: `/building-step/${id}`,
      data,
    };
  },

  getBuildingSteps: (params?: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/building-steps',
      params,
    };
  },
};

export default buildingJourney;
