import React from 'react';

import Typography from '../Typography';
import { LabelStyled, RadioButtonStyled, RadioButtonWrapper } from './style';

interface IRadioButtonProps extends React.ComponentProps<'input'> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selctedOption: string;
  options: Array<{ label: string; value: string }>;
}

const RadioButton = ({
  onChange,
  options,
  selctedOption,
  ...restProps
}: IRadioButtonProps) => {
  const handleRadioOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };
  return (
    <RadioButtonWrapper>
      {options.map((option, idx) => (
        <LabelStyled htmlFor={`radio-${option.value}`} key={idx}>
          <RadioButtonStyled
            id={`radio-${option.value}`}
            type="radio"
            value={option.value}
            onChange={handleRadioOptionChange}
            {...restProps}
            key={idx}
            checked={option.value === selctedOption}
          />
          <Typography>{option.label}</Typography>
        </LabelStyled>
      ))}
    </RadioButtonWrapper>
  );
};

export default RadioButton;
