import { createAsyncThunk } from '@reduxjs/toolkit';

import suppliers, {
  PaginationType,
  SuppliersType,
} from '@/apiConfigs/suppliers';
import requestAPI from '@/utils/requestAPI';

export const addSupplier = createAsyncThunk(
  'suppliers/addSupplier',
  async (data: SuppliersType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(suppliers.addSuppliers(data));

      return response;
    } catch (error: any) {
      console.log(error.response.data, 'suppliers response error');
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

export const getAllSuppliers = createAsyncThunk(
  'suppliers/getAllSuppliers',
  async (params?: PaginationType) => {
    const response = await requestAPI(suppliers.getSuppliers(params));

    return response;
  }
);

export const getSingleSupplier = createAsyncThunk(
  'suppliers/getSingleSupplier',
  async (showroomId: string) => {
    const response = await requestAPI(suppliers.getSingleSupplier(showroomId));
    console.log(response);

    return response;
  }
);
