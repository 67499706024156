import React from 'react';

import LoaderWrapper from './style';

type Props = {
  style?: React.CSSProperties;
};

const Loader = ({ style }: Props) => {
  return (
    <LoaderWrapper>
      <div className="spinner" style={{ ...style }} />
    </LoaderWrapper>
  );
};

export default Loader;
