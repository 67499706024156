import CrumbsWithButton from '@/components/UserDetails/CrumbsWithButton';
import HeadingTitle from '@/components/UserDetails/HeadingTitle';

import { newsLetterUserBreadCrumbData } from '../../data';
import { AccountDetailsWrapper, InfoRowStyled } from '../style';
import NewsLetterUserInformation from './NewsLetterUserInformation';

const NewLetterUser = () => {
  return (
    <AccountDetailsWrapper>
      <CrumbsWithButton
        breadCrumbData={newsLetterUserBreadCrumbData}
        isNewLetter
      />
      <div className="main-content">
        <InfoRowStyled>
          <HeadingTitle title="Email Capture Details" />
          <NewsLetterUserInformation
            createdAt="15 May 2020 7:00 pm"
            email="darrell5steward98@gmail.com"
            updateAt="15 May 2020 10:00 pm"
            newsConsentStatus="active"
            newsConsentTitle="Yes ✓"
            userType="Professional"
          />
        </InfoRowStyled>
      </div>
    </AccountDetailsWrapper>
  );
};

export default NewLetterUser;
