import styled from 'styled-components';

export const ProductDetailsWrapperStyled = styled.div``;

export const CrumbsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
`;

export const ImageWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  /* max-width: 400px; */
  margin: 20px 0px;
`;

export const InfoRowStyled = styled.div`
  display: flex;
  gap: 32px;

  & .label {
    width: 170px;
  }
  & .value {
  }
`;

export const ProductDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
