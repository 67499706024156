import React from 'react';
import styled from 'styled-components';

const AvatarContainer = styled.div<{ size: string; bgColor: string }>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
  background-color: ${(props) => props.bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(${(props) => props.size} / 2.5);
  color: white;
  overflow: hidden;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

interface AvatarProps {
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
  size?: string;
  bgColor?: string;
}

const Avatar = ({
  firstName = '',
  lastName = '',
  avatarUrl,
  size = '50px',
  bgColor = '#333',
}: AvatarProps) => {
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();

  return (
    <AvatarContainer size={size} bgColor={bgColor}>
      {avatarUrl ? (
        <AvatarImage src={avatarUrl} alt={`${firstName} ${lastName}`} />
      ) : (
        initials
      )}
    </AvatarContainer>
  );
};

export default Avatar;
