import * as Yup from 'yup';

const ProductValidationSchema = Yup.object().shape({
  title: Yup.string().required('Product title is required'),
  description: Yup.string().required('Description is required'),
  specification: Yup.object()
    .shape({
      content: Yup.string().required('Specification is required'),
      contentType: Yup.string().required(
        'Specification content Type is required'
      ),
    })
    .required('Specification is required'),
  price: Yup.number()
    .min(100, 'Price must be at least 100')
    .required('Price is required'),
  vat: Yup.number()
    .min(1, 'VAT must be at least 1')
    .required('VAT is required'),
  supplierId: Yup.object()
    .shape({
      label: Yup.string().required('Label is required'),
      value: Yup.string().required('Value is required'),
    })
    .required('Supplier is required'),
  region: Yup.array()
    .of(Yup.string())
    .min(1, 'At least one region is required')
    .required('Regions are required'),
  stock: Yup.number()
    .integer('Stock must be an integer')
    .min(1, 'Stock must be at least 1')
    .required('Stock is required'),
  // isDeliverable: Yup.boolean().,
  // region: Yup.array()
  //   .of(Yup.string())
  //   .min(1, 'At least one region must be selected')
  //   .required('Region is required'),
  // referralLink: Yup.string().url('Must be a valid URL'),
  // video: Yup.string().url('Must be a valid URL'),
  // type: Yup.string().required('Type is required'),
  // externalId: Yup.string(),
  // benefits: Yup.string(),
});

export default ProductValidationSchema;
