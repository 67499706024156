export type InitialValuesType = {
  documentTitle: string;
  documentType: string;
  parentTitle: string;
  icon: File[] | null;
  type: string;
  jurisdictions: string[];
};

export const breadCrumbData: Array<{ label: string; path: string }> = [
  {
    label: 'Go Green',
    path: '/',
  },
  {
    label: 'All Category',
    path: '/',
  },
  {
    label: 'Category Details',
    path: '/',
  },
];
export const dropDownOption: Array<{
  value: string;
  label: string;
}> = [
  {
    label: 'Go Green',
    value: 'go-green',
  },
  {
    label: 'Building Guides',
    value: 'building-guide',
  },
  {
    label: 'Building System',
    value: 'building-system',
  },
];

export const data = () => [
  {
    id: '1',
    user: '1408',
    name: 'Aj Browne',
    buildingProject: 'New build',
    buildingStep: 'Substructure',
    tabSelected: 'Trades',
    lastPageVisited: 'nill',
  },
  {
    id: '2',
    user: '1545',
    name: 'Sharon Mc Donagh',
    buildingProject: 'New build',
    buildingStep: 'Design & Finance',
    tabSelected: 'Guidelines',
    lastPageVisited: 'nill',
  },
  {
    id: '3',
    user: '1507',
    name: 'test too',
    buildingProject: 'New build',
    buildingStep: 'Design & Finance',
    tabSelected: 'Guidelines',
    lastPageVisited: 'nill',
  },
  {
    id: '1',
    user: '1408',
    name: 'Zenith Manuel',
    buildingProject: 'New build',
    buildingStep: 'SuperStructure',
    tabSelected: 'Building Systems',
    lastPageVisited: 'nill',
  },
];
