import React from 'react';
import styled from 'styled-components';

import Typography from '@/designComponents/Typography';

interface DataTableProps {
  data: Record<string, string | React.ReactNode>;
}

const TableWrapper = styled.table`
  width: 100%;
  max-width: 350px;
  border-collapse: collapse;
  overflow: hidden;

  td {
    padding: 5px 0px;
  }
`;

export default function DataTable({ data }: DataTableProps) {
  return (
    <TableWrapper>
      <tbody>
        {Object.entries(data).map(([key, value]) => (
          <tr key={key}>
            {key && value ? (
              <>
                <td>
                  <Typography>{key}</Typography>
                </td>
                {typeof value === 'string' ? (
                  <td>
                    <Typography>{value}</Typography>
                  </td>
                ) : (
                  value
                )}
              </>
            ) : null}
          </tr>
        ))}
      </tbody>
    </TableWrapper>
  );
}
