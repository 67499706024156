import { Form, Formik, FormikProps } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Modal from '@/designComponents/Modal';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import Typography from '@/designComponents/Typography';
import UploadFile from '@/designComponents/UploadFile';
import { InfoRowStyled, NewDocumentWrapper } from '@/pages/Documents/style';
import { ModalContainerStyled } from '@/pages/DownloadCenter/style';
import { useAppDispatch } from '@/store';
import { addSupplier, getAllSuppliers } from '@/store/suppliers/functions';
import { uploadFilesToS3OnlyKeys } from '@/utils/s3Upload';

import { validationSchema } from './validation';

export type InitialValuesType = {
  name: string;
  website: string;
  email: string;
  avatar?: File;
  inboundEmail: string;
  isInbound: boolean;
  showroom: {
    images?: File[];
    title: string;
    description: string;
    bottomBanner: {
      link: string;
      title: string;
      subtitle: string;
      image1?: File;
      image2?: File;
    };
  };
};

const AddSuppliers = () => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showShowroomForm, setShowShowroomForm] = useState(false);

  const [initialValues] = useState<InitialValuesType>({
    name: '',
    website: '',
    email: '',
    avatar: undefined,
    inboundEmail: '',
    isInbound: false,
    showroom: {
      images: [],
      title: '',
      description: '',
      bottomBanner: {
        link: '',
        title: '',
        subtitle: '',
        image1: undefined,
        image2: undefined,
      },
    },
  });

  const makeFinalValue = (
    values: InitialValuesType,
    avatarURL?: string[],
    imagesURL?: string[],
    image1URL?: string[],
    image2URL?: string[]
  ) => {
    return {
      name: values.name,
      website: values.website,
      email: values.email,
      avatar:
        avatarURL && avatarURL?.length > 0
          ? avatarURL[0]
          : 'default-avatar.png',

      inboundEmail: values.inboundEmail,
      isInbound: values.isInbound,
      showroom: {
        images: imagesURL || [],
        title: values.showroom.title,
        description: values.showroom.description,
        bottomBanner: {
          link: values.showroom.bottomBanner.link,
          title: values.showroom.bottomBanner.title,
          subtitle: values.showroom.bottomBanner.subtitle,
          image1:
            image1URL && image1URL?.length > 0
              ? image1URL[0]
              : 'default-avatar.png',
          image2: image2URL && image2URL?.length > 0 ? image2URL[0] : '',
        },
      },
    };
  };

  const handleFormSubmit = async (
    values: InitialValuesType,
    { setStatus }: any
  ) => {
    setLoading(true);
    try {
      let avatarURL: string[] | undefined = [];
      let imagesURL: string[] | undefined = [];
      let image1URL: string[] | undefined = [];
      let image2URL: string[] | undefined = [];

      if (values.avatar) {
        avatarURL = await uploadFilesToS3OnlyKeys([values.avatar]);
      }

      if (values.showroom?.images && values.showroom?.images.length > 0) {
        imagesURL = await uploadFilesToS3OnlyKeys(values.showroom?.images);
      }

      if (values?.showroom?.bottomBanner?.image1) {
        image1URL = await uploadFilesToS3OnlyKeys([
          values.showroom.bottomBanner?.image1,
        ]);
      }

      if (values.showroom.bottomBanner?.image2) {
        image2URL = await uploadFilesToS3OnlyKeys([
          values.showroom.bottomBanner?.image2,
        ]);
      }
      const finalValue = makeFinalValue(
        values,
        avatarURL,
        imagesURL,
        image1URL,
        image2URL
      );
      await dispatch(addSupplier(finalValue));
      toast.success('Supplier created successfully');
      dispatch(getAllSuppliers({ page: 1, limit: 10 }));
      setIsModalOpen(false);
    } catch (error: any) {
      if (error.message) {
        setStatus(error.message);
      } else {
        toast.error('Failed to create supplier');
      }
    } finally {
      setLoading(false);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalContainerStyled>
      <Button radius="md" onClick={openModal}>
        New Supplier
      </Button>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Create New Supplier"
        halfWidth={true}
      >
        <ModalContentWrapper>
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={validationSchema}
          >
            {(formikProps: FormikProps<InitialValuesType>) => {
              const { setFieldValue, values, touched, errors, status } =
                formikProps;

              return (
                <Form>
                  <NewDocumentWrapper>
                    <InfoRowStyled>
                      <Typography>Name</Typography>
                      <Input
                        name="name"
                        value={values.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                      {errors.name && touched.name && (
                        <div style={{ color: 'red' }}>{errors.name}</div>
                      )}
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography>Website</Typography>
                      <Input
                        name="website"
                        value={values.website}
                        onChange={(e) =>
                          setFieldValue('website', e.target.value)
                        }
                      />
                      {errors.website && touched.website && (
                        <div style={{ color: 'red' }}>{errors.website}</div>
                      )}
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography>Email</Typography>
                      <Input
                        name="email"
                        value={values.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                      />
                      {errors.email && touched.email && (
                        <div style={{ color: 'red' }}>{errors.email}</div>
                      )}
                    </InfoRowStyled>

                    <InfoRowStyled>
                      <Typography>Avatar</Typography>
                      <UploadFile
                        name="avatar"
                        title=""
                        files={values.avatar}
                        formikProps={formikProps}
                        onRemove={() => setFieldValue('avatar', '')}
                        variant="icon"
                      />
                      {errors.avatar && touched.avatar && (
                        <div style={{ color: 'red' }}>{errors.avatar}</div>
                      )}
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography>Inbound Email</Typography>
                      <Input
                        name="inboundEmail"
                        value={values.inboundEmail}
                        onChange={(e) =>
                          setFieldValue('inboundEmail', e.target.value)
                        }
                      />
                      {errors.inboundEmail && touched.inboundEmail && (
                        <div style={{ color: 'red' }}>
                          {errors.inboundEmail}
                        </div>
                      )}
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography>Is Inbound</Typography>
                      <Input
                        name="isInbound"
                        type="checkbox"
                        checked={values.isInbound}
                        onChange={(e) =>
                          setFieldValue('isInbound', e.target.checked)
                        }
                      />
                    </InfoRowStyled>
                    <Button
                      radius="md"
                      type="button"
                      onClick={() => setShowShowroomForm(!showShowroomForm)}
                    >
                      {showShowroomForm ? 'Hide Showroom' : 'Add Showroom'}
                    </Button>
                    {showShowroomForm && (
                      <>
                        <InfoRowStyled>
                          <Typography>Showroom Title</Typography>
                          <Input
                            name="showroom.title"
                            value={values.showroom.title}
                            onChange={(e) =>
                              setFieldValue('showroom.title', e.target.value)
                            }
                          />
                          {errors.showroom?.title &&
                            touched.showroom?.title && (
                              <div style={{ color: 'red' }}>
                                {errors.showroom.title}
                              </div>
                            )}
                        </InfoRowStyled>

                        <InfoRowStyled>
                          <Typography>Showroom Description</Typography>
                          <Input
                            name="showroom.description"
                            value={values.showroom.description}
                            onChange={(e) =>
                              setFieldValue(
                                'showroom.description',
                                e.target.value
                              )
                            }
                          />
                          {errors.showroom?.description &&
                            touched.showroom?.description && (
                              <div style={{ color: 'red' }}>
                                {errors.showroom.description}
                              </div>
                            )}
                        </InfoRowStyled>
                        <InfoRowStyled>
                          <Typography>Bottom Banner Link</Typography>
                          <Input
                            name="showroom.bottomBanner.link"
                            value={values.showroom.bottomBanner.link}
                            onChange={(e) =>
                              setFieldValue(
                                'showroom.bottomBanner.link',
                                e.target.value
                              )
                            }
                          />
                          {errors.showroom?.bottomBanner?.link &&
                            touched.showroom?.bottomBanner?.link && (
                              <div style={{ color: 'red' }}>
                                {errors.showroom.bottomBanner.link}
                              </div>
                            )}
                        </InfoRowStyled>
                        <InfoRowStyled>
                          <Typography>Images</Typography>
                          <UploadFile
                            name="showroom.images"
                            title=""
                            files={values.showroom.images}
                            formikProps={formikProps}
                            onRemove={() =>
                              setFieldValue('showroom.images', '')
                            }
                            variant="previewImage"
                            enableMultipleFile
                          />
                          {/* {errors.avatar && touched.avatar && (
                            <div style={{ color: 'red' }}>{errors.avatar}</div>
                          )} */}
                        </InfoRowStyled>
                        <InfoRowStyled>
                          <Typography>Bottom Banner Title</Typography>
                          <Input
                            name="showroom.bottomBanner.title"
                            value={values.showroom.bottomBanner.title}
                            onChange={(e) =>
                              setFieldValue(
                                'showroom.bottomBanner.title',
                                e.target.value
                              )
                            }
                          />
                          {errors.showroom?.bottomBanner?.title &&
                            touched.showroom?.bottomBanner?.title && (
                              <div style={{ color: 'red' }}>
                                {errors.showroom.bottomBanner.title}
                              </div>
                            )}
                        </InfoRowStyled>
                        <InfoRowStyled>
                          <Typography>Bottom Banner Subtitle</Typography>
                          <Input
                            name="showroom.bottomBanner.subtitle"
                            value={values.showroom.bottomBanner.subtitle}
                            onChange={(e) =>
                              setFieldValue(
                                'showroom.bottomBanner.subtitle',
                                e.target.value
                              )
                            }
                          />
                          {errors.showroom?.bottomBanner?.subtitle &&
                            touched.showroom?.bottomBanner?.subtitle && (
                              <div style={{ color: 'red' }}>
                                {errors.showroom.bottomBanner.subtitle}
                              </div>
                            )}
                        </InfoRowStyled>
                        <InfoRowStyled>
                          <Typography>Bottom Banner Image1</Typography>
                          <UploadFile
                            name="showroom.bottomBanner.image1"
                            title=""
                            files={values.showroom.bottomBanner.image1}
                            formikProps={formikProps}
                            onRemove={() =>
                              setFieldValue(
                                'showroom.bottomBanner.image1',
                                undefined
                              )
                            }
                            variant="icon"
                          />
                          {/* {errors.showroom?.bottomBanner?.image1 &&
                            touched.showroom?.bottomBanner?.image1 && (
                              <div style={{ color: 'red' }}>
                                {errors.showroom.bottomBanner.image1}
                              </div>
                            )} */}
                        </InfoRowStyled>
                        <InfoRowStyled>
                          <Typography>Bottom Banner Image2</Typography>
                          <UploadFile
                            name="showroom.bottomBanner.image2"
                            title=""
                            files={values.showroom.bottomBanner.image2}
                            formikProps={formikProps}
                            onRemove={() =>
                              setFieldValue(
                                'showroom.bottomBanner.image2',
                                undefined
                              )
                            }
                            variant="icon"
                          />
                          {/* {errors.showroom?.bottomBanner?.image2 &&
                            touched.showroom?.bottomBanner?.image2 && (
                              <div style={{ color: 'red' }}>
                                {errors.showroom.bottomBanner.image2}
                              </div>
                            )} */}
                        </InfoRowStyled>
                      </>
                    )}
                    {status && <div style={{ color: 'red' }}>{status}</div>}
                  </NewDocumentWrapper>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '10px',
                    }}
                  >
                    <Button radius="md" type="submit">
                      {loading ? 'Loading...' : 'Add'}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalContentWrapper>
      </Modal>
    </ModalContainerStyled>
  );
};

export default AddSuppliers;
