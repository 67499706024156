import styled, { css } from 'styled-components';

export const SelectWrapper = styled.div`
  min-height: 45px;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  border: 1px solid gray;
  width: 100%;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey.neutral_e0};
`;

export const OptionsContainerStyled = styled.div`
  background-color: white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10;
  margin-top: 5px;
  z-index: 9999;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey.neutral_e0};
  padding: 10px 0px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.dark.neutral_20};
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.dark.neutral_10};
    border-radius: 5px;
    width: 5px;
    height: 24px;
  }

  & .option {
    padding: 10px 12px;
    margin-bottom: 3px;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      background-color: ${({ theme }) => theme.colors.dark.neutral_20};
      color: black;
    }
  }
`;

export const selectDisable = css`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

export const StyledDiv = styled.div`
  ${selectDisable}
`;

export const Chip = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.dark.neutral_25};
  border-radius: 20px;
  margin-right: 4px;
`;
