import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';

import buildingJourney from '@/apiConfigs/buildingJourney';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Typography from '@/designComponents/Typography';
import { InfoRowStyled, NewDocumentWrapper } from '@/pages/Documents/style';
import { BottomContainerStyled } from '@/pages/DownloadCenter/style';
import { useAppDispatch } from '@/store';
import { getAllBuildngJourney } from '@/store/buildingJourney/functions';
import theme from '@/theme';
import requestAPI from '@/utils/requestAPI';

import { BuildingJourneyDetailsType } from '../BuildingJourneyDetails';
import { validationSchema } from './validationSchema';

type InitialValueType = {
  name: string;
  position: number;
  buildingSteps: Array<{ label: string; value: string }>;
};
type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  journeyId?: string;
  singleBuildingJourneyData?: BuildingJourneyDetailsType;
  refreshSingleData?: () => Promise<void>;
};

const CreateBuildingJourney = ({
  setIsModalOpen,
  singleBuildingJourneyData,
  journeyId,
  refreshSingleData,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [initialValues, setInitialValues] = useState<InitialValueType>({
    name: '',
    position: 1,
    buildingSteps: [],
  });

  const getBuildingSteps = async (inputValue: string) => {
    try {
      const res = await requestAPI(
        buildingJourney.getBuildingSteps({
          search: inputValue,
          limit: 100,
        })
      );
      return res.data.map((steps) => ({
        label: steps.name,
        value: steps._id,
      }));
    } catch (error) {
      console.error(error.message);
      toast.error('Failed to get building steps');
    }
  };

  const loadBuildingStepOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    getBuildingSteps(inputValue).then((options) => {
      callback(options);
    });
  };

  useEffect(() => {
    if (journeyId && singleBuildingJourneyData) {
      setInitialValues({
        name: singleBuildingJourneyData.name,
        position: singleBuildingJourneyData.position,
        buildingSteps: singleBuildingJourneyData.buildingSteps.map((steps) => ({
          label: steps.name,
          value: steps._id,
        })),
      });
    }
  }, [singleBuildingJourneyData, journeyId]);

  const handleFormSubmit = async (values: InitialValueType) => {
    setIsLoading(true);
    try {
      await requestAPI(
        buildingJourney.addBuildingJourney({
          name: values.name,
          position: values.position,
          buildingSteps: values.buildingSteps.map((step) => step.value),
        })
      );
      setIsLoading(false);
      dispatch(getAllBuildngJourney({}));
      setIsModalOpen(false);
      toast.success('New building journey created successfully');
    } catch (error: any) {
      setIsLoading(false);
      if (error.message.includes('E11000 duplicate key error')) {
        setApiError('Duplicate building journey');
        toast.error('Duplicate building journey');
      }
      console.error(error.message);
    }
  };

  const handleUpdateBuildingJourney = async (val: InitialValueType) => {
    setIsLoading(true);
    try {
      await requestAPI(
        buildingJourney.updateBuildingJourney(
          {
            ...val,
            buildingSteps: val.buildingSteps.map((step) => step.value),
          },
          journeyId
        )
      );
      setIsLoading(false);
      await refreshSingleData();
      setIsModalOpen(false);
      toast.success('Building journey updated successfully');
    } catch (error: any) {
      setIsLoading(false);
      setApiError(error.message);
      console.error(error.message);
      toast.error(error.message);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={journeyId ? handleUpdateBuildingJourney : handleFormSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <NewDocumentWrapper>
                <InfoRowStyled>
                  <Typography
                    fontFamily="roboto"
                    size="normal"
                    fontWeight="medium"
                    color="dark.neutral_45"
                  >
                    Name <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Input
                    name="name"
                    value={values.name}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                  />
                  {errors.name && touched.name && (
                    <div style={{ color: 'red' }}>{errors.name}</div>
                  )}
                </InfoRowStyled>
                <InfoRowStyled>
                  <Typography
                    fontFamily="roboto"
                    size="normal"
                    fontWeight="medium"
                    color="dark.neutral_45"
                  >
                    Position <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Input
                    name="position"
                    value={values.position}
                    onChange={(e) => setFieldValue('position', e.target.value)}
                  />
                  {errors.position && touched.position && (
                    <div style={{ color: 'red' }}>{errors.position}</div>
                  )}
                </InfoRowStyled>
                <InfoRowStyled>
                  <Typography
                    fontFamily="roboto"
                    size="normal"
                    fontWeight="medium"
                    color="dark.neutral_45"
                  >
                    Building Steps <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions
                    value={values.buildingSteps}
                    loadOptions={loadBuildingStepOptions}
                    onChange={(selected) => {
                      setFieldValue('buildingSteps', selected);
                    }}
                  />
                  {typeof errors.buildingSteps === 'string' &&
                    errors.buildingSteps &&
                    touched.buildingSteps && (
                      <div style={{ color: 'red' }}>{errors.buildingSteps}</div>
                    )}
                </InfoRowStyled>
              </NewDocumentWrapper>
              <BottomContainerStyled>
                <Button
                  radius="md"
                  variant="ghost"
                  style={{
                    border: `1px solid ${theme.colors.dark.neutral_250}`,
                    borderRadius: '10px',
                    color: theme.colors.text,
                    fontFamily: theme.fontFamily.roboto,
                    fontWeight: theme.fontWeights.medium,
                  }}
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  radius="normal"
                  style={{ backgroundColor: theme.colors.brandColorBlue }}
                >
                  {journeyId ? 'Edit Building Journey' : 'Save'}
                </Button>
              </BottomContainerStyled>
              {apiError && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                  {apiError}
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateBuildingJourney;
