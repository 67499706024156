import { useRef } from 'react';

import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import Input from '@/designComponents/Input';
// import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import useDetectOutsideClick from '@/hooks/useDetectOutsideClick';

import Badge from './Badge';
// import { dropDownOption } from './data';
import SearchResult from './SearchResult';
import { TableSearchWrapper } from './style';

type Props = {
  setClickedBadgeType?: React.Dispatch<React.SetStateAction<string>>;
  clickedBadgeType?: string;
  inputPlaceHolder?: string;
  hidebadgeData?: boolean;
  badgeData?: Array<{ title: string; value: string }>;
  hideResultIcon?: boolean;
  searchText?: string;
  setSearchValue: (value: string) => void;
  searchValue: string;
  searchData: Array<{
    badgeType: string;
    data: Array<{ title: string; category: string }>;
  }>;

  selectedBatchActionOption?: string | number;

  onBatchActionSelect?: (value: string | number) => void;
};
const TableSearchTop = ({
  searchValue,
  setSearchValue,
  searchData,
  // onBatchActionSelect,
  // selectedBatchActionOption,
  searchText,
  inputPlaceHolder,
  badgeData,
  hidebadgeData = false,
  hideResultIcon = false,
  clickedBadgeType,
  setClickedBadgeType,
}: Props) => {
  const containerRef = useRef(null);

  const [isOpen, setIsOpen] = useDetectOutsideClick(containerRef, false) as [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];
  const handleClick = (val: string) => {
    if (setClickedBadgeType) {
      setClickedBadgeType(val);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const reducedData = searchData.reduce((prev: any[], curr) => {
    if (clickedBadgeType && curr.badgeType === clickedBadgeType) {
      prev.push(...curr.data);
    } else if (!clickedBadgeType) {
      prev.push(...curr.data);
    }
    return prev;
  }, []);

  const filteredData = reducedData.filter((search) =>
    search.title.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <TableSearchWrapper $isOpen={isOpen} ref={containerRef}>
      <div className="input-with-badge">
        <Input
          iconLeft={<SearchSVG />}
          placeholder={inputPlaceHolder || 'Search documents...'}
          value={searchValue}
          onChange={handleChange}
          onClick={() => setIsOpen(true)}
        />
        <div className="search-result">
          {!hidebadgeData && (
            <div className="input-badge">
              {badgeData &&
                badgeData.map((badge, idx) => (
                  <Badge
                    key={idx}
                    title={badge.title}
                    value={badge.value}
                    onClick={handleClick}
                    isClicked={badge.value === clickedBadgeType}
                  />
                ))}
            </div>
          )}

          {searchValue.length > 0 || clickedBadgeType ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
              }}
            >
              {filteredData.length > 0 ? (
                filteredData.map((search, idx) => (
                  <SearchResult
                    key={idx}
                    category={search.category}
                    title={search.title}
                    searchTerm={searchValue}
                    hideResultIcon={hideResultIcon}
                  />
                ))
              ) : (
                <Typography
                  size="title"
                  color="dark.neutral_60"
                  fontWeight="medium"
                  style={{ textAlign: 'center' }}
                >
                  No search results
                </Typography>
              )}
            </div>
          ) : (
            <div
              className="search-with-icon"
              style={{
                marginTop: hidebadgeData ? '70px' : '',
              }}
            >
              <SearchSVG
                style={{
                  height: '40px',
                  width: '40px',
                }}
              />
              <Typography size="normal" color="dark.neutral_40">
                {searchText || 'Search Documents'}
              </Typography>
            </div>
          )}
        </div>
      </div>
      {/* <Select
        options={dropDownOption}
        placeholder="Batch Action"
        onSelect={(value: string | number) => {
          if (onBatchActionSelect) {
            onBatchActionSelect(value);
          }
        }}
        selected={selectedBatchActionOption}
      /> */}
    </TableSearchWrapper>
  );
};

export default TableSearchTop;
