import { ConfigType } from '@/utils/requestAPI';

export type addHowToVideoType = {
  title: string;
  link: string;
  duration: number | string;
  description: string;
  imageUrl: string;
  category: string;
  contentSection: string[];
};

export type PaginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
};

const videos = {
  addHowToVideo: (data: addHowToVideoType): ConfigType => {
    return {
      method: 'post',
      url: '/how-to-video',
      data,
    };
  },
  updateHowToVideo: (data: addHowToVideoType, id: string): ConfigType => {
    return {
      method: 'put',
      url: `/how-to-video/${id}`,
      data,
    };
  },
  addHowToVideoCategoryClass: (data: { name: string }): ConfigType => {
    return {
      method: 'post',
      url: '/video-content-section',
      data,
    };
  },
  updateHowToVideoCategoryClass: (
    data: { name: string },
    id: string
  ): ConfigType => {
    return {
      method: 'put',
      url: `/video-content-section/${id}`,
      data,
    };
  },
  addHowToVideoCategory: (data: {
    name: string;
    contentSection: string[];
    icon: string;
    position: string;
  }): ConfigType => {
    return {
      method: 'post',
      url: '/video-category',
      data,
    };
  },
  updateHowToVideoCategory: (
    data: {
      name: string;
      contentSection: string[];
      icon: string;
      position: string;
    },
    id: string
  ): ConfigType => {
    return {
      method: 'put',
      url: `/video-category/${id}`,
      data,
    };
  },
  getAllHowToVideo: (params?: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/how-to-video',
      params,
    };
  },
  getAllHowToVideoCategoryClass: (params?: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/video-content-section',
      params,
    };
  },
  getAllHowToVideoCategory: (params?: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/video-category',
      params,
    };
  },
  getSingleHowToVideoCategoryClass: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/video-content-section/${id}`,
    };
  },
  getSingleHowToVideoCategory: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/video-category/${id}`,
    };
  },
  getSingleHowToVideo: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/how-to-video-by/${id}`,
    };
  },
};

export default videos;
