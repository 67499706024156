import { createSlice } from '@reduxjs/toolkit';

import { getAllAdmins } from './functions';

const initialState: any = {
  admins: [],
  pagination: {
    total: 0,
    limit: 10,
    currentPage: 0,
    totalPages: 1,
  },
  error: null,
  loading: false,
};

const adminSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAdmins.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAdmins.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch users. Try again later.';
      })
      .addCase(getAllAdmins.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.admins = action.payload.data;
        state.pagination = action.payload.pagination;
      });
  },
});

export const adminReducers = adminSlice.reducer;
