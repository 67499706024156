import Typography from '@/designComponents/Typography';

import { BadgeWrapper } from './style';

type Props = {
  title: string;
  value: string;
  onClick?: (val: string) => void;
  isClicked: boolean;
};
const Badge = ({ title, onClick, value, isClicked }: Props) => {
  const handleClick = () => {
    if (onClick) {
      onClick(value);
    }
  };
  return (
    <BadgeWrapper onClick={handleClick} $isClicked={isClicked}>
      <Typography
        size="normal"
        color={isClicked ? 'dark.neutral_60' : 'dark.neutral_45'}
        fontWeight={isClicked ? 'medium' : 'normal'}
      >
        {title}
      </Typography>
    </BadgeWrapper>
  );
};

export default Badge;
