import slugify from 'slugify';

const SLUGIFY_DEFAULT_CONFIG = {
  lower: true,
  replacement: '-',
  strict: true,
  remove: /[^a-zA-Z0-9\s]/g,
};

export const showRespectiveHashtag = (
  hashTags: string[],
  hashTagOption: Array<{ label: string; value: string }>
) => {
  const storedHashTags =
    hashTagOption &&
    hashTagOption.reduce(
      (prev: Array<{ label: string; value: string }>, curr) => {
        if (hashTags && hashTags.includes(curr.value)) {
          prev.push(curr);
        }
        return prev;
      },
      [] as Array<{ label: string; value: string }>
    );
  return storedHashTags;
};

export const switchBodyContent = (
  contentType: string,
  body: {
    content: string;
    contentType: string;
  }
) => {
  if (contentType === body?.contentType) {
    return body?.content;
  }
  return null;
};

export const generateSlug = (value: string) => {
  const generatedSlug = slugify(value, SLUGIFY_DEFAULT_CONFIG);

  return generatedSlug;
};
