import { LinkNode } from '@lexical/link';
import { NodeKey } from 'lexical';

export class CustomLinkNode extends LinkNode {
  static getType(): string {
    return 'custom-link';
  }

  static clone(node: CustomLinkNode): CustomLinkNode {
    const clone = new CustomLinkNode(node.__url, node.__key);
    clone.__target = node.__target;
    clone.__rel = node.__rel;
    return clone;
  }

  constructor(url: string, key?: NodeKey) {
    super(url, key);
    this.__target = '_blank';
    this.__rel = 'noopener noreferrer';
  }

  createDOM(): HTMLElement {
    const dom = super.createDOM();
    dom.setAttribute('target', this.__target);
    dom.setAttribute('rel', this.__rel);
    dom.setAttribute('title', this.__url);
    return dom;
  }
}
