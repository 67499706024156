import React, { useState } from 'react';

import DropdownButton from '@/designComponents/DropdownButton';

import CreateBuildingSteps from './BuildingStep/CreateBuildingSteps';
import CreateBuildingJourney from './CreateBuildingJourney';

const AddBuildingJourneyModalContent = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <React.Fragment>
      <DropdownButton
        options={[
          {
            label: 'Create Building journey',
            modalContent: {
              title: 'Create Building journey',
              modalChildren: CreateBuildingJourney,
            },
          },
          {
            label: 'Create Building Step',
            modalContent: {
              title: 'Create Building Step',
              modalChildren: CreateBuildingSteps,
            },
          },
        ]}
        title="New Journey"
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </React.Fragment>
  );
};

export default AddBuildingJourneyModalContent;
