import { ITypography, ITypographyComponent, StyledTypography } from './style';

const Typography = ({
  children,
  variant = 'p',
  size = 'normal',
  className,
  color = 'dark.black',
  style,
  ...rest
}: ITypography & ITypographyComponent) => {
  return (
    <StyledTypography
      as={variant}
      size={size}
      className={className}
      color={color}
      style={style}
      {...rest}
    >
      {children}
    </StyledTypography>
  );
};

export default Typography;
