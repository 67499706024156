// store/documentSlice.ts
import { createSlice } from '@reduxjs/toolkit';

import { toKebabCase } from '@/utils/conversion';

import {
  addDocument,
  addGroupDocument,
  deleteDocument,
  getAllDocument,
  getAllDocumentWithAnalytics,
  getAllGroupDocument,
  getSingleDocument,
  getSingleDocumentParent,
  updateDocument,
} from './functions';

const initialState: any = {
  documents: [],
  analytics: {},
  documentGroup: [],
  singleDocument: {},
  parentList: {},
  pagination: {
    total: 0,
    limit: 10,
    currentPage: 1,
    totalPages: 1,
  },
  error: null,
  loading: false,
};

const documentSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addDocument.rejected, (state, action) => {
        state.loading = false;

        state.error = action.payload;
        console.log('i am store error', action.payload);
      })
      .addCase(addDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.documents = action.payload;
      });
    builder
      .addCase(deleteDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.loading = false;

        state.error =
          (typeof action.payload === 'string' && action.payload) ||
          'Failed to add document. Try again later.';
      })
      .addCase(deleteDocument.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(updateDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDocument.rejected, (state, action) => {
        state.loading = false;

        state.error =
          (typeof action.payload === 'string' && action.payload) ||
          'Failed to add document. Try again later.';
      })
      .addCase(updateDocument.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(getAllDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllDocument.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ||
          'Failed to add group document. Try again later.';
      })
      .addCase(getAllDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.documents = action.payload.data;
        state.pagination = action.payload.pagination;
      });
    builder
      .addCase(getSingleDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleDocument.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ||
          'Failed to add group document. Try again later.';
      })
      .addCase(getSingleDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.singleDocument = action.payload;
      });
    builder
      .addCase(getSingleDocumentParent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleDocumentParent.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ||
          'Failed to add group document. Try again later.';
      })
      .addCase(getSingleDocumentParent.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.parentList = action.payload;
      });

    builder
      .addCase(addGroupDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addGroupDocument.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ||
          'Failed to add group document. Try again later.';
      })
      .addCase(addGroupDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.documentGroup.push(action.payload);
      });

    builder
      .addCase(getAllGroupDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllGroupDocument.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ||
          'Failed to add group document. Try again later.';
      })
      .addCase(getAllGroupDocument.fulfilled, (state, action) => {
        // const updatedGroup: Record<string, any[]> =
        //   { ...state.documentGroup } || {};
        // if (!action?.payload?.parent) {
        //   updatedGroup.parent = action?.payload?.data;
        // } else {
        //   updatedGroup[action.payload.parent] = action.payload.data;
        // }
        state.loading = false;
        state.error = null;
        state.documentGroup = action.payload;
        // state.documentGroup = updatedGroup;
      });
    builder
      .addCase(getAllDocumentWithAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllDocumentWithAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ||
          'Failed to get documents with count. Try again later.';
      })
      .addCase(getAllDocumentWithAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.documents = action.payload.documents.data;
        state.pagination = action.payload.documents.pagination;
        state.analytics = action.payload.analytics
          ? Object.entries(action.payload.analytics).reduce(
              (acc: any, [key, value]: any) => {
                acc[toKebabCase(key)] = { key, value };
                return acc;
              },
              {}
            )
          : {};
      });
  },
});

export const documentReducers = documentSlice.reducer;
