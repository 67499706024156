import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  padding: 30px;
  max-width: fit-content;
  margin: 0 auto;
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: move;
  user-select: none;
  padding: 16px;
  margin: 0 0 8px 0;
  border-radius: 4px;
  width: 600px;
  background-color: aliceblue;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
`;
