import React from 'react';
import { Outlet } from 'react-router-dom';

import PublicHeader from './Header/PublicHeader';
import { PublicBodyStyled, PublicLayoutStyled } from './styles';
import { ILayoutProps } from './type';

export interface ILandingPageLayoutProps extends ILayoutProps {}

const PublicLayout: React.FunctionComponent<ILandingPageLayoutProps> = ({
  children,
}) => {
  return (
    <PublicLayoutStyled>
      <PublicHeader />
      <PublicBodyStyled>{children || <Outlet />}</PublicBodyStyled>
    </PublicLayoutStyled>
  );
};

export default PublicLayout;
