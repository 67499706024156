import { createBrowserRouter } from 'react-router-dom';

import CheckAuthElement from '@/components/CheckAuthElement';
import ProtectedComponent from '@/components/ProtectedComponent';
import configs from '@/configs';

import App from '../App';
import routesObjects from './routes';

const renderNestedRoutes = (route: any) => {
  if ('children' in route && Array.isArray(route.children)) {
    return route.children.map((child: any) => ({
      path: child.path,
      children: [
        {
          index: true,
          element: <child.component />,
          // errorElement: <ErrorBoundary />,
        },
        {
          path: '',
          children: renderNestedRoutes(child),
        },
      ],
    }));
  }
  return null;
};

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <App />,
      children: routesObjects.map((route) => {
        if (route?.path === '*') {
          return {
            path: route?.path,
            element: <route.component />,
          };
        }
        return {
          path: route?.path,
          // errorElement: <ErrorBoundary />,
          element: route.isProtected ? (
            <ProtectedComponent
              allowed={Array.isArray(route?.allowTo) ? route.allowTo : []}
            >
              <route.layout extraInfo={route.extraInfo || null} />
            </ProtectedComponent>
          ) : route.redirectToOnAuth ? (
            <CheckAuthElement redirectTo={route.redirectToOnAuth}>
              <route.layout extraInfo={route.extraInfo || null} />
            </CheckAuthElement>
          ) : (
            <route.layout extraInfo={route.extraInfo || null} />
          ),
          children: [
            {
              index: true,
              element: <route.component />,
              // errorElement: <ErrorBoundary />,
            },
            {
              path: '',
              children: renderNestedRoutes(route),
            },
          ],
        };
      }),
    },
  ],
  {
    basename: configs.baseName,
  }
);

export default router;
