import React from 'react';
import { useTheme } from 'styled-components';

import { ReactComponent as MailSVG } from '@/assets/icons/mail.svg';
import Typography from '@/designComponents/Typography';

import { EventsWrapper } from '../../pages/Users/Details/style';

type Props = {
  events: string[];
  isEmail?: boolean;
};
const Events = ({ events, isEmail = false }: Props) => {
  const theme = useTheme();
  return (
    <EventsWrapper>
      {events.map((event, idx) => (
        <React.Fragment key={idx}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {isEmail && <MailSVG />}
            <Typography
              fontFamily="roboto"
              fontWeight="normal"
              size="normal"
              color="dark.neutral_450"
            >
              {event}
            </Typography>
          </div>
          {events.length - 1 !== idx && (
            <hr
              style={{
                backgroundColor: theme.colors.dark.neutral_50,
                border: 'none',
                height: '1px',
              }}
            />
          )}
        </React.Fragment>
      ))}
    </EventsWrapper>
  );
};

export default Events;
