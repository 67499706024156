import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import advertiseAPI from '@/apiConfigs/ads';
import Button from '@/designComponents/Button';
import Loader from '@/designComponents/Loader';
import Modal from '@/designComponents/Modal';
import Typography from '@/designComponents/Typography';
import theme from '@/theme';
import { humanReadableDate } from '@/utils/date';
import requestAPI from '@/utils/requestAPI';

import CreateAdvertiser from '../../CreateAdvertiser';
import { AdvertiseDetailsWrapper } from '../../Details/style';

export type Advertisement = {
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  website: string;
  createdAt: string;
  updatedAt: string;
};

type Props = {};

const AdvertisementDetails = (_props: Props) => {
  const { advertiseId } = useParams();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [advertise, setAdvertise] = useState<Advertisement | null>(null);
  const [isFetchingAdvertiser, setIsFetchingAdvertiser] = useState(false);

  const getSingleAdvertise = async () => {
    try {
      setIsFetchingAdvertiser(true);
      const data = await requestAPI(
        advertiseAPI.getSingleAdvertiser(advertiseId)
      );
      setAdvertise(data);
    } catch (error) {
      console.error('Failed to fetch advertisement:', error);
    } finally {
      setIsFetchingAdvertiser(false);
    }
  };

  useEffect(() => {
    if (advertiseId) {
      getSingleAdvertise();
    }
  }, [advertiseId]);

  return (
    <AdvertiseDetailsWrapper>
      {!isFetchingAdvertiser ? (
        <div className="main-content">
          <div className="heading">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                fontFamily="jost"
                fontWeight="medium"
                size="heading"
                color="dark.neutral_60"
              >
                Advertiser Details
              </Typography>
              <Button radius="md" onClick={() => setIsEditModalOpen(true)}>
                Edit Advertiser
              </Button>
            </div>
            {isEditModalOpen && (
              <Modal
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                title="Edit Advertiser"
              >
                <CreateAdvertiser
                  isOpen={isEditModalOpen}
                  onClose={() => setIsEditModalOpen(false)}
                />
              </Modal>
            )}
            <hr
              style={{
                backgroundColor: theme.colors.dark.neutral_50,
                border: 'none',
                height: '1px',
              }}
            />
          </div>
          <div className="main-text">
            <div className="text">
              <Typography className="text-value">Name</Typography>
              <Typography className="text-value">
                {advertise?.fullName}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Email</Typography>
              <Typography className="text-value">{advertise?.email}</Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Phone Number</Typography>
              <Typography className="text-value">
                {advertise?.phoneNumber}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Created Date</Typography>
              <Typography className="text-value">
                {humanReadableDate(advertise?.createdAt)}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Updated Date</Typography>
              <Typography className="text-value">
                {humanReadableDate(advertise?.updatedAt)}
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '400px',
            width: '100%',
          }}
        >
          <Loader
            style={{
              height: 60,
              width: 60,
            }}
          />
        </div>
      )}
    </AdvertiseDetailsWrapper>
  );
};

export default AdvertisementDetails;
