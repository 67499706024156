import React from 'react';

import Item from './Item';
import menuList from './sidebarMenu';
import { Wrapper } from './style';

type Props = {};

const Sidebar: React.FC<Props> = (_props) => {
  return (
    <Wrapper>
      <div className="menu-container">
        {menuList?.map((menu) => <Item key={menu.key} menu={menu} />)}
      </div>
    </Wrapper>
  );
};

export default Sidebar;
