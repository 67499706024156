import styled from 'styled-components';

export const LabelandOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 5px;
  position: relative;
`;

export const SelectBoxStyled = styled.div<{ $isHideBorder: boolean }>`
  width: 100%;
  height: 45px;
  padding: 12px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid
    ${({ theme, $isHideBorder }) =>
      $isHideBorder ? 'transparent' : theme.colors.lightGrey.neutral_e0};
  & .input {
    border: none;
    color: black;
    caret-color: transparent !important;
    background-color: transparent;
    cursor: default;
    width: 100%;
  }
  & input:focus {
    outline: none;
  }
`;

export const OptionWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  top: 50px;
  width: 100%;
  right: 0;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey.neutral_e0};
  background: white;

  border-radius: 5px;
  padding: 5px 0px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 300px;

  &::-webkit-scrollbar {
    width: 6px;
    background: ${({ theme }) => theme.colors.lightGrey};
    border-radius: 7.5px;
    margin: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.scrollThumb};
  }
`;
export const IndividualOption = styled.div<{ $selectedOption: boolean }>`
  cursor: pointer;
  /* padding: 12px; */
  padding: 9px;

  color: ${({ theme, $selectedOption }) =>
    $selectedOption ? theme.colors.brandColorBlue : ''};
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark.neutral_20};
    color: black;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;
export const SelectInput = styled.input`
  border: 0px;
  font-size: 14px;
  outline: none;
  &:focus {
    outline-color: none;
  }
`;
export const Error = styled.div`
  width: 100%;
  color: red;
  margin-bottom: 8px;
  margin-top: 8px;
`;
