import { NavigateFunction } from 'react-router-dom';

import Button from '@/designComponents/Button';
import {
  ANALYTIC_USER_DETAILS_ROUTE,
  DELETE_USER_DETAILS_ROUTE,
  FREE_USER_DETAILS_ROUTE,
  NEWSLETTER_USER_DETAILS_ROUTE,
  PROFFESIONAL_USER_DETAILS_ROUTE,
} from '@/routes/routePaths';
import { toCapitalCase } from '@/utils/conversion';

export type InitialValuesType = {
  documentTitle: string;
  documentType: string;
  parentTitle: string;
  icon: File[] | null;
  type: string;
  jurisdictions: string[];
};

export const detailsCardData = [
  {
    title: 'Number of Times Appeared in Search in UK',
    count: 583,
  },
  {
    title: 'Number of Times Appeared in Co. Down',
    count: 556,
  },
  {
    title: 'Number of Times Appeared in Search',
    count: 177,
  },
  {
    title: 'Number of Times “Get Manufacture” was searched',
    count: 816,
  },
  {
    title: 'Profile Views',
    count: 185,
  },
  {
    title: 'Most Viewed Projects',
    count: 540,
  },
  {
    title: 'Number of Enquires',
    count: 429,
  },
  {
    title: 'Number of Posts Saved',
    count: 994,
  },
];
export const deleteUserCardData = [
  {
    title: 'Number of Times Appeared in Search',
    count: 177,
  },
  {
    title: 'Profile Views',
    count: 185,
  },
  {
    title: 'Most Viewed Projects',
    count: 540,
  },
  {
    title: 'Number of Enquires',
    count: 429,
  },
  {
    title: 'Number of Posts Saved',
    count: 994,
  },
];

export const eventsData = [
  'nvt.isst.nute@gmail.com’s invoice for €7,394.00 was sent.',
  'A draft invoice for €6,89,008.00 to tienlapspktnd@gmail.com was finalized.',
  'trungkienspktnd@gamail.com’s invoice changed.',
  'A new payment pi_e463446378449fbcbDgdrtscCv for €390.67 was created.',
  'manhhachkt08@gmail.com’s invoice changed.',
  'tranthuy.nute@gmail.com’s invoice changed.',
  'thuhang.nute@gmail.com applied the First purchase discount coupon',
];
export const sentEmailData = [
  'Invoice was sent to binhan628@gmail.com.',
  'New mail was sen to nvt.isst.nute@gmail.com.',
  'trungkienspktnd@gamail.com’s invoice changed.',
  'Invoice was ckctm12@gmail.com.',
  'Invoice was tienlapspktnd@gmail.com.',
  'tranthuy.nute@gmail.com’s invoice changed.',
  'Invoice was thuhang.nute@gmail.com.',
];

export const paymentsData: Array<{
  price: string;
  status: 'completed' | 'inComplete';
  statusTitle: string;
  date: string;
  paymentFor: string;
}> = [
  {
    date: 'Mar 03',
    paymentFor: 'Invoice',
    price: '64,347.00',
    status: 'inComplete',
    statusTitle: 'Incomplete',
  },
  {
    date: 'Mar 03',
    paymentFor: 'Invoice',
    price: '34,637.57',
    status: 'completed',
    statusTitle: 'Completed',
  },
];

export const dropDownOption: Array<{
  value: string;
  label: string;
}> = [
  {
    label: 'Go Green',
    value: 'go-green',
  },
  {
    label: 'Building Guides',
    value: 'building-guide',
  },
  {
    label: 'Building System',
    value: 'building-system',
  },
  {
    label: 'Download Center',
    value: 'download-center',
  },
];

export const professionalBreadCrumbData = (
  label?: string
): Array<{
  label: string;
  path: string;
}> => [
  {
    label: 'Accounts',
    path: '/users',
  },
  {
    label: `${toCapitalCase(label)} User`,
    path: PROFFESIONAL_USER_DETAILS_ROUTE,
  },
];
export const deleteUserBreadCrumbData: Array<{
  label: string;
  path: string;
}> = [
  {
    label: 'Accounts',
    path: '/users',
  },
  {
    label: 'Account Deletion Request',
    path: DELETE_USER_DETAILS_ROUTE,
  },
];
export const newsLetterUserBreadCrumbData: Array<{
  label: string;
  path: string;
}> = [
  {
    label: 'Accounts',
    path: '/users',
  },
  {
    label: 'Newsletter Users',
    path: NEWSLETTER_USER_DETAILS_ROUTE,
  },
];
export const analyticUserBreadCrumbData: Array<{
  label: string;
  path: string;
}> = [
  {
    label: 'Accounts',
    path: '/users',
  },
  {
    label: 'Users',
    path: ANALYTIC_USER_DETAILS_ROUTE,
  },
];
export const freeUserBreadCrumbData: Array<{
  label: string;
  path: string;
}> = [
  {
    label: 'Accounts',
    path: '/users',
  },
  {
    label: 'Free Users',
    path: FREE_USER_DETAILS_ROUTE,
  },
];

export const data = (navigate: NavigateFunction) => [
  {
    id: '1',
    name: 'Karamat',
    email: 'karamatullah46@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`professional/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '1',
    name: 'Pete',
    email: 'atalltimes@hotmail.co.uk',
    loginCount: '2',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`professional/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '3',
    name: 'Kiko',
    email: 'kiko@email.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    name: 'Hannah',
    email: 'hannahhealyconsulting@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '5',
    name: 'Roisin',
    email: 'roisinannwalsh@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    name: 'Éamonn',
    email: 'eamonnmhac@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    name: 'Hannah',
    email: 'hannahhealyconsulting@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    name: 'Hannah',
    email: 'hannahhealyconsulting@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    name: 'Hannah',
    email: 'hannahhealyconsulting@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    name: 'Hannah',
    email: 'hannahhealyconsulting@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    name: 'Hannah',
    email: 'hannahhealyconsulting@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    name: 'Hannah',
    email: 'hannahhealyconsulting@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    name: 'Hannah',
    email: 'hannahhealyconsulting@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    name: 'Hannah',
    email: 'hannahhealyconsulting@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    name: 'Hannah',
    email: 'hannahhealyconsulting@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '5',
    name: 'IacMxibJn',
    email: 'bettypope856@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '6',
    name: 'Shane',
    email: 'ksgconstruction@gmail.com',
    loginCount: '0',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button
          variant="gray"
          radius="normal"
          onClick={() => navigate(`newsLetter/${1}`)}
        >
          View
        </Button>

        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
];
