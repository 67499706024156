import { createAsyncThunk } from '@reduxjs/toolkit';

import dashboard from '@/apiConfigs/dashboard';
import requestAPI from '@/utils/requestAPI';

export const getCount = createAsyncThunk('dashboard/getCount', async () => {
  const response = await requestAPI(dashboard.countData());
  console.log(response, 'response from dashboard');

  return response;
});
