import { createSlice } from '@reduxjs/toolkit';

import {
  addHowToVideos,
  getAllHowToVideos,
  getSingleHowToVideos,
} from './functions';

export type Videos = {
  videos: {
    data: {
      title: string;
      link: string;
      duration: string;
      author: string;
      description: string;
      category: {
        name: string;
        _id: string;
      };
      subCategory: string[];
      _id: string;
      createdAt: string;
      updatedAt: string;
      slug: string;
      __v: number;
    }[];
    pagination: {
      status: boolean;
      total: number;
      totalPages: number;
      currentPage: number;
      limit: string;
    };
  };
  analytics: {
    category: number;
    contentSection: number;
  };
};

export type SingleVideo = {
  _id: string;
  title: string;
  link: string;
  duration: string;
  author: string;
  imageUrl: string;
  description: string;
  category: {
    name: string;
    _id: string;
  };
  contentSection: Array<{
    name: string;
    _id: string;
  }>;
  createdAt: string;
  updatedAt: string;
  slug: string;
  __v: number;
};
type InitialState = {
  videos: Videos | null;
  singleVideo: SingleVideo | null;
  error: string | null;
  loading: boolean;
  pagination: {
    total: number;
    limit: number;
    currentPage: number;
    totalPages: number;
  };
};

const initialState: InitialState = {
  videos: null,
  singleVideo: null,
  error: null,
  loading: false,
  pagination: {
    total: 0,
    limit: 11,
    currentPage: 0,
    totalPages: 1,
  },
};

const howToVideoSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addHowToVideos.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      addHowToVideos.fulfilled,
      (state: typeof initialState, action) => {
        state.loading = false;
        state.error = null;
        state.videos = action.payload;
      }
    );
    builder.addCase(addHowToVideos.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to add How To Videos. Try again later.';
    });

    builder.addCase(getAllHowToVideos.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllHowToVideos.fulfilled,
      (state: typeof initialState, action) => {
        state.loading = false;
        state.error = null;
        state.videos = action.payload;
      }
    );
    builder.addCase(getAllHowToVideos.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to add How To Videos. Try again later.';
    });
    builder.addCase(getSingleHowToVideos.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getSingleHowToVideos.fulfilled,
      (state: typeof initialState, action) => {
        state.loading = false;
        state.error = null;
        state.singleVideo = action.payload;
      }
    );
    builder.addCase(getSingleHowToVideos.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to add How To Videos. Try again later.';
    });
  },
});

export const howToVideoReducer = howToVideoSlice.reducer;
