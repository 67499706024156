import Typography from '@/designComponents/Typography';
import { humanReadableDateWithTime } from '@/utils/date';

import { InformationRowWrapper } from '../../pages/Users/Details/style';

type Props = {
  id?: string;
  businessName?: string;
  businessLogo?: string;
  businessType?: string;
  yearsOfTrading?: string;
  businessAddress?: string;
  createdAt?: string;
  updateAt?: string;
};
const BusinessDetails = ({
  createdAt,
  // id,
  updateAt,
  businessAddress,
  businessName,
  businessType,
  businessLogo,
}: Props) => {
  return (
    <InformationRowWrapper>
      {/* <div className="text">
        <Typography
          fontFamily="roboto"
          fontWeight="normal"
          color="dark.neutral_450"
          size="normal"
        >
          ID
        </Typography>
        <Typography
          fontFamily="roboto"
          fontWeight="normal"
          color="dark.neutral_450"
          size="normal"
        >
          {id}
        </Typography>
      </div> */}
      <div className="text">
        <Typography
          fontFamily="roboto"
          fontWeight="normal"
          color="dark.neutral_450"
          size="normal"
        >
          Business Name
        </Typography>
        <Typography
          fontFamily="roboto"
          fontWeight="normal"
          color="dark.neutral_450"
          size="normal"
        >
          {businessName}
        </Typography>
      </div>
      <div className="text">
        <Typography
          fontFamily="roboto"
          fontWeight="normal"
          color="dark.neutral_450"
          size="normal"
        >
          Business Type
        </Typography>
        <Typography
          fontFamily="roboto"
          fontWeight="normal"
          color="dark.neutral_450"
          size="normal"
        >
          {businessType}
        </Typography>
      </div>
      {/* <div className="text">
        <Typography
          fontFamily="roboto"
          fontWeight="normal"
          color="dark.neutral_450"
          size="normal"
        >
          Years of Trading
        </Typography>
        <Typography
          fontFamily="roboto"
          fontWeight="normal"
          color="dark.neutral_450"
          size="normal"
        >
          {yearsOfTrading}
        </Typography>
      </div> */}
      <div className="text">
        <Typography
          fontFamily="roboto"
          fontWeight="normal"
          color="dark.neutral_450"
          size="normal"
        >
          Business Address
        </Typography>
        <Typography
          fontFamily="roboto"
          fontWeight="normal"
          color="dark.neutral_450"
          size="normal"
        >
          {businessAddress}
        </Typography>
      </div>
      {createdAt && (
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Created At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            {humanReadableDateWithTime(createdAt)}
          </Typography>
        </div>
      )}
      {updateAt && (
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Update At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            {humanReadableDateWithTime(updateAt)}
          </Typography>
        </div>
      )}

      <div className="text" style={{ alignItems: 'start' }}>
        <Typography className="text-value">Business Logo</Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px solid gray',
            borderRadius: '10px',
            width: '120px',
          }}
        >
          <img
            src={businessLogo}
            alt="content image"
            style={{
              objectFit: 'contain',
              width: '100%',
              borderRadius: '10px',
            }}
          />
        </div>
      </div>
    </InformationRowWrapper>
  );
};

export default BusinessDetails;
