import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import * as yup from 'yup';

import videos from '@/apiConfigs/videos';
import HeadingTitle from '@/components/UserDetails/HeadingTitle';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import { InputContainerStyled } from '@/designComponents/Input/styles';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import MultiSelect from '@/designComponents/MultiSelect';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import UploadFile from '@/designComponents/UploadFile';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  getAllHowToVideos,
  getSingleHowToVideos,
} from '@/store/videos/HowToVideos/functions';
import { Videos } from '@/store/videos/HowToVideosCategory';
import requestAPI from '@/utils/requestAPI';
import { uploadFilesToS3OnlyKeys } from '@/utils/s3Upload';

import { HowToVideosFormWrapper, InfoRowStyled, TextAreaStyled } from './style';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
};

type InitialValueType = {
  title: string;
  link: string;
  duration: number;
  description: string;
  imageUrl: File | null | string;
  category: string;
  contentSection: string[];
};

const howToVideosSchema = yup.object().shape({
  title: yup
    .string()
    .required('Title is required')
    .typeError('Title should be string'),
  link: yup
    .string()
    .required('Link is required')
    .typeError('Link should be string'),
  imageUrl: yup
    .string()
    .required('Thumbnail is required')
    .typeError('Thumbnail should be string'),
  description: yup
    .string()
    .required('Description is required')
    .typeError('Description should be string'),
  duration: yup
    .number()
    .required('Duration is required')
    .typeError('Duration should be number')
    .min(1, 'Duration should be greater than 0'),
  category: yup
    .string()
    .required('Category is required')
    .typeError('Category should be string'),
  contentSection: yup
    .array()
    .of(yup.string())
    .required('Content Section IDs are required')
    .min(1, 'At least one content section ID is required'),
});
const AddHowToVideos = ({ setIsModalOpen }: Props) => {
  const theme = useTheme();

  const { videoId } = useParams();
  const formikRef = useRef<FormikProps<InitialValueType>>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [initialValues, setInitialValues] = useState<InitialValueType>({
    category: '',
    description: '',
    duration: 0,
    link: '',
    title: '',
    imageUrl: null,
    contentSection: [],
  });

  const [videoCategory, setVideoCategory] = useState<Videos['data']>([]);
  const [videoSection, setVideoSection] = useState<Videos['data']>([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { singleVideo } = useAppSelector((state) => state.howToVideos);

  useEffect(() => {
    if (videoId && singleVideo) {
      setInitialValues({
        category: singleVideo.category._id,
        description: singleVideo.description,
        duration: Number(singleVideo.duration),
        imageUrl: singleVideo.imageUrl,
        link: singleVideo.link,
        title: singleVideo.title,
        contentSection: singleVideo.contentSection.map((video) => video._id),
      });
    }
  }, [videoId, singleVideo]);

  const handleContainerClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const getAllVideoCategory = async () => {
    try {
      const res = await requestAPI(
        videos.getAllHowToVideoCategory({
          limit: 20,
        })
      );
      setVideoCategory(res.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const getAllVideoSection = async () => {
    try {
      const res = await requestAPI(
        videos.getAllHowToVideoCategoryClass({
          limit: 20,
        })
      );
      setVideoSection(res.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getAllVideoSection();
  }, []);
  useEffect(() => {
    getAllVideoCategory();
  }, []);

  const handleFormSubmit = async (values: InitialValueType) => {
    setIsLoading(true);
    try {
      let imageURL = values.imageUrl;

      if (values.imageUrl instanceof File) {
        const uploadedImages = await uploadFilesToS3OnlyKeys([values.imageUrl]);
        imageURL = uploadedImages[0];
      }

      await requestAPI(
        videos.addHowToVideo({
          ...values,
          imageUrl: imageURL as string,
        })
      );

      setIsLoading(false);
      setIsModalOpen(false);
      toast.success('New video added successfully');
      await dispatch(getAllHowToVideos({}));
    } catch (error: any) {
      setIsLoading(false);
      console.error(error.message);
      toast.error(error.message);
    }
  };
  const handleUpdateVideo = async (values: InitialValueType) => {
    setIsLoading(true);
    try {
      let imageURL = values.imageUrl;

      if (values.imageUrl instanceof File) {
        const uploadedImages = await uploadFilesToS3OnlyKeys([values.imageUrl]);
        imageURL = uploadedImages[0];
      }

      await requestAPI(
        videos.updateHowToVideo(
          {
            ...values,
            imageUrl: imageURL as string,
            duration: values.duration.toString(),
          },
          videoId
        )
      );

      setIsLoading(false);
      dispatch(getSingleHowToVideos(videoId || ''));
      setIsModalOpen(false);
      toast.success('Video updated successfully');
    } catch (error: any) {
      setIsLoading(false);
      console.error(error.message);
      toast.error(error.message);
    }
  };

  return (
    <>
      <ModalContentWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={videoId ? handleUpdateVideo : handleFormSubmit}
          innerRef={formikRef}
          validationSchema={howToVideosSchema}
          enableReinitialize
        >
          {(formikProps: FormikProps<InitialValueType>) => {
            const { setFieldValue, values, touched, errors } = formikProps;

            return (
              <Form>
                <HowToVideosFormWrapper>
                  <HeadingTitle title="Video Details" />
                  <InfoRowStyled>
                    <Typography className="text">
                      Title <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="title"
                      value={values.title}
                      onChange={(e) => setFieldValue('title', e.target.value)}
                    />
                    {errors.title && touched.title && (
                      <div style={{ color: 'red' }}>{errors.title}</div>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="text">
                      Description <span style={{ color: 'red' }}>*</span>
                    </Typography>

                    <InputContainerStyled
                      onClick={handleContainerClick}
                      className="input-container"
                    >
                      <TextAreaStyled
                        rows={10}
                        value={values.description}
                        onChange={(e) =>
                          setFieldValue('description', e.target.value)
                        }
                        name="description"
                      />
                    </InputContainerStyled>

                    {errors.description && touched.description && (
                      <div style={{ color: 'red' }}>{errors.description}</div>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="text">
                      Thumbnail <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <UploadFile
                      name="imageUrl"
                      variant="previewImage"
                      onChange={(value) => setFieldValue('imageUrl', value)}
                      onRemove={() => {
                        setFieldValue('imageUrl', null);
                      }}
                      title=""
                      apiUrls={
                        videoId && typeof values.imageUrl === 'string'
                          ? [values.imageUrl]
                          : []
                      }
                    />
                    {typeof errors.imageUrl === 'string' &&
                      errors.imageUrl &&
                      touched.imageUrl && (
                        <div style={{ color: 'red' }}>{errors.imageUrl}</div>
                      )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="text">
                      Youtube Url <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="link"
                      value={values.link}
                      onChange={(e) => setFieldValue('link', e.target.value)}
                    />
                    {errors.link && touched.link && (
                      <div style={{ color: 'red' }}>{errors.link}</div>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="text">
                      Duration <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="duration"
                      type="number"
                      min="1"
                      value={values.duration}
                      onChange={(e) =>
                        setFieldValue('duration', e.target.value)
                      }
                    />
                    {errors.duration && touched.duration && (
                      <div style={{ color: 'red' }}>{errors.duration}</div>
                    )}
                  </InfoRowStyled>

                  <HeadingTitle title="Relationships" />

                  <InfoRowStyled>
                    <Typography className="text">
                      Category <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Select
                      name="category"
                      formikProps={formikProps}
                      options={videoCategory?.map((video) => ({
                        label: video?.name,
                        value: video?._id,
                      }))}
                      placeholder=""
                    />
                    {errors.category &&
                      touched.category &&
                      typeof errors.category === 'string' && (
                        <div style={{ color: 'red' }}>{errors.category}</div>
                      )}
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography className="text">
                      Content Section <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <MultiSelect
                      name="contentSection"
                      options={videoSection.map((video) => ({
                        label: video.name,
                        value: video._id,
                      }))}
                      formikProps={formikProps}
                    />

                    {errors.contentSection && touched.contentSection && (
                      <div style={{ color: 'red' }}>
                        {errors.contentSection}
                      </div>
                    )}
                  </InfoRowStyled>
                </HowToVideosFormWrapper>
              </Form>
            );
          }}
        </Formik>
      </ModalContentWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '20px',
          padding: '0 20px 20px',
        }}
      >
        <Button
          radius="md"
          variant="ghost"
          style={{
            border: `1px solid ${theme.colors.dark.neutral_250}`,
            borderRadius: '10px',
            color: theme.colors.text,
            fontFamily: theme.fontFamily.roboto,
            fontWeight: theme.fontWeights.medium,
          }}
          onClick={() => {
            if (setIsModalOpen) {
              setIsModalOpen(false);
            }
          }}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          radius="normal"
          isLoading={isLoading}
          onClick={() => formikRef.current?.handleSubmit()}
          style={{ backgroundColor: theme.colors.brandColorBlue }}
        >
          {videoId ? 'Edit Video' : 'Create How to video'}
        </Button>
      </div>
    </>
  );
};

export default AddHowToVideos;
