import styled from 'styled-components';

export const MainContainer = styled.div`
  max-width: 900px;

  & .heading {
    font-weight: 600;
    font-size: 20px;
    /* margin-bottom: 20px; */
  }
`;

export const FolderDetailContainerStyled = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TypeDetailContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: calc(100vh - 180px);
  overflow-y: auto;

  & .text {
    position: sticky;
    top: 0px;
    background-color: white;
  }
`;

export const InfoRowStyled = styled.div`
  display: flex;
  gap: 10px;

  & .title {
    width: 130px;
  }
`;
