/* eslint-disable */

export const editorTheme = {
  autocomplete: 'hbe__autocomplete',
  blockCursor: 'hbe__blockCursor',
  characterLimit: 'hbe__characterLimit',
  code: 'hbe__code',
  codeHighlight: {
    atrule: 'hbe__tokenAttr',
    attr: 'hbe__tokenAttr',
    boolean: 'hbe__tokenProperty',
    builtin: 'hbe__tokenSelector',
    cdata: 'hbe__tokenComment',
    char: 'hbe__tokenSelector',
    class: 'hbe__tokenFunction',
    'class-name': 'hbe__tokenFunction',
    comment: 'hbe__tokenComment',
    constant: 'hbe__tokenProperty',
    deleted: 'hbe__tokenProperty',
    doctype: 'hbe__tokenComment',
    entity: 'hbe__tokenOperator',
    function: 'hbe__tokenFunction',
    important: 'hbe__tokenVariable',
    inserted: 'hbe__tokenSelector',
    keyword: 'hbe__tokenAttr',
    namespace: 'hbe__tokenVariable',
    number: 'hbe__tokenProperty',
    operator: 'hbe__tokenOperator',
    prolog: 'hbe__tokenComment',
    property: 'hbe__tokenProperty',
    punctuation: 'hbe__tokenPunctuation',
    regex: 'hbe__tokenVariable',
    selector: 'hbe__tokenSelector',
    string: 'hbe__tokenSelector',
    symbol: 'hbe__tokenProperty',
    tag: 'hbe__tokenProperty',
    url: 'hbe__tokenOperator',
    variable: 'hbe__tokenVariable',
  },
  embedBlock: {
    base: 'hbe__embedBlock',
    focus: 'hbe__embedBlockFocus',
  },
  hashtag: 'hbe__hashtag',
  heading: {
    h1: 'hbe__h1',
    h2: 'hbe__h2',
    h3: 'hbe__h3',
    h4: 'hbe__h4',
    h5: 'hbe__h5',
    h6: 'hbe__h6',
  },
  list: {
    checklist: 'hbe__checklist',
    listitem: 'hbe__listItem',
    listitemChecked: 'hbe__listItemChecked',
    listitemUnchecked: 'hbe__listItemUnchecked',
    nested: {
      listitem: 'hbe__nestedListItem',
    },
    olDepth: ['hbe__ol1', 'hbe__ol2', 'hbe__ol3', 'hbe__ol4', 'hbe__ol5'],
    ul: 'hbe__ul',
    ulDepth: ['hbe__ul1', 'hbe__ul2', 'hbe__ul3', 'hbe__ul4', 'hbe__ul5'],
  },
  hr: 'hbe__hr',
  image: 'editor-image',
  indent: 'hbe__indent',
  inlineImage: 'inline-editor-image',
  layoutContainer: 'hbe__layoutContainer',
  layoutItem: 'hbe__layoutItem',
  link: 'hbe__link',

  ltr: 'hbe__ltr',
  mark: 'hbe__mark',
  markOverlap: 'hbe__markOverlap',
  paragraph: 'hbe__paragraph',
  quote: 'hbe__quote',
  rtl: 'hbe__rtl',
  table: 'hbe__table',
  tableAddColumns: 'hbe__tableAddColumns',
  tableAddRows: 'hbe__tableAddRows',
  tableCell: 'hbe__tableCell',
  tableCellActionButton: 'hbe__tableCellActionButton',
  tableCellActionButtonContainer: 'hbe__tableCellActionButtonContainer',
  tableCellEditing: 'hbe__tableCellEditing',
  tableCellHeader: 'hbe__tableCellHeader',
  tableCellPrimarySelected: 'hbe__tableCellPrimarySelected',
  tableCellResizer: 'hbe__tableCellResizer',
  tableCellSelected: 'hbe__tableCellSelected',
  tableCellSortedIndicator: 'hbe__tableCellSortedIndicator',
  tableResizeRuler: 'hbe__tableCellResizeRuler',
  tableSelected: 'hbe__tableSelected',
  tableSelection: 'hbe__tableSelection',
  text: {
    bold: 'hbe__textBold',
    code: 'hbe__textCode',
    italic: 'hbe__textItalic',
    strikethrough: 'hbe__textStrikethrough',
    subscript: 'hbe__textSubscript',
    superscript: 'hbe__textSuperscript',
    underline: 'hbe__textUnderline',
    underlineStrikethrough: 'hbe__textUnderlineStrikethrough',
  },
};
