import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 18px;
`;

export const ContentWrapperStyled = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  & .stat {
    padding: 10px;
    border-radius: 10px;
    /* color: #08979c; */
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const JobsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & .job {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  & .btn {
    max-width: 80px;
    border: 1px solid blue;
    color: blue;
    background-color: white;
  }
`;
