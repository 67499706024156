import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import { ScrollToTop } from '@/components';
import GlobalStyles from '@/styles/globalStyles';
import theme from '@/theme';

import RootProvider from './context/RootProvider';
import store from './store';

function App() {
  return (
    <Provider store={store}>
      <RootProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <ScrollToTop />
          <Outlet />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            // hideProgressBar={false}
            // newestOnTop={false}
            // closeOnClick
            // rtl={false}
            // pauseOnHover
            // theme="light"
            // style={{ zIndex: theme.zIndices.toast }}
            // progressStyle={{ background: theme.colors.primary }}
          />
        </ThemeProvider>
      </RootProvider>
    </Provider>
  );
}

export default App;
