import { HTMLAttributes, ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import theme from '@/theme';
import { getColorValue, NestedColorKeys } from '@/theme/colors';

export interface IButtonProps extends HTMLAttributes<HTMLButtonElement> {
  variant?: 'ghost' | 'primary' | 'gray' | 'tag' | 'danger';
  radius?: 'round' | 'arc' | 'normal' | 'md';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  bgColor?: NestedColorKeys;
  color?: NestedColorKeys;
  isDisabled?: boolean;
  block?: boolean;
  type?: string;
  isLoading?: boolean;
}

export interface IButtonComponentProps {
  children?: ReactNode;
  icon?: ReactNode;

  href?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  className?: string;
  style?: CSSProperties;
  htmlType?: 'submit' | 'reset' | 'button';
}

const getBorderRadius = (radius: string) => {
  switch (radius) {
    case 'round':
      return '30';
    case 'arc':
      return '17';
    case 'md':
      return '6';
    case 'normal':
      return '5';
  }
};

const getBackgroundColor = (bgColor?: NestedColorKeys, variant?: string) => {
  switch (variant) {
    case 'ghost':
      return 'transparent';
    case 'primary':
      return theme.colors.primary;
    case 'gray':
      return theme.colors.dark.neutral_50;
    case 'tag':
      return bgColor ? getColorValue(bgColor) : theme.colors.accent;
    case 'danger':
      return bgColor ? getColorValue(bgColor) : theme.colors.status.danger;
    default:
      return bgColor ? getColorValue(bgColor) : theme.colors.primary;
  }
};

const getSize = (size: string, variant: string) => {
  if (variant === 'tag') {
    return `
      font-size: 14px;
      height: 23px;
    `;
  }
  switch (size) {
    case 'sm':
      return `
        font-size: 12px;
        height: 26px;
        min-width: 60px;
      `;
    case 'md':
      return `
        font-size: 16px;
        height: 36px;
        min-width: 90px;
      `;
    case 'lg':
      return `
        font-size: 22px;
        height: 42px;
        min-width: 120px;
      `;
    case 'xl':
      return `
        font-size: 28px;
        height: 50px;
        min-width: 150px;
      `;
    default:
      return `
        font-size: 16px;
        height: 30px;
        min-width: 100px;
      `;
  }
};

const getColor = (variant: string) => {
  switch (variant) {
    case 'ghost':
      return theme.colors.dark.neutral_94;
    case 'gray':
      return theme.colors.dark.black;
    default:
      return theme.colors.light.white;
  }
};

const getLoaderSize = (size: string) => {
  switch (size) {
    case 'sm':
      return '8px';
    case 'md':
      return '10px';
    case 'lg':
      return '12px';
    case 'xl':
      return '16px';
    default:
      return '16px';
  }
};

export const StyledButton = styled.button<
  IButtonProps & { $isDisabled?: boolean }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 4px;
  outline: none;
  border: none;
  ${({ size = 'md', variant = 'primary' }) => getSize(size, variant)};
  color: ${({ variant = 'primary' }) => `${getColor(variant)}`};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  border-radius: ${({ radius = 'normal' }) => `${getBorderRadius(radius)}px`};
  background-color: ${({ bgColor, variant, $isDisabled }) =>
    $isDisabled
      ? theme.colors.dark.neutral_94
      : getBackgroundColor(bgColor, variant)};
  padding: 5px 12px;
  font-family: 'Poppins';
  & .lds-hourglass {
    display: inline-block;
    position: relative;
  }

  > div {
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: ${({ size = 'md' }) => `${getLoaderSize(size)} solid`};
    border-color: ${({ variant }) =>
      variant === 'ghost' || variant === 'gray'
        ? '#414141 transparent #414141 transparent'
        : `#fff transparent ${theme.colors.light.white} transparent`};
    animation: lds-hourglass 1.2s infinite;
  }

  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
`;
