import { createSlice } from '@reduxjs/toolkit';

import { toKebabCase } from '@/utils/conversion';

import { getAllCities } from './functions';

const initialState: any = {
  cities: [],
  pagination: {
    total: 0,
    limit: 10,
    currentPage: 0,
    totalPages: 1,
  },
  error: null,
  loading: false,
};

const citySlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCities.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch users. Try again later.';
      })
      .addCase(getAllCities.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.cities = action.payload.cities.data;
        state.pagination = action.payload.cities.pagination;
        state.analytics = action.payload.analytics
          ? Object.entries(action.payload.analytics).reduce(
              (acc: any, [key, value]: any) => {
                acc[toKebabCase(key)] = { key, value };
                return acc;
              },
              {}
            )
          : {};
      });
  },
});

export const citiesReducers = citySlice.reducer;
