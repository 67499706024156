import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.header};
  position: sticky;
  width: 100%;
  position: -webkit-sticky;
  top: 0px;
  z-index: ${({ theme }) => theme.zIndices.header};

  .line {
    background-color: #e0e0e0;
    width: 1px;
    height: 100%;
    z-index: 9999999;
    position: absolute;
    left: 229.2px;
  }
`;

export const PrivateHeaderStyled = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 12px 40px;

  @media (max-width: ${({ theme }) => theme.breakpointsDeviceWise.laptop}) {
    justify-content: space-between;
    padding: 10px 40px;
  }

  .logo-with-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    & .content {
      margin-left: 40px;
    }
  }

  .avatar-container {
    position: relative;
    cursor: pointer;
  }

  .content-right {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: flex-end;

    & .flag-content {
      form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.dark.neutral_50};
        border-radius: 4px;
        padding-left: 12px;
        height: 40px;

        img {
          height: 20px;
        }
      }
    }
  }
`;

export const MenuWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: 8px 4px;
  &:hover {
    background-color: #f0f0f0;
    border-radius: 6px;
  }
`;

export const PublicHeaderStyled = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  padding: 10px 30px 10px 30px;
  z-index: ${({ theme }) => theme.zIndices.header};

  & .brand-name-logo-wrapper {
    padding: 0px;
    margin: 0px;
  }
`;

export const AvatarDropdownStyled = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  padding: 20px 0px;
  right: -20px;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;

  text-align: center;
  z-index: 10;

  div {
    padding: 10px 26px;
    cursor: pointer;
    font-size: 16px;
    &:hover {
      background: #f0f0f0;
    }
  }
`;
