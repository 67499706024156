import { useEffect, useState } from 'react';
import { CiEdit } from 'react-icons/ci';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import buildingJourney from '@/apiConfigs/buildingJourney';
import HeadingTitle from '@/components/UserDetails/HeadingTitle';
import BreadCrumb from '@/designComponents/BreadCrumb';
import Button from '@/designComponents/Button';
import Loader from '@/designComponents/Loader';
import Modal from '@/designComponents/Modal';
import Typography from '@/designComponents/Typography';
import { InformationRowWrapper } from '@/pages/Users/Details/style';
import { humanReadableDate } from '@/utils/date';
import requestAPI from '@/utils/requestAPI';

import CreateBuildingSteps from '../BuildingStep/CreateBuildingSteps';
import { BuildingJourneyBreadCrumbData } from '../BuildingStep/data';
import { SingleBuildingJourneyStepType } from '../BuildingStep/Details';
import CreateBuildingJourney from '../CreateBuildingJourney';
import { DetailsPageWrapper, InfoRowStyled, StepsWrapper } from '../style';

export interface BuildingJourneyDetailsType {
  name: string;
  position: number;
  buildingSteps: BuildingStep[];
  createdAt: string;
  updatedAt: string;
}

export interface BuildingStep {
  _id: string;
  name: string;
  position: number;
  icon: string;
  tabs: string[];
  documents: Document[];
  buildingStages: string[];
  professionals: Professional[];
  finance: Finance[];
  buildingSystems: BuildingSystem[];
}

export interface Document {
  title: string;
  parentTitle?: string;
  fullSlug: string;
  type: string;
}

export interface Professional {
  name: string;
  avatar?: string;
}

export interface Finance {
  title: string;
  subtitle: string;
  link: string;
  _id: string;
}

export interface BuildingSystem {
  _id: string;
  prevId: string;
  title: string;
  status: string;
  parentId: string;
  level: number;
  slug: string;
  fullSlug: string;
  type: string;
  region: string[];
  isHidden: boolean;
  image: string;
  __v: number;
  firstDocPath: string;
  sponsorSupplier?: string;
}

const BuildingJourneyDetails = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEditBuildingStepModal, setOpenEditBuildingStepModal] =
    useState(false);

  const [isLoading, setIsLoading] = useState<{
    isJourney: boolean;
    isStep: boolean;
  }>({
    isJourney: false,
    isStep: false,
  });

  const [buildingJourneyDetailsStepData, setBuildingJourneyDetailsStepData] =
    useState<SingleBuildingJourneyStepType>();

  const [buildingJourneyDetailsData, setBuildingJourneyDetailsData] =
    useState<BuildingJourneyDetailsType>();
  const [buildingStepId, setBuildingStepId] = useState('');
  const { journeyId } = useParams();

  const handleLoading = (key: keyof typeof isLoading, value: boolean) => {
    setIsLoading((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const getSingleBuildingJourney = async () => {
    try {
      handleLoading('isJourney', true);
      const res = await requestAPI(
        buildingJourney.getSingleBuildingJourney(journeyId)
      );
      setBuildingJourneyDetailsData(res);
    } catch (error) {
      console.error(error.message);
      toast.error('Error while fetching single building journey');
    } finally {
      handleLoading('isJourney', false);
    }
  };

  useEffect(() => {
    getSingleBuildingJourney();
  }, []);

  const getSingleBuildingJourneyStep = async () => {
    try {
      handleLoading('isStep', true);

      const res = await requestAPI(
        buildingJourney.getSingleBuildingJourneyStep(buildingStepId)
      );
      setBuildingJourneyDetailsStepData(res);
    } catch (error) {
      console.error(error.message);
      toast.error('Error while fetching single building journey');
    } finally {
      handleLoading('isStep', false);
    }
  };

  useEffect(() => {
    if (buildingStepId) {
      getSingleBuildingJourneyStep();
    }
  }, [buildingStepId]);

  return (
    <>
      <DetailsPageWrapper>
        {!isLoading.isJourney ? (
          <>
            <div className="crumb-btn">
              <BreadCrumb
                breadCrumb={BuildingJourneyBreadCrumbData(
                  buildingJourneyDetailsData?.name
                )}
              />
              <div className="btn">
                <Button radius="md" onClick={() => setOpenEditModal(true)}>
                  Edit Building Journey
                </Button>

                {openEditModal && (
                  <Modal
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    title="Edit Building Journey"
                  >
                    <CreateBuildingJourney
                      isModalOpen={openEditModal}
                      setIsModalOpen={setOpenEditModal}
                      journeyId={journeyId || ''}
                      singleBuildingJourneyData={buildingJourneyDetailsData}
                      refreshSingleData={getSingleBuildingJourney}
                    />
                  </Modal>
                )}
              </div>
            </div>
            <div className="main-content">
              <InfoRowStyled>
                <HeadingTitle title="Building Journey Details" />
                <InformationRowWrapper>
                  <>
                    <div className="text">
                      <Typography
                        fontFamily="roboto"
                        fontWeight="normal"
                        color="dark.neutral_450"
                        size="normal"
                      >
                        Name
                      </Typography>
                      <Typography
                        fontFamily="roboto"
                        fontWeight="normal"
                        color="dark.neutral_450"
                        size="normal"
                      >
                        {buildingJourneyDetailsData?.name}
                      </Typography>
                    </div>

                    <div className="text">
                      <Typography
                        fontFamily="roboto"
                        fontWeight="normal"
                        color="dark.neutral_450"
                        size="normal"
                      >
                        Position
                      </Typography>
                      <Typography
                        fontFamily="roboto"
                        fontWeight="normal"
                        color="dark.neutral_450"
                        size="normal"
                      >
                        {buildingJourneyDetailsData?.position}
                      </Typography>
                    </div>
                    <div className="text">
                      <Typography
                        fontFamily="roboto"
                        fontWeight="normal"
                        color="dark.neutral_450"
                        size="normal"
                      >
                        Created At
                      </Typography>
                      <Typography
                        fontFamily="roboto"
                        fontWeight="normal"
                        color="dark.neutral_450"
                        size="normal"
                      >
                        {humanReadableDate(
                          buildingJourneyDetailsData?.createdAt
                        )}
                      </Typography>
                    </div>
                    <div className="text">
                      <Typography
                        fontFamily="roboto"
                        fontWeight="normal"
                        color="dark.neutral_450"
                        size="normal"
                      >
                        Updated At
                      </Typography>
                      <Typography
                        fontFamily="roboto"
                        fontWeight="normal"
                        color="dark.neutral_450"
                        size="normal"
                      >
                        {humanReadableDate(
                          buildingJourneyDetailsData?.updatedAt
                        )}
                      </Typography>
                    </div>

                    <div className="text">
                      <Typography
                        fontFamily="roboto"
                        fontWeight="normal"
                        color="dark.neutral_450"
                        size="normal"
                        style={{
                          alignSelf: 'self-start',
                        }}
                      >
                        Building Steps
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                        }}
                      >
                        {buildingJourneyDetailsData?.buildingSteps.map(
                          (steps, idx) => {
                            return (
                              <StepsWrapper key={idx}>
                                <Typography
                                  fontFamily="roboto"
                                  fontWeight="normal"
                                  color="dark.neutral_450"
                                  size="normal"
                                >
                                  {steps.name}
                                </Typography>
                                <CiEdit
                                  size={18}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  role="button"
                                  onClick={() => {
                                    setOpenEditBuildingStepModal(true);
                                    setBuildingStepId(steps?._id);
                                  }}
                                />
                              </StepsWrapper>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </>
                </InformationRowWrapper>
              </InfoRowStyled>
            </div>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '400px',
              width: '100%',
            }}
          >
            <Loader
              style={{
                height: 60,
                width: 60,
              }}
            />
          </div>
        )}
      </DetailsPageWrapper>

      {openEditBuildingStepModal && (
        <Modal
          isOpen={openEditBuildingStepModal}
          onClose={() => setOpenEditBuildingStepModal(false)}
          title="Edit Building Journey Step"
        >
          <CreateBuildingSteps
            isModalOpen={openEditBuildingStepModal}
            setIsModalOpen={setOpenEditBuildingStepModal}
            refetchSingleBuildingJourneyStep={getSingleBuildingJourneyStep}
            singleStepData={buildingJourneyDetailsStepData}
            stepId={buildingStepId}
            loadingData={isLoading.isStep}
          />
        </Modal>
      )}
    </>
  );
};

export default BuildingJourneyDetails;
