import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button from '@/designComponents/Button';
import Loader from '@/designComponents/Loader';
import Modal from '@/designComponents/Modal';
import Typography from '@/designComponents/Typography';
import { RootState, useAppDispatch } from '@/store';
import { getSingleAds } from '@/store/advertise/functions';
import theme from '@/theme';
import { humanReadableDate } from '@/utils/date';

import CreateAds from '../CreateAds';
import { AdvertiseDetailsWrapper } from './style';

type Props = {};

const AdvertiseDetails = (_props: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { adsId } = useParams();
  const dispatch = useAppDispatch();
  const adsDetails = useSelector((state: RootState) => state.ads.singleAds);
  const { loading } = useSelector((state: RootState) => state.ads);

  useEffect(() => {
    if (adsId) {
      dispatch(getSingleAds(adsId));
    }
  }, []);

  return (
    <AdvertiseDetailsWrapper>
      {!loading ? (
        <div className="main-content">
          <div className="heading">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                fontFamily="jost"
                fontWeight="medium"
                size="heading"
                color="dark.neutral_60"
              >
                Ads Details
              </Typography>
              <Button radius="md" onClick={() => setIsEditModalOpen(true)}>
                Edit Ads
              </Button>
            </div>
            {isEditModalOpen && (
              <Modal
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                title="Edit Advertiser"
              >
                <CreateAds
                  isOpen={isEditModalOpen}
                  onClose={() => setIsEditModalOpen(false)}
                />
              </Modal>
            )}
            <hr
              style={{
                backgroundColor: theme.colors.dark.neutral_50,
                border: 'none',
                height: '1px',
              }}
            />
          </div>
          <div className="main-text">
            <div className="text">
              <Typography className="text-value">Name</Typography>
              <Typography className="text-value">{adsDetails?.name}</Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Description</Typography>
              <Typography className="text-value">
                {adsDetails?.content}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Content type</Typography>
              <Typography className="text-value">
                {adsDetails.contentType}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Start Date</Typography>
              <Typography className="text-value">
                {humanReadableDate(adsDetails?.startDate)}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">End Date</Typography>
              <Typography className="text-value">
                {humanReadableDate(adsDetails?.endDate)}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Type</Typography>
              <Typography className="text-value">{adsDetails?.type}</Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Image</Typography>
              <Typography className="text-value">
                <img
                  src={adsDetails?.content}
                  alt="advertise image"
                  height={200}
                  width={200}
                />
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '400px',
            width: '100%',
          }}
        >
          <Loader
            style={{
              height: 60,
              width: 60,
            }}
          />
        </div>
      )}
    </AdvertiseDetailsWrapper>
  );
};

export default AdvertiseDetails;
