import { ConfigType } from '@/utils/requestAPI';

export type PaginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
};

export type SuppliersType = {
  name: string;
  website: string;
  email: string;
  avatar: string;

  isInbound: boolean;
  showroom: {
    title: string;
    description: string;
    images: string[];
    bottomBanner?: {
      link: string;
      title: string;
      subtitle: string;
      image1: string;
      image2: string;
    };
  };
};

const suppliers = {
  addSuppliers: (data: SuppliersType): ConfigType => {
    return {
      method: 'post',
      url: '/supplier',
      data,
    };
  },
  getSuppliers: (params?: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/supplier',
      params,
    };
  },
  getSingleSupplier: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/supplier/${id}`,
    };
  },
};

export default suppliers;
