import { ConfigType } from '@/utils/requestAPI';

export type CategoryType = {
  title: string;
  region?: string;
};
export type ResourceType = {
  title: string;
  type?: string;
  downloadCenterCategoryId?: string;
  downloadCenterGroupId?: string;
  category?: string;
  region?: string;
  position?: number;
  body: {
    content: string;
    contentType: string;
  };
};
export type PaginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  search?: string;
  page?: number;
};

const downloadCenter = {
  addDownloadCenterCategory: (data: CategoryType): ConfigType => {
    return {
      method: 'post',
      url: '/download-center-category',
      data,
    };
  },
  getDownloadCenterCategory: (params: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/download-center-category',
      params,
    };
  },
  getSingleDownloadCenterCategory: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/download-center-category/${id}`,
    };
  },
  updateDownloadCenterCategory: (
    id: string,
    data: CategoryType
  ): ConfigType => {
    return {
      method: 'put',
      url: `/download-center-category/${id}`,
      data,
    };
  },
  addDownloadCenterGroup: (data: CategoryType): ConfigType => {
    return {
      method: 'post',
      url: '/download-center-group',
      data,
    };
  },
  getDownloadCenterGroup: (params: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/download-center-group',
      params,
    };
  },
  getSingleDownloadCenterGroup: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/download-center-groupId/${id}`,
    };
  },
  updateDownloadCenterGroup: (id: string, data?: CategoryType): ConfigType => {
    return {
      method: 'put',
      url: `/download-center-group/${id}`,
      data,
    };
  },
  deleteDownloadCenterGroup: (id: string): ConfigType => {
    return {
      method: 'delete',
      url: `/download-center-group/${id}`,
    };
  },
  addDownloadCenterType: (data: CategoryType): ConfigType => {
    return {
      method: 'post',
      url: '/download-center-group',
      data,
    };
  },
  addDownloadCenterResource: (data: ResourceType): ConfigType => {
    return {
      method: 'post',
      url: '/download-center-item',
      data,
    };
  },
  updateDownloadCenterResource: (
    id: string,
    data: ResourceType
  ): ConfigType => {
    return {
      method: 'put',
      url: `/download-center-item/${id}`,
      data,
    };
  },
  getDownloadCenterResources: (
    params: PaginationType & { filter?: { downloadCenterGroupId?: string } }
  ): ConfigType => {
    return {
      method: 'get',
      url: '/download-center-item',
      params,
    };
  },
  getSingleDownloadCenterResource: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/download-center-itemId/${id}`,
    };
  },
};

export default downloadCenter;
