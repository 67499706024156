import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PaginationType } from '@/apiConfigs/suppliers';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { RootState, useAppDispatch } from '@/store';
import { getAllSuppliers } from '@/store/suppliers/functions';
import usePaginationStore from '@/storeZustand/paginationStore';

import AddSuppliers from './AddSuppliers';

type Props = {};

const Showrooms = (_props: Props) => {
  const { getLimit, setLimit } = usePaginationStore();
  const [paginationParams, setPaginationParams] = useState<PaginationType>({
    page: 1,
    limit: getLimit('suppliers'),
  });
  const [searchTerm, setSearchTerm] = useState('');

  const dispatch = useAppDispatch();

  const suppliers = useSelector(
    (state: RootState) => state.suppliers.suppliers
  );
  const pagination = useSelector(
    (state: RootState) => state.suppliers.pagination
  );
  const { loading } = useSelector((state: RootState) => state.suppliers);

  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    // debouncedSearch(value);
  };

  const tableData: TableDataType = {
    headings: [
      // { key: 'id', label: 'Id' },
      { key: 'name', label: 'Name' },
      { key: 'website', label: 'Website' },

      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(suppliers)
      ? suppliers.map((supplier) => ({
          id: supplier._id,
          name: supplier?.name,
          slug: supplier?.slug,
          website: supplier?.website,
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                onClick={() => navigate(supplier._id)}
                style={{ marginLeft: '10px' }}
              >
                View
              </Button>
              {/* <Button
                variant="danger"
                radius="normal"
                onClick={() => navigate(supplier._id)}
                style={{ marginLeft: '10px' }}
              >
                Delete
              </Button> */}
            </div>
          ),
        }))
      : [],
  };

  const handleCurrentPage = (page: number) => {
    setPaginationParams((prev) => ({ ...prev, page: page }));
  };

  const handleLimitChange = (limit: number) => {
    setLimit('suppliers', limit);
    setPaginationParams((prev) => ({ ...prev, page: 1, limit }));
  };

  useEffect(() => {
    dispatch(getAllSuppliers(paginationParams));
  }, [dispatch, paginationParams]);

  useHeaderTitle('Suppliers');

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Input
          value={searchTerm}
          onChange={handleInputChange}
          placeholder="Search Supplier"
          iconLeft={<SearchSVG />}
        />
        <AddSuppliers />
      </div>

      <Table
        tableData={tableData}
        noData="No data to display."
        showCheckBox={false}
        maxWidthForCardView="768px"
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
        isLoading={loading}
      />

      <Pagination
        activePage={paginationParams.page || 1}
        onCurrentPageChange={handleCurrentPage}
        totalPages={pagination?.totalPages}
        limit={getLimit('suppliers') || 10}
        onLimitChange={handleLimitChange}
      />
    </div>
  );
};

export default Showrooms;
