import { CouponInitialValueType } from '@/pages/Coupons/CreateCoupon';
import { ConfigType } from '@/utils/requestAPI';

export type CouponsPaginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
};
const coupons = {
  getAllCoupons: (params?: CouponsPaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/coupons',
      params,
    };
  },

  getSingleCoupon: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/coupons/${id}`,
    };
  },
  getCouponsStats: (): ConfigType => {
    return {
      method: 'get',
      url: '/coupons/stats',
    };
  },
  addCoupon: (data: CouponInitialValueType): ConfigType => {
    return {
      method: 'post',
      url: '/coupons',
      data,
    };
  },
  updateCoupon: (id: string, data: CouponInitialValueType): ConfigType => {
    return {
      method: 'put',
      url: `/coupons/${id}`,
      data,
    };
  },
};
export default coupons;
