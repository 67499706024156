import styled from 'styled-components';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  /* min-height: 80vh; */
  overflow-y: auto;
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .error {
      color: red;
    }
  }
`;
