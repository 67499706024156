import styled from 'styled-components';

export const AccountDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;

export const CrumbsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InfoRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TagWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 4px 10px;
`;

export const InformationRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .text {
    display: grid;
    grid-template-columns: 90px 1fr;
    align-items: center;
    gap: 32px;
  }
`;

export const EventsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PaymentsWrapper = styled.div`
  display: flex;
  gap: 15px;

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 50px;
    background-color: ${({ theme }) => theme.colors.dark.neutral_50};
    padding: 5px 14px;
    height: 50px;
    flex-shrink: 0;
  }
  .text {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .price {
      display: flex;
      align-items: center;
      gap: 9px;
    }
  }
`;

export const SubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & .premium-tag {
    display: flex;
    gap: 9px;
  }

  & .text {
    display: grid;
    grid-template-columns: 90px 1fr;
    align-items: center;
    gap: 32px;
  }
`;

export const UserProfileWithImageWrapper = styled.div`
  display: flex;
  gap: 15px;

  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 2px solid ${({ theme }) => theme.colors.blue_d3};
    width: 50px;
    height: 50px;
    flex-shrink: 0;
  }
  .text {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
