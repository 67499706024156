import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type LimitType =
  | 'videos'
  | 'group'
  | 'documents'
  | 'tags'
  | 'coupons'
  | 'jobs'
  | 'categories'
  | 'users'
  | 'products'
  | 'suppliers'
  | 'cities'
  | 'adminUsers'
  | 'downloadCenters';
type PaginationStore = {
  limit: Partial<Record<LimitType, number>>;
  setLimit: (type: LimitType, newLimit: number) => void;
  getLimit: (type: LimitType) => number;
};

const usePaginationStore = create(
  persist<PaginationStore>(
    (set, get) => ({
      limit: { group: 10 },
      setLimit: (type, newLimit) => {
        const allLimit = get().limit;
        set({ limit: { ...allLimit, [type]: newLimit } });
      },
      getLimit: (type) => get().limit[type],
    }),
    { name: 'limit' }
  )
);

export default usePaginationStore;
