/* eslint-disable */

import type { EditorConfig, LexicalNode, SerializedTextNode } from 'lexical';
import { TextNode } from 'lexical';

export type SerializedKeywordNode = SerializedTextNode & {
  popupContent: string;
};

export class KeywordNode extends TextNode {
  __popupContent: string;

  constructor(text: string, popupContent: string, key?: string) {
    super(text, key);
    this.__popupContent = popupContent;
  }

  static getType(): string {
    return 'keyword';
  }

  static clone(node: KeywordNode): KeywordNode {
    return new KeywordNode(node.__text, node.__popupContent, node.__key);
  }

  static importJSON(serializedNode: SerializedKeywordNode): KeywordNode {
    const node = $createKeywordNode(
      serializedNode.text,
      serializedNode.popupContent
    );
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedKeywordNode {
    return {
      ...super.exportJSON(),
      popupContent: this.__popupContent,
      type: 'keyword',
      version: 1,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.style.cursor = 'pointer';
    dom.style.position = 'relative';
    dom.className = 'keyword';
    dom.setAttribute('data-lexical-node-key', this.getKey());

    return dom;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }

  isTextEntity(): true {
    return true;
  }

  getPopupContent(): string {
    return this.__popupContent;
  }
}

export function $createKeywordNode(
  keyword: string,
  popupContent: string
): KeywordNode {
  return new KeywordNode(keyword, popupContent);
}

export function $isKeywordNode(node: LexicalNode | null | undefined): boolean {
  return node instanceof KeywordNode;
}
