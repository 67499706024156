import { useRoot } from '@/context/RootProvider';
import Typography from '@/designComponents/Typography';
import { humanReadableDate } from '@/utils/date';

import { ProfileContainerStyled } from './styles';

const Profile = () => {
  const { auth }: any = useRoot();

  return (
    <ProfileContainerStyled>
      <Typography size="highlight">Admin Profile Details</Typography>
      <div className="main-text">
        <div className="text">
          <Typography className="text-value">Email</Typography>
          <Typography className="text-value">{auth?.email}</Typography>
        </div>
        <div className="text">
          <Typography className="text-value">PrevId</Typography>
          <Typography className="text-value">{auth?.prevId}</Typography>
        </div>
        <div className="text">
          <Typography className="text-value">Created At</Typography>
          <Typography className="text-value">
            {humanReadableDate(auth?.createdAt)}
          </Typography>
        </div>

        <div className="text">
          <Typography className="text-value">Updated At</Typography>
          <Typography className="text-value">
            {humanReadableDate(auth?.updatedAt)}
          </Typography>
        </div>
      </div>
    </ProfileContainerStyled>
  );
};

export default Profile;
