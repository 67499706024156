import { ReactComponent as DocumentSVG } from '@/assets/icons/document-text.svg';
import Typography from '@/designComponents/Typography';

import { SearchResultWrapper } from './style';

type Props = {
  title: string;
  searchTerm?: string;
  category: string;
  hideResultIcon?: boolean;
};

const SearchResult = ({
  title,
  category,
  searchTerm,
  hideResultIcon = false,
}: Props) => {
  const textPartsToHighlight = title.split(new RegExp(`(${searchTerm})`, 'gi'));

  return (
    <SearchResultWrapper>
      {!hideResultIcon && <DocumentSVG style={{ marginTop: '5px' }} />}
      <div className="text">
        <Typography size="title" color="dark.neutral_60">
          {textPartsToHighlight.map((part, index) =>
            part.toLowerCase() === searchTerm?.toLowerCase() ? (
              <mark key={index} className="mark-text">
                {part}
              </mark>
            ) : (
              part
            )
          )}
        </Typography>
        {!hideResultIcon && <Typography color="primary">{category}</Typography>}
      </div>
    </SearchResultWrapper>
  );
};

export default SearchResult;
