import { ConfigType } from '@/utils/requestAPI';

export type paginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
  filter?: {
    isApproved: boolean | null;
  };
};

const JobPosts = {
  getAllJobs: (params?: paginationType): ConfigType => {
    return {
      method: 'get',
      url: '/job-posts',
      params,
    };
  },

  getSingleJob: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/job-post/${id}`,
    };
  },
  approveJob: (id: string): ConfigType => {
    return {
      method: 'patch',
      url: `/job-post/${id}`,
    };
  },
};
export default JobPosts;
