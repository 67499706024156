import styled from 'styled-components';

export const HeadingStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const InfoRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & .text {
    font-family: ${({ theme }) => theme.fontFamily.roboto};
    font-size: ${({ theme }) => theme.fontSize.normal};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: #4c4c4c;
  }

  & .reactSelect {
    z-index: 999;
  }
`;

export const CreateStoriesFormWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

export const SearchInputWrapperStyled = styled.div`
  width: 100%;
  max-width: 280px;
`;

export const DetailsPageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;

  .crumb-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.dark.neutral_250};
  border-radius: 5px;
  padding: 6px 12px;
  position: relative;

  .icons-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    cursor: pointer;
  }
`;

export const FilterContentWrapper = styled.div<{ $isClicked: boolean }>`
  display: ${({ $isClicked }) => ($isClicked ? 'flex' : 'none')};
  position: absolute;
  top: 60px;
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.light.white};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.dark.neutral_250};
  border-radius: 8px;
  min-height: 50px;
  padding: 8px 10px;
`;
