import { ReactNode, useRef, useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';

import useDetectOutsideClick from '@/hooks/useDetectOutsideClick';

import Modal from '../Modal';
import Typography from '../Typography';
import { DropdownButtonWrapper, IndividualOption } from './style';

export type ComponentsProps = {
  searchTerm?: string;
  setSearchTerm?: (text: string) => void;
  isModalOpen?: boolean;
  setIsModalOpen?: (text: boolean) => void;
};

type ModalContent = {
  title: string;
  modalChildren: (data?: ComponentsProps) => JSX.Element;
};

type Props = {
  options: Array<{ label: string; modalContent: ModalContent }>;
  title: string;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  renderInModal?: boolean;
  defaultOption?: { label: string; modalContent: ModalContent };
  header?: ReactNode;
  searchTerm?: string;
  setSearchTerm?: (text: string) => void;
};

const DropdownButton = ({
  options,
  title,
  openModal,
  setOpenModal,
  renderInModal = true,
  defaultOption = null,
  header,
  searchTerm,
  setSearchTerm,
}: Props) => {
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useDetectOutsideClick(containerRef, false) as [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    modalContent: ModalContent;
  } | null>(defaultOption);

  const handleButtonClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option: {
    label: string;
    modalContent: ModalContent;
  }) => {
    setSelectedOption(option);
    if (renderInModal) {
      setOpenModal(true);
    }
    setSearchTerm('');
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {header && header}
        <DropdownButtonWrapper>
          <div
            className="button-with-dropdown"
            role="button"
            onClick={handleButtonClick}
            ref={containerRef}
          >
            <Typography color="light.white" size="normal" fontWeight="medium">
              {selectedOption ? selectedOption.label : title}
            </Typography>
            <IoMdArrowDropdown
              style={{
                transform: isOpen ? 'rotate(180deg)' : '',
                transition: 'all 0.3s ease',
              }}
              size={20}
            />
          </div>
          {isOpen && (
            <div className="content">
              {options.map((option, idx) => (
                <IndividualOption
                  key={idx}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </IndividualOption>
              ))}
            </div>
          )}
        </DropdownButtonWrapper>
      </div>

      {renderInModal && openModal && selectedOption && (
        <Modal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          title={selectedOption.modalContent.title}
        >
          {
            <selectedOption.modalContent.modalChildren
              searchTerm={searchTerm || ''}
              setIsModalOpen={setOpenModal}
              setSearchTerm={setSearchTerm}
            />
          }
        </Modal>
      )}

      {!renderInModal && selectedOption && (
        <div
          style={{
            marginTop: '20px',
          }}
        >
          {
            <selectedOption.modalContent.modalChildren
              searchTerm={searchTerm || ''}
              setIsModalOpen={setOpenModal}
              setSearchTerm={setSearchTerm}
            />
          }
        </div>
      )}
    </>
  );
};

export default DropdownButton;
