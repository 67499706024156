import styled from 'styled-components';

import Button from '@/components/TextEditor/ui/Button';
import theme from '@/theme';

import Typography from '../Typography';
interface ButtonTabItem {
  title: string;
  count?: number;
}

interface ButtonTabProps {
  items: ButtonTabItem[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ButtonTab = ({
  items,
  activeIndex,
  setActiveIndex,
}: ButtonTabProps) => {
  return (
    <Container>
      {items.map((item, index) => (
        <Button
          style={{
            color: activeIndex === index ? 'white' : 'black',
            backgroundColor:
              activeIndex === index ? theme.colors.primary : 'white',
            borderRadius: 4,
            border: '1px solid #D6D6D7',
            // borderRight:
            //   index !== items.length - 1 && activeIndex !== index
            //     ? `0.5px solid ${theme.colors.dark.neutral_20}`
            //     : 'none',
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            height: 38,
          }}
          key={item.title}
          onClick={() => {
            setActiveIndex(index);
          }}
        >
          {/**@ts-ignore */}
          <Typography style={{ color: activeIndex === index && 'white' }}>
            {item.title}
          </Typography>
          {item.count && (
            <Typography
              size="smallNormal"
              //@ts-ignore
              style={{ color: activeIndex === index && 'white' }}
            >
              ({item.count})
            </Typography>
          )}
        </Button>
      ))}
    </Container>
  );
};
