import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import { storiesApi, StoryType } from '@/apiConfigs/stories';
import CountrySelection from '@/components/CountrySelection';
import TextEditor from '@/components/TextEditor';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import { InputContainerStyled } from '@/designComponents/Input/styles';
import Modal from '@/designComponents/Modal';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import MultiSelect from '@/designComponents/MultiSelect';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import UploadFile from '@/designComponents/UploadFile';
import { getRespectiveOptions } from '@/pages/BuildingJourney/BuildingStep/data';
import { generateSlug } from '@/pages/Documents/function';
import { TextAreaStyled } from '@/pages/Videos/HowToVideos/style';
import { useAppDispatch, useAppSelector } from '@/store';
import { getAllStories, getSingleStory } from '@/store/stories/function';
import requestAPI from '@/utils/requestAPI';
import { uploadFilesToS3OnlyKeys } from '@/utils/s3Upload';

import { CreateStoriesFormWrapper, InfoRowStyled } from '../style';
import { createStoryValidationSchema } from './validationSchema';

interface InitialValueType {
  title: string;
  description: string;
  slug: string;
  body: string;
  storyType: StoryType | null;
  category: string[];
  region: 'ie' | 'uk';
  photos: (File | string)[];
}

const storiesTypeData: Array<{ label: string; value: string }> = [
  {
    label: 'Images',
    value: 'Interior',
  },
  {
    label: 'Story',
    value: 'Story',
  },
  {
    label: 'Blog',
    value: 'Blog',
  },
];
const CreateStories = () => {
  const theme = useTheme();
  const { storyId } = useParams();
  const [ideaCategory, setIdeaCategory] = useState<
    Array<{
      category: string;
      categoryIcon: string;
      _id: string;
    }>
  >([]);

  const dispatch = useAppDispatch();
  const singleStory = useAppSelector((state) => state.stories.singleStory);

  const [isLoading, setIsLoading] = useState(false);

  const [apiError, setApiError] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<InitialValueType>({
    title: '',
    description: '',
    body: '',
    slug: '',
    storyType: null,
    category: [],
    region: 'ie',
    photos: [],
  });
  const formikRef = useRef<FormikProps<InitialValueType>>(null);

  useEffect(() => {
    if (storyId) {
      dispatch(getSingleStory(storyId));
    }
  }, [storyId, dispatch]);

  const getIdeaCategory = async (type: string) => {
    try {
      const res = await requestAPI(storiesApi.getStoryCatByType(type));

      setIdeaCategory(res);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (storyId && singleStory) {
      setInitialValues({
        body: singleStory.body,
        category: singleStory.category.map((cat) => cat._id),
        description: singleStory.description,
        slug: singleStory.slug,
        storyType: singleStory.type?.[0],
        title: singleStory.title,
        region: singleStory.region?.[0],
        photos: singleStory.photos.map((img) => img.url),
      });
    }
  }, [storyId, singleStory]);

  useEffect(() => {
    getIdeaCategory(initialValues.storyType);
  }, [initialValues.storyType]);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCreateStory = async (val: InitialValueType) => {
    try {
      setIsLoading(true);
      const newPhotos = val.photos.filter(
        (photo): photo is File => photo instanceof File
      );
      const imgUrls = await uploadFilesToS3OnlyKeys(newPhotos);
      const existingPhotos = val.photos.filter(
        (photo): photo is string => typeof photo === 'string'
      );

      const allPhotos = [...existingPhotos, ...imgUrls];

      const slug = generateSlug(val.title);
      await requestAPI(
        storiesApi.addStories({
          category: val.category,
          body: val.body,
          title: val.title,
          slug,
          description: val.description,
          type: val.storyType,
          region: [val.region],
          photos: allPhotos.map((img) => ({
            url: img,
            isMain: true,
          })),
        })
      );
      setIsLoading(false);
      dispatch(getAllStories({}));
      setIsModalOpen(false);
      toast.success('Story added successfully');
    } catch (error: any) {
      setIsLoading(false);
      console.error(error.message);
      setApiError(error.message);
      toast.error(error.message);
    }
  };
  const handleUpdateStory = async (val: InitialValueType) => {
    try {
      setIsLoading(true);
      const newPhotos = val?.photos.filter(
        (photo): photo is File => photo instanceof File
      );

      let allPhotos: string[];

      if (newPhotos.length > 0) {
        const imgUrls = await uploadFilesToS3OnlyKeys(newPhotos);
        const existingPhotos = val?.photos?.filter(
          (photo): photo is string => typeof photo === 'string'
        );
        allPhotos = [...existingPhotos, ...imgUrls];
      } else {
        // If there are no new photos, use the existing photos
        allPhotos =
          val?.photos?.filter(
            (photo): photo is string => typeof photo === 'string'
          ) || [];
      }

      const slug = generateSlug(val.title);

      await requestAPI(
        storiesApi.updateStory(
          {
            category: val.category,
            body: val.body,
            title: val.title,
            slug,
            description: val.description,
            type: val.storyType,
            region: [val.region],
            photos: allPhotos.map((img) => ({
              url: img,
              isMain: true,
            })),
          },
          storyId
        )
      );

      setIsLoading(false);
      dispatch(getSingleStory(storyId));
      setIsModalOpen(false);
      toast.success('Story updated successfully');
    } catch (error: any) {
      setIsLoading(false);
      console.error(error.message);
      setApiError(error.message);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Button radius="md" onClick={openModal}>
        {storyId ? 'Edit Story' : 'New Story'}
      </Button>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={storyId ? 'Edit Story' : 'Create New Story'}
      >
        <ModalContentWrapper>
          <Formik
            initialValues={initialValues}
            onSubmit={storyId ? handleUpdateStory : handleCreateStory}
            validationSchema={createStoryValidationSchema}
            innerRef={formikRef}
            enableReinitialize
          >
            {(formikProps: FormikProps<InitialValueType>) => {
              const { setFieldValue, values, touched, errors } = formikProps;

              return (
                <Form>
                  <CreateStoriesFormWrapper>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <CountrySelection
                        selectedCountry={values.region}
                        setSelectedCountry={async (val) => {
                          setFieldValue('region', val);
                        }}
                      />
                    </div>
                    <InfoRowStyled>
                      <Typography className="text">
                        Title <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Input
                        name="title"
                        value={values.title}
                        onChange={(e) => setFieldValue('title', e.target.value)}
                      />
                      {errors.title && touched.title && (
                        <div style={{ color: 'red' }}>{errors.title}</div>
                      )}
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="text">Description</Typography>
                      <InputContainerStyled className="input-container">
                        <TextAreaStyled
                          rows={10}
                          value={values.description}
                          onChange={(e) =>
                            setFieldValue('description', e.target.value)
                          }
                          name="description"
                        />
                      </InputContainerStyled>
                      {errors.description && touched.description && (
                        <div style={{ color: 'red' }}>{errors.description}</div>
                      )}
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="text">
                        Type <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Select
                        name="storyType"
                        placeholder="Select  Type"
                        options={storiesTypeData}
                        selected={values.storyType}
                        onSelect={(value) => {
                          setFieldValue('storyType', value);
                          getIdeaCategory(value.toString());
                          setFieldValue('category', []);
                        }}
                      />
                      {errors.storyType && touched.storyType && (
                        <div style={{ color: 'red' }}>{errors.storyType}</div>
                      )}
                    </InfoRowStyled>
                    {ideaCategory.length > 0 && (
                      <InfoRowStyled>
                        <Typography className="text">
                          Category <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <MultiSelect
                          name="category"
                          options={getRespectiveOptions(
                            singleStory?.category,
                            ideaCategory,
                            storyId
                          )}
                          formikProps={formikProps}
                        />
                        {errors.category && touched.category && (
                          <div style={{ color: 'red' }}>{errors.category}</div>
                        )}
                      </InfoRowStyled>
                    )}

                    <InfoRowStyled>
                      <Typography className="text">
                        Photos <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <UploadFile
                        name="photos"
                        title=""
                        enableMultipleFile
                        variant="previewImage"
                        onChange={(file) => {
                          if (Array.isArray(file)) {
                            // Prevent duplication by filtering out existing files
                            const newFiles = file.filter(
                              (newFile) =>
                                !values.photos.some(
                                  (existingFile) =>
                                    existingFile instanceof File &&
                                    existingFile.name === newFile.name
                                )
                            );
                            setFieldValue('photos', [
                              ...values.photos,
                              ...newFiles,
                            ]);
                          }
                        }}
                        apiUrls={values.photos.filter(
                          (photo): photo is string => typeof photo === 'string'
                        )}
                        onRemove={(idx) => {
                          if (idx !== undefined) {
                            setFieldValue(
                              'photos',
                              values.photos.filter((_, i) => i !== idx)
                            );
                          }
                        }}
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="text">
                        Body <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <TextEditor
                        initialHtml={values.body}
                        setEditorState={({ html }) =>
                          setFieldValue('body', html)
                        }
                      />
                      {errors.body && touched.body && (
                        <div style={{ color: 'red' }}>{errors.body}</div>
                      )}
                    </InfoRowStyled>
                  </CreateStoriesFormWrapper>
                  {apiError && (
                    <div style={{ color: 'red', marginTop: '10px' }}>
                      {apiError}
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </ModalContentWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '20px',
            padding: '0 20px 20px',
          }}
        >
          <Button
            radius="md"
            variant="ghost"
            style={{
              border: `1px solid ${theme.colors.dark.neutral_250}`,
              borderRadius: '10px',
              color: theme.colors.text,
              fontFamily: theme.fontFamily.roboto,
              fontWeight: theme.fontWeights.medium,
            }}
            onClick={() => {
              if (setIsModalOpen) {
                setIsModalOpen(false);
              }
            }}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            radius="normal"
            isLoading={isLoading}
            onClick={() => formikRef.current?.handleSubmit()}
            style={{ backgroundColor: theme.colors.brandColorBlue }}
          >
            {storyId ? 'Update Story' : 'Save'}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CreateStories;
