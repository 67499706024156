import { ConfigType } from '@/utils/requestAPI';

export type paginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
};
type CityType = {
  id?: string;
  name: string;
  region: string;
};
const cities = {
  addCity: (data?: CityType): ConfigType => {
    return {
      method: 'post',
      url: '/city',
      data,
    };
  },
  updateCity: (id: string, data?: CityType): ConfigType => {
    return {
      method: 'put',
      url: `/city/${id}`,
      data,
    };
  },
  getCities: (params?: paginationType): ConfigType => {
    return {
      method: 'get',
      url: '/cities',
      params,
    };
  },

  deleteCity: (id: string): ConfigType => {
    return {
      method: 'delete',
      url: `/city/${id}`,
    };
  },
};

export default cities;
