import { EditorConfig, TextNode } from 'lexical';

export class TagNode extends TextNode {
  __tagData: { id: string; label: string };

  static getType() {
    return 'tag';
  }

  static clone(node: TagNode) {
    return new TagNode(node.__text, node.__tagData, node.__key);
  }

  constructor(
    text: string,
    tagData: { id: string; label: string },
    key?: string
  ) {
    super(text, key);
    this.__tagData = tagData;
  }

  createDOM(config: EditorConfig) {
    const dom = super.createDOM(config);
    dom.style.backgroundColor = '#d9f7be';
    dom.style.padding = '0 4px';
    dom.style.borderRadius = '4px';
    dom.style.cursor = 'pointer';
    return dom;
  }

  updateDOM() {
    return false;
  }

  static importJSON(serializedNode: any) {
    return new TagNode(serializedNode.text, serializedNode.tagData);
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      tagData: this.__tagData,
      type: TagNode.getType(),
    };
  }

  isInline() {
    return true;
  }
}
