import { createAsyncThunk } from '@reduxjs/toolkit';

import cities from '@/apiConfigs/cities';
import { PaginationType } from '@/apiConfigs/documents';
import requestAPI from '@/utils/requestAPI';

export const getAllCities = createAsyncThunk(
  'cities/getAllCities',
  async (params: PaginationType) => {
    const response = await requestAPI(cities.getCities(params));

    return response;
  }
);
