import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import Button from '@/designComponents/Button';
import Typography from '@/designComponents/Typography';

import { HeadingWrapper } from '../../pages/Users/Details/style';

type Props = {
  title: string;
  frontEndUrl?: string;
  button?: ReactNode;
};

const HeadingTitle = ({ title, frontEndUrl, button }: Props) => {
  const theme = useTheme();
  return (
    <HeadingWrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '30px',
          }}
        >
          <Typography
            fontFamily="jost"
            fontWeight="medium"
            size="heading"
            color="dark.neutral_60"
          >
            {title}
          </Typography>
          {frontEndUrl && (
            <Link to={frontEndUrl || ''} target="_blank">
              <Button
                style={{
                  backgroundColor: theme.colors.brandColorBlue,
                  borderRadius: '10px',
                }}
              >
                Preview in Frontend
              </Button>
            </Link>
          )}
        </div>
        {button}
      </div>
      <hr
        style={{
          backgroundColor: theme.colors.dark.neutral_50,
          border: 'none',
          height: '1px',
        }}
      />
    </HeadingWrapper>
  );
};

export default HeadingTitle;
