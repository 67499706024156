import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as yup from 'yup';

import products, { ProductMapperType } from '@/apiConfigs/products';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Modal from '@/designComponents/Modal';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import {
  BottomContainerStyled,
  ModalContainerStyled,
} from '@/pages/DownloadCenter/style';
import requestAPI from '@/utils/requestAPI';

const validationSchema = yup.object().shape({
  externalCategory: yup
    .string()
    .required('External Category is required')
    .min(2, 'External Category must be at least 2 characters long'),
  internalCategory: yup.string().required('Internal Category is required'),
});

type Props = {
  isOpenModal: boolean;
  onClose: () => void;
  refreshData: () => void;
  initialValues?: ProductMapperType | null;
};

export const NewAdminWrapperStyled = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;
export const InfoRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;
`;

const AddProductMapper = ({
  isOpenModal,
  onClose,
  refreshData,
  initialValues = null,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [productCategories, setProductCategories] = useState([]);

  const getProductCategories = async () => {
    try {
      const data = await requestAPI(products.getProductCategory());
      setProductCategories(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductCategories();
  }, []);

  const handleAddOrUpdateProductMapper = async (val: ProductMapperType) => {
    setIsLoading(true);
    try {
      if (initialValues) {
        await requestAPI(products.updateProductMapper(initialValues._id, val));
        toast.success('Product mapper updated successfully');
      } else {
        await requestAPI(
          products.addProductMapper({
            externalCategory: val.externalCategory,
            internalCategory: val.internalCategory,
          })
        );
        toast.success('Product mapper added successfully');
      }
      refreshData();
      setIsLoading(false);
      onClose();
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  return (
    <ModalContainerStyled>
      <Modal
        isOpen={isOpenModal}
        onClose={onClose}
        title={initialValues ? 'Edit Product Mapper' : 'Create Product Mapper'}
        halfWidth={true}
      >
        <Formik
          initialValues={
            initialValues || {
              externalCategory: '',
              internalCategory: '',
            }
          }
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleAddOrUpdateProductMapper}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: 'flex',
                    gap: '50px',
                    alignItems: 'center',
                  }}
                >
                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      External Category <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      value={values.externalCategory}
                      onChange={(e) =>
                        setFieldValue('externalCategory', e.target.value)
                      }
                      placeholder="External Category"
                    />
                    {errors.externalCategory && touched.externalCategory && (
                      <Typography style={{ color: 'red' }}>
                        {errors.externalCategory}
                      </Typography>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Internal Category <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Select
                      options={[
                        ...(productCategories?.map((cat) => ({
                          label: cat.title,
                          value: cat.title,
                        })) || []),
                      ]}
                      selected={values.internalCategory}
                      onSelect={(value) =>
                        setFieldValue('internalCategory', value)
                      }
                      placeholder="Select Internal Category"
                      onChange
                    />
                    {errors.internalCategory && touched.internalCategory && (
                      <Typography style={{ color: 'red' }}>
                        {errors.internalCategory}
                      </Typography>
                    )}
                  </InfoRowStyled>
                </div>
                {errorMessage && <Typography>{errorMessage}</Typography>}
                <BottomContainerStyled>
                  <Button onClick={onClose} variant="gray" radius="normal">
                    Cancel
                  </Button>
                  <Button
                    isLoading={isLoading}
                    variant="primary"
                    radius="normal"
                    type="submit"
                  >
                    {initialValues ? 'Update' : 'Create'}
                  </Button>
                </BottomContainerStyled>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </ModalContainerStyled>
  );
};

export default AddProductMapper;
