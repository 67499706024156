import { ConfigType } from '@/utils/requestAPI';

export type DocumentGroupParamsType = {
  region?: string;
};

export type TagsPaginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
};

export type TagType = {
  title?: string;
  category?: string;
  subCategory?: string[];
  products?: string[];
  region?: string;
  appliedTo?: string;
  parentId?: string[];
  refId?: string[];
  reference?: Array<{ id: string[]; type: string }>;
};
const tags = {
  getCategories: (params?: DocumentGroupParamsType): ConfigType => {
    return {
      method: 'get',
      url: '/document-group',
      params,
    };
  },
  getAllTags: (params?: TagsPaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/deep-tag',
      params,
    };
  },

  getSingleTag: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/deep-tag/${id}`,
    };
  },
  addTag: (data: TagType): ConfigType => {
    return {
      method: 'post',
      url: '/deep-tag',
      data,
      headers: {
        region: data.region,
      },
    };
  },
  updateTag: (id: string, data: TagType): ConfigType => {
    return {
      method: 'put',
      url: `/deep-tag/${id}`,
      data,
      headers: {
        region: data.region,
      },
    };
  },
  deleteTag: (id: string): ConfigType => {
    return {
      method: 'delete',
      url: `/deep-tag/${id}`,
    };
  },
};
export default tags;
