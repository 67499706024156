/* eslint-disable */

import React, { isValidElement, ReactNode } from 'react';

interface PopupProps {
  content: any;
  position: { top?: number; left?: number; bottom?: number; right?: number };
  onClose: () => void;
  component?: React.FC<any>;
}

const Popup: React.FC<PopupProps> = ({
  content,
  position,
  onClose,
  component: Component,
}) => {
  console.log(Component);

  return (
    <div
      className="fixed popup pt-3 px-2 bg-white border py-6 drop-shadow-lg"
      style={{ ...position }}
    >
      <div
        className="absolute right-1 top-1 cursor-pointer px-1  border  drop-shadow shrink-0"
        onClick={onClose}
      >
        &times;
      </div>
      {Component ? <Component {...content} /> : JSON.stringify(content)}
    </div>
  );
};

export default Popup;
