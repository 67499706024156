import UserPNG from '@/assets/user-img.png';
import CrumbsWithButton from '@/components/UserDetails/CrumbsWithButton';
import Events from '@/components/UserDetails/Events';
import HeadingTitle from '@/components/UserDetails/HeadingTitle';
import Payments from '@/components/UserDetails/Payments';
import Subscription from '@/components/UserDetails/Subscription';
import UserProfileWIthImage from '@/components/UserDetails/UserProfileWIthImage';
import CardTab from '@/designComponents/CardTab';

import {
  analyticUserBreadCrumbData,
  deleteUserCardData,
  eventsData,
  paymentsData,
  sentEmailData,
} from '../../data';
import { AccountDetailsWrapper, InfoRowStyled } from '../style';
import UserAnalyticInformation from './UserAnalyticInformation';

const AnalyticUser = () => {
  return (
    <AccountDetailsWrapper>
      <CrumbsWithButton
        breadCrumbData={analyticUserBreadCrumbData}
        deleteButtonText="Delete User"
        editButtonText="Edit User"
      />
      <div className="main-content">
        <InfoRowStyled>
          <HeadingTitle title="Users Analytics" />
          <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
            {' '}
            {deleteUserCardData.map((card, idx) => (
              <CardTab
                title={card.title}
                count={card.count}
                key={idx}
                enableBorder
                mainContentstyle={{ padding: '13px 12px' }}
                cardStyle={{
                  maxWidth: '190px',
                  width: '100%',
                  minHeight: '112px',
                }}
              />
            ))}
          </div>
        </InfoRowStyled>
        <InfoRowStyled>
          <HeadingTitle title="Active Subscription" />
          <Subscription price="34,637.57" />
        </InfoRowStyled>
        <InfoRowStyled>
          <HeadingTitle title="User Profile" />
          <UserProfileWIthImage
            email="darrell5steward98@gamil.com"
            imgUrl={UserPNG}
            name="Darrell Steward"
          />
        </InfoRowStyled>
        <InfoRowStyled>
          <HeadingTitle title="User Details" />
          <UserAnalyticInformation
            createdAt="15 May 2020 7:00 pm"
            email="darrell5steward98@gmail.com"
            fullName="Darrell Steward"
            id="445"
            updateAt="15 May 2020 10:00 pm"
            firstName="Darrell"
            lastName="Steward"
            currentSignAt="15 May 2020 9:00 pm"
            currentSignIP="93.107.158.97"
            lastSignAt="15 May 2020 9:00 pm"
            lastSignIP="93.107.158.97"
            phoneNumber="(+33)7 35 55 45 43"
            region="Ireland"
            signCount={4}
          />
        </InfoRowStyled>
        <InfoRowStyled>
          <HeadingTitle title="Events" />
          <Events events={eventsData} />
        </InfoRowStyled>
        <InfoRowStyled>
          <HeadingTitle title="Sent Emails" />
          <Events events={sentEmailData} isEmail />
        </InfoRowStyled>
        <InfoRowStyled>
          <HeadingTitle title="Payments" />
          <Payments payments={paymentsData} />
        </InfoRowStyled>
      </div>
    </AccountDetailsWrapper>
  );
};

export default AnalyticUser;
