import Downarrow from '@/assets/downarrow.svg';
import uparrow from '@/assets/uparrow.svg';
import Typography from '@/designComponents/Typography';

import PromoCodeCharts, { PromoCodeChartsData } from './PromoCodeCharts';

type Props = {
  chartsData: Array<PromoCodeChartsData>;
  increasedPercent: string;
  decreasedPercent: string;
};

const PromoCode = ({
  chartsData,
  decreasedPercent,
  increasedPercent,
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Typography
          size="highlight"
          fontWeight="medium"
          style={{ marginRight: '15px' }}
        >
          Promo Code Used Statistics
        </Typography>
        <div
          className="stat"
          style={{ background: '#B5F5EC', border: '1px solid #b5f5ec' }}
        >
          <img src={uparrow} alt="up arrow" />
          <Typography style={{ color: '#08979c', fontSize: '16px' }}>
            + {increasedPercent} %
          </Typography>
        </div>
        <div
          className="stat"
          style={{ background: '#EFDBFF', border: '1px solid #EFDBFF' }}
        >
          <img src={Downarrow} alt="down arrow" />
          <Typography style={{ color: '#9254DE', fontSize: '16px' }}>
            - {decreasedPercent} %
          </Typography>
        </div>
      </div>
      <PromoCodeCharts chartsData={chartsData} />
    </div>
  );
};

export default PromoCode;
