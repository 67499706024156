import styled from 'styled-components';

import { flexStart } from '@/styles/reUsableStyles';

export const IconLeft = styled.span`
  margin-top: 2px;
`;

export const IconRight = styled.span`
  margin-top: 2px;
`;

export const InputContainerStyled = styled.div`
  ${flexStart};
  gap: 10px;
  flex-wrap: nowrap;
  border: 1px solid ${({ theme }) => theme.colors.dark.neutral_20};
  padding: 10px 20px;
  border-radius: 5px;

  &:focus-within {
    &.input-container {
      outline-color: ${({ theme }) => theme.colors.dark.black};
      border: 1px solid ${({ theme }) => theme.colors.dark.black};
    }
  }

  &:focus-within&.error {
    outline-color: ${({ theme }) => theme.colors.status.red};
    border-color: ${({ theme }) => theme.colors.status.red};
  }

  &.error {
    border-color: ${({ theme }) => theme.colors.status.red};
  }
`;
export const InputStyled = styled.input`
  width: 100%;
  color: ${({ theme }) => theme.colors.dark.neutral_60};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  background-color: inherit;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  line-height: 18.75px;
  font-size: ${({ theme }) => theme.fontSize.title};
  border: none;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.dark.neutral_40};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSize.normal};
    line-height: 16.41px;
  }
`;
