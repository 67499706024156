import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import buildingJourney from '@/apiConfigs/buildingJourney';
import HeadingTitle from '@/components/UserDetails/HeadingTitle';
import BreadCrumb from '@/designComponents/BreadCrumb';
import Button from '@/designComponents/Button';
import Modal from '@/designComponents/Modal';
import Typography from '@/designComponents/Typography';
import { regionMapping } from '@/pages/Documents';
import { InformationRowWrapper } from '@/pages/Users/Details/style';
import { humanReadableDate } from '@/utils/date';
import requestAPI from '@/utils/requestAPI';

import { DetailsPageWrapper, InfoRowStyled } from '../../style';
import CreateBuildingSteps from '../CreateBuildingSteps';
import { BuildingJourneyStepBreadCrumbData } from '../data';

export interface SingleBuildingJourneyStepType {
  _id: string;
  prevId: string;
  name: string;
  icon: string;
  position: number;
  region: Region[];
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Region {
  name: string;
  tabs: string[];
  finance: Finance[];
  documents: Document[];
  recommendedProfessionals: RecommendedProfessional[];
  productSubCategory: Array<{
    _id: string;
    prevId: string;
    title: string;
    productCategoryId: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  }>;
  buildingStage: Array<{
    _id: string;
    prevId: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  }>;
  buildingSystems: BuildingSystem[];
  _id: string;
}

export interface Finance {
  title: string;
  subtitle: string;
  link: string;
  _id: string;
}

export interface Document {
  body: Body;
  _id: string;
  prevId: string;
  title: string;
  documentGroupId: string;
  type: string;
  status: string;
  hashTags?: any[];
  deepTags: any[];
  region: string[];
  position: number;
  viewCount: number;
  slug: string;
  level1Slug: string;
  fullSlug: string;
  ads: Ad[];
  createdAt: string;
  updatedAt: string;
  lastModified: string;
  __v: number;
}

export interface Body {
  content: string;
  contentType: string;
}

export interface Ad {
  id: string;
  location: string;
  _id: string;
}

export interface RecommendedProfessional {
  _id: string;
  prevId: string;
  name: string;
  lightIcon: string;
  darkIcon: string;
  region: string;
  __v: number;
  createdAt: string;
  updatedAt: string;
}

export interface BuildingSystem {
  _id: string;
  prevId: string;
  title: string;
  status: string;
  parentId: string;
  level: number;
  slug: string;
  fullSlug: string;
  type: string;
  region: string[];
  isHidden: boolean;
  image: string;
  sponsorSupplier?: string;
  __v: number;
  firstDocPath: string;
}
const BuildingJourneyStepDetails = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [buildingJourneyDetailsStepData, setBuildingJourneyDetailsStepData] =
    useState<SingleBuildingJourneyStepType>();
  const { journeyId } = useParams();

  const getSingleBuildingJourneyStep = async () => {
    try {
      const res = await requestAPI(
        buildingJourney.getSingleBuildingJourneyStep(journeyId)
      );
      setBuildingJourneyDetailsStepData(res);
    } catch (error) {
      console.error(error.message);
      toast.error('Error while fetching single building journey');
    }
  };

  useEffect(() => {
    getSingleBuildingJourneyStep();
  }, []);

  return (
    <DetailsPageWrapper>
      <div className="crumb-btn">
        <BreadCrumb
          breadCrumb={BuildingJourneyStepBreadCrumbData(
            buildingJourneyDetailsStepData?.name
          )}
        />
        <div className="btn">
          <Button radius="md" onClick={() => setOpenEditModal(true)}>
            Edit Building Journey
          </Button>

          {openEditModal && (
            <Modal
              isOpen={openEditModal}
              onClose={() => setOpenEditModal(false)}
              title="Edit Building Journey Step"
            >
              <CreateBuildingSteps
                isModalOpen={openEditModal}
                setIsModalOpen={setOpenEditModal}
                refetchSingleBuildingJourneyStep={getSingleBuildingJourneyStep}
                singleStepData={buildingJourneyDetailsStepData}
                stepId={journeyId}
              />
            </Modal>
          )}
        </div>
      </div>
      <div className="main-content">
        <InfoRowStyled>
          <HeadingTitle title="Building Journey Step Details" />
          <InformationRowWrapper>
            <>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Name
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {buildingJourneyDetailsStepData?.name}
                </Typography>
              </div>

              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Position
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {buildingJourneyDetailsStepData?.position}
                </Typography>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Region
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {buildingJourneyDetailsStepData?.region
                    .map((region) => regionMapping[region.name])
                    .join(', ')}
                </Typography>
              </div>

              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Created At
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {humanReadableDate(buildingJourneyDetailsStepData?.createdAt)}
                </Typography>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Updated At
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {humanReadableDate(buildingJourneyDetailsStepData?.updatedAt)}
                </Typography>
              </div>
              <div className="text" style={{ alignItems: 'start' }}>
                <Typography className="text-value">Icon</Typography>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '2px solid gray',
                    borderRadius: '20px',
                    width: '120px',
                    padding: '5px',
                  }}
                >
                  <img
                    src={buildingJourneyDetailsStepData?.icon}
                    alt="content image"
                    height={100}
                    width={100}
                  />
                </div>
              </div>
            </>
          </InformationRowWrapper>
        </InfoRowStyled>
      </div>
    </DetailsPageWrapper>
  );
};

export default BuildingJourneyStepDetails;
