import { createAsyncThunk } from '@reduxjs/toolkit';

import auth from '@/apiConfigs/auth';
import {
  setAuthorizationHeader,
  setUsersToLocalStorage,
} from '@/utils/apiHelpers';
import requestAPI from '@/utils/requestAPI';

// Define the login async thunk
export const login = createAsyncThunk(
  'auth/login',
  async (credentials: { email: string; password: string }) => {
    const response = await requestAPI(auth.login(credentials));

    if (response && response?.token) {
      setAuthorizationHeader(response?.token);
      setUsersToLocalStorage(response?.admin);
    }
    return response?.admin;
  }
);
