import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import { storiesApi } from '@/apiConfigs/stories';
import HeadingTitle from '@/components/UserDetails/HeadingTitle';
import Tag from '@/components/UserDetails/Tag';
import configs from '@/configs';
import BreadCrumb from '@/designComponents/BreadCrumb';
import Button from '@/designComponents/Button';
import Typography from '@/designComponents/Typography';
import { regionMapping } from '@/pages/Documents';
import { InformationRowWrapper } from '@/pages/Users/Details/style';
import { useAppDispatch, useAppSelector } from '@/store';
import { getSingleStory } from '@/store/stories/function';
import { toCapitalCase } from '@/utils/conversion';
import { humanReadableDate } from '@/utils/date';
import requestAPI from '@/utils/requestAPI';

import CreateStories from '../CreateStories';
import { DetailsPageWrapper, InfoRowStyled } from '../style';

const StoryDetailPage = () => {
  const { storyId } = useParams();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const singleStory = useAppSelector((state) => state.stories.singleStory);

  useEffect(() => {
    if (storyId) {
      dispatch(getSingleStory(storyId));
    }
  }, [storyId, dispatch]);

  const handleApprove = async () => {
    if (storyId) {
      try {
        await requestAPI(storiesApi.approveStory(storyId));
        toast.success('Story approved successfully');
        dispatch(getSingleStory(storyId));
      } catch (error: any) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  return (
    <DetailsPageWrapper>
      <div className="crumb-btn">
        <BreadCrumb
          breadCrumb={[
            {
              label: 'Story',
              path: '/stories',
            },
            {
              label: toCapitalCase(singleStory?.title),
              path: '#',
            },
          ]}
        />
        <div className="btn">
          <div>
            {singleStory?.approved === false && (
              <Button
                variant="primary"
                radius="md"
                style={{
                  backgroundColor: theme.colors.colorActive,
                }}
                onClick={handleApprove}
              >
                Approve
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="main-content">
        <InfoRowStyled>
          <HeadingTitle
            title="Story Details"
            button={<CreateStories />}
            frontEndUrl={`${configs.frontendURL}/inspiration/stories/${singleStory?.slug}`}
          />

          <InformationRowWrapper>
            <>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Title
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {toCapitalCase(singleStory?.title)}
                </Typography>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Description
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {singleStory?.description}
                </Typography>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Category
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {singleStory?.category.map((cat) => cat.title).join(', ')}
                </Typography>
              </div>

              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Story Type
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {singleStory?.type.join(', ')}
                </Typography>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Region
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {singleStory?.region
                    .map((region) => regionMapping[region] || region)
                    .join(', ')}
                </Typography>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Status
                </Typography>
                <div
                  style={{
                    width: 'fit-content',
                  }}
                >
                  <Tag
                    title={
                      singleStory?.approved === true
                        ? 'Approved'
                        : 'Not Approved'
                    }
                    variant={
                      singleStory?.approved === true ? 'active' : 'danger'
                    }
                    hidePoint
                  />
                </div>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Slug
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {singleStory?.slug}
                </Typography>
              </div>

              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Created At
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {humanReadableDate(singleStory?.createdAt)}
                </Typography>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Updated At
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {humanReadableDate(singleStory?.updatedAt)}
                </Typography>
              </div>
            </>
          </InformationRowWrapper>
        </InfoRowStyled>
      </div>
    </DetailsPageWrapper>
  );
};

export default StoryDetailPage;
