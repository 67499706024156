import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import downloadCenter from '@/apiConfigs/downloadCenter';
import Button from '@/designComponents/Button';
import Typography from '@/designComponents/Typography';
import { AdvertiseDetailsWrapper } from '@/pages/Advertisement/Details/style';
import theme from '@/theme';
import { humanReadableDate } from '@/utils/date';
import requestAPI from '@/utils/requestAPI';

import CreateCategory from '../CreateCategory';

type CategoryDetailsType = {
  prevId: string;
  title: string;
  updatedAt: string;
};

const DownloadCenterDetails = () => {
  const { downloadId } = useParams();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [category, setCategory] = useState<CategoryDetailsType>();

  const getCategory = async (id: string) => {
    const data = await requestAPI(
      downloadCenter.getSingleDownloadCenterCategory(id)
    );
    setCategory(data);
  };

  useEffect(() => {
    if (downloadId) {
      getCategory(downloadId);
    }
  }, []);

  // console.log(category, 'download category');

  return (
    <>
      <AdvertiseDetailsWrapper>
        <div className="main-content">
          <div className="heading">
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <Typography
                fontFamily="jost"
                fontWeight="medium"
                size="heading"
                color="dark.neutral_60"
              >
                Download Category Details
              </Typography>
              <Button variant="gray" onClick={() => setIsEditModalOpen(true)}>
                Edit Category Detail
              </Button>
              <CreateCategory
                isOpenModal={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
              />
            </div>
            <hr
              style={{
                backgroundColor: theme.colors.dark.neutral_50,
                border: 'none',
                height: '1px',
              }}
            />
          </div>
          <div className="main-text">
            <div className="text">
              <Typography className="text-value">Id</Typography>
              <Typography className="text-value">{category?.prevId}</Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Name</Typography>
              <Typography className="text-value">{category?.title}</Typography>
            </div>
            {/* <div className="text">
              <Typography className="text-value">Download resources</Typography>
              <Typography className="text-value">
                Products Filler Resource, Structure Filler Resource, Planning
                Filler Resource, Health and Safety in Ireland, The Saefty Health
                and Welfare at Work Construction Regulations 2013, BCAR - Sample
                Diagram 01, Irish Water Filler Resource, Ventillation Folder
                Filler Resource, BCAR - Sample Doc 01
              </Typography>
            </div>

            <div className="text">
              <Typography className="text-value">Created At</Typography>
              <Typography
                className="text-value"
                style={{ textDecoration: 'underline' }}
              >
                15 May 2020 7:00 pm
              </Typography>
            </div> */}
            <div className="text">
              <Typography className="text-value">Update At</Typography>
              <Typography
                className="text-value"
                style={{ textDecoration: 'underline' }}
              >
                {humanReadableDate(category?.updatedAt)}
              </Typography>
            </div>
          </div>
        </div>
      </AdvertiseDetailsWrapper>
    </>
  );
};

export default DownloadCenterDetails;
