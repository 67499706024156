import React from 'react';
import { Outlet } from 'react-router-dom';

import PrivateHeader from './Header/PrivateHeader';
import Sidebar from './Sidebar';
import { BodyStyled, MainLayoutStyled, MainWrapperStyled } from './styles';
import { ILayoutProps } from './type';

export interface ILandingPageLayoutProps extends ILayoutProps {
  extraInfo?: any;
}

const PrivateLayout: React.FunctionComponent<ILandingPageLayoutProps> = ({
  children,
}) => {
  return (
    <MainLayoutStyled>
      <PrivateHeader />
      <BodyStyled>
        <Sidebar />

        <MainWrapperStyled>{children || <Outlet />}</MainWrapperStyled>
      </BodyStyled>
    </MainLayoutStyled>
  );
};

export default PrivateLayout;
