import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FaCirclePlus, FaRegTrashCan } from 'react-icons/fa6';
import { IoIosCloseCircle } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PaginationType } from '@/apiConfigs/documents';
import products from '@/apiConfigs/products';
import tags from '@/apiConfigs/tag';
import CountrySelection from '@/components/CountrySelection';
import configs from '@/configs';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Loader from '@/designComponents/Loader';
import Modal from '@/designComponents/Modal';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import { SearchInputWithDropdown } from '@/designComponents/SearchInput';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import axiosInstance from '@/utils/apiHelpers';
import { debounce } from '@/utils/debounce';
import requestAPI from '@/utils/requestAPI';

import { newParentData } from './data';

type InitialValueType = {
  title?: string;
  category?: string;
  subCategory?: string[];
  products?: string[];
  parentId?: string[];
  region?: string;
  cats?: Array<string[]>;
};

type Props = {
  isOpenModal: boolean;
  onClose: () => void;
  fetchAllTags?: () => Promise<void>;
};

const AddTag = ({ isOpenModal, onClose, fetchAllTags }: Props) => {
  const formikRef = useRef<FormikProps<InitialValueType>>(null);
  const { tagId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [tagProducts, setTagProducts] = useState([]);
  const [recomendedProducts, setRecomendedProducts] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<InitialValueType>({
    title: '',
    category: '',
    subCategory: [],
    region: 'ie',
    parentId: [],
    cats: [['']],
  });
  const [activeTab, setActiveTab] = useState<string[] | null>(null);
  const [currentTabIndex, setCurrentTabIndex] = useState<number | null>(null);
  const [parentData, setParentData] = useState<Record<number, any>>({});
  const [isRecommendedProductLoading, setIsRecommendedProductLoading] =
    useState(false);
  const [selectedTreeData, setSelectedTreeData] = useState<
    Record<number, any[][]>
  >({});

  const getProducts = async (params?: PaginationType) => {
    try {
      setIsProductLoading(true);
      const data = await requestAPI(
        products.getAllProduct({
          search: params.search,
        })
      );
      setTagProducts(data.products.data);
    } catch (error) {
      console.error('Error fetching products', error);
    } finally {
      setIsProductLoading(false);
    }
  };

  const getRecommendedProducts = async (keyword: string) => {
    try {
      setIsRecommendedProductLoading(true);
      const data = await requestAPI(
        products.getRecommendedProduct({
          search: keyword,
        })
      );
      if (data) {
        setRecomendedProducts(data);
      }
    } catch (error) {
      console.error('Error fetching products', error);
    } finally {
      setIsRecommendedProductLoading(false);
    }
  };

  const fetchDocTreeData = async (tab: string, index: number) => {
    try {
      const {
        data: { data: resData },
      } = await axiosInstance.get(`${configs.apiURL}/sidebar/${tab}/tree`, {
        headers: {
          Region: initialValues.region,
        },
      });
      if (resData) {
        const { children, ...parentDataWithoutChildren } = resData;
        setSelectedTreeData((prev) => ({
          ...prev,
          [index]: [children],
        }));
        setParentData((prev) => ({
          ...prev,
          [index]: parentDataWithoutChildren,
        }));
      }
    } catch (error) {
      console.error('Error while fetching doc tree');
    }
  };

  useEffect(() => {
    if (activeTab) {
      const currentTab = activeTab[currentTabIndex];
      if (currentTab && currentTab !== 'all') {
        fetchDocTreeData(currentTab, currentTabIndex);
      }
    }
  }, [activeTab, currentTabIndex]);

  const fetchTagDetails = async () => {
    if (!tagId) return;
    try {
      setIsFetchingData(true);
      const data = await requestAPI(tags.getSingleTag(tagId));
      const firstElements = data.reference.map((item) => item.id[0]);
      const restElements = data.reference.map((item) => item.id.slice(1));
      await getRecommendedProducts(data?.title);
      const foundActiveTabsData = firstElements.map((item) => {
        const matchedData = newParentData.find((x) => x._id === item).type;
        return matchedData;
      });

      for (const [index, elem] of foundActiveTabsData.entries()) {
        await fetchDocTreeData(elem, index);
      }

      setActiveTab(foundActiveTabsData);

      setInitialValues({
        title: data?.title,
        products: data?.products?.map((prod: any) => prod._id),
        region: data?.region,
        cats: data.appliedTo === 'all' ? [''] : restElements,
      });
      if (data.appliedTo === 'all') {
        setActiveTab(['all']);
      }
      setSelectedProducts(data.products || []);
    } catch (error) {
      console.error('Error fetching tag details', error);
    } finally {
      setIsFetchingData(false);
    }
  };

  const handleAddTag = async (val: InitialValueType) => {
    const references = val.cats
      .map((catValues, idx) => {
        const categoryData = selectedTreeData[idx];
        if (!categoryData?.length || !catValues.length) return null;
        const ids = catValues.filter(Boolean);
        const lastArray = categoryData[categoryData.length - 1];
        const lastType = lastArray?.[lastArray.length - 1]?.type;

        return {
          id: [parentData[idx].refId, ...ids],
          type: lastType,
        };
      })
      .filter(Boolean);

    try {
      setLoading(true);
      await requestAPI(
        tags.addTag({
          title: val.title,
          products: selectedProducts.map((item) => item._id),
          region: val.region,
          appliedTo: activeTab[currentTabIndex] === 'all' ? 'all' : 'specific',
          reference: references && references.length ? references : undefined,
        })
      );
      toast.success('Tag added successfully');
      await fetchAllTags();
      onClose();
    } catch (e) {
      setErrorMessage(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateTag = async (val: InitialValueType) => {
    const references = val.cats
      .map((catValues, idx) => {
        const categoryData = selectedTreeData[idx];
        if (!categoryData?.length || !catValues.length) return null;
        const ids = catValues.filter(Boolean);
        const lastArray = categoryData[categoryData.length - 1];
        const lastType = lastArray?.[lastArray.length - 1]?.type;

        return {
          id: [parentData[idx].refId, ...ids],
          type: lastType,
        };
      })
      .filter(Boolean);
    try {
      setLoading(true);
      await requestAPI(
        tags.updateTag(tagId, {
          title: val.title,
          products: selectedProducts.map((item) => item._id),
          region: val.region,
          appliedTo: activeTab[currentTabIndex] === 'all' ? 'all' : 'specific',
          reference: references && references.length ? references : undefined,
        })
      );
      fetchTagDetails();
      toast.success('Tag updated successfully');
      onClose();
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      if (tagId) {
        fetchTagDetails();
      }
    }
  }, [tagId, isOpenModal]);

  const fetchProductsWhenSearch = useCallback(
    debounce((search: string) => {
      if (search.length > 0) {
        getProducts({
          search: search || undefined,
        });
      }
    }, 300),
    []
  );

  const fetchRecommendedProduct = useCallback(
    debounce((keyword: string) => {
      if (keyword.length > 0) {
        getRecommendedProducts(keyword || undefined);
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (searchTerm) {
      fetchProductsWhenSearch(searchTerm);
    }
  }, [searchTerm]);

  const handleSearch = (val: string) => {
    setSearchTerm(val);
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onClose={onClose}
      title={tagId ? 'Update Tag' : 'Add Tag'}
      halfWidth
    >
      <ModalContentWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={tagId ? handleUpdateTag : handleAddTag}
          enableReinitialize
          innerRef={formikRef}
        >
          {(formikProps) => {
            const { values, setFieldValue, errors, touched } = formikProps;
            return (
              <Form
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              >
                {!isFetchingData ? (
                  <div>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <CountrySelection
                        selectedCountry={values.region}
                        setSelectedCountry={(val) =>
                          setFieldValue('region', val)
                        }
                      />
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                      <Typography
                        fontWeight="medium"
                        style={{ marginBottom: '10px' }}
                      >
                        Title
                      </Typography>
                      <Input
                        title="title"
                        value={values.title}
                        onChange={(e) => {
                          setFieldValue('title', e.target.value);
                          fetchRecommendedProduct(e.target.value);
                        }}
                      />
                      {errors.title && touched.title ? (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                          {errors.title}
                        </div>
                      ) : null}
                    </div>

                    <div
                      style={{
                        marginTop: '20px',
                      }}
                    >
                      <Typography fontWeight="medium"> Categories*</Typography>

                      <FieldArray name="cats">
                        {({ push, remove }) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 10,
                              }}
                            >
                              {values.cats.map((item, idx) => {
                                return (
                                  <div
                                    key={idx}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      flexWrap: 'wrap',
                                      flex: 1,
                                      width: '100%',
                                    }}
                                  >
                                    <Select
                                      placeholder="Select Parent Group"
                                      options={[
                                        {
                                          label: 'All',
                                          value: 'all',
                                        },
                                        ...newParentData.map((x) => ({
                                          label: x.title,
                                          value: x.type,
                                        })),
                                      ]}
                                      style={{
                                        width: '100px !important',
                                        maxWidth: '200px !important',
                                      }}
                                      selected={
                                        activeTab &&
                                        activeTab.length > 0 &&
                                        activeTab[idx]
                                      }
                                      onSelect={(value) => {
                                        setActiveTab((prev) => {
                                          const newTabs = prev
                                            ? [...prev]
                                            : new Array(
                                                values.cats.length
                                              ).fill('');
                                          newTabs[idx] = value.toString();
                                          return newTabs;
                                        });
                                        setCurrentTabIndex(idx);
                                      }}
                                    />
                                    <div
                                      style={{
                                        display: 'flex',
                                        gap: 10,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {activeTab &&
                                        activeTab.length > 0 &&
                                        activeTab[idx] !== 'all' &&
                                        selectedTreeData &&
                                        selectedTreeData[idx]?.map((x, i) => {
                                          return (
                                            <Select
                                              key={`${idx}-${i}`}
                                              placeholder="Select Child"
                                              options={x.map((y) => ({
                                                label: y.title,
                                                value: y.refId,
                                                otherFields: {
                                                  ...y,
                                                },
                                              }))}
                                              style={{
                                                width: '100px !important',
                                                maxWidth: '200px !important',
                                              }}
                                              selected={
                                                values.cats[idx]?.[i] || ''
                                              }
                                              onSelect={(
                                                value,
                                                otherFields
                                              ) => {
                                                setFieldValue(`cats[${idx}]`, [
                                                  ...values.cats[idx].slice(
                                                    0,
                                                    i
                                                  ),
                                                  value,
                                                ]);
                                                setSelectedTreeData((prev) => {
                                                  const categoryData =
                                                    prev[idx]?.slice(
                                                      0,
                                                      i + 1
                                                    ) || [];
                                                  return {
                                                    ...prev,
                                                    [idx]: otherFields.children
                                                      ?.length
                                                      ? [
                                                          ...categoryData,
                                                          otherFields.children,
                                                        ]
                                                      : categoryData,
                                                  };
                                                });
                                              }}
                                            />
                                          );
                                        })}
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        gap: 10,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                      }}
                                    >
                                      {!activeTab?.includes('all') && (
                                        <FaCirclePlus
                                          size={20}
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            push(['']);
                                            setSelectedTreeData((prev) => ({
                                              ...prev,
                                              [values.cats.length]: [],
                                            }));
                                          }}
                                        />
                                      )}

                                      {idx > 0 && (
                                        <FaRegTrashCan
                                          size={20}
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            remove(idx);
                                            setSelectedTreeData((prev) => {
                                              const newData = { ...prev };
                                              delete newData[idx];
                                              return newData;
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }}
                      </FieldArray>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                      <Typography
                        fontWeight="medium"
                        style={{ marginBottom: '5px' }}
                      >
                        Products*
                      </Typography>
                      <SearchInputWithDropdown
                        onSearch={handleSearch}
                        searchTerm={searchTerm}
                        isOptionsLoading={isProductLoading}
                        onOptionSelect={(value, otherFields) => {
                          setSelectedProducts((prev) =>
                            prev.some((prod) => prod._id === otherFields._id)
                              ? prev
                              : [...prev, otherFields]
                          );
                        }}
                        options={tagProducts.map((prod) => ({
                          label: prod.title,
                          value: prod._id,
                          otherFields: {
                            ...prod,
                          },
                        }))}
                      />
                    </div>

                    <div
                      style={{
                        marginBottom: '20px',
                        display: 'flex',
                        gap: 10,
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      {selectedProducts.map((item) => {
                        return (
                          <div
                            style={{
                              height: 180,
                              width: 100,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 10,
                              padding: 5,
                              position: 'relative',
                            }}
                            key={item._id}
                          >
                            <img
                              src={item?.photos?.[0] || item?.photo}
                              alt={item.title}
                              style={{
                                height: 60,
                                width: '100%',
                                objectFit: 'fill',
                                border: '1px solid blue',
                              }}
                            />

                            <Typography>
                              {item.title.substring(0, 40) + '...'}
                            </Typography>
                            <IoIosCloseCircle
                              style={{
                                position: 'absolute',
                                right: '-5px',
                                top: '-5px',
                                zIndex: 999,
                                cursor: 'pointer ',
                              }}
                              size={22}
                              onClick={() => {
                                return setSelectedProducts((prev) =>
                                  prev.some((prod) => prod._id === item._id)
                                    ? prev.filter(
                                        (prod) => prod._id !== item._id
                                      )
                                    : prev
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>

                    {recomendedProducts.length > 0 ? (
                      <div style={{ marginBottom: '20px' }}>
                        <Typography
                          fontWeight="medium"
                          style={{ marginBottom: '5px' }}
                        >
                          Recommended Products*
                        </Typography>
                        <div
                          style={{
                            marginBottom: '20px',
                            display: 'flex',
                            gap: 10,
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}
                        >
                          {recomendedProducts.map((item) => {
                            return (
                              <div
                                style={{
                                  height: 180,
                                  width: 100,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 10,
                                  padding: 5,
                                  position: 'relative',
                                }}
                                key={item._id}
                              >
                                <img
                                  src={item.photo}
                                  alt={item.title}
                                  style={{
                                    height: 60,
                                    width: '100%',
                                    objectFit: 'fill',
                                    border: '1px solid blue',
                                  }}
                                />

                                <Typography>
                                  {item.title.substring(0, 20) + '...'}
                                </Typography>
                                {selectedProducts.some(
                                  (prod) => prod._id === item._id
                                ) ? (
                                  <button type="button">Used</button>
                                ) : (
                                  <button
                                    type="button"
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      return setSelectedProducts((prev) => [
                                        ...prev,
                                        item,
                                      ]);
                                    }}
                                  >
                                    Tag Product
                                  </button>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 5,
                        }}
                      >
                        <Typography
                          fontWeight="medium"
                          style={{ marginBottom: '5px' }}
                        >
                          Recommended Products
                        </Typography>
                        {isRecommendedProductLoading ? (
                          <Typography style={{ marginBottom: '5px' }}>
                            Loading recommended product
                          </Typography>
                        ) : (
                          <Typography style={{ marginBottom: '5px' }}>
                            No recommended product found
                          </Typography>
                        )}
                      </div>
                    )}

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '20px',
                        gap: '20px',
                      }}
                    >
                      <Button
                        variant="gray"
                        radius="normal"
                        type="button"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" isLoading={loading} radius="normal">
                        {tagId ? 'Update' : 'Save'}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minHeight: '300px',
                      width: '100%',
                    }}
                  >
                    <Loader
                      style={{
                        height: 60,
                        width: 60,
                      }}
                    />
                  </div>
                )}
                {errorMessage && (
                  <div style={{ color: 'red', marginTop: '10px' }}>
                    {errorMessage}
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </ModalContentWrapper>
    </Modal>
  );
};

export default AddTag;
