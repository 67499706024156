import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import products from '@/apiConfigs/products';
import DeleteModal from '@/components/DeleteModal';
import BreadCrumb from '@/designComponents/BreadCrumb';
import Button from '@/designComponents/Button';
import Modal from '@/designComponents/Modal';
import { useAppSelector } from '@/store';
import requestAPI from '@/utils/requestAPI';

import CreateProduct from '../CreateProduct';
import { breadCrumbData } from '../data';
import { CrumbsWrapper } from './style';

type Props = {
  productTitle: string;
  productId?: string;
};
const CrumbsWithButton = ({ productTitle, productId }: Props) => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const singleProduct = useAppSelector((state) => state.products.singleProduct);

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await requestAPI(products.deleteSingleProduct(productId));
      setIsLoading(false);
      setIsDeleteModalOpen(false);
      toast.success(`${productTitle} deleted successfully`);
      navigate(-1);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.message);
      console.error('error from product delete', error.message);
    }
  };

  return (
    <CrumbsWrapper>
      <BreadCrumb breadCrumb={breadCrumbData(productTitle)} />
      <div className="btn">
        <Button
          onClick={() => setIsDeleteModalOpen(true)}
          radius="md"
          variant="ghost"
          style={{
            border: `1px solid ${theme.colors.colorDanger}`,
            color: theme.colors.light.white,
            fontWeight: theme.fontWeights.medium,
            fontSize: theme.fontSize.normal,
            fontFamily: theme.fontFamily.roboto,
            backgroundColor: theme.colors.colorDanger,
          }}
        >
          Delete Product
        </Button>
        <Button
          onClick={() => setIsModalOpen(true)}
          radius="md"
          variant="ghost"
          style={{
            border: `1px solid ${theme.colors.brandColorBlue}`,
            color: theme.colors.light.white,
            fontWeight: theme.fontWeights.medium,
            fontSize: theme.fontSize.normal,
            fontFamily: theme.fontFamily.roboto,
            backgroundColor: theme.colors.brandColorBlue,
          }}
        >
          Edit Product
        </Button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Edit Product"
      >
        <CreateProduct
          setIsModalOpen={setIsModalOpen}
          singleProduct={singleProduct}
          productId={productId}
        />
      </Modal>

      <DeleteModal
        confirmBtnLoading={isLoading}
        title="housebuild.com says"
        onClose={() => setIsDeleteModalOpen(false)}
        isOpen={isDeleteModalOpen}
        onConfirmDelete={handleDelete}
        deleteText={`Are you sure you want to delete ${productTitle} ?`}
      />
    </CrumbsWrapper>
  );
};

export default CrumbsWithButton;
