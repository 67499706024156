import styled from 'styled-components';

export const AccountWrapperStyled = styled.div`
  /* background-color: red; */
  .main {
    display: flex;
    gap: 8px;
    align-items: center;
    position: relative;

    & .overlay {
      position: absolute;
      top: 100%;
      left: 90;
      right: 0;

      padding: 20px;

      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
  }

  .overlay-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .custom-datepicker {
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  .react-datepicker {
    font-size: 14px;
  }

  .react-datepicker__header {
    background-color: #e9ecef;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.5rem;
    line-height: 2.5rem;
  }

  .react-datepicker__current-month {
    font-weight: bold;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #007bff;
    color: white;
  }

  .react-datepicker__day--in-range {
    background-color: #ebab34;
  }

  .react-datepicker__day--in-selecting-range {
    background-color: #ebab34;
  }
`;
export const SearchInputWrapperStyled = styled.div`
  width: 100%;
  max-width: 280px;
`;
