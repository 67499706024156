import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import products, {
  ProductMapPaginationType,
  ProductMapperType,
} from '@/apiConfigs/products';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import Pagination from '@/components/Pagination';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Table, { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { SearchInputWrapperStyled } from '@/pages/Users/style';
import { debounce } from '@/utils/debounce';
import requestAPI from '@/utils/requestAPI';

import AddProductMapper from './AddProductMapper';

type Props = {};

const ExternalProductMapper = (_props: Props) => {
  const [openProductMapperModal, setOpenProductMapperModal] = useState(false);
  const [selectedProductMapper, setSelectedProductMapper] =
    useState<ProductMapperType | null>(null);
  const [productMaps, setProductMaps] = useState([]);

  const [paginationParams, setPaginationParams] =
    useState<ProductMapPaginationType>({
      page: 1,
      limit: 10,
      search: '',
    });
  const [pagination, setPagination] = useState({ totalPages: 1 });

  const fetchProductMaps = async (params: ProductMapPaginationType) => {
    try {
      const data = await requestAPI(products.getProductMaps(params));
      setProductMaps(data.data);
      setPagination({
        totalPages: data.pagination.totalPages,
      });
    } catch (error) {
      console.log(error, 'Failed to get product maps');
    }
  };

  const delayedFetch = useCallback(debounce(fetchProductMaps, 300), []);

  useEffect(() => {
    fetchProductMaps(paginationParams);
  }, [paginationParams.page, paginationParams.limit]);

  const tableData: TableDataType = {
    headings: [
      { key: 'externalCategory', label: 'External Category' },
      { key: 'internalCategory', label: 'Internal Category' },
      { key: 'date', label: 'Created At' },
      {
        key: 'actions',
        label: 'Action',
      },
    ],
    body: Array.isArray(productMaps)
      ? productMaps.map((category) => ({
          id: category._id,
          externalCategory: category.externalCategory,
          internalCategory: category.internalCategory,
          date: moment(category.createdAt).format('MMM DD, YYYY  HH:mm'),
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="primary"
                radius="normal"
                onClick={() => {
                  setSelectedProductMapper(category);
                  setOpenProductMapperModal(true);
                }}
              >
                Edit
              </Button>
            </div>
          ),
        }))
      : [],
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPaginationParams((prevParams) => {
      const updatedParams = { ...prevParams, search: value, page: 1 };
      delayedFetch(updatedParams);
      return updatedParams;
    });
  };

  const handleCurrentPage = (page: number) => {
    setPaginationParams((prevParams) => ({ ...prevParams, page }));
  };

  const handleLimitChange = (limit: number) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      limit,
      page: 1,
    }));
  };

  const handleNextPage = () => {
    if (paginationParams.page < pagination.totalPages) {
      handleCurrentPage(paginationParams.page + 1);
    }
  };

  const handleLastPage = () => {
    if (paginationParams.page !== pagination.totalPages) {
      handleCurrentPage(pagination.totalPages);
    }
  };

  useHeaderTitle('External Product Mapper');

  return (
    <div>
      <div style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchInputWrapperStyled>
            <Input
              value={paginationParams.search}
              onChange={handleInputChange}
              placeholder="Search"
              iconLeft={<SearchSVG />}
            />
          </SearchInputWrapperStyled>

          {openProductMapperModal && (
            <AddProductMapper
              isOpenModal={openProductMapperModal}
              onClose={() => {
                setOpenProductMapperModal(false);
                setSelectedProductMapper(null);
              }}
              initialValues={selectedProductMapper}
              refreshData={() => fetchProductMaps(paginationParams)}
            />
          )}

          <Button radius="md" onClick={() => setOpenProductMapperModal(true)}>
            New Product Mapper
          </Button>
        </div>
      </div>

      <Table
        tableData={tableData}
        noData="No data to display."
        showCheckBox={false}
        maxWidthForCardView="768px"
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
      />

      <Pagination
        activePage={paginationParams.page || 1}
        onCurrentPageChange={handleCurrentPage}
        totalPages={pagination?.totalPages || 1}
        limit={paginationParams.limit || 10}
        onLimitChange={handleLimitChange}
        onNext={handleNextPage}
        onLast={handleLastPage}
      />
    </div>
  );
};

export default ExternalProductMapper;
