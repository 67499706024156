export const darkColors = {
  neutral_69: '#696969',
  neutral_55: '#555555',
  neutral_5d: '#5d5d5d',
  neutral_50: '#F1F1F1',
  neutral_33: '#333333',
  neutral_450: '#4C4C4C',
  neutral_44: '#444444',
  neutral_24: '#242124',
  neutral_1b: '#1b1b1b',
  black: '#000000',
  neutral_61: '#616161',
  neutral_42: '#424242',
  neutral_92: '#929292',
  neutral_94: '#949494',
  neutral_8a: '#8A8A8A',
  neutral_60: '#282828',
  neutral_45: '#4C4C4C',
  neutral_20: '#D6D6D7',
  neutral_40: '#747474',
  neutral_10: '#E8E8E8',
  neutral_25: '#F8F8F8',
  neutral_250: '#B8B8B8',
} as const;

export const lightColors = {
  white: '#ffffff',
  neutral_fc: '#fcfcfc',
  snow: '#fffafa',
  seashell: '#FFF5EE',
  ivory: '#FFFFF0',
  neutral_f5: '#f5f5f5',
  boneWhite: '#f9f6ee',
  neutral_e0: '#E0E0E0',
  neutral_eb: '#EBEBEB',
  neutral_db: '#dbdbdb',
  neutral_fd: '#fdfdfd',
  neutral_fa: '#fafafa',
  neutral_d9: '#D9D9D9',
} as const;

export const statusColors = {
  warning: '#D8882E',
  caution: '#FED440',
  danger: '#D64960',
  success: '#37B75A',
  info: '#4069E1',
  shadeOfBlue: '#2061F8',
  shadeOfGreen: '#00DE90',
  red: '#FF0000',
  hard: {
    accent: '#D4618B',
    secondary: '#CFBF5A',
    highlight: '#1F3C62',
    warning: '#A96E1E',
    caution: '#DDBF37',
    danger: '#A8213B',
    success: '#2E7441',
    info: '#314E8A',
  },
  soft: {
    accent: '#FED7E4',
    secondary: '#FAEBC2',
    highlight: '#6E8FCC',
    warning: '#E0B55A',
    caution: '#FEEDA7',
    danger: '#E6868F',
    success: '#7FCF97',
    info: '#8CA3ED',
  },
} as const;

export type DarkColorsType = typeof darkColors;
export type LightColorsType = typeof lightColors;
export type StatusColorsType = typeof statusColors;

export const colors = {
  primary: '#1890FF',
  accent: '#5cffc6',
  secondary: '#1890FF',
  highlight: '#0E1335',
  blue_f4: '#F4FBFF',
  blue_e8: '#E8F7FF',
  blue_d3: '#D3EFFF',
  text: '#333333',
  caption: '#666666',
  darkText: '#444444',
  darkBlue: '#00359E',
  brandColorBlue: '#0050F9',
  borderColor: '#dbdbdb',
  background: '#fdfdfd',
  activeBg: '#DFF5C5',
  colorActive: '#4F9435',
  dangerBg: '#FFF1F0',
  colorDanger: '#FF4D4F',
  blue_ba: '#BAE7FF',
  lightBlue: '#2395D7',
  soft: {
    primary: '#E6F7FF',
  },
  status: statusColors,
  light: lightColors,
  dark: darkColors,
  disableText: '#555d50',
  lightGrey: lightColors,
  scrollThumb: '#c7c7c7',
};

export type ColorsType = typeof colors;

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}.${P}`
    : never
  : never;

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, ...0[]];

type FilteredPaths<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? {
        [K in keyof T]-?: T[K] extends string
          ? `${Extract<K, string>}`
          : T[K] extends object
            ? Join<K, FilteredPaths<T[K], Prev[D]>>
            : never;
      }[keyof T]
    : '';

export type NestedColorKeys = FilteredPaths<ColorsType>;

export const getColorValue = (
  path: NestedColorKeys | undefined
): string | undefined => {
  if (!path) return undefined;
  return path.split('.').reduce<string | undefined>((o, i) => {
    if (o && typeof o === 'object') {
      return (o as any)[i];
    }
    return undefined;
  }, colors as any);
};
