import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Use useNavigate for navigation
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { ReactComponent as ISVG } from '@/assets/icons/i-btn.svg';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Typography from '@/designComponents/Typography';
import { useAppDispatch } from '@/store';
import { login } from '@/store/auth/functions';

import { ContainerStyled, LoginWrapperStyled } from './styles';

interface LoginFormValues {
  email: string;
  password: string;
}

const Login = () => {
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (values: LoginFormValues) => {
    setIsLoading(true);
    try {
      const resultAction = await dispatch(
        login({ email: values.email, password: values.password })
      );

      if (login.fulfilled.match(resultAction)) {
        const user = resultAction.payload;

        if (user.isPasswordResetRequired) {
          toast.info('Please change your password before logging in.');
          navigate(`/setPassword?email=${user.email}`);
        } else {
          toast.success('Logged in successfully');
          navigate('/');
        }
      } else if (login.rejected.match(resultAction)) {
        const eMessage = resultAction.payload || 'Failed to login';
        setErrorMessage(eMessage);
      }

      setIsLoading(false);
    } catch (error) {
      setErrorMessage(error.message || 'Failed to login');
      setIsLoading(false);
    }
  };

  return (
    <LoginWrapperStyled>
      <div className="sign-slogan">
        <div className="main-content">
          <ISVG />
          <Typography
            color="primary"
            fontFamily="jost"
            fontWeight="medium"
            size="title"
          >
            You need to sign in or sign up before continuing.
          </Typography>
        </div>
      </div>
      <ContainerStyled>
        <div className="header">
          <Typography
            variant="h1"
            size="display"
            fontWeight="medium"
            fontFamily="jost"
          >
            Welcome to <span style={{ color: '#0050F9' }}>HouseBuild</span>
          </Typography>
          <Typography
            color="dark.neutral_60"
            size="normal"
            fontWeight="normal"
            fontFamily="jost"
          >
            Ireland's Number One Source Of Building Information
          </Typography>
        </div>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={handleLogin}
          validationSchema={Yup.object().shape({
            email: Yup.string().required('Email is required'),
            password: Yup.string().required('Password is required'),
          })}
        >
          {({ values, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <div className="form-container">
                  <Typography>Email*</Typography>
                  <Input
                    placeholder="alma.lawson@example.com"
                    radius="15px"
                    name="email"
                    value={values.email}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                  />
                  {errors.email && touched.email ? (
                    <Typography color="status.red" size="subtitle">
                      {errors.email}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>
                <div className="form-container">
                  <Typography>Password*</Typography>
                  <Input
                    placeholder="Password"
                    radius="15px"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={(e) => setFieldValue('password', e.target.value)}
                  />
                  {errors.password && touched.password ? (
                    <Typography color="status.red" size="subtitle">
                      {errors.password}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>

                {errorMessage && (
                  <Typography style={{ color: 'red' }}>
                    {errorMessage}
                  </Typography>
                )}

                <Button
                  variant="primary"
                  style={{ width: '100%', marginTop: '30px' }}
                  radius="normal"
                  isLoading={isLoading}
                >
                  Login
                </Button>
              </Form>
            );
          }}
        </Formik>
        <Link to="/forgot-password">
          <Typography
            style={{ textAlign: 'center', marginTop: '20px' }}
            fontFamily="roboto"
            color="dark.neutral_60"
            fontWeight="medium"
            size="title"
          >
            Forgot your password?
          </Typography>
        </Link>
      </ContainerStyled>
    </LoginWrapperStyled>
  );
};

export default Login;
