import { ConfigType } from '@/utils/requestAPI';

interface TagsType {
  _id: string;
  slug?: string;
  product?: string;
  xCoordinate?: number;
  yCoordinate?: number;
  color?: string;
}

export type PaginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string | null;
  type?: 'Story' | 'Interior';
  filter?: {
    approved: boolean | null;
  };
};

interface PhotosType {
  isMain: boolean;
  url: string;
  imageContent?: string;
  caption?: string;
  importedFromURL?: string;
  externalURL?: string;
  tags?: Array<TagsType>;
}

export enum StoryType {
  Interior = 'Interior',
  Story = 'Story',
  ProfessionalProject = 'Professional Project',
  ImageGallery = 'Image Gallery',
  Blog = 'Blog',
}

export interface AddStoriesType {
  body: string;
  title: string;
  slug: string;
  author?: string;
  publishedAt?: Date;
  category: string[];
  description?: string;
  featured?: boolean;
  approved?: boolean;
  createdUnderCompanyName?: boolean;
  type?: StoryType;
  region?: string[];
  photos?: Array<PhotosType>;
  prevId?: string;
}

const storiesApi = {
  addStories: (data: AddStoriesType): ConfigType => {
    return {
      method: 'post',
      url: '/idea',
      data,
    };
  },
  getStoryCatByType: (type: string): ConfigType => {
    return {
      method: 'get',
      url: '/idea/category',
      params: {
        type,
      },
    };
  },
  getAllStories: (params?: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/idea',
      params,
    };
  },
  getSingleStory: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/idea/id/${id}`,
    };
  },
  updateStory: (data: AddStoriesType, id: string): ConfigType => {
    return {
      method: 'put',
      url: `/idea/id/${id}`,
      data,
    };
  },
  approveStory: (id: string): ConfigType => {
    return {
      method: 'put',
      url: `/idea/${id}/approve`,
    };
  },
};

export { storiesApi };
