import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Modal from '@/designComponents/Modal';
import Typography from '@/designComponents/Typography';
import { InfoRowStyled, NewDocumentWrapper } from '@/pages/Documents/style';
// import { useAppDispatch } from '@/store';

export type InitialValuesType = {
  name: string;
};

const CreateTypes = () => {
  const theme = useTheme();
  //   const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFormSubmit = async (val: InitialValuesType) => {
    setLoading(true);

    console.log(val);

    setLoading(false);
    setIsModalOpen(false);
    toast.success('Download Group created');
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button size="md" onClick={openModal}>
        New Type
      </Button>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Create New Folder"
      >
        <div
          style={{
            padding: '20px',
            minHeight: '400px',
            maxWidth: '400px',
            backgroundColor: 'white',
          }}
        >
          <Formik
            initialValues={{
              name: '',
            }}
            onSubmit={handleFormSubmit}
          >
            {({ values, setFieldValue, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <NewDocumentWrapper>
                    <InfoRowStyled>
                      <Typography
                        fontFamily="roboto"
                        size="normal"
                        fontWeight="medium"
                        color="dark.neutral_45"
                      >
                        Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Input
                        name="name"
                        value={values.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                    </InfoRowStyled>
                  </NewDocumentWrapper>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button
                      radius="md"
                      variant="ghost"
                      style={{
                        border: `1px solid ${theme.colors.dark.neutral_250}`,
                        borderRadius: '10px',
                        color: theme.colors.text,
                        fontFamily: theme.fontFamily.roboto,
                        fontWeight: theme.fontWeights.medium,
                      }}
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      radius="normal"
                      isLoading={loading}
                      style={{ backgroundColor: theme.colors.brandColorBlue }}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default CreateTypes;
