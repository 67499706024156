import React from 'react';
import styled from 'styled-components';

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
}

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchTrack = styled.div<{ checked: boolean }>`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  background-color: ${(props) =>
    props.checked
      ? props.theme.colors.brandColorBlue
      : props.theme.colors.dark.neutral_250};
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:focus-within {
    outline: none;
    box-shadow: 0 0 0 2px ${(props) => props.theme.colors.brandColorBlue}40;
  }
`;

const SwitchThumb = styled.span<{ checked: boolean }>`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;
  transform: translateX(${(props) => (props.checked ? '16px' : '0')});
`;

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

const Label = styled.span`
  margin-left: 8px;
  font-size: ${(props) => props.theme.fontSize.h5};
  color: ${(props) => props.theme.colors.dark.neutral_45};
  font-family: ${(props) => props.theme.fontFamily.roboto};
`;

const Switch: React.FC<SwitchProps> = ({ checked, onChange, label }) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onChange(!checked);
    }
  };

  return (
    <SwitchContainer>
      <SwitchTrack
        checked={checked}
        onClick={() => onChange(!checked)}
        onKeyDown={handleKeyDown}
        role="switch"
        aria-checked={checked}
        tabIndex={0}
      >
        <SwitchThumb checked={checked} />
        <HiddenInput
          type="checkbox"
          checked={checked}
          onChange={() => onChange(!checked)}
        />
      </SwitchTrack>
      {label && <Label>{label}</Label>}
    </SwitchContainer>
  );
};

export default Switch;
