import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DragAndDrop from '@/components/dragAndDrop';
import BreadCrumb from '@/designComponents/BreadCrumb';
import Button from '@/designComponents/Button';
import Select from '@/designComponents/Select';
import { useAppDispatch } from '@/store';
import {
  getAllGroupDocument,
  getSingleDocument,
} from '@/store/documents/functions';

import { breadCrumbData } from './data';
import { WrapperStyled } from './style';

type Props = {};

interface DocumentId {
  documentGroupId: string;
}

const Reposition = (_props: Props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [initialValues, _setInitialValues] = useState<DocumentId>({
    documentGroupId: '',
  });

  const documentGroup = useSelector(
    (state: any) => state.documents.documentGroup
  );

  const handleDocumentSearch = async (values: DocumentId) => {
    setLoading(true);
    await dispatch(getSingleDocument(values.documentGroupId));

    setLoading(false);
  };

  useEffect(() => {
    dispatch(getAllGroupDocument({}));
  }, [dispatch]);

  return (
    <>
      <BreadCrumb breadCrumb={breadCrumbData} />
      <Formik initialValues={initialValues} onSubmit={handleDocumentSearch}>
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <WrapperStyled>
                <Select
                  onChange
                  name="documentGroupId"
                  placeholder="Select the component to reposition"
                  options={[
                    {
                      label: 'Select the component to reposition',
                      value: '',
                    },
                    ...(documentGroup?.map((group: any) => ({
                      label: group.title,
                      value: group._id,
                    })) || []),
                  ]}
                  selected={values.documentGroupId}
                  onSelect={(value) => setFieldValue('documentGroupId', value)}
                />
                <Button type="submit" isLoading={loading}>
                  Search
                </Button>
              </WrapperStyled>
            </Form>
          );
        }}
      </Formik>
      <DragAndDrop />
    </>
  );
};

export default Reposition;
