import { FieldArray, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';

import buildingJourney from '@/apiConfigs/buildingJourney';
import documents from '@/apiConfigs/documents';
import CountrySelection from '@/components/CountrySelection';
import HeadingTitle from '@/components/UserDetails/HeadingTitle';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Loader from '@/designComponents/Loader';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import Typography from '@/designComponents/Typography';
import UploadFile from '@/designComponents/UploadFile';
import { InfoRowStyled, NewDocumentWrapper } from '@/pages/Documents/style';
import { BottomContainerStyled } from '@/pages/DownloadCenter/style';
import { useAppDispatch } from '@/store';
import { getAllBuildngSteps } from '@/store/buildingJourney/functions';
import theme from '@/theme';
import axiosInstance from '@/utils/apiHelpers';
import requestAPI from '@/utils/requestAPI';
import { uploadFilesToS3OnlyKeys } from '@/utils/s3Upload';

import {
  AddButton,
  FlexEndContainer,
  RegionContainer,
  RemoveButton,
} from '../../style';
import { getRespectiveOptions, tabsData, validationSchema } from '../data';
import { SingleBuildingJourneyStepType } from '../Details';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  stepId?: string;
  singleStepData?: SingleBuildingJourneyStepType;
  refetchSingleBuildingJourneyStep?: () => Promise<void>;
  loadingData?: boolean;
};

export type InitialValuesType = {
  name: string;
  position: number;
  icon: File | string | null;
  region: string[];
  tabs: Array<{ label: string; value: string }>;
  finance: Array<{ title: string; subtitle: string; link: string }>;
  documents: Array<{ label: string; value: string }>;
  recommendedProfessionals: Array<{ label: string; value: string }>;
  productSubCategory: Array<{ label: string; value: string }>;
  buildingStage: Array<{ label: string; value: string }>;
  buildingSystems: Array<{ label: string; value: string }>;
  otherRegion?: string[];
};

const CreateBuildingSteps = ({
  setIsModalOpen,
  refetchSingleBuildingJourneyStep,
  singleStepData,
  stepId,
  loadingData,
}: Props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const formikRef = useRef<FormikProps<InitialValuesType>>(null);
  const [initialValues, setInitialValues] = useState<InitialValuesType>({
    name: '',
    buildingStage: [],
    buildingSystems: [],
    documents: [],
    finance: [],
    icon: null,
    position: 1,
    productSubCategory: [],
    recommendedProfessionals: [],
    region: [],
    tabs: [],
    otherRegion: [],
  });

  useEffect(() => {
    if (stepId && singleStepData) {
      setInitialValues({
        name: singleStepData.name,
        position: singleStepData.position,
        buildingStage: singleStepData?.region?.[0]?.buildingStage.map(
          (stage) => ({
            label: stage.name,
            value: stage._id,
          })
        ),
        buildingSystems: singleStepData?.region?.[0]?.buildingSystems.map(
          (system) => ({
            label: system.title,
            value: system._id,
          })
        ),
        documents: singleStepData?.region[0]?.documents.map((doc) => ({
          label: doc.title,
          value: doc._id,
        })),
        finance: singleStepData?.region[0]?.finance,
        icon: singleStepData?.icon,
        productSubCategory: singleStepData?.region[0]?.productSubCategory.map(
          (subCat) => ({
            label: subCat.title,
            value: subCat._id,
          })
        ),
        recommendedProfessionals:
          singleStepData?.region[0]?.recommendedProfessionals.map(
            (professional) => ({
              label: professional.name,
              value: professional._id,
            })
          ),
        region: singleStepData?.region.map((reg) => reg.name),
        tabs: singleStepData?.region[0]?.tabs.map((tab) => ({
          label: tab,
          value: tab,
        })),
      });
    }
  }, [stepId, singleStepData]);
  const getAllDocuments = async (inputValue: string) => {
    try {
      const res = await requestAPI(
        documents.getAllDocument({
          limit: 20,
          search: inputValue,
        })
      );

      return getRespectiveOptions(
        singleStepData?.region[0]?.documents,
        res.data,
        stepId
      );
    } catch (error) {
      console.error(error.message);
      toast.error('Error while fetching all documents');
    }
  };

  const getTradeData = async (inputValue: string) => {
    try {
      const res = await axiosInstance.get('/trade', {
        params: {
          limit: 20,
          search: inputValue,
        },
      });

      return getRespectiveOptions(
        singleStepData?.region[0]?.recommendedProfessionals,
        res.data.data.data,
        stepId
      );
    } catch (error) {
      console.error(error.message);
      toast.error('Error while fetching trades');
    }
  };

  const loadProfessionalOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    getTradeData(inputValue).then((options) => {
      callback(options);
    });
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    getAllDocuments(inputValue).then((options) => {
      callback(options);
    });
  };

  const getProductSubCategoryData = async (inputValue: string) => {
    try {
      const res = await axiosInstance.get('/product-sub-category', {
        params: {
          limit: 20,
          search: inputValue,
        },
      });
      return getRespectiveOptions(
        singleStepData?.region[0]?.productSubCategory,
        res.data.data.data,
        stepId
      );
    } catch (error) {
      console.error(error.message);
      toast.error('Failed to fetch products sub-category');
    }
  };

  const loadProductSubCatOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    getProductSubCategoryData(inputValue).then((options) => {
      callback(options);
    });
  };

  const getBuildingSystemsData = async (
    inputValue: string,
    region?: string
  ) => {
    try {
      const res = await requestAPI(
        documents.getAllDocumentGroup({
          limit: 20,
          search: inputValue,
          region: region,
          filter: {
            type: 'building-system',
          },
        })
      );

      return getRespectiveOptions(
        singleStepData?.region[0]?.buildingSystems,
        res,
        stepId
      );
    } catch (error) {
      console.error(error.message);
      toast.error('Failed to fetch building systems');
    }
  };

  const loadBuildingSystemOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    getBuildingSystemsData(inputValue, 'ie').then((options) => {
      callback(options);
    });
  };

  const getBuildingStagesData = async (inputValue: string) => {
    try {
      const res = await axiosInstance.get('/building-stages', {
        params: {
          limit: 20,
          search: inputValue,
        },
      });

      return getRespectiveOptions(
        singleStepData?.region[0]?.buildingStage,
        res.data.data.data,
        stepId
      );
    } catch (error: any) {
      console.error(error.messasge);
      toast.error('Failed to fetch building stages data');
    }
  };

  const loadBuildingStageOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    getBuildingStagesData(inputValue).then((options) => {
      callback(options);
    });
  };

  const getUniqueRegionArray = (region: string[]) => {
    const uniqueOtherRegion = Array.from(new Set(region));
    return uniqueOtherRegion;
  };

  const handleFormSubmit = async (values: InitialValuesType) => {
    setLoading(true);
    try {
      let imageURL = values.icon;

      if (values.icon instanceof File) {
        const uploadedImages = await uploadFilesToS3OnlyKeys([values.icon]);
        imageURL = uploadedImages[0];
      }
      await requestAPI(
        buildingJourney.addBuildingStep({
          name: values.name,
          position: values.position,
          icon: imageURL as string,
          region: getUniqueRegionArray(values.region).map((region) => ({
            name: region,
            tabs: values.tabs.map((tab) => tab.value),
            buildingStage: values.buildingStage.map((stage) => stage.value),
            buildingSystems: values.buildingSystems.map(
              (system) => system.value
            ),
            documents: values.documents.map((doc) => doc.value),
            finance: values.finance,
            productSubCategory: values.productSubCategory.map(
              (cat) => cat.value
            ),
            recommendedProfessionals: values.recommendedProfessionals.map(
              (professional) => professional.value
            ),
          })),
        })
      );
      setLoading(false);
      toast.success('Building step created successfully');
      dispatch(getAllBuildngSteps({}));
      setIsModalOpen(false);
    } catch (error: any) {
      setLoading(false);

      console.error(error.message);
      toast.error(error.message);
    }
  };

  const handleUpdateBuildingStep = async (values: InitialValuesType) => {
    setLoading(true);
    try {
      let imageURL = values.icon;
      if (values.icon instanceof File) {
        const uploadedImages = await uploadFilesToS3OnlyKeys([values.icon]);
        imageURL = uploadedImages[0];
      }
      await requestAPI(
        buildingJourney.updateBuildingStep(
          {
            name: values.name,
            position: values.position,
            icon: imageURL as string,
            region: getUniqueRegionArray(values.region).map((region) => ({
              name: region,
              tabs: values.tabs.map((tab) => tab.value),
              buildingStage: values.buildingStage.map((stage) => stage.value),
              buildingSystems: values.buildingSystems.map(
                (system) => system.value
              ),
              documents: values.documents.map((doc) => doc.value),
              finance: values.finance,
              productSubCategory: values.productSubCategory.map(
                (cat) => cat.value
              ),
              recommendedProfessionals: values.recommendedProfessionals.map(
                (professional) => professional.value
              ),
            })),
          },
          stepId
        )
      );

      setLoading(false);
      await refetchSingleBuildingJourneyStep();
      setIsModalOpen(false);
      toast.success('Building step updated successfully');
    } catch (error: any) {
      setLoading(false);
      console.error(error.message);
      toast.error(error.message);
    }
  };

  return (
    <>
      <ModalContentWrapper>
        <Formik
          initialValues={initialValues}
          innerRef={formikRef}
          onSubmit={stepId ? handleUpdateBuildingStep : handleFormSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, setFieldValue, errors, touched }) => {
            return (
              <Form>
                {!loadingData ? (
                  <>
                    <NewDocumentWrapper>
                      <FlexEndContainer>
                        <FieldArray name="region">
                          {({ push, remove }) => (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: '10px',
                                }}
                              >
                                {values?.region?.map((other, idx) => (
                                  <RegionContainer key={idx}>
                                    <CountrySelection
                                      selectedCountry={other}
                                      setSelectedCountry={async (val) => {
                                        await getBuildingSystemsData(
                                          undefined,
                                          val
                                        );
                                        setFieldValue(`region[${idx}]`, val);
                                      }}
                                    />
                                    <RemoveButton
                                      type="button"
                                      onClick={() => remove(idx)}
                                      style={{
                                        marginBottom: '10px',
                                      }}
                                    >
                                      Remove
                                    </RemoveButton>
                                  </RegionContainer>
                                ))}
                              </div>
                              <AddButton
                                type="button"
                                isDisabled={
                                  values.region.includes('ie') &&
                                  values.region.includes('uk')
                                }
                                onClick={() => push('ie')}
                              >
                                Add Region
                              </AddButton>
                            </div>
                          )}
                        </FieldArray>
                        {typeof errors.region === 'string' &&
                          errors.region &&
                          touched.region && (
                            <div style={{ color: 'red' }}>{errors.region}</div>
                          )}
                      </FlexEndContainer>

                      <InfoRowStyled>
                        <Typography
                          fontFamily="roboto"
                          size="normal"
                          fontWeight="medium"
                          color="dark.neutral_45"
                        >
                          Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Input
                          name="name"
                          value={values.name}
                          onChange={(e) =>
                            setFieldValue('name', e.target.value)
                          }
                        />
                        {errors.name && touched.name && (
                          <div style={{ color: 'red' }}>{errors.name}</div>
                        )}
                      </InfoRowStyled>
                      <InfoRowStyled>
                        <Typography
                          fontFamily="roboto"
                          size="normal"
                          fontWeight="medium"
                          color="dark.neutral_45"
                        >
                          Position <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Input
                          name="position"
                          value={values.position}
                          type="number"
                          onChange={(e) =>
                            setFieldValue('position', e.target.value)
                          }
                        />
                        {errors.position && touched.position && (
                          <div style={{ color: 'red' }}>{errors.position}</div>
                        )}
                      </InfoRowStyled>
                      <InfoRowStyled>
                        <Typography
                          fontFamily="roboto"
                          size="normal"
                          fontWeight="medium"
                          color="dark.neutral_45"
                        >
                          Tabs <span style={{ color: 'red' }}>*</span>
                          <Select
                            isMulti
                            name="tabs"
                            options={tabsData}
                            value={values.tabs}
                            onChange={(selected) =>
                              setFieldValue('tabs', selected)
                            }
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                          />
                        </Typography>

                        {typeof errors.tabs === 'string' &&
                          errors.tabs &&
                          touched.tabs && (
                            <div style={{ color: 'red' }}>{errors.tabs}</div>
                          )}
                      </InfoRowStyled>
                      <InfoRowStyled>
                        <Typography
                          fontFamily="roboto"
                          size="normal"
                          fontWeight="medium"
                          color="dark.neutral_45"
                        >
                          Recommended Professionals{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          value={values.recommendedProfessionals}
                          loadOptions={loadProfessionalOptions}
                          onChange={(selected) => {
                            setFieldValue('recommendedProfessionals', selected);
                          }}
                          closeMenuOnSelect={false}
                        />
                        {typeof errors.recommendedProfessionals === 'string' &&
                          errors.recommendedProfessionals &&
                          touched.recommendedProfessionals && (
                            <div style={{ color: 'red' }}>
                              {errors.recommendedProfessionals}
                            </div>
                          )}
                      </InfoRowStyled>
                      <InfoRowStyled>
                        <Typography
                          fontFamily="roboto"
                          size="normal"
                          fontWeight="medium"
                          color="dark.neutral_45"
                        >
                          Product SubCategory{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          value={values.productSubCategory}
                          loadOptions={loadProductSubCatOptions}
                          onChange={(selected) => {
                            setFieldValue('productSubCategory', selected);
                          }}
                          closeMenuOnSelect={false}
                        />
                        {typeof errors.productSubCategory === 'string' &&
                          errors.productSubCategory &&
                          touched.productSubCategory && (
                            <div style={{ color: 'red' }}>
                              {errors.productSubCategory}
                            </div>
                          )}
                      </InfoRowStyled>

                      <InfoRowStyled>
                        <Typography
                          fontFamily="roboto"
                          size="normal"
                          fontWeight="medium"
                          color="dark.neutral_45"
                        >
                          Documents <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          loadOptions={loadOptions}
                          value={values.documents}
                          onChange={(selected) => {
                            setFieldValue('documents', selected);
                          }}
                          closeMenuOnSelect={false}
                        />
                        {typeof errors.documents === 'string' &&
                          errors.documents &&
                          touched.documents && (
                            <div style={{ color: 'red' }}>
                              {errors.documents}
                            </div>
                          )}
                      </InfoRowStyled>
                      <InfoRowStyled>
                        <Typography
                          fontFamily="roboto"
                          size="normal"
                          fontWeight="medium"
                          color="dark.neutral_45"
                        >
                          Building Stage <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          loadOptions={loadBuildingStageOptions}
                          value={values.buildingStage}
                          onChange={(selected) => {
                            setFieldValue('buildingStage', selected);
                          }}
                          closeMenuOnSelect={false}
                        />
                        {typeof errors.buildingStage === 'string' &&
                          errors.buildingStage &&
                          touched.buildingStage && (
                            <div style={{ color: 'red' }}>
                              {errors.buildingStage}
                            </div>
                          )}
                      </InfoRowStyled>
                      <InfoRowStyled>
                        <Typography
                          fontFamily="roboto"
                          size="normal"
                          fontWeight="medium"
                          color="dark.neutral_45"
                        >
                          Building System{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          loadOptions={loadBuildingSystemOptions}
                          value={values.buildingSystems}
                          onChange={(selected) => {
                            setFieldValue('buildingSystems', selected);
                          }}
                          closeMenuOnSelect={false}
                        />
                        {typeof errors.buildingSystems === 'string' &&
                          errors.buildingSystems &&
                          touched.buildingSystems && (
                            <div style={{ color: 'red' }}>
                              {errors.buildingSystems}
                            </div>
                          )}
                      </InfoRowStyled>
                      <HeadingTitle title="Finance" />
                      <FieldArray name="finance">
                        {({ push, remove }) => (
                          <>
                            {values?.finance?.map((finance, index) => (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '10px',
                                }}
                              >
                                <InfoRowStyled>
                                  <Typography
                                    fontFamily="roboto"
                                    size="normal"
                                    fontWeight="medium"
                                    color="dark.neutral_45"
                                  >
                                    Title{' '}
                                    <span style={{ color: 'red' }}>*</span>
                                  </Typography>
                                  <Input
                                    name={`finance[${index}].title`}
                                    value={finance.title}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `finance[${index}].title`,
                                        e.target.value
                                      )
                                    }
                                  />
                                  {typeof errors.finance?.[index] !==
                                    'string' &&
                                    typeof errors.finance?.[index].title ===
                                      'string' &&
                                    errors.finance?.[index]?.title &&
                                    touched.finance?.[index]?.title && (
                                      <div style={{ color: 'red' }}>
                                        {errors.finance[index].title}
                                      </div>
                                    )}
                                </InfoRowStyled>
                                <InfoRowStyled>
                                  <Typography
                                    fontFamily="roboto"
                                    size="normal"
                                    fontWeight="medium"
                                    color="dark.neutral_45"
                                  >
                                    Subtitle{' '}
                                    <span style={{ color: 'red' }}>*</span>
                                  </Typography>
                                  <Input
                                    name={`finance[${index}].subtitle`}
                                    value={finance.subtitle}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `finance[${index}].subtitle`,
                                        e.target.value
                                      )
                                    }
                                  />
                                  {typeof errors.finance?.[index] !==
                                    'string' &&
                                    typeof errors.finance?.[index].subtitle ===
                                      'string' &&
                                    errors.finance?.[index]?.subtitle &&
                                    touched.finance?.[index]?.subtitle && (
                                      <div style={{ color: 'red' }}>
                                        {errors.finance[index].subtitle}
                                      </div>
                                    )}
                                </InfoRowStyled>
                                <InfoRowStyled>
                                  <Typography
                                    fontFamily="roboto"
                                    size="normal"
                                    fontWeight="medium"
                                    color="dark.neutral_45"
                                  >
                                    Link <span style={{ color: 'red' }}>*</span>
                                  </Typography>
                                  <Input
                                    name={`finance[${index}].link`}
                                    value={finance.link}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `finance[${index}].link`,
                                        e.target.value
                                      )
                                    }
                                  />
                                  {typeof errors.finance?.[index] !==
                                    'string' &&
                                    typeof errors.finance?.[index].link ===
                                      'string' &&
                                    errors.finance?.[index]?.link &&
                                    touched.finance?.[index]?.link && (
                                      <div style={{ color: 'red' }}>
                                        {errors.finance[index].link}
                                      </div>
                                    )}
                                </InfoRowStyled>
                                <RemoveButton
                                  type="button"
                                  onClick={() => remove(index)}
                                  style={{
                                    width: 'fit-content',
                                  }}
                                >
                                  Remove
                                </RemoveButton>
                              </div>
                            ))}
                            {typeof errors.finance === 'string' &&
                              errors.finance &&
                              touched.finance && (
                                <div style={{ color: 'red' }}>
                                  {errors.finance}
                                </div>
                              )}
                            <AddButton
                              type="button"
                              onClick={() =>
                                push({ title: '', subtitle: '', link: '' })
                              }
                            >
                              Add Finance
                            </AddButton>
                          </>
                        )}
                      </FieldArray>
                      <HeadingTitle title="Icon" />
                      <InfoRowStyled>
                        <UploadFile
                          title=""
                          name="icon"
                          onChange={(file) => setFieldValue('icon', file)}
                          variant="previewImage"
                          onRemove={() => {
                            setFieldValue('icon', null);
                          }}
                          apiUrls={
                            stepId && typeof values.icon === 'string'
                              ? [values.icon]
                              : []
                          }
                        />
                        {typeof errors.icon === 'string' &&
                          errors.icon &&
                          touched.icon && (
                            <div style={{ color: 'red' }}>{errors.icon}</div>
                          )}
                      </InfoRowStyled>
                    </NewDocumentWrapper>
                    <BottomContainerStyled>
                      <Button
                        radius="md"
                        variant="ghost"
                        style={{
                          border: `1px solid ${theme.colors.dark.neutral_250}`,
                          borderRadius: '10px',
                          color: theme.colors.text,
                          fontFamily: theme.fontFamily.roboto,
                          fontWeight: theme.fontWeights.medium,
                        }}
                        onClick={() => setIsModalOpen(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        isLoading={loading}
                        radius="normal"
                        style={{ backgroundColor: theme.colors.brandColorBlue }}
                      >
                        {stepId ? 'Update Building Step' : 'Save'}
                      </Button>
                    </BottomContainerStyled>
                  </>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minHeight: '400px',
                      width: '100%',
                    }}
                  >
                    <Loader
                      style={{
                        height: 60,
                        width: 60,
                      }}
                    />
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </ModalContentWrapper>
    </>
  );
};

export default CreateBuildingSteps;
