import { NavigateFunction } from 'react-router-dom';

import Button from '@/designComponents/Button';
import { TableDataType } from '@/designComponents/Table';
import {
  HOW_TO_VIDEOS_DETAILS_ROUTE,
  HOW_TO_VIDEOS_ROUTE,
} from '@/routes/routePaths';
import { toCapitalCase } from '@/utils/conversion';

import { CardKey } from '.';

export const HowToVideosTableData = (
  navigate: NavigateFunction
): TableDataType => {
  return {
    headings: [
      { key: 'title', label: 'Title' },
      { key: 'link', label: 'External Uid' },
      { key: 'duration', label: 'Duration' },
      { key: 'category', label: 'Category' },
      { key: 'desc', label: 'Description' },
      { key: 'actions', label: 'Actions' },
    ],
    body: [
      {
        id: `1`,
        link: 'fgjjdfkjg',
        duration: 5,
        category: 'Underfloor Heating',
        desc: 'This is test',
        actions: (
          <div
            style={{
              display: 'flex',
              gap: '4px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="gray"
              radius="normal"
              onClick={() => navigate('1')}
            >
              View
            </Button>
            <Button variant="danger" radius="normal">
              Delete
            </Button>
          </div>
        ),
      },
      {
        id: `1`,
        link: 'fgjjdfkjg',
        duration: 5,
        category: 'Underfloor Heating',
        desc: 'This is test',
        actions: (
          <div
            style={{
              display: 'flex',
              gap: '4px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="gray"
              radius="normal"
              onClick={() => navigate('1')}
            >
              View
            </Button>
            <Button variant="danger" radius="normal">
              Delete
            </Button>
          </div>
        ),
      },
      {
        id: `1`,
        link: 'fgjjdfkjg',
        duration: 5,
        category: 'Underfloor Heating',
        desc: 'This is test',
        actions: (
          <div
            style={{
              display: 'flex',
              gap: '4px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="gray"
              radius="normal"
              onClick={() => navigate('1')}
            >
              View
            </Button>
            <Button variant="danger" radius="normal">
              Delete
            </Button>
          </div>
        ),
      },
      {
        id: `1`,
        link: 'fgjjdfkjg',
        duration: 5,
        category: 'Underfloor Heating',
        desc: 'This is test',
        actions: (
          <div
            style={{
              display: 'flex',
              gap: '4px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="gray"
              radius="normal"
              onClick={() => navigate('1')}
            >
              View
            </Button>
            <Button variant="danger" radius="normal">
              Delete
            </Button>
          </div>
        ),
      },
      {
        id: `1`,
        link: 'fgjjdfkjg',
        duration: 5,
        category: 'Underfloor Heating',
        desc: 'This is test',
        actions: (
          <div
            style={{
              display: 'flex',
              gap: '4px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="gray"
              radius="normal"
              onClick={() => navigate('1')}
            >
              View
            </Button>
            <Button variant="danger" radius="normal">
              Delete
            </Button>
          </div>
        ),
      },
      {
        id: `1`,
        link: 'fgjjdfkjg',
        duration: 5,
        category: 'Underfloor Heating',
        desc: 'This is test',
        actions: (
          <div
            style={{
              display: 'flex',
              gap: '4px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="gray"
              radius="normal"
              onClick={() => navigate('1')}
            >
              View
            </Button>
            <Button variant="danger" radius="normal">
              Delete
            </Button>
          </div>
        ),
      },
      {
        id: `1`,
        link: 'fgjjdfkjg',
        duration: 5,
        category: 'Underfloor Heating',
        desc: 'This is test',
        actions: (
          <div
            style={{
              display: 'flex',
              gap: '4px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="gray"
              radius="normal"
              onClick={() => navigate('1')}
            >
              View
            </Button>
            <Button variant="danger" radius="normal">
              Delete
            </Button>
          </div>
        ),
      },
    ],
  };
};

export const cardData = ({
  allVideos,
  allCategories,
  allVideoSection,
}: {
  allVideos: number;
  allCategories: number;
  allVideoSection: number;
}): Array<{
  title: string;
  count: number;
  key: CardKey;
}> => [
  {
    title: 'All Videos',
    key: 'how-to-videos',
    count: allVideos,
  },
  {
    title: 'Video Category',
    key: 'how-to-category',
    count: allCategories,
  },
  {
    title: 'Video Content Section',
    key: 'how-to-category-class',
    count: allVideoSection,
  },
];

export const howToVideosBreadCrumbData = (
  label: string
): Array<{
  label: string;
  path: string;
}> => [
  {
    label: 'Videos',
    path: '/videos',
  },
  {
    label,
    path: HOW_TO_VIDEOS_ROUTE,
  },
];
export const howToVideosCatBreadCrumbData = (
  label: string
): Array<{
  label: string;
  path: string;
}> => [
  {
    label: 'Videos',
    path: '/videos',
  },
  {
    label: 'How To Videos Category',
    path: `/videos`,
  },
  {
    label: toCapitalCase(label),
    path: HOW_TO_VIDEOS_DETAILS_ROUTE,
  },
];
export const howToVideosCatClassBreadCrumbData = (
  label: string
): Array<{
  label: string;
  path: string;
}> => [
  {
    label: 'Videos',
    path: '/videos',
  },
  {
    label: 'Video Content Section',
    path: `/videos`,
  },
  {
    label: toCapitalCase(label),
    path: HOW_TO_VIDEOS_DETAILS_ROUTE,
  },
];
