import styled from 'styled-components';

export const ProfileContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  & .main-text {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & .text {
      display: grid;
      grid-template-columns: 90px 1fr;
      align-items: center;
      gap: 32px;
    }
    & .text-value {
      font-family: 'roboto';
      font-weight: normal;
      color: ${({ theme }) => theme.colors.dark.neutral_450};
      font-size: 14px;
    }
  }
`;
