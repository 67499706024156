import UserPNG from '@/assets/user-img.png';
import CrumbsWithButton from '@/components/UserDetails/CrumbsWithButton';
import HeadingTitle from '@/components/UserDetails/HeadingTitle';
import Subscription from '@/components/UserDetails/Subscription';
import UserProfileWIthImage from '@/components/UserDetails/UserProfileWIthImage';

import { analyticUserBreadCrumbData } from '../../data';
import { AccountDetailsWrapper, InfoRowStyled } from '../style';
import SubscribedUserInformation from './SubscribedUserInformation';

const SubscribedUser = () => {
  return (
    <AccountDetailsWrapper>
      <CrumbsWithButton
        breadCrumbData={analyticUserBreadCrumbData}
        deleteButtonText="Delete Subscription Plan"
        editButtonText="Edit Subscription Plan"
      />
      <div className="main-content">
        <InfoRowStyled>
          <HeadingTitle title="Active Subscription" />
          <Subscription price="34,637.57" />
        </InfoRowStyled>
        <InfoRowStyled>
          <HeadingTitle title="User Profile" />
          <UserProfileWIthImage
            email="darrell5steward98@gamil.com"
            imgUrl={UserPNG}
            name="Darrell Steward"
          />
        </InfoRowStyled>
        <InfoRowStyled>
          <HeadingTitle title="User Details" />
          <SubscribedUserInformation
            createdAt="15 May 2020 7:00 pm"
            fullName="Sharon Mc Donagh"
            updateAt="15 May 2020 10:00 pm"
            interval="Yearly"
            planName="Advanced Plan"
            resetAt="15 May 2020 9:00 pm"
            viewedDownloadResources={0}
            viewedDrawings={0}
            viewedGuidelines={0}
            viewedRegulations={0}
          />
        </InfoRowStyled>
      </div>
    </AccountDetailsWrapper>
  );
};

export default SubscribedUser;
