export const fontFamily = {
  helvetica: 'Helvetica',
  openSans: 'Open-Sans',
  poppins: 'Poppins',
  lato: 'Lato',
  roboto: 'Roboto',
  spartan: 'Spartan',
  jost: 'Jost',
} as const;

export type FontFamilyType = typeof fontFamily;
