import React from 'react';

import Typography from '@/designComponents/Typography';
import theme from '@/theme';

import { ProfessionalDetailsWrapper } from './style';

type Props = {};

const ProfessionalDetails = (_props: Props) => {
  return (
    <ProfessionalDetailsWrapper>
      <div className="main-content">
        <div className="heading">
          <Typography
            fontFamily="jost"
            fontWeight="medium"
            size="heading"
            color="dark.neutral_60"
          >
            Professional Details
          </Typography>
          <hr
            style={{
              backgroundColor: theme.colors.dark.neutral_50,
              border: 'none',
              height: '1px',
            }}
          />
        </div>
        <div className="main-text">
          <div className="text">
            <Typography className="text-value">ID</Typography>
            <Typography className="text-value">251</Typography>
          </div>
          <div className="text">
            <Typography className="text-value">Professional</Typography>
            <Typography className="text-value">Megan Ennis</Typography>
          </div>
          <div className="text">
            <Typography className="text-value">Enquiry Type</Typography>
            <Typography className="text-value">Presentational</Typography>
          </div>
          <div className="text">
            <Typography className="text-value">Professional Email</Typography>
            <Typography className="text-value">mennis0145@gmail.com</Typography>
          </div>
          <div className="text">
            <Typography className="text-value">User</Typography>
            <Typography className="text-value">4</Typography>
          </div>
          <div className="text">
            <Typography className="text-value">Name</Typography>
            <Typography className="text-value">
              Testing Enquiry Functionality
            </Typography>
          </div>
          <div className="text">
            <Typography className="text-value">Description</Typography>
            <Typography className="text-value">
              This should not allow the professional to view, and send them an
              email prompting to upgrade their account
            </Typography>
          </div>
          <div className="text">
            <Typography className="text-value">Email</Typography>
            <Typography className="text-value">megan@example.com</Typography>
          </div>
          <div className="text">
            <Typography className="text-value">Status</Typography>
            <Typography className="text-value">New Enquiry</Typography>
          </div>

          <div className="text">
            <Typography className="text-value">Created At</Typography>
            <Typography
              className="text-value"
              style={{ textDecoration: 'underline' }}
            >
              15 May 2020 7:00 pm
            </Typography>
          </div>
          <div className="text">
            <Typography className="text-value">Update At</Typography>
            <Typography
              className="text-value"
              style={{ textDecoration: 'underline' }}
            >
              15 May 2020 10:00 pm
            </Typography>
          </div>
        </div>
      </div>
    </ProfessionalDetailsWrapper>
  );
};

export default ProfessionalDetails;
