export function toKebabCase(str: string) {
  return str
    ?.replace(/([a-z])([A-Z])/g, '$1-$2') // camelCase or PascalCase to kebab-case
    ?.replace(/[\s_]+/g, '-') // spaces and underscores to hyphens
    ?.toLowerCase();
}

export function toCapitalCase(str: string) {
  return str
    ?.replace(/[-_]+/g, ' ') // Replace hyphens and underscores with spaces
    ?.replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camelCase words
    ?.toLowerCase() // Convert the whole string to lowercase
    ?.replace(/(^\w|\s\w)/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word
}
export function toSnakeUpperCase(str: string) {
  return str
    ?.replace(/([a-z])([A-Z])/g, '$1-$2') // camelCase or PascalCase to kebab-case
    ?.replace(/[\s-]+/g, '_') // spaces and hyphen to underscore
    ?.toUpperCase();
}
