import { useState } from 'react';

import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import DropdownButton from '@/designComponents/DropdownButton';
import Input from '@/designComponents/Input';

import Insurance from './Insurance';
import MortgageLoans from './MortgageLoans';
import Utility from './Utility';

const Forms = () => {
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const options = [
    {
      label: 'Insurance',
      modalContent: {
        title: 'Insurance',
        modalChildren: Insurance,
      },
    },
    {
      label: 'Mortgage Loans',
      modalContent: {
        title: 'Mortgage Loans',
        modalChildren: MortgageLoans,
      },
    },
    {
      label: 'Utility',
      modalContent: {
        title: 'Utility',
        modalChildren: Utility,
      },
    },
  ];

  return (
    <>
      <div>
        <DropdownButton
          options={options}
          title="Select Component"
          openModal={openModal}
          setOpenModal={setOpenModal}
          renderInModal={false}
          defaultOption={options[0]}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          header={
            <Input
              iconLeft={<SearchSVG />}
              placeholder="search forms"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
          }
        />
      </div>
    </>
  );
};

export default Forms;
