import React, { useRef } from 'react';

import {
  IconLeft,
  IconRight,
  InputContainerStyled,
  InputStyled,
} from './styles';

interface InputProps extends React.ComponentProps<'input'> {
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  error?: boolean;
  className?: string;
  [key: string]: any;
}

const Input: React.FC<InputProps> = ({
  iconLeft,
  iconRight,
  error,
  className = '',
  containerStyle = {},
  ...restProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleContainerClick = (_e: React.MouseEvent<HTMLDivElement>) => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <InputContainerStyled
      className={
        error
          ? `input-container error ${className}`
          : `input-container ${className}`
      }
      style={containerStyle}
      onClick={handleContainerClick}
    >
      {iconLeft && <IconLeft>{iconLeft}</IconLeft>}
      <InputStyled ref={inputRef} {...restProps} />
      {iconRight && <IconRight>{iconRight}</IconRight>}
    </InputContainerStyled>
  );
};

export default Input;
