import styled from 'styled-components';

export const ForgotPasswordWrapper = styled.div`
  max-width: 400px;
  margin: 120px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .banner-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .btns {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;
