import { FormikProps } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { ReactComponent as ChevronDownSVG } from '@/assets/chevron-down.svg';
import { ReactComponent as CloseSVG } from '@/assets/icons/close.svg';

import Input from '../Input';
import Typography from '../Typography';
import {
  Chip,
  OptionsContainerStyled,
  SelectWrapper,
  StyledDiv,
} from './style';

export type OptionType = { label: string; value: string };

interface SelectProps {
  options: OptionType[];
  onSelect?: (selectedValues: string[]) => void;
  formikProps?: FormikProps<any>;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  selectedOptions?: string[];
}

const MultiSelect: React.FC<SelectProps> = ({
  options,
  onSelect,
  placeholder,
  disabled,
  formikProps,
  selectedOptions,
  name,
}) => {
  const selectWrapperRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const [isActive, setIsActive] = useState(false);
  const isActiveRef = useRef(isActive);
  isActiveRef.current = isActive;

  // Memoize selected values to avoid unnecessary re-renders
  const selectedValues = useMemo(() => {
    return formikProps?.values[name] || selectedOptions || [];
  }, [formikProps?.values, name, selectedOptions]);

  // Memoize filtered options to avoid unnecessary re-renders
  const filteredOptions = useMemo(() => {
    return options.filter((option) => !selectedValues.includes(option.value));
  }, [options, selectedValues]);

  // Handle option click to update selected values
  const handleOptionClick = (option: OptionType) => {
    let newSelectedValues: string[] = [];

    if (selectedValues.includes(option.value)) {
      newSelectedValues = selectedValues.filter(
        (value: string) => value !== option.value
      );
    } else {
      newSelectedValues = [...selectedValues, option.value];
    }

    formikProps?.setFieldValue(name, newSelectedValues);
    onSelect?.(newSelectedValues);
  };

  // Get the label for a given option value
  const getOptionLabel = (value: string) => {
    const optionLabel = options.find((option) => option.value === value);
    return optionLabel ? optionLabel.label : value;
  };

  // Render a chip for a selected value
  const renderChip = (value: string) => (
    <Chip
      key={value}
      onClick={(e) => {
        e.stopPropagation();
        handleOptionClick({ label: getOptionLabel(value), value });
      }}
    >
      <Typography
        fontFamily="roboto"
        color="dark.neutral_450"
        fontWeight="medium"
        size="title"
      >
        {getOptionLabel(value)}
      </Typography>
      <CloseSVG style={{ marginLeft: '8px', cursor: 'pointer' }} />
    </Chip>
  );

  // Handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectWrapperRef.current &&
        !selectWrapperRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <StyledDiv
      style={{
        position: 'relative',
        width: '100%',
        opacity: disabled ? '0.5' : '1',
      }}
      ref={selectWrapperRef}
    >
      <SelectWrapper
        {...(!disabled && { onClick: () => setIsActive(!isActiveRef.current) })}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
          {selectedValues.map(renderChip)}
          {selectedValues.length === 0 && (
            <Typography
              style={{ padding: '0px 13px', color: theme.colors.disableText }}
            >
              {placeholder}
            </Typography>
          )}
        </div>
        <ChevronDownSVG
          style={{
            transform: isActive ? 'rotate(180deg)' : '',
            transition: 'all 0.3s ease',
            display: 'flex',
            flexShrink: 0,
          }}
        />
      </SelectWrapper>

      {!disabled && isActive && (
        <OptionsContainerStyled>
          <ul>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li
                  className="option"
                  key={option.value}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </li>
              ))
            ) : (
              <Typography style={{ textAlign: 'center' }}>
                No more data available
              </Typography>
            )}
          </ul>
        </OptionsContainerStyled>
      )}
    </StyledDiv>
  );
};

export default MultiSelect;
