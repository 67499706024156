import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import documents from '@/apiConfigs/documents';
import CountrySelection from '@/components/CountrySelection';
import Button from '@/designComponents/Button';
import Checkbox from '@/designComponents/Checkbox';
import Input from '@/designComponents/Input';
import Loader from '@/designComponents/Loader';
import Modal from '@/designComponents/Modal';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import UploadFile from '@/designComponents/UploadFile';
import { useAppDispatch } from '@/store';
import { getAllGroupDocument } from '@/store/documents/functions';
import requestAPI from '@/utils/requestAPI';
import { uploadFilesToS3OnlyKeys } from '@/utils/s3Upload';

import { generateSlug } from '../function';
import { WrapperStyled } from './style';
import { validationSchema } from './validationSchema';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  documentGroupId?: string;
  countryRegion?: string;
  parentId?: string;
  parentTitle?: string;
  type?: string;
  status?: string;
};

interface GroupDocumentValues {
  title: string;
  slug: string;
  // order: number;
  level: string;
  status: string;
  position: number;
  parentId: string[];
  region: string;
  image?: File | null | string;
}

const CreateDocumentGroup = ({
  isOpen,
  onClose,
  documentGroupId,
  countryRegion,
  parentId,
  parentTitle,
  type,
  status,
}: Props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [statusChange, setStatusChange] = useState(false);

  const [parentsList, setParentList] = useState([]);
  const [initialValues, setInitialValues] = useState<GroupDocumentValues>({
    title: '',
    slug: '',
    level: '',
    position: 1,
    status: status || '',
    parentId: [],
    region: countryRegion || 'ie',
    image: null,
  });

  useEffect(() => {
    const fetchDocumentGroup = async (id: string) => {
      setIsLoading(true);
      try {
        const groupData = await requestAPI(
          documents.getSingleDocumentGroup(id)
        );
        const groupParent = await requestAPI(
          documents.getSingleDocumentGroupParent(documentGroupId)
        );
        setParentList(groupParent?.parentList);
        setInitialValues({
          title: groupData?.title,
          slug: groupData?.slug,
          position: groupData?.position || 1,
          level: groupData?.level || '',
          image: groupData?.image || '',
          status: groupData?.status || '',
          parentId: groupParent?.parentIds || [],
          region: Array.isArray(groupData?.region)
            ? groupData?.region[0]
            : groupData.region,
        });
      } catch (error) {
        console.error('Error fetching document group:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (documentGroupId) {
      fetchDocumentGroup(documentGroupId);
      dispatch(getAllGroupDocument({ region: 'ie' }));
    } else {
      setIsLoading(false);
    }
  }, [isOpen, documentGroupId, dispatch]);

  const handleGroupDocumentSubmit = async (values: GroupDocumentValues) => {
    const level = values.parentId.length;
    const lastId = values.parentId.pop();
    setLoading(true);

    try {
      let iconUrl = '';
      if (values.image && typeof values.image !== 'string') {
        const uploadedFiles = await uploadFilesToS3OnlyKeys([values.image]);
        if (uploadedFiles.length > 0) {
          iconUrl = uploadedFiles[0];
        }
      }

      const payload = {
        title: values.title,
        slug: values.slug,
        position: values.position,
        region: [values.region],
        level: level.toString(),
        status: values.status,
        parentId: documentGroupId ? lastId : parentId || lastId,
        type,
        statusChange,
      };

      if (iconUrl) {
        payload.image = iconUrl;
      }

      if (documentGroupId) {
        await requestAPI(
          documents.updateDocumentGroup(documentGroupId, payload)
        );
        toast.success('Document group updated successfully');
      } else {
        await requestAPI(documents.addDocumentGroup(payload));
        toast.success('Document group created successfully');
      }

      setLoading(false);
      onClose();
    } catch (error: any) {
      setLoading(false);
      setApiError(error.message);
      toast.error(error.message);
    }
  };

  const fetchDocumentGroups = async (region: string, parentId?: string) => {
    const data = await requestAPI(
      documents.getAllDocumentGroup({
        parentId,
        region: region,
      })
    );

    return data;
  };

  const loadInitial = async () => {
    const data = await fetchDocumentGroups('ie');
    setParentList([data]);
  };

  useEffect(() => {
    loadInitial();
  }, []);

  console.log('status', status);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        documentGroupId ? 'Edit Document Group' : 'Create New Group Document'
      }
      halfWidth
    >
      <ModalContentWrapper>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '300px',
              width: '100%',
            }}
          >
            <Loader
              style={{
                height: 60,
                width: 60,
              }}
            />
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={handleGroupDocumentSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, touched }) => {
              const handleTitleChange = (
                e: React.ChangeEvent<HTMLInputElement>
              ) => {
                const title = e.target.value;
                setFieldValue('title', title);
                if (title) {
                  const generatedSlug = generateSlug(title);
                  setFieldValue('slug', generatedSlug);
                }
              };

              return (
                <Form>
                  <WrapperStyled>
                    <div className="container">
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <CountrySelection
                          selectedCountry={values.region}
                          setSelectedCountry={async (val) => {
                            setFieldValue('region', val);
                            const data = await fetchDocumentGroups('ie');
                            setParentList([data]);
                            setFieldValue('parentId', []);
                          }}
                        />
                      </div>
                      <Typography fontWeight="medium">
                        Group Document Title
                      </Typography>
                      <Input
                        name="title"
                        value={values.title}
                        onChange={handleTitleChange}
                      />
                      {errors.title && touched.title ? (
                        <div className="error">{errors.title}</div>
                      ) : null}
                    </div>
                    <Typography fontWeight="medium">
                      Position <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="position"
                      value={values.position}
                      onChange={(e) =>
                        setFieldValue('position', e.target.value)
                      }
                      type="number"
                      min={0}
                    />
                    {errors.position && touched.position && (
                      <div style={{ color: 'red' }}>{errors.position}</div>
                    )}
                    <Typography fontWeight="medium">Status</Typography>
                    <div style={{ display: 'flex', gap: '30px' }}>
                      <Select
                        name="status"
                        placeholder="Select Status"
                        options={[
                          { label: 'Active', value: 'ACTIVE' },
                          { label: 'Draft', value: 'DRAFT' },
                        ]}
                        selected={values.status}
                        onSelect={(value) => setFieldValue('status', value)}
                        style={{ maxWidth: '300px' }}
                      />

                      {documentGroupId && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                          }}
                        >
                          <Typography>Modify all children status</Typography>
                          <Checkbox
                            name="modifyChildren"
                            onChange={(e) => {
                              setStatusChange(e.target.checked);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="container">
                      <Typography fontWeight="medium">
                        Slug <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Input
                        name="slug"
                        value={values.slug}
                        onChange={(e) => setFieldValue('slug', e.target.value)}
                      />
                      {errors.slug && touched.slug ? (
                        <div className="error">{errors.slug}</div>
                      ) : null}
                      <div className="info-box">
                        <Typography>
                          This slug will be used to generate the page route for
                          SEO purposes. Please ensure it is readable and
                          descriptive to improve search engine visibility and
                          user experience.
                        </Typography>
                      </div>
                    </div>

                    <div className="container">
                      <Typography fontWeight="medium">Parent Group</Typography>
                      {parentTitle ? (
                        <Typography fontWeight="medium">
                          {parentTitle}
                        </Typography>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            flex: 1,
                            width: '100%',
                          }}
                        >
                          {parentsList?.map?.((parent, index) => (
                            <Select
                              key={parent || index}
                              name="parentId"
                              placeholder="Select Parent Group"
                              options={[
                                { label: '', value: '' },
                                ...(parentsList?.[index]?.map?.(
                                  (group: any) => ({
                                    label: group.title,
                                    value: group._id,
                                  })
                                ) || []),
                              ]}
                              style={{
                                width: '100px !important',
                                maxWidth: '200px !important',
                              }}
                              selected={values.parentId[index]}
                              onSelect={async (value) => {
                                if (!value) return;
                                const childData = await fetchDocumentGroups(
                                  values.region,
                                  String(value)
                                );
                                setParentList((prev) =>
                                  [
                                    ...prev.slice(0, index + 1),
                                    childData.length ? childData : undefined,
                                  ].filter(Boolean)
                                );
                                setFieldValue('parentId', [
                                  ...values.parentId.slice(0, index),
                                  value,
                                ]);
                              }}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="container">
                      <Typography fontWeight="medium">Upload Image</Typography>
                      <UploadFile
                        enableMultipleFile={false}
                        title=""
                        name="image"
                        onChange={(file) => setFieldValue('image', file)}
                        variant="previewImage"
                        apiUrls={
                          values.image && typeof values.image === 'string'
                            ? [values.image]
                            : []
                        }
                        onRemove={() => {
                          setFieldValue('image', null);
                        }}
                      />
                    </div>

                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button type="submit" isLoading={loading}>
                        {documentGroupId ? 'Update' : 'Create'}
                      </Button>
                    </div>
                  </WrapperStyled>
                  {apiError && <div style={{ color: 'red' }}>{apiError}</div>}
                </Form>
              );
            }}
          </Formik>
        )}
      </ModalContentWrapper>
    </Modal>
  );
};

export default CreateDocumentGroup;
