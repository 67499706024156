// store/documentSlice.ts
import { createSlice } from '@reduxjs/toolkit';

import { addSupplier, getAllSuppliers, getSingleSupplier } from './functions';

const initialState: any = {
  suppliers: [],
  singleSupplier: {},
  pagination: {
    total: 0,
    limit: 10,
    currentPage: 1,
    totalPages: 1,
  },
  error: null,
  loading: false,
};

const supplierSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    clearDownloadCenterError: (state) => {
      return {
        ...state,
        error: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || 'Failed to add supplier. Try again later.';
      })
      .addCase(addSupplier.fulfilled, (state, action) => {
        return {
          loading: false,
          error: null,
          suppliers: action.payload,
        };
      });
    builder
      .addCase(getAllSuppliers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllSuppliers.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ||
          'Failed to add group document. Try again later.';
      })
      .addCase(getAllSuppliers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.suppliers = action.payload.data;
        state.pagination = action.payload.pagination;
      });
    builder
      .addCase(getSingleSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ||
          'Failed to add group document. Try again later.';
      })
      .addCase(getSingleSupplier.fulfilled, (state, action) => {
        return {
          loading: false,
          error: null,
          singleSupplier: action.payload,
        };
      });
  },
});

export const supplierReducers = supplierSlice.reducer;
