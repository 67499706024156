/* eslint-disable */

import {
  DecoratorNode,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  Spread,
} from 'lexical';
import React, { useEffect, useState } from 'react';
import Button from '../ui/Button';
import requestAPI from '@/utils/requestAPI';
import products from '@/apiConfigs/products';
import { SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';

type Product = {
  _id: string;
  title: string;
  price: number;
  fullDescription: string | null;
  externalUrl: string;
  photos: { url: string }[];
  type: string;
};

type ProductDetails = {
  keyword: string;
  productId: string;
};

export type SerializedProductNode = Spread<
  {
    productId: string;
    keyword: string;
  },
  SerializedDecoratorBlockNode
>;

// ProductNode class
class ProductNode extends DecoratorNode<JSX.Element> {
  __productDetails: ProductDetails;

  static getType(): string {
    return 'product';
  }

  static clone(node: ProductNode): ProductNode {
    return new ProductNode(node.__productDetails, node.__key);
  }

  constructor(productDetails: ProductDetails, key?: string) {
    super(key);
    this.__productDetails = productDetails;
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('span');
    dom.className = 'hbe__product';
    dom.setAttribute('data-product-id', this.__productDetails.productId);
    return dom;
  }

  updateDOM(prev: ProductNode, el): boolean {
    // Update the DOM if the product details have changed
    if (this.__productDetails.productId !== prev.__productDetails.productId) {
      el?.setAttribute('data-product-id', this.__productDetails.productId);
      return true;
    }
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (node: Node) => ({
        conversion: $convertProductElement,
        priority: 4,
      }),
    };
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-product-id', this.__productDetails.productId);
    element.textContent = this.__productDetails.keyword;
    console.log('exporting');
    return { element };
  }

  static importJSON(serializedNode: SerializedProductNode): ProductNode {
    return $createProductNode({
      keyword: serializedNode.keyword,
      productId: serializedNode.productId,
    });
  }

  exportJSON(): SerializedProductNode {
    return {
      ...super.exportJSON(),
      productId: this.__productDetails.productId,
      keyword: this.__productDetails.keyword,
      type: 'product',
      version: 1,
    };
  }

  getTextContent(): string {
    return this.__productDetails.keyword;
  }

  decorate(): JSX.Element {
    return <ProductComponent productDetails={this.__productDetails} />;
  }
}

// ProductComponent
const ProductComponent: React.FC<{ productDetails: ProductDetails }> = ({
  productDetails,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [productInfo, setProductInfo] = useState<Product | null>(null);

  useEffect(() => {
    const fetchProductInfo = async () => {
      try {
        const product = await requestAPI(
          products.getSingleProduct(productDetails.productId)
        );
        setProductInfo(product || null);
      } catch (error) {
        console.error('Failed to fetch product info', error);
        setProductInfo(null);
      }
    };

    fetchProductInfo();
  }, [productDetails.productId]);
  console.log('product info', productInfo);

  return (
    <span
      className="product-component"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {productDetails.keyword}
      {isHovered && productInfo && (
        <div
          className="product-tooltip"
          style={{
            maxWidth: '400px',
            padding: '10px',
            backgroundColor: 'white',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            zIndex: 9999,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              padding: '10px',
            }}
          >
            <div>
              {productInfo.photos && productInfo.photos.length > 0 && (
                <img
                  src={productInfo.photos[0].url}
                  alt={productInfo.title}
                  height="150px"
                  width="150px"
                />
              )}
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            >
              <p>Type: {productInfo.type}</p>
              <p>Name: {productInfo.title}</p>
              <p>€{productInfo.price}</p>
              <p>
                Description:{' '}
                {productInfo.fullDescription || 'No description available'}
              </p>
              <Button
                title="More Information"
                onClick={() => window.open(productInfo.externalUrl, '_blank')}
              >
                More Information
              </Button>
            </div>
          </div>
        </div>
      )}
    </span>
  );
};

// Helper functions
export function $createProductNode(
  productDetails: ProductDetails
): ProductNode {
  return new ProductNode(productDetails);
}

export function $isProductNode(node: unknown): node is ProductNode {
  return node instanceof ProductNode;
}

function $convertProductElement(domNode: Element): DOMConversionOutput {
  let node = null;
  const productId = domNode.getAttribute('data-product-id');

  if (productId) {
    const content = domNode.textContent?.trim();
    if ((content !== null && content !== '') || domNode.children.length > 0) {
      node = $createProductNode({
        productId,
        keyword: content || '',
      });
    }
  }

  return { node };
}

export default ProductNode;
