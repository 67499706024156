import React, { useState } from 'react';

import DropdownButton from '@/designComponents/DropdownButton';

import CreateAds from '../CreateAds';
import CreateAdvertiser from '../CreateAdvertiser';

type Props = {};

const AdvertisementModal = (_props: Props) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <React.Fragment>
      <DropdownButton
        options={[
          {
            label: 'Create Ads',
            modalContent: {
              title: 'Create Ads',
              modalChildren: CreateAds,
            },
          },
          {
            label: 'Create Advertiser',
            modalContent: {
              title: 'Create Advertiser',
              modalChildren: CreateAdvertiser,
            },
          },
        ]}
        title="New Ads"
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </React.Fragment>
  );
};

export default AdvertisementModal;
