import DataTable from '@/components/UserDetails/DataTable';
import { humanReadableDate } from '@/utils/date';
import { getRespectiveRegion } from '@/utils/function.utils';

type Props = {
  id: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  createdAt?: string;
  updateAt: string;
  region?: string;
};

const ProfessionalUserInformation = ({
  createdAt,
  email,
  fullName,
  id,
  updateAt,
  firstName,
  lastName,
  region,
}: Props) => {
  return (
    <DataTable
      data={{
        ID: id,
        ['Full Name']: fullName,
        ['First Name']: firstName,
        ['Last Name']: lastName,
        Email: email,
        Region: getRespectiveRegion(region),
        ['Created At']: humanReadableDate(createdAt),
        ['Update At']: humanReadableDate(updateAt),
      }}
    />
  );
};

export default ProfessionalUserInformation;
