import styled from 'styled-components';

export const HeadingStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const ModalContainerStyled = styled.div`
  & .modal-body {
    min-height: auto;
    height: min-content;
    padding: 30px 50px;
  }
`;

export const BottomContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 30px;
`;
