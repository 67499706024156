import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaginationType } from '@/apiConfigs/documents';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import Button from '@/designComponents/Button';
import { regionMapping } from '@/pages/Documents';
import { debounce } from '@/pages/DownloadCenter';
import { useAppDispatch, useAppSelector } from '@/store';
import { getAllBuildngSteps } from '@/store/buildingJourney/functions';
import { getAllDownloadGroup } from '@/store/downloadCenter/functions';
import { humanReadableDateWithTime } from '@/utils/date';

// const downloadTypeOptions = [
//   { value: 'DOCUMENT', label: 'Document' },
//   { value: 'diagram', label: 'Diagram' },
//   { value: 'table', label: 'Table' },
// ];
const BuildingStep = ({ buildingJourneyTabs }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { buildingSteps, loading } = useAppSelector(
    (state) => state.buildingJourney
  );
  const [paginationParams, setPaginationParams] = useState<PaginationType>({
    page: 1,
    limit: 10,
  });

  const fetchBuildingSteps = useCallback(
    debounce(() => {
      dispatch(
        getAllBuildngSteps({
          limit: paginationParams.limit,
          page: paginationParams.page,
        })
      );
    }, 300),
    [paginationParams]
  );

  useEffect(() => {
    fetchBuildingSteps();
    return () => {
      fetchBuildingSteps.cancel();
    };
  }, [paginationParams]);

  useEffect(() => {
    dispatch(getAllDownloadGroup(paginationParams));
  }, [dispatch, paginationParams]);

  const handleCurrentPage = (page: number) => {
    setPaginationParams({
      ...paginationParams,
      page,
    });
  };

  const handleLimitChange = (count: number) => {
    setPaginationParams({
      ...paginationParams,
      limit: count,
    });
  };
  const tableData = {
    headings: [
      { key: 'name', label: 'Name' },
      { key: 'region', label: 'Region' },
      { key: 'date', label: 'Created At' },
      { key: 'updatedDate', label: 'Updated At' },
      { key: 'position', label: 'Position' },
      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(buildingSteps?.data)
      ? buildingSteps?.data?.map((category) => {
          return {
            id: `${category._id}`,
            name: category.name,
            region: category.region
              .map((region) => regionMapping[region.name])
              .join(', '),
            date: humanReadableDateWithTime(category.createdAt),
            updatedDate: humanReadableDateWithTime(category.updatedAt),
            position: category?.position,
            actions: (
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="gray"
                  radius="normal"
                  onClick={() => navigate(category._id)}
                >
                  View
                </Button>
              </div>
            ),
          };
        })
      : [],
  };

  return (
    <div>
      <Table
        customRow={buildingJourneyTabs}
        tableData={tableData}
        isLoading={loading}
        noData="No data to display."
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
      />
      <Pagination
        activePage={paginationParams.page || 1}
        onCurrentPageChange={handleCurrentPage}
        totalPages={buildingSteps?.pagination.totalPages || 1}
        limit={paginationParams.limit || 10}
        onLimitChange={handleLimitChange}
      />
    </div>
  );
};

export default BuildingStep;
