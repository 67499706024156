import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import JobPosts from '@/apiConfigs/jobs';
import HeadingTitle from '@/components/UserDetails/HeadingTitle';
import Tag from '@/components/UserDetails/Tag';
import Button from '@/designComponents/Button';
import Typography from '@/designComponents/Typography';
import theme from '@/theme';
import { humanReadableDate } from '@/utils/date';
import requestAPI from '@/utils/requestAPI';

import {
  DetailsPageWrapper,
  InformationRowWrapper,
  InfoRowStyled,
} from './style';

type Props = {};
type SingleJobType = {
  isApproved: boolean;
  city: string;
  status: string;
  createdAt: string;
  description: {
    content: string;
    contentType: string;
  };
};

const JobsDetails = (_props: Props) => {
  const { jobId } = useParams();
  const [singleJob, setSingleJob] = useState<SingleJobType>();

  const getSingleJob = async (id: string) => {
    const data = await requestAPI(JobPosts.getSingleJob(id));
    setSingleJob(data);
  };

  useEffect(() => {
    if (jobId) {
      getSingleJob(jobId);
    }
  }, [jobId]);

  const handleApprove = async () => {
    if (jobId) {
      try {
        await requestAPI(JobPosts.approveJob(jobId));
        toast.success('Job approved successfully');
        getSingleJob(jobId);
      } catch (error: any) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };
  return (
    <DetailsPageWrapper>
      <div className="main-content">
        <InfoRowStyled>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {' '}
            <HeadingTitle title="Job Details" />
            <div className="btn">
              <div>
                {singleJob?.isApproved === false && (
                  <Button
                    variant="primary"
                    radius="md"
                    style={{
                      backgroundColor: theme.colors.colorActive,
                    }}
                    onClick={handleApprove}
                  >
                    Approve
                  </Button>
                )}
              </div>
            </div>
          </div>

          <InformationRowWrapper>
            <>
              <div className="text">
                <Typography className="value">City</Typography>
                <Typography className="value">{singleJob?.city}</Typography>
              </div>
              <div className="text">
                <Typography className="value">Description</Typography>
                <Typography className="value">
                  {singleJob?.description?.content}
                </Typography>
              </div>

              <div className="text">
                <Typography className="value">Status</Typography>
                <div
                  style={{
                    width: 'fit-content',
                  }}
                >
                  <Tag
                    title={
                      singleJob?.isApproved === true
                        ? 'Approved'
                        : 'Not Approved'
                    }
                    variant={
                      singleJob?.isApproved === true ? 'active' : 'danger'
                    }
                    hidePoint
                  />
                </div>
              </div>

              <div className="text">
                <Typography className="value">Created At</Typography>
                <Typography className="value">
                  {humanReadableDate(singleJob?.createdAt)}
                </Typography>
              </div>
            </>
          </InformationRowWrapper>
        </InfoRowStyled>
      </div>
    </DetailsPageWrapper>
  );
};

export default JobsDetails;
