import { useParams } from 'react-router-dom';

import CountrySelection from '@/components/CountrySelection';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import Typography from '@/designComponents/Typography';

import Modal from '../../ui/Modal';
import { OPEN_CUSTOM_LINK_MODAL_COMMAND } from '../command';

type Props = {
  initialTag: string;
  onClose: () => void;
  editor: any;
  isOpen: boolean;
};

const TagPicker = ({ editor, onClose, initialTag, isOpen }: Props) => {
  const handleSelectTag = (tag) => {
    editor.dispatchCommand(OPEN_CUSTOM_LINK_MODAL_COMMAND, {
      id: tag.id,
      label: tag.label,
      text: `#${tag.label}`,
    });
    onClose();
  };

  console.log(initialTag, 'selected tyag');

  console.log(handleSelectTag);
  const { tagId } = useParams();

  if (!isOpen) {
    return null;
  }
  return (
    <Modal
      //   isOpen={isOpenModal}
      onClose={onClose}
      title={tagId ? 'Update Tag' : 'Add Tag'}
      //   halfWidth
    >
      <ModalContentWrapper>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CountrySelection
            selectedCountry={''}
            setSelectedCountry={() => {}}
          />
        </div>

        <div style={{ marginBottom: '20px' }}>
          <Typography fontWeight="medium" style={{ marginBottom: '10px' }}>
            Title
          </Typography>
          <Input title="title" value={initialTag} />

          <div style={{ marginTop: '20px' }}>
            <Typography fontWeight="medium" style={{ marginBottom: '5px' }}>
              Products*
            </Typography>
          </div>

          <div
            style={{
              marginBottom: '20px',
              display: 'flex',
              gap: 10,
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          ></div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
              gap: '20px',
            }}
          >
            <Button
              variant="gray"
              radius="normal"
              type="button"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" radius="normal">
              {tagId ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </ModalContentWrapper>
    </Modal>
  );
};
export default TagPicker;
