import * as Yup from 'yup';

export const ValidationSchema = (selectedType: string) => {
  const baseSchema = {
    title: Yup.string().required('Title is required'),
    type: Yup.string().required('Type is required'),
    hashTags: Yup.array().min(1, 'At least one hashtag is required'),
    position: Yup.number()
      .required('Position is required')
      .min(1, 'Position must be greater than 0'),
  };

  switch (selectedType) {
    case 'icon':
      return Yup.object().shape({
        ...baseSchema,
        icon: Yup.mixed().required('Image is required').nullable(),
      });
    case 'pdf':
      return Yup.object().shape({
        ...baseSchema,
        pdf: Yup.mixed().required('PDF is required').nullable(),
      });
    case 'text':
      return Yup.object().shape({
        ...baseSchema,
        editorHtml: Yup.string().required('Text is required'),
      });
    default:
      return Yup.object().shape(baseSchema);
  }
};
