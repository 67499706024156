import { createSlice } from '@reduxjs/toolkit';

import {
  addHowToVideosCategory,
  getAllHowToVideosCategory,
  getSingleHowToVideosCategory,
} from './functions';

export type Videos = {
  data: {
    _id: string;
    name: string;
    category: string[];
    icon: string;
    position: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  }[];
  pagination: {
    status: boolean;
    total: number;
    totalPages: number;
    currentPage: number;
    limit: string;
  };
};

export type SingleVideo = {
  _id: string;
  name: string;
  contentSection: string[];
  icon: string;
  position: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
type InitialState = {
  videos: Videos | null;
  singleVideo: SingleVideo | null;
  error: string | null;
  loading: boolean;
  pagination: {
    total: number;
    limit: number;
    currentPage: number;
    totalPages: number;
  };
};

const initialState: InitialState = {
  videos: null,
  singleVideo: null,
  error: null,
  loading: false,
  pagination: {
    total: 0,
    limit: 11,
    currentPage: 0,
    totalPages: 1,
  },
};

const howToVideoCategorySlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addHowToVideosCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      addHowToVideosCategory.fulfilled,
      (state: typeof initialState, action) => {
        state.loading = false;
        state.error = null;
        state.videos = action.payload;
      }
    );
    builder.addCase(addHowToVideosCategory.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to add How To Videos. Try again later.';
    });

    builder.addCase(getAllHowToVideosCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllHowToVideosCategory.fulfilled,
      (state: typeof initialState, action) => {
        state.loading = false;
        state.error = null;
        state.videos = action.payload;
      }
    );
    builder.addCase(getAllHowToVideosCategory.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to add How To Videos. Try again later.';
    });
    builder.addCase(getSingleHowToVideosCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getSingleHowToVideosCategory.fulfilled,
      (state: typeof initialState, action) => {
        state.loading = false;
        state.error = null;
        state.singleVideo = action.payload;
      }
    );
    builder.addCase(getSingleHowToVideosCategory.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to add How To Videos. Try again later.';
    });
  },
});

export const howToVideoCategoryReducer = howToVideoCategorySlice.reducer;
