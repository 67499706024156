import { createAsyncThunk } from '@reduxjs/toolkit';

import { PaginationType } from '@/apiConfigs/documents';
import users from '@/apiConfigs/users';
import requestAPI from '@/utils/requestAPI';

export type SingleUserData = {
  _id: string;
  firstName: string;
  lastName: string;
  city: string;
  email: string;
  accountType: string;
  subscription: {
    pausedPlan: {};
    upcomingPlan: {};
    id: string;
    name: string;
    interval: string;
    price: number;
    currency: string;
    status: string;
    expiresAt: string;
    statsResetAt: string;
  };
  professional: {};
  business: {
    banner: {
      url: string;
    };
    name: string;
    website: string;
    desc: string;
    fullDesc: string;
    address: {
      city: string;
      country: string;
      postalCode: string;
      streetAddress1: string;
      streetAddress2: string;
    };
    tradeType: string;
  };
};

export const getAllUsers = createAsyncThunk(
  'users/getAllUsers',
  async (params: PaginationType) => {
    const response = await requestAPI(users.getUsers(params));

    return response;
  }
);

export const getSingleUsers = createAsyncThunk(
  'users/getSingleUsers',
  async (userId: string) => {
    const response = await requestAPI(users.getUserDetails(userId));

    return response as SingleUserData;
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (userId: string) => {
    const response = await requestAPI(users.deleteUser(userId));
    return response;
  }
);

export const getAllUsersWithAnalytics = createAsyncThunk(
  'users/getAllUsersWithAnalytics',
  async (params: PaginationType) => {
    const response = await requestAPI(users.getUsersWithAnalytics(params));
    return response;
  }
);
