/* eslint-disable import/no-extraneous-dependencies */
import { arrayMoveImmutable } from 'array-move';
import { useEffect, useState } from 'react';
import SortableList, { SortableItem } from 'react-easy-sort';

import Button from '@/designComponents/Button';
import Typography from '@/designComponents/Typography';

import { Box, Wrapper } from './style';

type Props = {};

const DragAndDrop = (_props: Props) => {
  const [items, setItems] = useState([
    'Retrofitting',
    'Renewable Details',
    'Roof level',
    'Guidelines',
    'Passive House Details',
    'Substructure',
    'Superstructure',
    'Building System',
    'Download Center',
  ]);

  useEffect(() => {
    console.log(items);
  }, [items]);
  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setItems((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };
  return (
    <Wrapper>
      <Typography fontWeight="medium" size="h2">
        Drag and drop the items to their corresponding positions
      </Typography>
      <SortableList
        onSortEnd={onSortEnd}
        className="list"
        draggedItemClassName="dragged"
      >
        {items.map((item) => (
          <SortableItem key={item}>
            <Box className="item">{item}</Box>
          </SortableItem>
        ))}
      </SortableList>
      <Button size="lg">Update</Button>
    </Wrapper>
  );
};

export default DragAndDrop;
