import styled from 'styled-components';

export const TableSearchWrapper = styled.div<{ $isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .input-with-badge {
    position: relative;
    min-width: 400px;
    display: flex;
    flex-direction: column;

    & .search-result {
      position: absolute;
      z-index: 99999;
      top: 50px;
      width: 100%;
      height: 221px;
      background-color: white;
      border-radius: 10px;
      border: 1px solid ${({ theme }) => theme.colors.dark.neutral_20};
      overflow-y: auto;
      padding: 10px;
      display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
      flex-direction: column;
      gap: 20px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: ${({ theme }) => theme.colors.dark.neutral_20};
      }
      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.dark.neutral_10};
        border-radius: 5px;
        width: 5px;
        height: 24px;
      }

      & .input-badge {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
      }

      & .search-with-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
  }
`;

export const BadgeWrapper = styled.div<{ $isClicked: boolean }>`
  min-width: 78px;
  border-radius: 20px;
  min-height: 30px;
  padding: 7px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, $isClicked }) =>
    $isClicked ? theme.colors.blue_e8 : theme.colors.dark.neutral_10};
  cursor: pointer;
`;

export const SearchResultWrapper = styled.div`
  display: flex;
  gap: 13px;

  .text {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & .mark-text {
      color: ${({ theme }) => theme.colors.primary};
      background-color: transparent;
    }
  }
`;
