import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PaginationType } from '@/apiConfigs/documents';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import Button from '@/designComponents/Button';
import { RootState, useAppDispatch } from '@/store';
import { getAllDownloadGroup } from '@/store/downloadCenter/functions';
import { humanReadableDateWithTime } from '@/utils/date';
import { debounce } from '@/utils/debounce';

// const downloadTypeOptions = [
//   { value: 'DOCUMENT', label: 'Document' },
//   { value: 'diagram', label: 'Diagram' },
//   { value: 'table', label: 'Table' },
// ];
const DownloadGroup = ({ downloadCenterTabs, search, refreshTrigger }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [paginationParams, setPaginationParams] = useState<PaginationType>({
    page: 1,
    limit: 10,
    search: '',
  });

  const downloadGroups = useSelector(
    (state: RootState) => state.downloadCenterCategory.downloadCenterGroup.data
  );
  const pagination = useSelector(
    (state: RootState) =>
      state.downloadCenterCategory.downloadCenterGroup.pagination
  );
  const { loading } = useSelector(
    (state: RootState) => state.downloadCenterCategory
  );

  const fetchDownloadFolders = useCallback(() => {
    dispatch(getAllDownloadGroup(paginationParams));
  }, [dispatch, paginationParams]);

  useEffect(() => {
    fetchDownloadFolders();
  }, [fetchDownloadFolders, refreshTrigger]);

  const handleCurrentPage = (page: number) => {
    setPaginationParams((prev) => ({ ...prev, page: page }));
  };

  const handleLimitChange = (limit: number) => {
    setPaginationParams((prev) => ({ ...prev, page: 1, limit }));
  };

  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      setPaginationParams((prev) => ({
        ...prev,
        search: searchTerm,
        page: 1,
      }));
    }, 300),
    []
  );

  useEffect(() => {
    if (search) {
      debouncedSearch(search);
    }
  }, [search]);
  const tableData = {
    headings: [
      { key: 'title', label: 'Title' },
      { key: 'date', label: 'Created At' },
      { key: 'updatedDate', label: 'Updated At' },
      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(downloadGroups)
      ? downloadGroups?.map((category) => {
          return {
            id: category._id,
            title: category.title,
            date: humanReadableDateWithTime(category.createdAt),
            updatedDate: humanReadableDateWithTime(category.updatedAt),
            actions: (
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="gray"
                  radius="normal"
                  onClick={() => navigate(category._id)}
                >
                  View
                </Button>
                {/* <Button variant="danger" radius="normal" onClick={() => {}}>
                  Delete
                </Button> */}
              </div>
            ),
          };
        })
      : [],
  };

  return (
    <div>
      <Table
        tableData={tableData}
        customRow={downloadCenterTabs}
        isLoading={loading}
        noData="No data to display."
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
      />
      <Pagination
        activePage={paginationParams.page || 1}
        onCurrentPageChange={handleCurrentPage}
        totalPages={pagination?.totalPages || 1}
        limit={paginationParams.limit || 10}
        onLimitChange={handleLimitChange}
      />
    </div>
  );
};

export default DownloadGroup;
