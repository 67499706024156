export type InitialValuesType = {
  documentTitle: string;
  documentType: string;
  parentTitle: string;
  icon: File[] | null;
  type: string;
  jurisdictions: string[];
};

export const breadCrumbData: Array<{ label: string; path: string }> = [
  {
    label: 'Go Green',
    path: '/',
  },
  {
    label: 'All Category',
    path: '/',
  },
  {
    label: 'Category Details',
    path: '/',
  },
];
export const dropDownOption: Array<{
  value: string;
  label: string;
}> = [
  {
    label: 'Go Green',
    value: 'go-green',
  },
  {
    label: 'Building Guides',
    value: 'building-guide',
  },
  {
    label: 'Building System',
    value: 'building-system',
  },
];

export const data = () => [
  {
    id: '125',
    user: 'Joseph Houlihan',
    projectTitle: 'Cappagh Lodge - Renovation',
    spreadsheet: '	Renovations',
    date: '15 may 2020 11:00 pm',
  },
  {
    id: '127',
    user: 'Don Mc Namara',
    projectTitle: '3 bed-semi',
    spreadsheet: 'House and Garage',
    date: '15 may 2020 11:00 pm',
  },
  {
    id: '117',
    user: 'John Joe OFarrell',
    projectTitle: '	JJOF 1.0',
    spreadsheet: '	Renovations',
    date: '15 may 2020 11:00 pm',
  },
  {
    id: '47',
    user: 'Colum Smith',
    projectTitle: 'House',
    spreadsheet: '	Renovations',
    date: '15 may 2020 11:00 pm',
  },
  {
    id: '46',
    user: 'Ciara Browne',
    projectTitle: 'Template',
    spreadsheet: '	House and Garage',
    date: '15 may 2020 11:00 pm',
  },
  {
    id: '105',
    user: 'ciara doherty',
    projectTitle: 'Pretender Cost estimate',
    spreadsheet: '	Renovations',
    date: '15 may 2020 11:00 pm',
  },
  {
    id: '115',
    user: 'Cliodhna Ni Chathain',
    projectTitle: 'Dan Matty’s',
    spreadsheet: '	Renovations',
    date: '15 may 2020 11:00 pm',
  },
  {
    id: '195',
    user: '	Peter Wickham',
    projectTitle: 'Untitled',
    spreadsheet: '	Renovations',
    date: '15 may 2020 11:00 pm',
  },
  {
    id: '125',
    user: 'Joseph Houlihan',
    projectTitle: 'Cappagh Lodge - Renovation',
    spreadsheet: '	Renovations',
    date: '15 may 2020 11:00 pm',
  },
  {
    id: '125',
    user: 'Joseph Houlihan',
    projectTitle: 'Cappagh Lodge - Renovation',
    spreadsheet: '	Renovations',
    date: '15 may 2020 11:00 pm',
  },
  {
    id: '125',
    user: 'Joseph Houlihan',
    projectTitle: 'Cappagh Lodge - Renovation',
    spreadsheet: '	Renovations',
    date: '15 may 2020 11:00 pm',
  },
];
