import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import HeadingTitle from '@/components/UserDetails/HeadingTitle';
import Tag from '@/components/UserDetails/Tag';
import { useRoot } from '@/context/RootProvider';
import Typography from '@/designComponents/Typography';
import { useAppDispatch, useAppSelector } from '@/store';
import { getSingleProductDetail } from '@/store/products/functions';
import { humanReadableDateWithTime } from '@/utils/date';

import CrumbsWithButton from './CrumbsWithButton';
import {
  ImageWrapperStyled,
  InfoRowStyled,
  ProductDetail,
  ProductDetailsWrapperStyled,
} from './style';

type Props = {};

const ProductView = (_props: Props) => {
  const { productId } = useParams();
  const { region } = useRoot();
  const singleProduct = useAppSelector((state) => state.products.singleProduct);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (productId) {
      dispatch(getSingleProductDetail(productId));
    }
  }, [productId, dispatch, region]);

  return (
    <ProductDetailsWrapperStyled>
      <CrumbsWithButton
        productTitle={singleProduct?.title}
        productId={productId}
      />
      <HeadingTitle title="Product Photos Details" />
      <ImageWrapperStyled>
        <Typography
          fontFamily="roboto"
          color="dark.neutral_450"
          fontWeight="medium"
        >
          Image Preview
        </Typography>
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '10px',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          {singleProduct?.photos.map((photo, idx) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '2px solid gray',
                borderRadius: '20px',
                width: '120px',
                padding: '5px',
              }}
              key={idx}
            >
              <img src={photo} alt="Product Image" height={100} width={100} />
            </div>
          ))}
        </div>
        <InfoRowStyled>
          <Typography className="label">ID</Typography>
          <Typography className="value">{singleProduct?._id}</Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">Image URL</Typography>
          <Typography className="value">
            {singleProduct?.photos?.[0]}
          </Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">Created At</Typography>
          <Typography className="value">
            {humanReadableDateWithTime(singleProduct?.createdAt)}
          </Typography>
        </InfoRowStyled>
      </ImageWrapperStyled>
      <HeadingTitle title="Product Details" />
      <ProductDetail>
        <InfoRowStyled>
          <Typography className="label">ID</Typography>
          <Typography className="value">{singleProduct?._id}</Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">Shown on Showroom Page</Typography>
          <Tag
            variant={!singleProduct?.archived ? 'completed' : 'inComplete'}
            title={singleProduct?.archived ? 'No' : 'Yes'}
            hidePoint
          />
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">Featured</Typography>
          <Tag
            variant={singleProduct?.featured ? 'completed' : 'inComplete'}
            title={singleProduct?.featured ? 'Yes' : 'No'}
            hidePoint
          />
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">Go Green</Typography>
          <Tag
            variant={singleProduct?.goGreen ? 'completed' : 'inComplete'}
            title={singleProduct?.goGreen ? 'Yes' : 'No'}
            hidePoint
          />
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">External UID</Typography>
          <Typography className="value">{singleProduct?.externalId}</Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">Price</Typography>
          <Typography className="value">€ {singleProduct?.price}</Typography>
        </InfoRowStyled>
        {/* <InfoRowStyled>
          <Typography className="label">Jurisdictions</Typography>
          <Typography className="value">Empty</Typography>
        </InfoRowStyled> */}

        <InfoRowStyled>
          <Typography className="label">Product Parent Category</Typography>
          <Typography className="value">Bathroom</Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">Product Subcategory</Typography>
          <Typography className="value">Sinks</Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">Product Subcategory</Typography>
          <Tag variant="completed" title="Yes" hidePoint />
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">External URL</Typography>
          <Typography className="value">https://www.dagegar.co</Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">Description</Typography>
          <Typography className="value">
            <div
              dangerouslySetInnerHTML={{
                __html: singleProduct?.description,
              }}
            />
          </Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">Supplier</Typography>
          <Typography className="value">Roadstone</Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">Published At</Typography>
          <Typography className="value">
            {!singleProduct?.publishedAt ? 'Empty' : singleProduct?.publishedAt}
          </Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">Created At</Typography>
          <Typography className="value">
            {humanReadableDateWithTime(singleProduct?.createdAt)}
          </Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="label">Updated At</Typography>
          <Typography className="value">
            {humanReadableDateWithTime(singleProduct?.updatedAt)}pm
          </Typography>
        </InfoRowStyled>
        {/* <InfoRowStyled>
          <Typography className="label">Stock Location IDs</Typography>
          <Typography className="value">Empty</Typography>
        </InfoRowStyled> */}
      </ProductDetail>
    </ProductDetailsWrapperStyled>
  );
};

export default ProductView;
