import { ConfigType } from '@/utils/requestAPI';

export type changePassword = {
  email?: string;
  temporaryPassword?: string;
  password: string;
  confirmPassword?: string;
};

const auth = {
  login: (data: { email: string; password: string }): ConfigType => {
    return {
      method: 'post',
      url: '/admin/login',
      data,
    };
  },

  changePassword: (data: changePassword): ConfigType => ({
    method: 'post',
    url: '/admin/password/reset/first',
    data,
  }),
};

export default auth;
