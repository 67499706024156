// import { EditorState } from 'lexical';

import React, { useEffect, useState } from 'react';
import { GoChecklist } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import coupons from '@/apiConfigs/coupons';
import { storiesApi } from '@/apiConfigs/stories';
import BellIcon from '@/assets/bellIcon.svg';
import Logo from '@/assets/icons/user.svg';
import { useRoot } from '@/context/RootProvider';
import Button from '@/designComponents/Button';
import CardTab from '@/designComponents/CardTab';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { RootState, useAppDispatch } from '@/store';
import { getCount } from '@/store/dashboard/functions';
import requestAPI from '@/utils/requestAPI';

import PromoCode from './PromoCode';
import { ContentWrapperStyled } from './style';
import SubscribedUsers, { TableProps } from './SubscribedUsers';
import { calculateCouponsStats } from './utility.function';

export type CouponsStatsData = {
  count: number;
  year: number;
  month: string;
};

const Dashboard = () => {
  const [stories, setStories] = useState([]);
  const [couponStats, setCouponStats] = useState<CouponsStatsData[]>([]);

  const { region } = useRoot();
  const couponsData = calculateCouponsStats(couponStats);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { dashboard } = useSelector((state: RootState) => state.dashboard);
  const [selectedUserCount, setSelectedUserCount] = useState(0);

  const [activeUserCountDropdown, setActiveUserCountDropdown] =
    useState('Last7days');

  const totalSubscribedUser =
    dashboard?.subscribedUsers?.premiumYearly +
    dashboard?.subscribedUsers?.premiumMonthly +
    dashboard?.subscribedUsers?.advancedYearly +
    dashboard?.subscribedUsers?.advancedMonthly;

  const tableData: TableProps['tableData'] = {
    headings: [
      {
        key: 'premiumYearly',
        title: 'Premium Yearly Subscribed User',
      },
      {
        key: 'premiumMonthly',
        title: 'Premium Monthly Subscribed User',
      },
      {
        key: 'advancedYearly',
        title: 'Advanced Yearly Subscribed User',
      },
      {
        key: 'advancedMonthly',
        title: 'Advanced Monthly Subscribed User',
      },
      {
        key: 'total',
        title: 'Total',
      },
    ],
    body: {
      premiumYearly: dashboard?.subscribedUsers?.premiumYearly,
      premiumMonthly: dashboard?.subscribedUsers?.premiumMonthly,
      advancedYearly: dashboard?.subscribedUsers?.advancedYearly,
      advancedMonthly: dashboard?.subscribedUsers?.advancedMonthly,
      total: totalSubscribedUser,
    },
  };

  const userDropdownOption = [
    {
      label: 'Last 7 Days',
      value: 'Last7days',
    },
    {
      label: 'Today',
      value: 'Today',
    },

    {
      label: 'Last Month',
      value: 'lastMonth',
    },
  ];

  const cardData: Array<{
    title: string;
    count: number;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    link?: string;
  }> = [
    {
      title: 'New Users',
      count: dashboard?.newUsersCount?.Last7days || 0,
    },
    {
      title: 'Total Subscription',
      count: totalSubscribedUser || 0,
    },
    {
      title: 'Total Documents',
      count: dashboard?.documents || 0,
    },
    {
      title: 'Total Products',
      count: dashboard?.products || 0,
    },
    {
      title: 'Total Professionals',
      count: dashboard?.professionalUsers || 0,
    },
    {
      title: 'Total Stories',
      count: dashboard?.stories || 0,
    },
    {
      title: 'Journey Flow Users',
      count: dashboard?.journeyFlowUsers || 0,
      link: '/users?activeTab=journey-flow-users',
    },
    {
      title: 'Total Compliance Checklist Users',
      count: dashboard?.complianceChecklists?.uniqueOwners || 0,
      link: '/users?activeTab=compliance-checklist-users',
    },
    {
      title: 'Total Compliance Checklists',
      count: dashboard?.complianceChecklists?.totalChecklists || 0,
      icon: GoChecklist,
    },
  ];

  useEffect(() => {
    if (dashboard.newUsersCount) {
      const selectedData = dashboard?.newUsersCount?.[activeUserCountDropdown];

      setSelectedUserCount(selectedData);
    }
  }, [dashboard]);

  useEffect(() => {
    dispatch(getCount());
  }, [dispatch, region]);

  const handleUserCountSelect = (option: string) => {
    console.log(option, 'option');

    setActiveUserCountDropdown(option);
    const selectedData = dashboard.newUsersCount[option];

    setSelectedUserCount(selectedData);
  };

  useHeaderTitle('Dashboard');

  const handleGetStories = async () => {
    try {
      const data = await requestAPI(storiesApi.getAllStories());
      setStories(data.data.data);
    } catch (error) {
      console.error('Error encountered: ', error);
    }
  };
  useEffect(() => {
    handleGetStories();
  }, []);

  const getStatsData = async () => {
    try {
      const result = await requestAPI(coupons.getCouponsStats());
      if (result) {
        setCouponStats(result);
      }
    } catch (error) {
      console.error('Error while fetching stats data');
    }
  };

  useEffect(() => {
    getStatsData();
  }, []);

  const handleNavigation = (link: string) => {
    navigate(link);
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '40px',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '10px',
            marginBottom: '20px',
          }}
        >
          {' '}
          {cardData.map((data, idx) => (
            <React.Fragment key={idx}>
              <CardTab
                enableBorder
                img={data.title !== 'Total Compliance Checklists' && Logo}
                title={data.title}
                icon={data.icon}
                count={idx === 0 ? selectedUserCount : data.count}
                key={idx}
                onClick={() => {
                  handleNavigation(data.link);
                }}
                dropDownComponent={
                  idx === 0 && (
                    <div
                      style={{
                        width: '150px',
                      }}
                    >
                      <Select
                        options={userDropdownOption}
                        name="stories"
                        placeholder={activeUserCountDropdown}
                        isHideBorder
                        isReduceIconGap
                        onSelect={handleUserCountSelect}
                        selected={selectedUserCount}
                      />
                    </div>
                  )
                }
              />
            </React.Fragment>
          ))}
        </div>
        <ContentWrapperStyled>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginBottom: '20px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Typography
                size="highlight"
                fontWeight="medium"
                style={{ marginRight: '15px' }}
              >
                Graph Statistics
              </Typography>
              <div
                className="stat"
                style={{ background: '#B5F5EC', border: '1px solid #b5f5ec' }}
              >
                <img src={uparrow} alt="up arrow" />
                <Typography style={{ color: '#08979c', fontSize: '16px' }}>
                  + 40 %
                </Typography>
              </div>
              <div
                className="stat"
                style={{ background: '#EFDBFF', border: '1px solid #EFDBFF' }}
              >
                <img src={Downarrow} alt="down arrow" />
                <Typography style={{ color: '#9254DE', fontSize: '16px' }}>
                  - 10 %
                </Typography>
              </div>
            </div>
            <Charts />
            <div
              style={{
                width: '100%',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                gap: '10px',
                marginTop: '90px',
              }}
            >
              <Typography
                size="highlight"
                fontWeight="medium"
                style={{ marginRight: '15px' }}
              >
                Jobs Statistics
              </Typography>
              <div
                className="stat"
                style={{ background: '#B5F5EC', border: '1px solid #b5f5ec' }}
              >
                <img src={uparrow} alt="up arrow" />
                <Typography style={{ color: '#08979c', fontSize: '16px' }}>
                  + 30 %
                </Typography>
              </div>
              <div
                className="stat"
                style={{ background: '#372f3d', border: '1px solid #EFDBFF' }}
              >
                <img src={Downarrow} alt="down arrow" />
                <Typography style={{ color: '#9254DE', fontSize: '16px' }}>
                  - 20 %
                </Typography>
              </div>
            </div>
            <JobCharts />
          </div> */}
          <PromoCode
            chartsData={couponsData.chartData}
            decreasedPercent={couponsData.stats.decrease}
            increasedPercent={couponsData.stats.increase}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              flex: 1,
            }}
          >
            <div
              style={{
                border: '1px solid #E8E8E8',
                borderRadius: '7px',
                padding: '15px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
              >
                <div style={{ display: 'flex', gap: '7px' }}>
                  <img src={BellIcon} alt="bell icon" />
                  <Typography size="title" fontWeight="medium">
                    Stories Approval Notifications
                  </Typography>
                </div>
                {/* <Select
                  options={dropDownOption}
                  name="stories"
                  placeholder="Select"
                  isHideBorder
                  isReduceIconGap
                /> */}
              </div>

              {stories?.slice(0, 2)?.map((story) => {
                const firstPhoto = story?.photos?.[0];

                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: '30px',
                    }}
                    key={story?._id}
                  >
                    <div style={{ display: 'flex', gap: '30px' }}>
                      <div>
                        {firstPhoto && (
                          <img
                            src={firstPhoto.url}
                            alt="home"
                            key={firstPhoto?._id}
                            height={120}
                            width={120}
                            // style={{ objectFit: 'contain' }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '19px',
                        }}
                      >
                        <Typography size="highlight" fontWeight="medium">
                          {story?.title}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '7px',
                          }}
                        >
                          <img src={Logo} />
                          <Typography>Cameron Williamson</Typography>
                        </div>
                        <Typography>{story?.description}</Typography>
                        <Button
                          variant="gray"
                          radius="normal"
                          style={{
                            maxWidth: '80px',
                            border: '1px solid blue',
                            color: 'blue',
                            backgroundColor: 'white',
                          }}
                          onClick={() => navigate(`/stories/${story._id}`)}
                        >
                          View
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}

              <Typography
                style={{
                  textAlign: 'center',
                  textDecoration: 'underline',
                  fontSize: '14px',
                  fontWeight: '500',
                  marginTop: '15px',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/stories')}
              >
                See all stories approval requests
              </Typography>
            </div>
            {/* <JobPosting /> */}
          </div>
        </ContentWrapperStyled>
        {/* <ContentWrapperStyled>
          <PromoCode />
        </ContentWrapperStyled> */}
        {/* <TextEditor setEditorState={setEditorState} />
        <TextEditor readOnly initialHtml={editorState?.html} /> */}
        <div
          style={{
            display: 'flex',
            gap: '20px',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Typography size="highlight" fontWeight="medium">
            Subscribed Users
          </Typography>
          <div>
            <SubscribedUsers tableData={tableData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
