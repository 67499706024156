import { NavigateFunction } from 'react-router-dom';

import Button from '@/designComponents/Button';

export type InitialValuesType = {
  documentTitle: string;
  documentType: string;
  parentTitle: string;
  icon: File[] | null;
  type: string;
  jurisdictions: string[];
};

export const dropDownOption: Array<{
  value: string;
  label: string;
}> = [
  {
    label: 'Building Materials',
    value: 'building-materials',
  },
  {
    label: 'Certified Constructions',
    value: 'certified-constructions',
  },
  {
    label: 'Furniture and Decor',
    value: 'furniture-and-decor',
  },
];

export const badgeData: Array<{ title: string; value: string }> = [
  {
    title: 'Building & Materials',
    value: 'building-material',
  },
  {
    title: 'Certified Construction',
    value: 'certified-construction',
  },
  {
    title: 'Furniture & Decor',
    value: 'furniture-decor',
  },
  {
    title: 'Supplier',
    value: 'supplier',
  },
];

export const searchData: Array<{
  badgeType: string;
  data: Array<{ title: string; category: string }>;
}> = [
  {
    badgeType: 'building-material',
    data: [
      {
        title: 'Retrofitting',
        category: 'All Categories',
      },
      {
        title: 'Renewable Details',
        category: 'All Resources',
      },
      {
        title: 'Introduction',
        category: 'NZEB Resources',
      },
      {
        title: 'Passive House Details',
        category: 'NZEB Subcategories',
      },
      {
        title: 'FAQ’S - Typing Costing',
        category: 'Trade Gropings',
      },
    ],
  },
  {
    badgeType: 'certified-construction',
    data: [
      {
        title: 'Foundation Types',
        category: 'Architecture',
      },
      {
        title: 'Roofing Solutions',
        category: 'Design & Materials',
      },
      {
        title: 'Insulation Best Practices',
        category: 'Energy Saving',
      },
      {
        title: 'Window and Door Efficiency',
        category: 'Installation',
      },
      {
        title: 'Landscaping for Energy Conservation',
        category: 'Exterior Design',
      },
    ],
  },
  {
    badgeType: 'furniture-decor',
    data: [
      {
        title: 'HVAC Innovations',
        category: 'Climate Control',
      },
      {
        title: 'Smart Home Systems',
        category: 'Technology',
      },
      {
        title: 'Solar Panel Integration',
        category: 'Renewable Energy',
      },
      {
        title: 'Rainwater Harvesting',
        category: 'Water Management',
      },
      {
        title: 'Sustainable Urban Development',
        category: 'City Planning',
      },
    ],
  },
  {
    badgeType: 'supplier',
    data: [
      {
        title: 'Blueprints for Energy Efficient Homes',
        category: 'Architectural Plans',
      },
      {
        title: 'DIY Solar Panel Installation Guide',
        category: 'Renewable Energy Resources',
      },
      {
        title: 'Eco-Friendly Material Suppliers',
        category: 'Vendor Lists',
      },
      {
        title: 'Green Certification Process',
        category: 'Regulatory Guidelines',
      },
      {
        title: 'Sustainable Gardening eBook',
        category: 'Lifestyle',
      },
    ],
  },
];

export const breadCrumbData = (
  label: string
): Array<{ label: string; path: string }> => [
  {
    label: 'Ecommerce',
    path: '/',
  },
  {
    label: label,
    path: '/',
  },
];

export const data = (navigate: NavigateFunction) => [
  {
    id: '1',
    name: 'Sink for bathroom',
    documentType: 'Building Materials',
    parentTitle: 'Tile Merchant Ireland',
    price: '7,791.90',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '2',
    name: 'Test Product 121 test more',
    documentType: 'Furniture & Decor',
    parentTitle: 'EZ Living Ireland',
    price: '6065.04',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '3',
    name: '4 Piece Grey Garden Sofa Set - Lisbon',
    documentType: 'Furniture & Decor',
    parentTitle: 'EZ Living Ireland',
    price: '937.08',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    name: 'White Feather Owl with Tartan Bow Christmas Wreath',
    documentType: 'Building Materials',
    parentTitle: 'Tile Merchant Ireland',
    price: '157.00',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '5',
    name: '2 Seater Teal Velvet Sofa - Colton',
    documentType: 'Furniture & Decor',
    parentTitle: 'The Merchant Ireland',
    price: '4152.78',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '6',
    name: '4 Seater Teal Fabric Sofa - Madison',
    documentType: 'Building Materials',
    parentTitle: 'The Merchant Ireland',
    price: '8,861.00',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '7',
    name: 'Waterproofing Techniques 700345',
    documentType: 'Technical Guides',
    parentTitle: 'Construction Details',
    price: '19 may 2020 11:00 am',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '8',
    name: 'Thermal Insulation Analysis 800456',
    documentType: 'Research Papers',
    parentTitle: 'Insulation Techniques',
    price: '20 may 2020 01:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '9',
    name: 'Acoustic Insulation in Buildings 900567',
    documentType: 'Technical Notes',
    parentTitle: 'Building Acoustics',
    price: '21 may 2020 03:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '10',
    name: 'Building Code Compliance 1000789',
    documentType: 'Compliance Documents',
    parentTitle: 'Regulations',
    price: '22 may 2020 05:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '11',
    name: 'Fire Safety Measures 1100890',
    documentType: 'Safety Guidelines',
    parentTitle: 'Safety Standards',
    price: '23 may 2020 07:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '12',
    name: 'Green Roof Systems 1200123',
    documentType: 'Sustainable Practices',
    parentTitle: 'Green Building',
    price: '24 may 2020 08:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '13',
    name: 'Solar Panel Installation 1300456',
    documentType: 'Installation Guides',
    parentTitle: 'Renewable Energy',
    price: '25 may 2020 09:00 am',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '14',
    name: 'Advanced HVAC Systems 1400567',
    documentType: 'Mechanical Systems',
    parentTitle: 'HVAC',
    price: '26 may 2020 11:00 am',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '15',
    name: 'Foundation Types and Designs 1500678',
    documentType: 'Structural Engineering',
    parentTitle: 'Foundations',
    price: '26 may 2020 11:00 am',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
];
