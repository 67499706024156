import { ReactNode, useRef } from 'react';
import { FaFilter } from 'react-icons/fa';
import { MdKeyboardArrowRight } from 'react-icons/md';

import Typography from '@/designComponents/Typography';
import useDetectOutsideClick from '@/hooks/useDetectOutsideClick';

import { FilterContentWrapper, FilterWrapper } from './style';

type Props = {
  filterContentChildren: ReactNode;
};
const Filter = ({ filterContentChildren }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isClicked, setIsClicked] = useDetectOutsideClick(ref, false) as [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];
  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <FilterWrapper ref={ref}>
      <div className="icons-text" role="button" onClick={handleClick}>
        <FaFilter />
        <Typography>Filters</Typography>
        <MdKeyboardArrowRight
          size={20}
          style={{
            transform: isClicked ? 'rotate(90deg)' : 'rotate(0deg)',
            transition: 'all 0.3s ease',
          }}
        />
      </div>
      <FilterContentWrapper $isClicked={isClicked}>
        {filterContentChildren}
      </FilterContentWrapper>
    </FilterWrapper>
  );
};

export default Filter;
