import { CSSProperties, ReactNode, useEffect, useRef } from 'react';

// import { ReactComponent as HomeSvg } from '@/assets/icons/home.svg';
import Typography from '../Typography';
import { CardTabWrapper } from './style';

type Props = {
  title: string;
  count: number;
  img?: any;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  isActive?: boolean;
  enableBorder?: boolean;
  mainContentstyle?: CSSProperties;
  cardStyle?: CSSProperties;
  dropDownComponent?: ReactNode;
};

const CardTab = ({
  count,
  title,
  img,
  isActive = false,
  onClick,
  enableBorder = false,
  mainContentstyle,
  cardStyle,
  dropDownComponent,
  icon: Icon,
}: Props) => {
  const displayCountRef = useRef(0);
  const countDisplayElement = useRef(null);

  useEffect(() => {
    const duration = 500;
    const startTime = performance.now();

    const animateCounter = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      displayCountRef.current = Math.floor(progress * count);

      if (countDisplayElement.current) {
        (countDisplayElement.current as HTMLSpanElement).textContent =
          displayCountRef.current.toString();
      }

      if (progress < 1) {
        requestAnimationFrame(animateCounter);
      }
    };

    requestAnimationFrame(animateCounter);
  }, [count]);

  const handleCardClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <CardTabWrapper
      $isActive={isActive}
      onClick={handleCardClick}
      $enableBorder={enableBorder}
      style={{ ...cardStyle, border: enableBorder ? '1px solid #ccc' : 'none' }}
    >
      <div className="main-content" style={{ ...mainContentstyle }}>
        {Icon && <Icon fontSize={22} />}
        {img && <img src={img} alt="icon" style={{ marginRight: '8px' }} />}
        <Typography size="normal" fontWeight="medium" color="dark.neutral_45">
          {title}
        </Typography>
        {dropDownComponent ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography
              fontWeight="semibold"
              fontFamily="roboto"
              color="dark.neutral_60"
              size="extraLargeHeading"
            >
              <span ref={countDisplayElement}>0</span>
            </Typography>
            {dropDownComponent}
          </div>
        ) : (
          <Typography
            fontWeight="semibold"
            fontFamily="roboto"
            color="dark.neutral_60"
            size="extraLargeHeading"
          >
            <span ref={countDisplayElement}>0</span>
          </Typography>
        )}
      </div>
    </CardTabWrapper>
  );
};

export default CardTab;
