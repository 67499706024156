import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import videos, { PaginationType } from '@/apiConfigs/videos';
import requestAPI from '@/utils/requestAPI';

export const addHowToVideosCategoryClass = createAsyncThunk(
  'videos/addHowToVideoCategoryClass',
  async (data: { name: string }, { rejectWithValue }) => {
    try {
      const response = await requestAPI(
        videos.addHowToVideoCategoryClass(data)
      );
      toast.success('Added how to videos category class successfully');
      return response;
    } catch (error: any) {
      console.error(error.message, 'error from catch');
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getAllHowToVideosCategoryClass = createAsyncThunk(
  'videos/video-category',
  async (pagination: PaginationType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(
        videos.getAllHowToVideoCategoryClass(pagination)
      );
      return response;
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
export const getSingleHowToVideosCategoryClass = createAsyncThunk(
  'videos/getSingleHowToVideos',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await requestAPI(
        videos.getSingleHowToVideoCategoryClass(id)
      );
      return response;
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
