import { ConfigType } from '@/utils/requestAPI';

const dashboard = {
  countData: (): ConfigType => {
    return {
      method: 'get',
      url: '/analytics',
    };
  },
};

export default dashboard;
