import { createSlice } from '@reduxjs/toolkit';

import { StoryType } from '@/apiConfigs/stories';

import {
  addStories,
  getAllStories,
  getSingleStory,
  getStoryCatByType,
} from './function';

export type SingleStoryData = {
  _id: string;
  title: string;
  slug: string;
  body: string;
  category: Array<{
    _id: string;
    title: string;
  }>;
  description: string;
  userId: any;
  adminUserId: any;
  featured: boolean;
  approved: boolean;
  type: StoryType[];
  region: any[];
  photos: any[];
  createdAt: string;
  updatedAt: string;
  __v: number;
};

export type Stories = {
  data: Array<{
    adminUserId: any;
    _id: string;
    title: string;
    author: any;
    publishedAt: any;
    body: string;
    category: any[];
    description: string;
    userId: string;
    featured: boolean;
    approved: boolean;
    createdUnderCompanyName: boolean;
    type: string[];
    region: string[];
    photos: any[];
    prevId: string;
    createdAt: string;
    updatedAt: string;
    slug: string;
    __v: number;
  }>;
  pagination: {
    status: boolean;
    total: number;
    totalPages: number;
    currentPage: number;
    limit: string;
  };
  analytics: {
    noOfIdeas: number;
    noOfStories: number;
  };
};

export type StoryCategory = {
  category: string;
  categoryIcon: string;
};

type InitialState = {
  stories: Stories | null;
  singleStory: SingleStoryData | null;
  categories: StoryCategory | null;
  error: string | null;
  loading: boolean;
  pagination: {
    total: number;
    limit: number;
    currentPage: number;
    totalPages: number;
  };
};

const initialState: InitialState = {
  stories: null,
  categories: null,
  singleStory: null,
  error: null,
  loading: false,
  pagination: {
    total: 0,
    limit: 11,
    currentPage: 0,
    totalPages: 1,
  },
};

const storiesSlice = createSlice({
  initialState,
  name: 'stories',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addStories.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addStories.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.stories = action.payload;
    });
    builder.addCase(addStories.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to add Stories. Please, try again later.';
    });
    builder.addCase(getStoryCatByType.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getStoryCatByType.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.categories = action.payload;
    });
    builder.addCase(getStoryCatByType.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to add Stories. Please, try again later.';
    });
    builder.addCase(getAllStories.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllStories.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;

      state.stories = action.payload.data;
      state.stories.analytics = action.payload.analytics;
    });
    builder.addCase(getAllStories.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to get all stories. Please, try again later.';
    });
    builder.addCase(getSingleStory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSingleStory.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.singleStory = action.payload;
    });
    builder.addCase(getSingleStory.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to get single story. Please, try again later.';
    });
  },
});

export const storiesReducer = storiesSlice.reducer;
