import { breakpoints, breakpointsDeviceWise } from './breakpoints';
import { colors } from './colors';
import { fontFamily } from './fontFamily';
import { fontSize } from './fontSizes';
import fontWeights from './fontWeights';
import zIndices from './zIndices';

const theme = {
  colors,
  breakpoints,
  breakpointsDeviceWise,
  fontFamily,
  fontWeights,
  zIndices,
  fontSize,
};

export default theme;
export type ThemeType = typeof theme;
