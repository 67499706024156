import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import Button from '@/designComponents/Button';
import { ButtonTab } from '@/designComponents/ButtonTab';
import Input from '@/designComponents/Input';
import { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import {
  FEATURED_VIDEOS_CATEGORY_CLASS_ROUTE,
  FEATURED_VIDEOS_CATEGORY_ROUTE,
  HOW_TO_VIDEOS_CATEGORY_ROUTE,
  HOW_TO_VIDEOS_CLASS_ROUTE,
  VIDEOS_ROUTE,
} from '@/routes/routePaths';
import { useAppDispatch, useAppSelector } from '@/store';
import { getAllHowToVideos } from '@/store/videos/HowToVideos/functions';
import usePaginationStore from '@/storeZustand/paginationStore';

import { debounce } from '../DownloadCenter';
import { cardData } from './data';
import HowToVideos from './HowToVideos';
import { SearchInputWrapperStyled } from './HowToVideos/style';
import VideoCategoryTable from './HowToVideosCategory/VideoCategoryTable';
import CategoryClassTable from './HowToVideosCategoryClass/CategoryClassTable';

export type CardKey =
  | 'how-to-videos'
  | 'how-to-category'
  | 'feature-category'
  | 'feature-category-class'
  | 'how-to-category-class';

const Videos = () => {
  const { getLimit, setLimit } = usePaginationStore();
  const [cardKey, setCardKey] = useState<CardKey>('how-to-videos');
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  const navigate = useNavigate();

  const { videos, loading } = useAppSelector((state) => state.howToVideos);

  const dispatch = useAppDispatch();

  const handleClick = (key: CardKey) => {
    setCardKey(key);
    if (key === 'how-to-videos') {
      navigate(VIDEOS_ROUTE);
    }
    if (key === 'how-to-category') {
      navigate(VIDEOS_ROUTE + '/' + HOW_TO_VIDEOS_CATEGORY_ROUTE);
    }
    if (key === 'feature-category') {
      navigate(VIDEOS_ROUTE + '/' + FEATURED_VIDEOS_CATEGORY_ROUTE);
    }
    if (key === 'how-to-category-class') {
      navigate(VIDEOS_ROUTE + '/' + HOW_TO_VIDEOS_CLASS_ROUTE);
    }
    if (key === 'feature-category-class') {
      navigate(VIDEOS_ROUTE + '/' + FEATURED_VIDEOS_CATEGORY_CLASS_ROUTE);
    }
  };

  const tableData: TableDataType = {
    headings: [
      { key: 'title', label: 'Title' },
      { key: 'link', label: 'External Uid' },
      { key: 'duration', label: 'Duration' },
      { key: 'category', label: 'Category' },
      { key: 'actions', label: 'Actions' },
    ],
    body: videos?.videos?.data
      ? videos?.videos?.data.map((video) => ({
          id: `${video._id}`,
          link: video.link,
          duration: video.duration,
          category: video.category.name,
          title: video.title,
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                onClick={() => navigate(video._id)}
              >
                View
              </Button>
            </div>
          ),
        }))
      : [],
  };

  const fetchHowToVideos = useCallback(
    debounce((searchTerm) => {
      dispatch(
        getAllHowToVideos({
          limit: getLimit('videos') || 10,
          page: currentPage,
          search: searchTerm,
        })
      );
    }, 300),
    [dispatch, currentPage, getLimit]
  );

  useEffect(() => {
    fetchHowToVideos(search);
    return () => {
      fetchHowToVideos.cancel();
    };
  }, [fetchHowToVideos, search, currentPage, getLimit]);

  const handleCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (count: number) => {
    setLimit('videos', count);
  };

  const cardCountValuesWithData = cardData({
    allVideos: videos?.videos?.pagination.total,
    allCategories: videos?.analytics?.category,
    allVideoSection: videos?.analytics?.contentSection,
  });

  useHeaderTitle('Videos');

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <SearchInputWrapperStyled>
          <Input
            value={search}
            onChange={(e) => {
              const { value } = e.target;
              setSearch(value);
            }}
            placeholder="Search HowToVideos"
            iconLeft={<SearchSVG />}
          />
        </SearchInputWrapperStyled>
        <HowToVideos />
      </div>

      {cardKey === 'how-to-category' ? (
        <VideoCategoryTable
          downloadCenterTabs={
            <div style={{ height: '50px' }}>
              <ButtonTab
                items={cardCountValuesWithData}
                activeIndex={cardCountValuesWithData.findIndex(
                  (x) => x.key === cardKey
                )}
                setActiveIndex={(index) =>
                  handleClick(cardCountValuesWithData[index].key)
                }
              />
            </div>
          }
        />
      ) : cardKey === 'how-to-category-class' ? (
        <CategoryClassTable
          downloadCenterTabs={
            <div style={{ height: '50px' }}>
              <ButtonTab
                items={cardCountValuesWithData}
                activeIndex={cardCountValuesWithData.findIndex(
                  (x) => x.key === cardKey
                )}
                setActiveIndex={(index) =>
                  handleClick(cardCountValuesWithData[index].key)
                }
              />
            </div>
          }
        />
      ) : (
        <>
          <Table
            tableData={tableData}
            customRow={
              <div style={{ height: '50px' }}>
                <ButtonTab
                  items={cardCountValuesWithData}
                  activeIndex={cardCountValuesWithData.findIndex(
                    (x) => x.key === cardKey
                  )}
                  setActiveIndex={(index) =>
                    handleClick(cardCountValuesWithData[index].key)
                  }
                />
              </div>
            }
            isLoading={loading}
            noData="No data to display."
            maxWidthForCardView="768px"
            headingBgColor="#f0f0f0"
            showMenuIcon={false}
            evenRowBgColor="#fafafa"
          />
          <Pagination
            activePage={currentPage}
            onCurrentPageChange={handleCurrentPage}
            totalPages={videos?.videos.pagination?.totalPages || 10}
            limit={getLimit('videos') || 10}
            onLimitChange={handleLimitChange}
          />
        </>
      )}
    </div>
  );
};

export default Videos;
