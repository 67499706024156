import { PaymentHistoryData } from '@/store/users';
import { toCapitalCase } from '@/utils/conversion';
import { humanReadableDate } from '@/utils/date';
import { getRespectiveCurrencySymbol } from '@/utils/function.utils';

import DataTable from './DataTable';

export default function PaymentHistory(props: PaymentHistoryData) {
  return (
    <DataTable
      data={{
        ID: props._id,
        Amount: `${getRespectiveCurrencySymbol(props.currency) + ' ' + props.amount}`,
        Currency: props.currency.toUpperCase(),
        ['Created At']: humanReadableDate(props.createdAt),
        ['Updated At']: humanReadableDate(props.updatedAt),
        ['Payment Gateway']: toCapitalCase(props.provider),
      }}
    />
  );
}
