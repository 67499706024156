import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import advertiseAPI from '@/apiConfigs/ads';
import advertise from '@/apiConfigs/ads';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Typography from '@/designComponents/Typography';
import { WrapperStyled } from '@/pages/Documents/CreateDocumentGroup/style';
import { useAppDispatch } from '@/store';
import { getAllAdvertiser } from '@/store/advertise/functions';
import requestAPI from '@/utils/requestAPI';

import { Advertisement } from '../Advertise/Details';

type AdvertiseValues = {
  fullName?: string;
  email?: string;
  website?: string;
  phoneNumber?: string;
};

type Props = {
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  onClose: any;
};

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Name is required'),
  email: Yup.string().required('Email is required'),
  website: Yup.string().required('Website is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
});

const CreateAdvertiser = ({ setIsModalOpen, onClose }: Props) => {
  const { advertiseId } = useParams();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<AdvertiseValues>({
    fullName: '',
    email: '',
    website: '',
    phoneNumber: '',
  });

  const [advertises, setAdvertise] = useState<Advertisement | null>(null);

  const getSingleAdvertise = async (id: string) => {
    try {
      const data = await requestAPI(advertise.getSingleAdvertiser(id));
      setAdvertise(data);
    } catch (error) {
      console.error('Failed to fetch advertisement:', error);
    }
  };

  useEffect(() => {
    if (advertiseId) {
      getSingleAdvertise(advertiseId);
    }
  }, [advertiseId]);

  console.log(advertises, 'advertises');

  useEffect(() => {
    if (advertiseId && advertises) {
      setInitialValues({
        fullName: advertises?.fullName || '',
        email: advertises?.email || '',
        website: advertises?.website || '',
        phoneNumber: advertises?.phoneNumber || '',
      });
    }
  }, [advertiseId, advertises]);

  const handleAddAdvertiser = async (val: AdvertiseValues) => {
    setLoading(true);
    try {
      await requestAPI(
        advertise.addAdvertiser({
          fullName: val.fullName,
          email: val.email,
          website: val.website,
          phoneNumber: val.phoneNumber,
        })
      );
      setLoading(false);
      setIsModalOpen(false);
      toast.success('Advertiser added successfully');
      dispatch(getAllAdvertiser({}));
    } catch (e: any) {
      setErrorMessage(e.message);
      toast.error(e.message);
    }
  };

  const handleUpdateAdvertiser = async (val: AdvertiseValues) => {
    setLoading(true);
    try {
      await requestAPI(
        advertiseAPI.updateAdvertiser(advertiseId, {
          fullName: val.fullName,
          email: val.email,
          website: val.website,
          phoneNumber: val.phoneNumber,
        })
      );
      setLoading(false);
      toast.success('Advertiser updated successfully');
      onClose();
      getSingleAdvertise(advertiseId);
    } catch (error) {
      console.error('Failed to update advertisement:', error);
      toast.error('Failed to update advertiser');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={advertiseId ? handleUpdateAdvertiser : handleAddAdvertiser}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, touched, errors }) => {
          console.log(values, 'values set initial');

          return (
            <Form>
              <WrapperStyled>
                <div className="container">
                  <Typography fontWeight="medium">
                    Name <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Input
                    name="fullName"
                    value={values.fullName}
                    onChange={(e) => setFieldValue('fullName', e.target.value)}
                  />
                  {errors.fullName && touched.fullName ? (
                    <div className="error">{errors.fullName}</div>
                  ) : null}
                </div>
                <Typography fontWeight="medium">
                  Email <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Input
                  name="email"
                  value={values.email}
                  onChange={(e) => setFieldValue('email', e.target.value)}
                />
                {errors.email && touched.email ? (
                  <div style={{ color: 'red' }}>{errors.email}</div>
                ) : null}
                <Typography fontWeight="medium">
                  Website <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Input
                  name="website"
                  value={values.website}
                  onChange={(e) => setFieldValue('website', e.target.value)}
                />
                {errors.website && touched.website ? (
                  <div style={{ color: 'red' }}>{errors.website}</div>
                ) : null}
                <Typography fontWeight="medium">
                  Phone Number <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Input
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={(e) => setFieldValue('phoneNumber', e.target.value)}
                />
                {errors.phoneNumber && touched.phoneNumber ? (
                  <div style={{ color: 'red' }}>{errors.phoneNumber}</div>
                ) : null}

                {errorMessage && (
                  <div style={{ color: 'red' }}>{errorMessage}</div>
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="submit" isLoading={loading}>
                    {advertiseId ? 'Update' : 'Create'}
                  </Button>
                </div>
              </WrapperStyled>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateAdvertiser;
