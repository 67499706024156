export const breadCrumbData: Array<{ label: string; path: string }> = [
  {
    label: 'Documents',
    path: '/',
  },
  {
    label: 'Reposition',
    path: '/',
  },
];
