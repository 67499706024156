import styled from 'styled-components';

import Button from '@/designComponents/Button';

export const InfoRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & .text {
    font-family: ${({ theme }) => theme.fontFamily.roboto};
    font-size: ${({ theme }) => theme.fontSize.normal};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: #4c4c4c;
  }
`;

export const DetailsPageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;

  .crumb-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;

export const StepsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.soft.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border-radius: 20px;
  width: fit-content;
`;

export const FlexEndContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  flex-direction: column;
  margin-top: -20px;
`;

export const RegionContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

export const RemoveButton = styled.button`
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background-color: #c82333;
  }
`;

export const AddButton = styled(Button)`
  margin-bottom: 10px;
  width: fit-content;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;
