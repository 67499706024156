import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import Typography from '../Typography';
import { BreadCrumbStyle } from './style';

const BreadCrumb = ({
  breadCrumb,
}: {
  breadCrumb: { label: string; path: string }[];
}) => {
  const theme = useTheme();
  return (
    <BreadCrumbStyle>
      <nav>
        <ol style={{ display: 'flex', gap: 6 }}>
          {breadCrumb?.map((label, idx) => (
            <li key={idx}>
              {idx === breadCrumb.length - 1 ? (
                <Typography
                  color="dark.neutral_60"
                  fontFamily="jost"
                  fontWeight="medium"
                  size="highlight"
                >
                  {label.label}
                </Typography>
              ) : (
                <Link
                  to={label.path}
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Typography
                    color="dark.neutral_450"
                    fontFamily="jost"
                    fontWeight="medium"
                    size="normal"
                  >
                    {label.label}{' '}
                    <span
                      style={{
                        fontSize: theme.fontSize.highlight,
                        fontWeight: theme.fontWeights.medium,
                        fontFamily: theme.fontFamily.jost,
                        color: theme.colors.dark.neutral_60,
                      }}
                    >
                      /
                    </span>
                  </Typography>
                </Link>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </BreadCrumbStyle>
  );
};

export default BreadCrumb;
