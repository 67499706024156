import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import HeadingTitle from '@/components/UserDetails/HeadingTitle';
import BreadCrumb from '@/designComponents/BreadCrumb';
import Button from '@/designComponents/Button';
import Loader from '@/designComponents/Loader';
import Modal from '@/designComponents/Modal';
import Typography from '@/designComponents/Typography';
import { InformationRowWrapper } from '@/pages/Users/Details/style';
import { useAppDispatch, useAppSelector } from '@/store';
import { getSingleHowToVideosCategoryClass } from '@/store/videos/HowToVideosCategoryClass/functions';
import { humanReadableDate } from '@/utils/date';

import { howToVideosCatClassBreadCrumbData } from '../../data';
import { DetailsPageWrapper, InfoRowStyled } from '../../HowToVideos/style';
import AddHowToVideosCategoryClass from '../AddHowToVideosCategoryClass';

const HowToVideosCategoryClassDetails = () => {
  const { videoId } = useParams();
  const [openEditModal, setOpenEditModal] = useState(false);

  const { singleVideo, loading } = useAppSelector(
    (state) => state.howToVideosCategoryClass
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getSingleHowToVideosCategoryClass(videoId || ''));
  }, [dispatch, videoId]);
  return (
    <DetailsPageWrapper>
      {!loading ? (
        <>
          <div className="crumb-btn">
            <BreadCrumb
              breadCrumb={howToVideosCatClassBreadCrumbData(singleVideo?.name)}
            />
            <div className="btn">
              <Button radius="md" onClick={() => setOpenEditModal(true)}>
                Edit Video Content Section
              </Button>

              {openEditModal && (
                <Modal
                  isOpen={openEditModal}
                  onClose={() => setOpenEditModal(false)}
                  title="Edit Video Content Section"
                >
                  <AddHowToVideosCategoryClass
                    isModalOpen={openEditModal}
                    setIsModalOpen={setOpenEditModal}
                  />
                </Modal>
              )}
            </div>
          </div>
          <div className="main-content">
            <InfoRowStyled>
              <HeadingTitle title="Video Content Section Details" />
              <InformationRowWrapper>
                <>
                  <div className="text">
                    <Typography
                      fontFamily="roboto"
                      fontWeight="normal"
                      color="dark.neutral_450"
                      size="normal"
                    >
                      ID
                    </Typography>
                    <Typography
                      fontFamily="roboto"
                      fontWeight="normal"
                      color="dark.neutral_450"
                      size="normal"
                    >
                      {singleVideo?._id}
                    </Typography>
                  </div>
                  <div className="text">
                    <Typography
                      fontFamily="roboto"
                      fontWeight="normal"
                      color="dark.neutral_450"
                      size="normal"
                    >
                      Name
                    </Typography>
                    <Typography
                      fontFamily="roboto"
                      fontWeight="normal"
                      color="dark.neutral_450"
                      size="normal"
                    >
                      {singleVideo?.name}
                    </Typography>
                  </div>
                  <div className="text">
                    <Typography
                      fontFamily="roboto"
                      fontWeight="normal"
                      color="dark.neutral_450"
                      size="normal"
                    >
                      Created At
                    </Typography>
                    <Typography
                      fontFamily="roboto"
                      fontWeight="normal"
                      color="dark.neutral_450"
                      size="normal"
                    >
                      {humanReadableDate(singleVideo?.createdAt)}
                    </Typography>
                  </div>
                  <div className="text">
                    <Typography
                      fontFamily="roboto"
                      fontWeight="normal"
                      color="dark.neutral_450"
                      size="normal"
                    >
                      Updated At
                    </Typography>
                    <Typography
                      fontFamily="roboto"
                      fontWeight="normal"
                      color="dark.neutral_450"
                      size="normal"
                    >
                      {humanReadableDate(singleVideo?.updatedAt)}
                    </Typography>
                  </div>
                </>
              </InformationRowWrapper>
            </InfoRowStyled>
          </div>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '400px',
            width: '100%',
          }}
        >
          <Loader
            style={{
              height: 60,
              width: 60,
            }}
          />
        </div>
      )}
    </DetailsPageWrapper>
  );
};

export default HowToVideosCategoryClassDetails;
