export type StoryCardKey = 'all' | 'Story' | 'Interior';

export const storiesCardData = ({
  totalCount,
  totalIdeas,
  totalStories,
}: {
  totalCount: number;
  totalStories: number;
  totalIdeas: number;
}): Array<{
  title: string;
  count: number;
  key: StoryCardKey;
}> => [
  {
    title: 'All',
    key: 'all',
    count: totalCount,
  },
  {
    title: 'Stories',
    key: 'Story',
    count: totalStories,
  },
  {
    title: 'Images',
    key: 'Interior',
    count: totalIdeas,
  },
];
