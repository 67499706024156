import styled from 'styled-components';

export const DetailsPageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;

  & .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;

export const InformationRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .text {
    display: grid;
    grid-template-columns: 90px 1fr;
    align-items: center;
    gap: 32px;
  }
  .value {
    font-family: roboto;
    font-weight: 400;
    color: #4c4c4c;
    size: 14px;
  }
`;

export const InfoRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & .text {
    font-family: ${({ theme }) => theme.fontFamily.roboto};
    font-size: ${({ theme }) => theme.fontSize.normal};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: #4c4c4c;
  }
`;
