import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import products, { PaginationType, ProductType } from '@/apiConfigs/products';
import requestAPI from '@/utils/requestAPI';

export const addProduct = createAsyncThunk(
  'products/addProduct',
  async (product: ProductType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(products.addproduct(product));
      return response;
    } catch (error: any) {
      console.log(error, 'error');

      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

export const getAllProducts = createAsyncThunk(
  'products/getAllProducts',
  async (params: PaginationType) => {
    try {
      console.log('Fetching all products');
      const response = await requestAPI(products.getAllProduct(params));
      return response;
    } catch (error: any) {
      console.error('Error fetching products:', error);
    }
  }
);
export const getSingleProductDetail = createAsyncThunk(
  'products/getSingleProduct',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await requestAPI(products.getSingleProduct(id));
      return response;
    } catch (error: any) {
      console.error(error.message, 'error from catch');
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
