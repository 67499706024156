import React, { ComponentType, useEffect } from 'react';

import { Body, CloseButton, Header, ModalContainer, Overlay } from './style';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  halfWidth?: any;
  HeaderComponent?: ComponentType;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  halfWidth,
  HeaderComponent,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup function to reset the overflow style when the component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);
  if (!isOpen) {
    return null;
  }

  return (
    <Overlay>
      <ModalContainer halfWidth={halfWidth}>
        <Header>
          <h2>{title}</h2>
          {!!HeaderComponent && <HeaderComponent />}
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </Header>
        <Body className="modal-body">{children}</Body>
      </ModalContainer>
    </Overlay>
  );
};

export default Modal;
