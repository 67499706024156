import { createSlice } from '@reduxjs/toolkit';

import { AuthType } from '@/context/RootProvider';

import { login } from './functions';

const initialState: { user: AuthType | null; loading: boolean; error: any } = {
  user: null,
  error: null,
  loading: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addAuth(state, action) {
      return { ...state, user: action.payload };
    },
    removeAuth() {
      return initialState;
    },
    updateAuth(state, action) {
      return { ...state, user: { ...(state || {}), ...action.payload } };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        return {
          ...state,
          loading: true,
          error: '',
        };
      })
      .addCase(login.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error:
            action?.payload || action?.error?.message || 'Failed to login.',
        };
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log('PP = ', action.payload);

        return {
          loading: false,
          error: null,
          user: action.payload,
        };
      });
  },
});

export const { addAuth, removeAuth, updateAuth } = authSlice.actions;
export const authReducers = authSlice.reducer;
