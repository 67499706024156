import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import { Overlay } from '@/designComponents/Modal/style';
import Typography from '@/designComponents/Typography';

import { CloseButton, DeleteContainer } from './style';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  deleteText: string;
  onConfirmDelete?: () => void;
  confirmBtnLoading?: boolean;
  errorMessage?: string;
}
const DeleteModal = ({
  isOpen,
  onClose,
  title,
  deleteText,
  onConfirmDelete,
  errorMessage,
  confirmBtnLoading = false,
}: ModalProps) => {
  const [informtionText, setInformationText] = useState('');
  const theme = useTheme();

  const text = 'permanently delete';

  if (!isOpen) {
    return null;
  }

  return (
    <Overlay>
      <DeleteContainer>
        <div className="delete-header">
          <Typography fontFamily="jost" fontWeight="medium" size="title">
            {title}
          </Typography>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </div>
        <div className="body">
          <Typography fontFamily="jost" fontWeight="medium" size="highlight">
            {deleteText}
          </Typography>
          <div>
            <Typography style={{ marginBottom: '15px' }}>
              To confirm deletion, type{' '}
              <span style={{ userSelect: 'none', fontWeight: 700 }}>
                permanently delete
              </span>{' '}
              in the text input field.
            </Typography>
            <Input
              name="informationText"
              onChange={(e) => setInformationText(e.target.value)}
            />
          </div>
          {errorMessage && (
            <Typography style={{ color: 'red' }}>{errorMessage}</Typography>
          )}
          <div className="btns">
            <Button
              radius="md"
              variant="ghost"
              style={{
                border: `1px solid ${theme.colors.brandColorBlue}`,
                color: theme.colors.brandColorBlue,
                fontWeight: theme.fontWeights.medium,
                fontSize: theme.fontSize.normal,
                fontFamily: theme.fontFamily.roboto,
              }}
              onClick={onClose}
            >
              No
            </Button>
            <Button
              radius="md"
              isLoading={confirmBtnLoading}
              variant="ghost"
              isDisabled={informtionText !== text}
              style={{
                border: `1px solid ${theme.colors.colorDanger}`,
                color: theme.colors.light.white,
                fontWeight: theme.fontWeights.medium,
                fontSize: theme.fontSize.normal,
                fontFamily: theme.fontFamily.roboto,
                backgroundColor: theme.colors.colorDanger,
              }}
              onClick={onConfirmDelete}
            >
              Yes, Delete
            </Button>
          </div>
        </div>
      </DeleteContainer>
    </Overlay>
  );
};

export default DeleteModal;
