import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Typography from '@/designComponents/Typography';

import { MenuItemType } from './sidebarMenu';
import { MenuDropdownContent, SidebarItem } from './style';

type Props = {
  menu: MenuItemType;
};

const Item: React.FC<Props> = ({ menu }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (pathname.startsWith(menu.path)) {
      setOpen(true);
    }
  }, [menu.path, pathname]);

  const Icon = menu.icon;
  const checkPath = menu.path.split('/')?.[1];

  return (
    <React.Fragment key={menu.key}>
      <SidebarItem
        $active={
          menu.path === '/'
            ? pathname === menu.path
            : pathname.startsWith(menu.path)
        }
        className="sidebar-item"
        onClick={() =>
          menu.children ? setOpen((prev) => !prev) : navigate(menu.path)
        }
      >
        <Icon className="icon" />
        <Typography className="label">{menu.label}</Typography>
        {menu?.children?.length && (
          <FontAwesomeIcon
            icon={isOpen ? 'caret-up' : 'caret-down'}
            className="caret-icon"
          />
        )}
      </SidebarItem>
      {!!isOpen && !!menu?.children?.length && (
        <MenuDropdownContent>
          {!!menu?.children?.length &&
            menu.children.map((item) => {
              const activePath = `/${checkPath}/${item.path.split('/')[2]}`;
              return (
                <SidebarItem
                  key={item.key}
                  $active={pathname.startsWith(activePath)}
                  className="sidebar-item"
                  onClick={() => navigate(item.path)}
                >
                  <Typography className="label">{item.label}</Typography>
                </SidebarItem>
              );
            })}
        </MenuDropdownContent>
      )}
    </React.Fragment>
  );
};

export default Item;
