import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
  AddStoriesType,
  PaginationType,
  storiesApi,
} from '@/apiConfigs/stories';
import requestAPI from '@/utils/requestAPI';

export const addStories = createAsyncThunk(
  '/idea/add',
  async (data: AddStoriesType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(storiesApi.addStories(data));
      toast.success('Stories added successfully');
      return response;
    } catch (error: any) {
      console.error(error.message, 'error from catch');
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
export const getStoryCatByType = createAsyncThunk(
  '/idea/category',
  async (type: string, { rejectWithValue }) => {
    try {
      const response = await requestAPI(storiesApi.getStoryCatByType(type));
      return response;
    } catch (error: any) {
      console.error(error.message, 'error from catch');
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
export const getAllStories = createAsyncThunk(
  '/ideas',
  async (pagination: PaginationType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(storiesApi.getAllStories(pagination));
      return response;
    } catch (error: any) {
      console.error(error.message, 'error from catch');
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
export const getSingleStory = createAsyncThunk(
  '/idea/id',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await requestAPI(storiesApi.getSingleStory(id));
      return response;
    } catch (error: any) {
      console.error(error.message, 'error from catch');
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
