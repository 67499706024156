/* eslint-disable */

import requestAPI from './requestAPI';

export type FileWithKeys = { file: File; key: string };

const getPresignedURl = async (fileName: string, fileType: string) => {
  try {
    const { url, key } = await requestAPI({
      method: 'get',
      url: '/signed-url ',
      data: {
        fileName: fileName,
        fileType: fileType,
      },
    });
    return { url, key }; 
  } catch (error) {
    console.error('Error fetching pre-signed URL:', error);
    throw error;
  }
};

export const uploadFilesToS3OnlyKeys = async (filesArr: any) => {
  const _files = Array.from(filesArr);
  if (_files.length === 0) return;

  try {
    const uploadMultipleImagesPromise = [] as Promise<any>[];
    const keys = [] as string[];

    for (let i = 0; i < _files.length; i++) {
      const file = _files[i] as File;
      const uploadPromise = new Promise(async (resolve, reject) => {
        try {
          const { url, key } = await getPresignedURl(file?.name, file?.type);
          keys.push(key);

          const uploadResponse = await fetch(url, {
            method: 'PUT',
            body: file,
          });

          if (!uploadResponse.ok) {
            throw new Error(`Failed to upload file ${file.name} to S3`);
          }

          resolve(`File ${file.name} uploaded successfully`);
        } catch (error) {
          console.error('Error uploading file:', error);
          reject(error);
        }
      });
      uploadMultipleImagesPromise.push(uploadPromise);
    }

    await Promise.all(uploadMultipleImagesPromise);
    return keys;
  } catch (error) {
    console.error('Error uploading files:', error);
    throw error;
  }
};

export const uploadFilesToS3 = async (filesArr: File[]) => {
  const _files = Array.from(filesArr);
  if (_files.length === 0) return;

  try {
    const uploadMultipleImagesPromise = [] as Promise<any>[];
    const uploadedFileWithKeys = [] as FileWithKeys[];

    for (let i = 0; i < _files.length; i++) {
      const file = _files[i] as File;
      const uploadPromise = new Promise(async (resolve, reject) => {
        try {
          const { url, key } = await getPresignedURl(file?.name, file?.type);
          uploadedFileWithKeys.push({ key, file });

          const uploadResponse = await fetch(url, {
            method: 'PUT',
            body: file,
          });

          if (!uploadResponse.ok) {
            throw new Error(`Failed to upload file ${file.name} to S3`);
          }

          resolve(`File ${file.name} uploaded successfully`);
        } catch (error) {
          console.error('Error uploading file:', error);
          reject(error);
        }
      });

      uploadMultipleImagesPromise.push(uploadPromise);
    }

    await Promise.all(uploadMultipleImagesPromise);
    return uploadedFileWithKeys;
  } catch (error) {
    console.error('Error uploading files:', error);
    throw error;
  }
};

export const getFileURL = (fileWithKeys: FileWithKeys[], file: File) => {
  const uploadedFile = fileWithKeys.find(
    (f) => f?.file?.name === file?.name && f?.file?.type === file?.type
  );

  if (uploadedFile) {
    return uploadedFile?.key;
  }

  return '';
};
