import Typography from '@/designComponents/Typography';

import { InformationRowWrapper } from '../style';

type Props = {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  region: string;
  phoneNumber: string;
  signCount: number;
  rememberSignAt?: string;
  currentSignAt: string;
  currentSignIP: string;
  lastSignAt: string;

  lastSignIP: string;

  updateAt: string;
  createdAt: string;
  professional?: string;
  supplier?: string;
  customer?: string;
  picPreview?: string;
  picUrl?: string;
};
const FreeUserInformation = ({
  createdAt,
  email,
  fullName,
  id,
  updateAt,
  firstName,
  lastName,
  currentSignAt,
  currentSignIP,
  lastSignAt,
  lastSignIP,
  phoneNumber,
  region,
  signCount,
  customer,
  picPreview,
  picUrl,
  professional,
  rememberSignAt,
  supplier,
}: Props) => {
  return (
    <InformationRowWrapper>
      <>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            ID
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {id}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Full Name
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {fullName}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            First Name
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {firstName}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Last Name
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {lastName}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Email
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {email}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Region
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {region}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Mobile Number
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {phoneNumber}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Sign In Count
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {signCount}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Remember Sign In At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {rememberSignAt || 'Empty'}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Current Sign In At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            {currentSignAt}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Current Sign In IP
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="primary"
            size="normal"
            style={{ textDecoration: 'none' }}
          >
            {currentSignIP}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Last Sign In At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            {lastSignAt}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Last Sign In IP
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="primary"
            size="normal"
            style={{ textDecoration: 'none' }}
          >
            {lastSignIP}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Update At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            {updateAt}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Created At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            {createdAt}
          </Typography>
        </div>

        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Professional
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {professional || 'Empty'}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Supplier
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {supplier || 'Empty'}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Customer
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {customer || 'Empty'}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Profile Picture Preview
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {picPreview || 'Empty'}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Profile Picture URL
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {picUrl || 'Empty'}
          </Typography>
        </div>
      </>
    </InformationRowWrapper>
  );
};

export default FreeUserInformation;
