import { adminReducers } from './admins';
import { adsReducer } from './advertise';
import { authReducers } from './auth';
import { buildingJourneyReducers } from './buildingJourney';
import { citiesReducers } from './cities';
import { dashboardReducer } from './dashboard';
import { documentReducers } from './documents';
import { downloadCenterReducer } from './downloadCenter';
import { formsReducer } from './forms';
import { hashTagReducer } from './hashTags';
import { productReducer } from './products';
import { storiesReducer } from './stories';
import { supplierReducers } from './suppliers';
import { userReducers } from './users';
import { howToVideoReducer } from './videos/HowToVideos';
import { howToVideoCategoryReducer } from './videos/HowToVideosCategory';
import { howToVideoCategoryClassReducer } from './videos/HowToVideosCategoryClass';
export default {
  auth: authReducers,
  documents: documentReducers,
  hashTags: hashTagReducer,
  products: productReducer,
  users: userReducers,
  forms: formsReducer,
  stories: storiesReducer,
  downloadCenterCategory: downloadCenterReducer,
  dashboard: dashboardReducer,
  ads: adsReducer,
  buildingJourney: buildingJourneyReducers,
  cities: citiesReducers,
  admins: adminReducers,
  suppliers: supplierReducers,
  howToVideos: howToVideoReducer,
  howToVideosCategoryClass: howToVideoCategoryClassReducer,
  howToVideosCategory: howToVideoCategoryReducer,
};
