export const staticParentData: Array<{
  _id: string;
  title?: string;
  status: string;
  level?: number;
  slug?: string;
  type?: string;
  region: string[];
  isHidden: boolean;
  __v: number;
  fullSlug?: string;
  updatedAt: string;
  isDeleted: boolean;
  uniqueId: number;
  uniqueSlug: string;
  firstDocPath?: string;
}> = [
  {
    _id: '66bc09fd769ac7250836699c',
    title: 'Go Green',
    status: 'ACTIVE',
    level: 0,
    slug: 'go-green',
    type: 'go-green',
    region: ['ie', 'uk'],
    isHidden: false,
    __v: 0,
    fullSlug: '/go-green',
    updatedAt: '2024-11-28T09:29:20.687Z',
    isDeleted: false,
    uniqueId: 1,
    uniqueSlug: '/1/go-green',
  },
  {
    _id: '66bc09fd769ac7250836699d',
    title: 'Detailed Construction Drawing',
    status: 'ACTIVE',
    level: 0,
    slug: 'construction-drawings',
    type: 'construction-drawing',
    region: [],
    isHidden: false,
    __v: 0,
    fullSlug: '/construction-drawings',
    updatedAt: '2024-11-28T09:29:20.687Z',
    isDeleted: false,
    uniqueId: 2,
    uniqueSlug: '/2/detailed-construction-drawing',
  },
  {
    _id: '66bc09fd769ac7250836699b',
    title: 'Regulations',
    status: 'ACTIVE',
    level: 0,
    slug: 'construction/building-regulations',
    type: 'regulation',
    region: ['ie', 'uk'],
    isHidden: false,
    __v: 0,
    fullSlug: '/construction/building-regulations',
    updatedAt: '2024-11-28T09:29:20.687Z',
    isDeleted: false,
    uniqueId: 3,
    uniqueSlug: '/3/regulations',
  },
  {
    _id: '66bc09fd769ac7250836699a',
    title: 'Guidelines',
    status: 'ACTIVE',
    level: 0,
    slug: 'construction/building-guidelines',
    type: 'guideline',
    region: ['ie', 'uk'],
    isHidden: false,
    __v: 0,
    fullSlug: '/construction/building-guidelines',
    updatedAt: '2024-11-28T09:29:20.687Z',
    isDeleted: false,
    uniqueId: 4,
    uniqueSlug: '/4/guidelines',
  },
  {
    _id: '66bc0a04769ac72508366b1e',
    title: 'Building System',
    status: 'ACTIVE',
    level: 0,
    slug: 'building-system',
    type: 'building-system',
    region: [],
    isHidden: false,
    __v: 0,
    fullSlug: '/building-system',
    updatedAt: '2024-11-28T09:29:20.692Z',
    isDeleted: false,
    uniqueId: 37,
    uniqueSlug: '/37/building-system',
  },
  {
    _id: '66bc0a08769ac72508366cb4',
    status: 'DRAFT',
    region: [],
    isHidden: false,
    __v: 0,
    firstDocPath: '',
    updatedAt: '2024-11-28T09:29:36.504Z',
    isDeleted: false,
    uniqueId: 74,
    uniqueSlug: '/74/',
  },
  {
    _id: '66bc0a08769ac72508366caa',
    status: 'DRAFT',
    region: [],
    isHidden: false,
    __v: 0,
    firstDocPath: '',
    updatedAt: '2024-11-28T09:29:36.505Z',
    isDeleted: false,
    uniqueId: 75,
    uniqueSlug: '/75/',
  },
];

const requiredParentData: string[] = [
  'go-green',
  'regulation',
  'guideline',
  'building-system',
];

export const newParentData = requiredParentData.map((item) => {
  const matchedItem = staticParentData.find((x) => x.type === item);
  return matchedItem
    ? {
        ...matchedItem,
      }
    : null;
});
