import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { PaginationType } from '@/apiConfigs/buildingJourney';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import Button from '@/designComponents/Button';
import { ButtonTab } from '@/designComponents/ButtonTab';
import Input from '@/designComponents/Input';
// import { ButtonTab } from '@/designComponents/ButtonTab';
import { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { BUILDING_ROUTE, BUILDING_STEP_ROUTE } from '@/routes/routePaths';
import { RootState, useAppDispatch } from '@/store';
import { getAllBuildngJourney } from '@/store/buildingJourney/functions';

import { debounce } from '../DownloadCenter';
import { SearchInputWrapperStyled } from '../Stories/style';
import AddBuildingJourneyModalContent from './AddBuildingJourneyModalContent';
import BuildingStep from './BuildingStep';
import { data } from './data';

type Props = {};

const BuildingJourney = (_props: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [cardKey, setCardKey] = useState<string>('building-journey');
  const [paginationParams, setPaginationParams] = useState<PaginationType>({
    page: 1,
    limit: 10,
    search: '',
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { buildingJourney, loading } = useSelector(
    (state: RootState) => state.buildingJourney
  );

  useEffect(() => {
    if (pathname.split('/').pop() === 'building-steps') {
      setCardKey('building-steps');
    }
  }, [pathname]);

  const fetchBuildingJourneyWhenParamsChange = useCallback(
    debounce((searchTerm) => {
      dispatch(
        getAllBuildngJourney({
          ...paginationParams,
          search: searchTerm || null,
        })
      );
    }, 300),
    [paginationParams]
  );

  useEffect(() => {
    fetchBuildingJourneyWhenParamsChange(paginationParams.search);
    return () => {
      fetchBuildingJourneyWhenParamsChange.cancel();
    };
  }, [dispatch, paginationParams]);

  useHeaderTitle('Building Journey');

  const cardData = [
    {
      title: 'Building Journey',
      key: 'building-journey',
      count: 4,
    },

    {
      title: 'Building Steps',
      key: 'building-steps',
      count: 6,
    },
  ];
  const dataArray = data();

  const tableData: TableDataType = {
    headings: [
      { key: 'name', label: 'Name' },
      { key: 'position', label: 'Position' },
      { key: 'slug', label: 'Slug' },
      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(buildingJourney?.data)
      ? buildingJourney?.data.map((building) => ({
          id: building._id,
          name: building.name,
          position: building.position,
          slug: building.slug,
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                onClick={() => navigate(building._id)}
              >
                View
              </Button>
            </div>
          ),
        }))
      : [],
  };

  const handleCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (limit: number) => {
    setPaginationParams({
      ...paginationParams,
      limit,
    });
  };

  const handleClick = (key: string) => {
    setCardKey(key);
    if (key === 'building-journey') {
      navigate(BUILDING_ROUTE);
    }
    if (key === 'building-steps') {
      navigate(BUILDING_ROUTE + '/' + BUILDING_STEP_ROUTE);
    }
  };

  const buildingJourneyTabs = (
    <ButtonTab
      items={cardData}
      activeIndex={cardData.findIndex((x) => x.key === cardKey)}
      setActiveIndex={(index) => handleClick(cardData[index].key)}
    />
  );
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <SearchInputWrapperStyled>
          <Input
            value={paginationParams.search}
            onChange={(e) => {
              const { value } = e.target;
              setPaginationParams({
                ...paginationParams,
                search: value,
              });
            }}
            placeholder="Search Journey"
            iconLeft={<SearchSVG />}
          />
        </SearchInputWrapperStyled>
        <AddBuildingJourneyModalContent />
      </div>

      {cardKey === 'building-steps' ? (
        <BuildingStep buildingJourneyTabs={buildingJourneyTabs} />
      ) : (
        <>
          <Table
            customRow={buildingJourneyTabs}
            tableData={tableData}
            noData="No data to display."
            showCheckBox={false}
            maxWidthForCardView="768px"
            headingBgColor="#f0f0f0"
            showMenuIcon={false}
            evenRowBgColor="#fafafa"
            isLoading={loading}
          />

          <Pagination
            activePage={currentPage}
            onCurrentPageChange={handleCurrentPage}
            limit={paginationParams.limit}
            onLimitChange={handleLimitChange}
            totalPages={Math.ceil(dataArray.length / 10)}
          />
        </>
      )}
    </div>
  );
};

export default BuildingJourney;
