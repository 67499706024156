import styled from 'styled-components';

export const MainLayoutStyled = styled.div``;

export const BodyStyled = styled.div<{}>`
  transition: all 0.5s;
  background-color: #fff;
  flex: 1;
  width: 100%;
  display: flex;
  min-height: 100vh;
`;

export const MainWrapperStyled = styled.main<{}>`
  padding: 30px;
  width: 100%;
`;

export const PublicLayoutStyled = styled.div`
  width: 100%;
`;

export const PublicBodyStyled = styled.div`
  transition: all 0.5s;
  background-color: #fff;
  flex: 1;
  width: 100%;
  /* min-height: 110vh; */
`;
