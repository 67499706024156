import Typography from '@/designComponents/Typography';

import { UserProfileWithImageWrapper } from '../../pages/Users/Details/style';

type Props = {
  imgUrl: string;
  name: string;
  email: string;
};
const UserProfileWIthImage = ({ email, imgUrl, name }: Props) => {
  return (
    <UserProfileWithImageWrapper>
      <img src={imgUrl} alt="user-image" className="img" />
      <div className="text">
        <Typography>{name}</Typography>
        <Typography>{email}</Typography>
      </div>
    </UserProfileWithImageWrapper>
  );
};

export default UserProfileWIthImage;
