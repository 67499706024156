import styled from 'styled-components';

export const AdvertiseDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;

  & .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & .heading {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    & .main-text {
      display: flex;
      flex-direction: column;
      gap: 12px;

      & .text {
        display: grid;
        grid-template-columns: 90px 1fr;
        align-items: center;
        gap: 32px;
      }
      & .text-value {
        font-family: 'roboto';
        font-weight: normal;
        color: ${({ theme }) => theme.colors.dark.neutral_450};
        font-size: 14px;
      }
    }
  }
`;
