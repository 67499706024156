import { ErrorMessage, Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import auth from '@/apiConfigs/auth';
import Eye from '@/assets/eye.svg';
import EyeSlash from '@/assets/eyeSlash.svg';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Typography from '@/designComponents/Typography';
import requestAPI from '@/utils/requestAPI';

import { Container, LoginWrapper } from './style';

type Props = {};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters long'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm Password is required'),
});

const UpdatePassword = (_props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    temporaryPassword: '',
    password: '',
    confirmPassword: '',
  };

  const handleUpdatePassword = async (data: typeof initialValues) => {
    setIsLoading(true);
    try {
      await requestAPI(
        auth.changePassword({
          temporaryPassword: data.temporaryPassword,
          password: data.password,
        })
      );
      setIsLoading(false);
      toast.success('Password updated successfully');
      navigate('/');
    } catch (error) {
      setIsLoading(false);
      toast.error('Failed to update password');
      console.error(error);
    }
  };

  return (
    <LoginWrapper>
      <Container>
        <Typography style={{ textAlign: 'center', marginBottom: '20px' }}>
          Set Your Password
        </Typography>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleUpdatePassword}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                <div>
                  <Input
                    name="temporaryPassword"
                    value={values.temporaryPassword}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) =>
                      setFieldValue('temporaryPassword', e.target.value)
                    }
                    placeholder="Enter temporary password"
                    iconRight={
                      <img
                        src={showPassword ? EyeSlash : Eye}
                        alt="Eye icon"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowPassword((prev) => !prev)}
                      />
                    }
                  />
                </div>

                <div>
                  <Input
                    name="password"
                    value={values.password}
                    onChange={(e) => setFieldValue('password', e.target.value)}
                    placeholder="Enter password"
                    type={showPassword ? 'text' : 'password'}
                    iconRight={
                      <img
                        src={showPassword ? EyeSlash : Eye}
                        alt="Eye icon"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowPassword((prev) => !prev)}
                      />
                    }
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    style={{ color: 'red', marginTop: '5px' }}
                  />
                </div>

                <div>
                  <Input
                    name="confirmPassword"
                    onChange={(e) =>
                      setFieldValue('confirmPassword', e.target.value)
                    }
                    placeholder="Confirm Password"
                    type="password"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    style={{ color: 'red', marginTop: '5px' }}
                  />
                </div>

                <Button
                  variant="primary"
                  type="submit"
                  isLoading={isLoading}
                  style={{ marginBottom: '20px', width: '100%' }}
                >
                  Update
                </Button>

                {errorMessage && (
                  <Typography style={{ color: 'red' }}>
                    {errorMessage}
                  </Typography>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </LoginWrapper>
  );
};

export default UpdatePassword;
