import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

export type PromoCodeChartsData = {
  name: string;
  PromoCodeUsed: number;
};

const data: Array<PromoCodeChartsData> = [
  {
    name: 'Jan',
    PromoCodeUsed: 300,
  },
  {
    name: 'Feb',
    PromoCodeUsed: 300,
  },
  {
    name: 'Mar',
    PromoCodeUsed: 900,
  },
  {
    name: 'Apr',
    PromoCodeUsed: 270,
  },
  {
    name: 'May',
    PromoCodeUsed: 190,
  },
  {
    name: 'Jun',
    PromoCodeUsed: 390,
  },
  {
    name: 'July',
    PromoCodeUsed: 390,
  },
  {
    name: 'Aug',
    PromoCodeUsed: 490,
  },
  {
    name: 'Sep',
    PromoCodeUsed: 990,
  },
  {
    name: 'Oct',
    PromoCodeUsed: 340,
  },
  {
    name: 'Nov',
    PromoCodeUsed: 490,
  },
  {
    name: 'Dec',
    PromoCodeUsed: 1000,
  },
];
const ChartContainer = styled.div`
  display: flex;
  width: 100%;
  height: 340px;
`;

type Props = {
  chartsData: Array<PromoCodeChartsData>;
};

const PromoCodeCharts = ({ chartsData }: Props) => {
  return (
    <ChartContainer>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <LineChart
          width={400}
          height={400}
          data={chartsData || data}
          margin={{
            top: 30,
            right: 0,
            left: 0,
          }}
        >
          <Line
            type="monotone"
            dataKey="PromoCodeUsed"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 'auto']} allowDataOverflow />
          <Tooltip />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default PromoCodeCharts;
