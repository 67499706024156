import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  website: Yup.string().required('Website is required'),
  email: Yup.string().required('Email is required'),
  avatar: Yup.string().required('Avatar is required'),
  // regions: Yup.array().of(Yup.string()).required('Regions are required'),
  inboundEmail: Yup.string().required('Inbound email is required'),
  isInbound: Yup.boolean(),
  // showroom: Yup.object().shape({
  //   title: Yup.string().required('Showroom title is required'),
  //   images: Yup.array().of(Yup.string()),
  //   logo: Yup.string().required('Showroom logo is required'),
  //   description: Yup.string().required('Showroom description is required'),
  // bottomBanner: Yup.object().shape({
  //   title: Yup.string().required('Bottom banner title is required'),
  //   subtitle: Yup.string().required('Bottom banner subtitle is required'),
  //   link: Yup.string()
  //     .url('Invalid URL')
  //     .required('Bottom banner link is required'),
  //   image1: Yup.string().required('Bottom banner image1 is required'),
  //   image2: Yup.string().required('Bottom banner image2 is required'),
  //   slug: Yup.string().required('Bottom banner slug is required'),
  // }),
});
// });
