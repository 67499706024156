import React from 'react';
import { useTheme } from 'styled-components';

import { ReactComponent as CardSVG } from '@/assets/icons/card.svg';
import Typography from '@/designComponents/Typography';

import { PaymentsWrapper } from '../../pages/Users/Details/style';
import Tag from './Tag';

type Props = {
  payments: Array<{
    price: string;
    status: 'completed' | 'inComplete';
    statusTitle: string;
    date: string;
    paymentFor: string;
  }>;
};
const Payments = ({ payments }: Props) => {
  const theme = useTheme();
  return (
    <>
      {payments.map((payment, idx) => (
        <React.Fragment key={idx}>
          <PaymentsWrapper>
            <CardSVG className="card" />
            <div className="text">
              <div className="price">
                <Typography
                  fontWeight="medium"
                  size="highlight"
                  color="dark.neutral_60"
                  fontFamily="roboto"
                >
                  {' '}
                  € {payment.price}
                </Typography>
                <Tag
                  title={payment.statusTitle}
                  variant={payment.status}
                  hidePoint
                />
              </div>
              <div>
                <Typography
                  color="dark.neutral_450"
                  fontWeight="normal"
                  fontFamily="roboto"
                  size="normal"
                >
                  Created on {payment.date}{' '}
                  <span style={{ color: theme.colors.dark.neutral_20 }}>•</span>{' '}
                  Payment for
                  <span>{payment.paymentFor}</span>
                </Typography>
              </div>
            </div>
          </PaymentsWrapper>
          {payments.length - 1 !== idx && (
            <hr
              style={{
                backgroundColor: theme.colors.dark.neutral_50,
                border: 'none',
                height: '1px',
              }}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default Payments;
