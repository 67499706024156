import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';

import { ReactComponent as AddFileSVG } from '@/assets/icons/add-file.svg';
import { ReactComponent as CloseXSVG } from '@/assets/icons/close-x-red.svg';
import { ReactComponent as ImageSVG } from '@/assets/icons/Image.svg';
import { ReactComponent as PDFSVG } from '@/assets/icons/pdf-icon.svg';
import { ReactComponent as TrashSVG } from '@/assets/icons/trash.svg';

import Input from '../Input';
import Typography from '../Typography';
import {
  ImageContainer,
  ImagePreviewWrapper,
  UploadFileWrapper,
} from './style';

type Props = {
  formikProps?: FormikProps<any>;
  title: string;
  name: string;
  onChange?: (value: File[] | File | null) => void;
  variant?: 'icon' | 'previewImage';
  enableMultipleFile?: boolean;
  onRemove: (index?: number) => void;
  files?: File[] | File | null;
  acceptedFiles?: string;
  apiUrls?: string[];
};

const ACCEPTED_FILE_TYPES = 'image/jpeg, image/png, image/jpg';

export const createUrlForFile = (passedFiles: File[]) => {
  const urls = passedFiles?.map((file) => URL.createObjectURL(file));
  return urls;
};

const UploadFile = ({
  formikProps,
  title,
  name,
  onChange,
  variant = 'icon',
  enableMultipleFile = false,
  onRemove,
  files,
  acceptedFiles = ACCEPTED_FILE_TYPES,
  apiUrls = [],
}: Props) => {
  const [imagePreviewUrls, setImagePreviewUrls] = useState<string[]>([]);
  const [filesAdd, setFilesAdd] = useState<File[]>([]);

  useEffect(() => {
    const updateImageUrls = () => {
      const fileUrls = createUrlForFile(filesAdd);
      setImagePreviewUrls([...apiUrls, ...fileUrls]);
    };

    updateImageUrls();
  }, [filesAdd, apiUrls]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    if (fileInput.files) {
      const selectedFiles = Array.from(fileInput.files);

      if (enableMultipleFile) {
        setFilesAdd((prevFiles) => {
          const newFiles = selectedFiles.filter(
            (file) => !prevFiles.some((prevFile) => prevFile.name === file.name)
          );
          return [...prevFiles, ...newFiles];
        });
      } else {
        setFilesAdd([selectedFiles[0]]);
      }
      onChange?.(enableMultipleFile ? selectedFiles : selectedFiles[0] || null);

      if (formikProps) {
        formikProps.setFieldValue(
          name,
          enableMultipleFile ? selectedFiles : selectedFiles[0]
        );
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const fileInput = document.getElementById(
      `upload-file-${name}`
    ) as HTMLInputElement;
    if (fileInput && event.dataTransfer.files.length) {
      fileInput.files = event.dataTransfer.files;
      const changeEvent = new Event('change', { bubbles: true });
      fileInput.dispatchEvent(changeEvent);
    }
  };

  const handleFileInputClick = () => {
    const uploadInput = document.getElementById(
      `upload-file-${name}`
    ) as HTMLInputElement;
    if (uploadInput) uploadInput.click();
  };

  const handleRemoveImage = (index?: number) => {
    if (index < apiUrls.length) {
      // Removing API URL
      const updatedApiUrls = apiUrls.filter((_, i) => i !== index);
      onRemove(index);
      setImagePreviewUrls([...updatedApiUrls, ...createUrlForFile(filesAdd)]);
    } else {
      // Removing newly added file
      const fileIndex = index - apiUrls.length;
      const updatedFiles = filesAdd.filter((_, i) => i !== fileIndex);
      setFilesAdd(updatedFiles);
      setImagePreviewUrls([...apiUrls, ...createUrlForFile(updatedFiles)]);
      onRemove(index);
    }

    if (!enableMultipleFile) {
      setFilesAdd([]);
    }

    if (formikProps) {
      const updatedFiles = enableMultipleFile
        ? [...apiUrls, ...filesAdd].filter((_, i) => i !== index)
        : null;
      formikProps.setFieldValue(name, updatedFiles);
    }
  };

  return (
    <>
      <Typography
        fontFamily="roboto"
        size="normal"
        fontWeight="medium"
        color="dark.neutral_45"
      >
        {title}
      </Typography>
      <UploadFileWrapper
        onClick={handleFileInputClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div style={{ visibility: 'hidden', position: 'absolute' }}>
          <Input
            type="file"
            id={`upload-file-${name}`}
            onChange={handleFileChange}
            accept={acceptedFiles}
            multiple={enableMultipleFile}
          />
        </div>
        <div>
          {variant === 'previewImage' ? (
            <ImageContainer>
              {imagePreviewUrls.length > 0 ? (
                <>
                  {imagePreviewUrls.map((img, idx) => (
                    <ImagePreviewWrapper key={idx}>
                      {acceptedFiles === '.pdf' ? (
                        <PDFSVG width={110} height={110} />
                      ) : (
                        <img src={img} alt="images" className="images" />
                      )}
                      <CloseXSVG
                        className="close"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveImage(idx);
                        }}
                      />
                    </ImagePreviewWrapper>
                  ))}
                  <AddFileSVG />
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <ImageSVG />
                  <Typography
                    fontFamily="roboto"
                    fontWeight="normal"
                    size="normal"
                    color="dark.neutral_40"
                  >
                    Choose a photo or upload multiple photos at once here.
                  </Typography>
                </div>
              )}
            </ImageContainer>
          ) : (
            <>
              {files && !Array.isArray(files) ? (
                <div style={{ display: 'flex', gap: '40px' }}>
                  <Typography>{files.name}</Typography>
                  <TrashSVG
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveImage();
                    }}
                  />
                </div>
              ) : formikProps?.values[name] &&
                !Array.isArray(formikProps.values[name]) ? (
                <div style={{ display: 'flex', gap: '40px' }}>
                  <Typography>{formikProps.values[name]?.name}</Typography>
                  <TrashSVG
                    onClick={(e) => {
                      e.stopPropagation();
                      formikProps.setFieldValue(name, null);
                      handleRemoveImage();
                    }}
                  />
                </div>
              ) : (
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  size="normal"
                  color="dark.neutral_40"
                >
                  Choose a file or drag and drop here
                </Typography>
              )}
            </>
          )}
        </div>
      </UploadFileWrapper>
    </>
  );
};

export default UploadFile;
