import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { paginationType } from '@/apiConfigs/users';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import TableSearchTop from '@/components/TableSearchTop';
import Button from '@/designComponents/Button';
import { ButtonTab } from '@/designComponents/ButtonTab';
import { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { RootState, useAppDispatch } from '@/store';
import { getAllCities } from '@/store/cities/functions';

type Props = {};

const Cities = (_props: Props) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [paginationParams, setPaginationParams] = useState<paginationType>({
    page: 1,
    limit: 10,
  });
  const usersState = useSelector((state: RootState) => state.users);
  const { users, pagination } = usersState;
  const [searchValue, setSearchValue] = useState('');
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    dispatch(getAllCities(paginationParams));
  }, [dispatch, paginationParams]);

  const tableData: TableDataType = {
    headings: [
      { key: 'name', label: 'name' },
      { key: 'region', label: 'Region' },
      { key: 'professionalCount', label: 'Professionals' },
      { key: 'jobPostsCount', label: 'Job Posts' },
      { key: 'users', label: 'Users' },
      { key: 'date', label: 'Created at' },
      {
        key: 'actions',
        label: '',
      },
    ],
    body: Array.isArray(users)
      ? users?.map((user) => ({
          id: user._id,
          name: (
            <Link to={`/users/${user._id}`}>
              {user.firstName} {user.lastName}
            </Link>
          ),
          email: user.email,
          loginCount: user.loginCount,
          date: moment(user.createdAt).format('MMM DD, YYYY  HH:mm'),
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                onClick={() => navigate(user._id)}
              >
                Edit
              </Button>
              <Button variant="danger" radius="normal">
                Delete
              </Button>
            </div>
          ),
        }))
      : [],
  };

  const handleCurrentPage = (page: number) => {
    setPaginationParams((prev) => ({ ...prev, page: page }));
  };

  const handleLimitChange = (limit: number) => {
    setPaginationParams((prev) => ({ ...prev, page: 1, limit }));
  };
  const handleNextPage = () => {
    if (
      paginationParams.page &&
      pagination?.totalPages &&
      paginationParams?.page < pagination.totalPages
    ) {
      handleCurrentPage(paginationParams.page + 1);
    }
  };
  const handleLastPage = () => {
    if (
      pagination?.totalPages &&
      paginationParams.page !== pagination.totalPages
    ) {
      handleCurrentPage(pagination.totalPages);
    }
  };

  useHeaderTitle('Video categories');

  return (
    <div>
      <div style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TableSearchTop
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            searchData={[]}
            hidebadgeData
            searchText="Search professional"
            hideResultIcon
            inputPlaceHolder="Search by name"
          />
          <ButtonTab
            items={[
              {
                title: 'All',
                count: 15000,
              },
              {
                title: 'Ireland',
                count: 15000,
              },
              {
                title: 'UK',
                count: 15000,
              },
            ]}
            activeIndex={activeTabIndex}
            setActiveIndex={(index) => setActiveTabIndex(index)}
          />
        </div>
      </div>
      <Table
        tableData={tableData}
        noData="No data to display."
        showCheckBox={false}
        maxWidthForCardView="768px"
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
      />

      <Pagination
        activePage={paginationParams.page || 1}
        onCurrentPageChange={handleCurrentPage}
        totalPages={pagination?.totalPages || 1}
        limit={paginationParams.limit || 10}
        onLimitChange={handleLimitChange}
        onNext={handleNextPage}
        onLast={handleLastPage}
      />
    </div>
  );
};

export default Cities;
