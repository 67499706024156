/* eslint-disable */

import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EditorState } from 'lexical';
import React, { useEffect } from 'react';

type Props = {
  onChange: (data: { editorState: EditorState; html: string }) => void;
};

const CustomOnChangePlugin = ({ onChange }: Props) => {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(
      ({ editorState }) => {
        editorState.read(() => {
          const htmlString = $generateHtmlFromNodes(editor, null);

          onChange({ editorState, html: htmlString });
        });
      }
    );
    return () => {
      removeUpdateListener();
    };
  }, [editor]);

  return null;
};

export default CustomOnChangePlugin;
