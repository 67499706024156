import { NavigateFunction } from 'react-router-dom';

import Button from '@/designComponents/Button';

export type InitialValuesType = {
  documentTitle: string;
  documentType: string;
  parentTitle: string;
  icon: File[] | null;
  type: string;
  jurisdictions: string[];
};

export const breadCrumbData: Array<{ label: string; path: string }> = [
  {
    label: 'Go Green',
    path: '/',
  },
  {
    label: 'All Category',
    path: '/',
  },
  {
    label: 'Category Details',
    path: '/',
  },
];
export const dropDownOption: Array<{
  value: string;
  label: string;
}> = [
  {
    label: 'Go Green',
    value: 'go-green',
  },
  {
    label: 'Building Guides',
    value: 'building-guide',
  },
  {
    label: 'Building System',
    value: 'building-system',
  },
];

export const data = (navigate: NavigateFunction) => [
  {
    id: '1',
    name: 'Structure – Loading and ground movement part 2012012000122',
    documentType: 'Go Green',
    parentTitle: 'NZEB Subcategories',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '2',
    name: 'Initial Investigation Part 300000',
    documentType: 'Resources',
    parentTitle: 'Resources',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '3',
    name: 'Floor Insulation',
    documentType: 'Building Guides',
    parentTitle: 'NZEB Categories',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    name: 'Roofing Systems Overview 400123',
    documentType: 'Building Materials',
    parentTitle: 'Construction',
    date: '16 may 2020 12:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '5',
    name: 'Sustainable Building Practices 500678',
    documentType: 'Green Building',
    parentTitle: 'Sustainability',
    date: '17 may 2020 10:00 am',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '6',
    name: 'Energy Efficiency Standards 600234',
    documentType: 'Regulations',
    parentTitle: 'Energy Policies',
    date: '18 may 2020 09:00 am',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '7',
    name: 'Waterproofing Techniques 700345',
    documentType: 'Technical Guides',
    parentTitle: 'Construction Details',
    date: '19 may 2020 11:00 am',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '8',
    name: 'Thermal Insulation Analysis 800456',
    documentType: 'Research Papers',
    parentTitle: 'Insulation Techniques',
    date: '20 may 2020 01:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '9',
    name: 'Acoustic Insulation in Buildings 900567',
    documentType: 'Technical Notes',
    parentTitle: 'Building Acoustics',
    date: '21 may 2020 03:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '10',
    name: 'Building Code Compliance 1000789',
    documentType: 'Compliance Documents',
    parentTitle: 'Regulations',
    date: '22 may 2020 05:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '11',
    name: 'Fire Safety Measures 1100890',
    documentType: 'Safety Guidelines',
    parentTitle: 'Safety Standards',
    date: '23 may 2020 07:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '12',
    name: 'Green Roof Systems 1200123',
    documentType: 'Sustainable Practices',
    parentTitle: 'Green Building',
    date: '24 may 2020 08:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '13',
    name: 'Solar Panel Installation 1300456',
    documentType: 'Installation Guides',
    parentTitle: 'Renewable Energy',
    date: '25 may 2020 09:00 am',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '14',
    name: 'Advanced HVAC Systems 1400567',
    documentType: 'Mechanical Systems',
    parentTitle: 'HVAC',
    date: '26 may 2020 11:00 am',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '15',
    name: 'Foundation Types and Designs 1500678',
    documentType: 'Structural Engineering',
    parentTitle: 'Foundations',
    date: '26 may 2020 11:00 am',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal">
          View
        </Button>
        <Button variant="gray" radius="normal">
          Edit
        </Button>
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
];
