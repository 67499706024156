import { useEffect } from 'react';

import { useRoot } from '@/context/RootProvider';

const useHeaderTitle = (title: string | React.ReactNode) => {
  const { setHeaderTitle } = useRoot();

  useEffect(() => {
    setHeaderTitle(title);
    return () => {
      setHeaderTitle(null);
    };
  }, [setHeaderTitle, title]);

  return null;
};

export default useHeaderTitle;
