import { CSSProperties } from 'react';
import { useTheme } from 'styled-components';

import { ReactComponent as TimeSVG } from '@/assets/icons/time.svg';

import { TagWrapper } from '../../pages/Users/Details/style';

type Props = {
  variant?: 'active' | 'danger' | 'completed' | 'inComplete';
  style?: CSSProperties;
  title: string;
  hidePoint?: boolean;
};

const Tag = ({
  variant = 'active',
  style,
  title,
  hidePoint = false,
}: Props) => {
  const theme = useTheme();
  const applyColor = () => {
    switch (variant) {
      case 'active':
        return {
          backgroundColor: theme.colors.activeBg,
          color: theme.colors.colorActive,
        };
      case 'danger':
        return {
          backgroundColor: theme.colors.dangerBg,
          color: theme.colors.colorDanger,
        };
      case 'completed':
        return {
          backgroundColor: theme.colors.blue_e8,
          color: theme.colors.primary,
        };
      case 'inComplete':
        return {
          backgroundColor: theme.colors.dark.neutral_50,
          color: theme.colors.dark.neutral_450,
        };

      default:
        return {
          backgroundColor: theme.colors.light.white,
          color: theme.colors.dark.neutral_450,
        };
    }
  };
  return (
    <TagWrapper style={{ ...applyColor(), ...style }}>
      <p
        style={{
          fontSize: theme.fontSize.subtitle,
          fontFamily: theme.fontFamily.roboto,
          fontWeight: theme.fontWeights.normal,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        {!hidePoint && <span>•</span>} {title}{' '}
        {variant === 'inComplete' && <TimeSVG />}
        {variant === 'completed' && <span>✓</span>}
      </p>
    </TagWrapper>
  );
};

export default Tag;
