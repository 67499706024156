import styled from 'styled-components';

const LoaderWrapper = styled.div`
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 0.8;
    }
    50% {
      opacity: 0.2;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;

  .spinner {
    position: relative;
    width: 40px;
    height: 40px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      border: 3px solid transparent;
    }

    &:before {
      border-top-color: #000;
      border-right-color: #000;
      animation: rotate 0.8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }

    &:after {
      border-bottom-color: rgba(0, 0, 0, 0.3);
      border-left-color: rgba(0, 0, 0, 0.3);
      animation: rotate 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
      width: 75%;
      height: 75%;
      top: 12.5%;
      left: 12.5%;
    }
  }
`;

export default LoaderWrapper;
