import { Form, Formik } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as Yup from 'yup';

import admins, { AdminData } from '@/apiConfigs/admins';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Modal from '@/designComponents/Modal';
import Typography from '@/designComponents/Typography';
import {
  BottomContainerStyled,
  ModalContainerStyled,
} from '@/pages/DownloadCenter/style';
import theme from '@/theme';
import requestAPI from '@/utils/requestAPI';

type Props = {
  isOpenModal: boolean;
  onClose: () => void;
};

export const NewAdminWrapperStyled = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;
export const InfoRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const AdminSchema = Yup.object().shape({
  firstName: Yup.string()

    .required('First name is required'),
  lastName: Yup.string()

    .required('Last name is required'),
  email: Yup.string().required('Email is required'),
});

const AddAdmin = ({ isOpenModal, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValues, _setInitialValues] = useState<AdminData>({
    firstName: '',
    lastName: '',
    email: '',
  });

  const handleAddAdmin = async (val: AdminData) => {
    setIsLoading(true);
    try {
      await requestAPI(
        admins.AddAdmin({
          firstName: val.firstName,
          lastName: val.lastName,
          email: val.email,
        })
      );
      setIsLoading(false);
      onClose();
      toast.success('Admin added successfully');
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  return (
    <ModalContainerStyled>
      <Modal
        isOpen={isOpenModal}
        onClose={onClose}
        title="Create New Admin"
        halfWidth={true}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={AdminSchema}
          enableReinitialize
          onSubmit={handleAddAdmin}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <NewAdminWrapperStyled>
                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      First Name <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="firstName"
                      value={values.firstName}
                      onChange={(e) =>
                        setFieldValue('firstName', e.target.value)
                      }
                    />
                    {errors.firstName && touched.firstName && (
                      <div style={{ color: 'red' }}>{errors.firstName}</div>
                    )}
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Last Name <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="lastName"
                      value={values.lastName}
                      onChange={(e) =>
                        setFieldValue('lastName', e.target.value)
                      }
                    />
                    {errors.lastName && touched.lastName && (
                      <div style={{ color: 'red' }}>{errors.lastName}</div>
                    )}
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Email <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="email"
                      value={values.email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                    />
                    {errors.email && touched.email && (
                      <div style={{ color: 'red' }}>{errors.email}</div>
                    )}
                  </InfoRowStyled>
                </NewAdminWrapperStyled>
                {errorMessage && (
                  <Typography style={{ color: 'red' }}>
                    {errorMessage}
                  </Typography>
                )}

                <BottomContainerStyled>
                  <Button
                    radius="md"
                    variant="ghost"
                    style={{
                      border: `1px solid ${theme.colors.dark.neutral_250}`,
                      borderRadius: '10px',
                      color: theme.colors.text,
                      fontFamily: theme.fontFamily.roboto,
                      fontWeight: theme.fontWeights.medium,
                    }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    radius="normal"
                    isLoading={isLoading}
                    style={{ backgroundColor: theme.colors.brandColorBlue }}
                  >
                    Create
                  </Button>
                </BottomContainerStyled>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </ModalContainerStyled>
  );
};

export default AddAdmin;
