import './style.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';

import Typography from '../Typography';

type Options = {
  label: string;
  value: string;
  otherFields?: {
    [key: string]: string;
  };
};

type SearchInputDropdownProps = {
  label?: string;
  onSearch?: (val: string) => void;
  options?: Options[];
  searchTerm?: string;
  onOptionSelect?: (
    label: string,
    otherFields?: Options['otherFields']
  ) => void;
  selectedLabel?: string;
  rightIcon?: React.ReactNode;
  placeholder?: string;
  placeholderTextColor?: string;
  onReset?: () => void;
  isOptionsLoading?: boolean;
  containerStyle?: React.CSSProperties;
};

export function SearchInputWithDropdown({
  label,
  onSearch,
  options = [],
  searchTerm = '',
  onOptionSelect,
  selectedLabel,
  rightIcon,
  placeholder = 'Search',
  onReset,
  isOptionsLoading = false,
  containerStyle,
}: SearchInputDropdownProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [inputValue, setInputValue] = useState(
    searchTerm || selectedLabel || ''
  );
  const handleFocus = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleSearch = useCallback(
    (search: string) => {
      setInputValue(search);
      onSearch?.(search);
      setIsDropdownVisible(!!search);
    },
    [onSearch]
  );

  const handleOptionSelect = useCallback(
    (val: string, otherFields: Options['otherFields']) => {
      setInputValue('');
      onOptionSelect?.(val, otherFields);
      setIsDropdownVisible(false);
    },
    [onOptionSelect]
  );

  const handleReset = () => {
    setInputValue('');
    onSearch?.('');
    onReset?.();
    if (isDropdownVisible) {
      setIsDropdownVisible(false);
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutSide = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  }, []);

  return (
    <div
      className="search-input-container"
      ref={containerRef}
      style={containerStyle}
    >
      {label && <Typography>{label}</Typography>}
      <div className="input-container" onClick={handleFocus}>
        <div
          className={`input-wrapper`}
          style={{
            borderColor: isFocused ? '#3B82F6' : '#D1D5DB',
            borderRadius: '6px',
          }}
        >
          <FaSearch size={22} color={isFocused ? '#2563EB' : '#9CA3AF'} />
          <input
            ref={inputRef}
            className="text-input"
            style={{ color: 'black' }}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            value={inputValue}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={placeholder}
          />
          {inputValue ? (
            <FaTimes size={22} color="#2563EB" onClick={handleReset} />
          ) : (
            rightIcon
          )}
        </div>
        {isDropdownVisible && (
          <div className="dropdown">
            {options.length > 0 ? (
              options.map((item, index) => (
                <div
                  key={item.value + index}
                  className="option"
                  onClick={() =>
                    handleOptionSelect(item.value, item.otherFields)
                  }
                >
                  <span
                    className="option-text"
                    style={{
                      fontWeight: item.label === selectedLabel ? '600' : '400',
                      color: item.label === selectedLabel ? '#005c00' : '#444',
                    }}
                  >
                    {item.label}
                  </span>
                </div>
              ))
            ) : isOptionsLoading ? (
              <p className="loading">Loading result...</p>
            ) : (
              <div className="no-result">
                <Typography>No result</Typography>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
