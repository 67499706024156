import Typography from '@/designComponents/Typography';

import { InformationRowWrapper } from '../style';

type Props = {
  fullName: string;
  planName: string;
  interval: string;
  viewedDrawings: number;
  viewedRegulations: number;
  viewedGuidelines: number;
  viewedDownloadResources: number;
  resetAt: string;
  createdAt: string;
  updateAt: string;
  previousPlan?: string;
  status?: string;
};
const SubscribedUserInformation = ({
  createdAt,
  fullName,
  updateAt,
  interval,
  planName,
  resetAt,
  viewedDownloadResources,
  viewedDrawings,
  viewedGuidelines,
  viewedRegulations,
  previousPlan,
  status,
}: Props) => {
  return (
    <InformationRowWrapper>
      <>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            User
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {fullName}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Name
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {planName}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Interval
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {interval}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Viewed Construction Drawings
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {viewedDrawings}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Viewed Regulations
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {viewedRegulations}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Viewed Guidelines
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {viewedGuidelines}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Viewed Download Resources
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {viewedDownloadResources}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Reset At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            {resetAt}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Created At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            {createdAt}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Update At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            {updateAt}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Previous Plan
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {previousPlan || 'Empty'}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Status
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {status || 'Empty'}
          </Typography>
        </div>{' '}
      </>
    </InformationRowWrapper>
  );
};

export default SubscribedUserInformation;
