import React, { useState } from 'react';

import DropdownButton from '@/designComponents/DropdownButton';

import AddHowToVideosCategory from '../HowToVideosCategory/AddHowToVideosCategory';
import AddHowToVideos from './AddHowToVideos';

const HowToVideos = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <React.Fragment>
      <DropdownButton
        options={[
          {
            label: 'Add Video',
            modalContent: {
              title: 'How To Videos',
              modalChildren: AddHowToVideos,
            },
          },
          {
            label: 'Add Video Category',
            modalContent: {
              title: 'Video Category',
              modalChildren: AddHowToVideosCategory,
            },
          },
          {
            label: 'Add Video Content Section',
            modalContent: {
              title: 'Video Content Section',
              modalChildren: AddHowToVideosCategory,
            },
          },
        ]}
        title="New Video"
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </React.Fragment>
  );
};

export default HowToVideos;
