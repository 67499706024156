import { createSlice } from '@reduxjs/toolkit';

import {
  addBuildingJourney,
  getAllBuildngJourney,
  getAllBuildngSteps,
} from './functions';

type BuildingJourneyData = {
  data: Array<{
    _id: string;
    prevId: string;
    name: string;
    slug: string;
    buildingSteps: string[];
    position: number;
    __v: number;
    createdAt: string;
    updatedAt: string;
  }>;

  pagination: {
    status: boolean;
    total: number;
    totalPages: number;
    currentPage: number;
    limit: string;
  };
};

type InitialState = {
  buildingJourney: BuildingJourneyData | null;
  buildingSteps: { data: any[]; pagination: any } | null;
  buildingJourneyDetail: null;
  error: string | null;
  loading: boolean;
};

const initialState: InitialState = {
  buildingJourney: null,
  buildingSteps: null,
  buildingJourneyDetail: null,
  error: null,
  loading: false,
};

const buildingJourneySlice = createSlice({
  name: 'buildingJourney',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addBuildingJourney.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      addBuildingJourney.fulfilled,
      (state: typeof initialState, action) => {
        state.loading = false;
        state.error = null;
        state.buildingJourney = action.payload;
      }
    );
    builder.addCase(addBuildingJourney.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to add Building Journey. Try again later.';
    });
    builder.addCase(getAllBuildngSteps.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllBuildngSteps.fulfilled,
      (state: typeof initialState, action) => {
        state.loading = false;
        state.error = null;
        state.buildingSteps = action.payload;
      }
    );
    builder.addCase(getAllBuildngSteps.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to get Building Journey Steps. Try again later.';
    });
    builder.addCase(getAllBuildngJourney.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllBuildngJourney.fulfilled,
      (state: typeof initialState, action) => {
        state.loading = false;
        state.error = null;
        state.buildingJourney = action.payload;
      }
    );
    builder.addCase(getAllBuildngJourney.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to get Building Journey. Try again later.';
    });
  },
});

export const buildingJourneyReducers = buildingJourneySlice.reducer;
