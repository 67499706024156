import React from 'react';

import Typography from '@/designComponents/Typography';

import Tag from '../../../../components/UserDetails/Tag';
import { InformationRowWrapper } from '../style';

type Props = {
  newsConsentStatus: 'active';
  newsConsentTitle: string;
  email: string;
  createdAt: string;
  updateAt: string;
  userType: string;
};
const NewsLetterUserInformation = ({
  createdAt,
  email,
  updateAt,
  newsConsentStatus,
  newsConsentTitle,
  userType,
}: Props) => {
  return (
    <InformationRowWrapper>
      <>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Email
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {email}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            User Type
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {userType}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Created At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            {createdAt}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Update At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            {updateAt}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Newsletter Consent
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tag
              title={newsConsentTitle}
              hidePoint
              variant={newsConsentStatus}
              style={{ borderRadius: '4px' }}
            />
          </div>
        </div>
      </>
    </InformationRowWrapper>
  );
};

export default NewsLetterUserInformation;
