import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import TableSearchTop from '@/components/TableSearchTop';
import { searchData } from '@/components/TableSearchTop/data';
import CardTab from '@/designComponents/CardTab';
import { TableDataType } from '@/designComponents/Table';
import Typography from '@/designComponents/Typography';

import { data } from './data';

type Props = {};

const Professionals = (_props: Props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [searchValue, setSearchValue] = useState('');

  const [index, setIndex] = useState<number>(0);
  const navigate = useNavigate();

  const cardData = [
    {
      title: 'Professionals Users',
      count: 492,
    },
    {
      title: 'Free Users',
      count: 1000,
    },
    {
      title: 'Subscribed Plans',
      count: 1000,
    },
    {
      title: 'Delete Request',
      count: 492,
    },
    {
      title: 'Newsletter Users',
      count: 492,
    },
  ];

  const lastIndex = currentPage * 10;
  const firstIndex = lastIndex - 10;
  const currentRecords = data && data(navigate).slice(firstIndex, lastIndex);

  const tableData: TableDataType = {
    headings: [
      { key: 'professional', label: 'Professional' },
      { key: 'professionalEmail', label: 'Professional Email' },
      { key: 'name', label: 'Name' },
      { key: 'enquiryType', label: 'Enquiry Type' },
      { key: 'email', label: 'Email' },
      // { key: 'date', label: 'created At' },
      { key: 'actions', label: 'Actions' },
    ],
    body: currentRecords,
  };

  const handleCurrentPage = (page: number) => {
    setCurrentPage(page);
  };
  const handleNextPage = (page: number) => {
    setCurrentPage(page);
  };
  const handleLastPage = (page: number) => {
    setCurrentPage(page);
  };
  const handleClick = (i: number) => {
    setIndex(i);
  };
  return (
    <div>
      <Typography
        size="h4"
        color="dark.neutral_60"
        style={{ marginBottom: '30px' }}
      >
        Advertisements
      </Typography>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '40px' }}>
        {' '}
        {cardData.map((card, idx) => (
          <CardTab
            title={card.title}
            count={card.count}
            key={idx}
            isActive={index === idx}
            onClick={() => handleClick(idx)}
          />
        ))}
      </div>
      <div style={{ marginBottom: '30px' }}>
        <TableSearchTop
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchData={searchData}
          hidebadgeData
          searchText="Search professional"
          hideResultIcon
          inputPlaceHolder="Search professional..."
        />
      </div>
      <Table
        tableData={tableData}
        noData="No data to display."
        showCheckBox={false}
        maxWidthForCardView="768px"
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
      />

      <Pagination
        activePage={currentPage}
        onCurrentPageChange={handleCurrentPage}
        onLast={handleLastPage}
        onNext={handleNextPage}
        totalPages={Math.ceil(data(navigate).length / 10)}
      />
    </div>
  );
};

export default Professionals;
