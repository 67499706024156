import styled from 'styled-components';

export const TableStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
  &:hover {
    background-color: #f1f1f1;
  }
`;

export const TableData = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
  &:first-child {
    border-right: 1px solid #ddd;
    font-weight: bold;
    background-color: #f4f4f4;
  }
`;

export const TableHeader = styled.th`
  padding: 12px 15px;
  background-color: #f4f4f4;
  border-bottom: 2px solid #ddd;
  text-align: left;
  font-weight: bold;
`;
