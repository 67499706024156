import { createSlice } from '@reduxjs/toolkit';

import {
  addHowToVideosCategoryClass,
  getAllHowToVideosCategoryClass,
  getSingleHowToVideosCategoryClass,
} from './functions';

export type Videos = {
  data: {
    name: string;
    _id: string;
    createdAt: string;
    updatedAt: string;
    slug: string;
    __v: number;
  }[];
  pagination: {
    status: boolean;
    total: number;
    totalPages: number;
    currentPage: number;
    limit: string;
  };
};

export type SingleVideo = {
  _id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
type InitialState = {
  videos: Videos | null;
  singleVideo: SingleVideo | null;
  error: string | null;
  loading: boolean;
  pagination: {
    total: number;
    limit: number;
    currentPage: number;
    totalPages: number;
  };
};

const initialState: InitialState = {
  videos: null,
  singleVideo: null,
  error: null,
  loading: false,
  pagination: {
    total: 0,
    limit: 11,
    currentPage: 0,
    totalPages: 1,
  },
};

const howToVideoCategoryClassSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addHowToVideosCategoryClass.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      addHowToVideosCategoryClass.fulfilled,
      (state: typeof initialState, action) => {
        state.loading = false;
        state.error = null;
        state.videos = action.payload;
      }
    );
    builder.addCase(addHowToVideosCategoryClass.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to add How To Videos. Try again later.';
    });

    builder.addCase(getAllHowToVideosCategoryClass.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllHowToVideosCategoryClass.fulfilled,
      (state: typeof initialState, action) => {
        state.loading = false;
        state.error = null;
        state.videos = action.payload;
      }
    );
    builder.addCase(
      getAllHowToVideosCategoryClass.rejected,
      (state, action) => {
        state.loading = false;

        state.error =
          (typeof action.payload === 'string' && action.payload) ||
          'Failed to add How To Videos. Try again later.';
      }
    );
    builder.addCase(getSingleHowToVideosCategoryClass.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getSingleHowToVideosCategoryClass.fulfilled,
      (state: typeof initialState, action) => {
        state.loading = false;
        state.error = null;
        state.singleVideo = action.payload;
      }
    );
    builder.addCase(
      getSingleHowToVideosCategoryClass.rejected,
      (state, action) => {
        state.loading = false;

        state.error =
          (typeof action.payload === 'string' && action.payload) ||
          'Failed to add How To Videos. Try again later.';
      }
    );
  },
});

export const howToVideoCategoryClassReducer =
  howToVideoCategoryClassSlice.reducer;
