import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { subscriptionApi } from '@/apiConfigs/subscription';
import users from '@/apiConfigs/users';
import PaymentHistory from '@/components/UserDetails/PaymentHistory';
import Avatar from '@/designComponents/Avatar';
import Button from '@/designComponents/Button';
import Divider from '@/designComponents/Divider';
import Loader from '@/designComponents/Loader';
import Typography from '@/designComponents/Typography';
import { RootState, useAppDispatch } from '@/store';
import { PaymentHistoryData } from '@/store/users';
import { getSingleUsers } from '@/store/users/functions';
import { getRespectiveRegion } from '@/utils/function.utils';
import requestAPI from '@/utils/requestAPI';

import BusinessDetails from '../../../../components/UserDetails/BusinessDetails';
import CrumbsWithButton from '../../../../components/UserDetails/CrumbsWithButton';
import HeadingTitle from '../../../../components/UserDetails/HeadingTitle';
import Subscription from '../../../../components/UserDetails/Subscription';
import ChangeSubscriptionModal, {
  InitialValue,
} from '../../ChangeSubscriptionModal';
import { professionalBreadCrumbData } from '../../data';
import { AccountDetailsWrapper, InfoRowStyled } from '../style';
import ProfessionalUserInformation from './ProfessionalUserInformation';

const ProfessionalUsers = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isChangingSubs, setIsChangingSubs] = useState(false);
  const [openChangeSubsModal, setOpenChangeSubsModal] = useState(false);
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubsModal = () => {
    setOpenChangeSubsModal(!openChangeSubsModal);
  };

  const handleFormSubmit = async (values: InitialValue) => {
    try {
      setIsChangingSubs(true);
      await requestAPI(
        subscriptionApi.changeSubscription(userId, {
          accountPlanType: values.accountPlan,
          duration: values.duration,
          subscription: values.subscription,
        })
      );
      dispatch(getSingleUsers(userId));
      toast.success('Subscription changed successfully');
      handleSubsModal();
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setIsChangingSubs(false);
    }
  };

  const { singleUser, loading } = useSelector(
    (state: RootState) => state.users
  );

  useEffect(() => {
    if (userId) {
      dispatch(getSingleUsers(userId));
    }
  }, [dispatch, userId]);

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await requestAPI(users.deleteUser(userId || ''));
      toast.success(
        `${singleUser.firstName + ' ' + singleUser.lastName} deleted successfully`
      );
      navigate('/users');
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const userPaymentHistory = singleUser as {
    paymentHistory: PaymentHistoryData[];
  };
  return (
    <AccountDetailsWrapper>
      {!loading ? (
        <>
          <CrumbsWithButton
            breadCrumbData={professionalBreadCrumbData(
              singleUser?.accountType || 'Free'
            )}
            deleteButtonText="Delete User"
            onDeleteComplete={handleConfirmDelete}
            isDeleting={isDeleting}
            deletingUserName={singleUser.firstName + ' ' + singleUser.lastName}
          />
          <div className="main-content">
            {singleUser.subscription && (
              <InfoRowStyled>
                <HeadingTitle
                  title="Active Subscription"
                  button={(() => {
                    if (
                      !(
                        singleUser.subscription.id &&
                        singleUser.subscription.id.startsWith('sub_') &&
                        singleUser.subscription.name !== 'basic'
                      )
                    ) {
                      return (
                        <Button radius="md" onClick={handleSubsModal}>
                          Change Subscription
                        </Button>
                      );
                    }
                    return null;
                  })()}
                />
                <Subscription
                  subscriptionName={singleUser.subscription.name}
                  status={singleUser.subscription.status}
                  planName={singleUser?.subscription?.interval}
                  hasCouponCodeUsed={singleUser?.subscription?.couponCodeUsed}
                  planExpiry={singleUser?.subscription?.expiresAt}
                  isPlanManual={singleUser?.subscription?.isCreatedManually}
                />
              </InfoRowStyled>
            )}
            <InfoRowStyled>
              <HeadingTitle title="User Profile" />
              <div
                style={{ display: 'flex', gap: '20px', alignItems: 'center' }}
              >
                <Avatar
                  avatarUrl={singleUser?.avatarUrl}
                  firstName={singleUser?.firstName}
                  lastName={singleUser?.lastName}
                />
                <Typography>
                  {singleUser?.firstName + ' ' + singleUser?.lastName}
                </Typography>
              </div>
            </InfoRowStyled>
            <InfoRowStyled>
              <HeadingTitle title="User Details" />
              <ProfessionalUserInformation
                createdAt={singleUser?.createdAt}
                email={singleUser?.email}
                fullName={singleUser?.firstName + ' ' + singleUser.lastName}
                id={singleUser?._id}
                updateAt={singleUser?.updatedAt}
                firstName={singleUser?.firstName}
                lastName={singleUser?.lastName}
                region={singleUser?.region}
              />
            </InfoRowStyled>
            {singleUser?.business?.name && (
              <InfoRowStyled>
                <HeadingTitle title="Business Details" />
                <BusinessDetails
                  businessAddress={singleUser?.business?.address?.city}
                  businessName={singleUser?.business?.name}
                  businessType={singleUser?.business?.tradeType}
                  businessLogo={singleUser?.business?.banner?.url}
                />
              </InfoRowStyled>
            )}
            {userPaymentHistory.paymentHistory &&
              userPaymentHistory.paymentHistory.length > 0 && (
                <InfoRowStyled>
                  <HeadingTitle title="Payment History" />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 10,
                    }}
                  >
                    {userPaymentHistory?.paymentHistory.map(
                      (item, idx, arr) => (
                        <React.Fragment key={item._id}>
                          <PaymentHistory {...item} />
                          {idx !== arr.length - 1 && <Divider />}
                        </React.Fragment>
                      )
                    )}
                  </div>
                </InfoRowStyled>
              )}
          </div>
          {openChangeSubsModal && (
            <ChangeSubscriptionModal
              openModal={openChangeSubsModal}
              onClose={handleSubsModal}
              onSubmit={(values) => handleFormSubmit(values)}
              loading={isChangingSubs}
            />
          )}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '400px',
            width: '100%',
          }}
        >
          <Loader
            style={{
              height: 60,
              width: 60,
            }}
          />
        </div>
      )}
    </AccountDetailsWrapper>
  );
};

export default ProfessionalUsers;
