import styled from 'styled-components';

export const HeadingStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const NewDocumentWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

export const InfoRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & .text {
    font-family: ${({ theme }) => theme.fontFamily.roboto};
    font-size: ${({ theme }) => theme.fontSize.normal};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: #4c4c4c;
  }

  & .reactSelect {
    z-index: 999;
  }
`;

export const SearchInputWrapperStyled = styled.div`
  width: 100%;
  max-width: 280px;
`;
