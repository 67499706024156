import styled from 'styled-components';

export const DropdownButtonWrapper = styled.div`
  width: 200px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  .button-with-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .content {
    position: absolute;
    z-index: 9999;
    top: 40px;
    width: 100%;
    right: 0;
    border: 1px solid ${({ theme }) => theme.colors.lightGrey.neutral_e0};
    background-color: white;

    border-radius: 5px;
    padding: 5px 0px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    max-height: 300px;

    &::-webkit-scrollbar {
      width: 6px;
      background: ${({ theme }) => theme.colors.lightGrey};
      border-radius: 7.5px;
      margin: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.scrollThumb};
    }
  }
`;

export const IndividualOption = styled.div`
  cursor: pointer;
  padding: 12px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark.neutral_20};
    color: black;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  color: black;
`;
