import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import JobPosts, { paginationType } from '@/apiConfigs/jobs';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import Pagination from '@/components/Pagination';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import RadioButton from '@/designComponents/Radio';
import Table, { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import usePaginationStore from '@/storeZustand/paginationStore';
import { toCapitalCase } from '@/utils/conversion';
import { debounce } from '@/utils/debounce';
import requestAPI from '@/utils/requestAPI';

import { SearchInputWrapperStyled } from '../Documents/style';
import Filter from '../Stories/Filter';

const Jobs = () => {
  const navigate = useNavigate();
  const { setLimit, getLimit } = usePaginationStore();
  const [isJobFetching, setIsJobFetching] = useState(false);
  const [jobs, setJobs] = useState<{
    pagination: {
      status: boolean;
      total: number;
      totalPages: number;
      currentPage: string;
      limit: string;
    } | null;
    data: any[];
  }>({
    pagination: null,
    data: [],
  });
  const [searchTerm, setSearchTerm] = useState('');

  const [approvedOption, setApprovedOption] = useState('all');

  const approvedMapping: { [key: string]: boolean | null } = {
    all: null,
    approved: true,
    'not-approved': false,
  };

  const [paginationParams, setPaginationParams] = useState<paginationType>({
    page: 1,
    limit: getLimit('jobs'),
    search: '',
    filter: {
      isApproved: approvedMapping.all,
    },
  });

  useHeaderTitle('Job Posts');

  const getAllJobPosts = async (params: paginationType) => {
    try {
      setIsJobFetching(true);
      const data = await requestAPI(JobPosts.getAllJobs(params));

      setJobs({
        data: data.data,
        pagination: data.pagination,
      });
    } catch (error) {
      console.error('Error encountered: ', error);
    } finally {
      setIsJobFetching(false);
    }
  };

  const fetchJobs = useCallback(() => {
    getAllJobPosts(paginationParams);
  }, [paginationParams]);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const handleCurrentPage = (page: number) => {
    setPaginationParams((prev) => ({ ...prev, page }));
  };

  const handleLimitChange = (limit: number) => {
    setLimit('jobs', limit);
    setPaginationParams((prev) => ({ ...prev, page: 1, limit }));
  };
  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setApprovedOption(value);

    setPaginationParams((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        isApproved: approvedMapping[value],
      },
      page: 1,
    }));
  };

  const debouncedSearch = useCallback(
    debounce((term: string) => {
      setPaginationParams((prev) => ({
        ...prev,
        search: term,
        page: 1,
      }));
    }, 300),
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const tableData: TableDataType = {
    headings: [
      { key: 'city', label: 'City' },
      { key: 'budget', label: 'Budget' },
      { key: 'status', label: 'Status' },
      { key: 'approvalStatus', label: 'Approval Status' },
      { key: 'date', label: 'Created Date' },
      {
        key: 'actions',
        label: 'Action',
      },
    ],
    body: Array.isArray(jobs.data)
      ? jobs.data?.map((job) => ({
          id: job?._id,
          city: job?.city,
          budget: job?.budget,
          status: toCapitalCase(job?.status),
          approvalStatus:
            job?.isApproved === true ? 'Approved' : 'Not Approved',
          date: moment(job?.createdAt).format('MMM DD, YYYY  HH:mm'),
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                onClick={() => navigate(job._id)}
              >
                View
              </Button>
            </div>
          ),
        }))
      : [],
  };

  return (
    <div>
      <div style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchInputWrapperStyled>
            <Input
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search Jobs"
              iconLeft={<SearchSVG />}
            />
          </SearchInputWrapperStyled>

          <Filter
            filterContentChildren={
              <RadioButton
                options={[
                  { label: 'All', value: 'all' },
                  { label: 'Approved', value: 'approved' },
                  { label: 'Not Approved', value: 'not-approved' },
                ]}
                onChange={handleRadioClick}
                selctedOption={approvedOption}
              />
            }
          />
        </div>
      </div>

      <Table
        tableData={tableData}
        noData="No data to display."
        showCheckBox={false}
        maxWidthForCardView="768px"
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
        isLoading={isJobFetching}
      />

      <Pagination
        activePage={+jobs?.pagination?.currentPage || 1}
        onCurrentPageChange={handleCurrentPage}
        totalPages={jobs?.pagination?.totalPages}
        limit={getLimit('jobs') || 10}
        onLimitChange={handleLimitChange}
      />
    </div>
  );
};

export default Jobs;
