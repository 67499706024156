import { createSlice } from '@reduxjs/toolkit';

import {
  addAds,
  addAdvertiser,
  getAllAds,
  getAllAdvertiser,
  getSingleAds,
} from './functions';

const initialState: any = {
  ads: [],
  advertisers: [],
  singleAds: {},
  pagination: {
    total: 0,
    limit: 10,
    currentPage: 1,
    totalPages: 1,
  },
  error: null,
  loading: false,
};

const advertiseSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addAds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addAds.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to add ads. Try again later.';
      })
      .addCase(addAds.fulfilled, (state, action) => {
        return {
          loading: false,
          error: null,
          ads: action.payload,
        };
      });

    builder
      .addCase(getAllAds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAds.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to add ads. Try again later.';
      })
      .addCase(getAllAds.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.ads = action.payload.data;
        state.pagination = action.payload.pagination;
      });

    builder
      .addCase(getSingleAds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleAds.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ||
          'Failed to add group document. Try again later.';
      })
      .addCase(getSingleAds.fulfilled, (state, action) => {
        return {
          loading: false,
          error: null,
          singleAds: action.payload,
        };
      });

    builder
      .addCase(addAdvertiser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addAdvertiser.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to add advertiser. Try again later.';
      })
      .addCase(addAdvertiser.fulfilled, (state, action) => {
        return {
          loading: false,
          error: null,
          advertisers: action.payload,
        };
      });
    builder
      .addCase(getAllAdvertiser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAdvertiser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.advertisers = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(getAllAdvertiser.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ||
          'Failed to fetch advertiser. Try again later.';
      });
  },
});

export const adsReducer = advertiseSlice.reducer;
