import * as Yup from 'yup';
export const createDocValidationSchema = (selectedType: string) => {
  const baseSchema = {
    title: Yup.string().required('Title is required'),
    // hashTags: Yup.array().min(1, 'At least one hashtag is required'),
    // type: Yup.string().required('Document Type is required'),
    status: Yup.string().required('Status is required'),
    position: Yup.number()
      .required('Position is required')
      .min(1, 'Position must be greater than 0'),
  };
  switch (selectedType) {
    case 'icon':
      return Yup.object().shape({
        ...baseSchema,
        icon: Yup.string().required('Image is required'),
      });
    case 'pdf':
      return Yup.object().shape({
        ...baseSchema,
        pdf: Yup.string().required('Pdf is required'),
      });
    case 'text':
      return Yup.object().shape({
        ...baseSchema,
        editorHtml: Yup.string().required('Text is required'),
      });
    default:
      return Yup.object().shape(baseSchema);
  }
};
