import React, { CSSProperties } from 'react';

import { CheckBoxStyled, LabelStyled } from './style';

interface ICheckboxProps extends React.ComponentProps<'input'> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  children?: React.ReactNode;
  labelStyle?: CSSProperties;
  style?: CSSProperties;
}

const Checkbox = ({
  children,
  isChecked,
  labelStyle,
  ...restProps
}: ICheckboxProps) => {
  return (
    <LabelStyled style={labelStyle}>
      <CheckBoxStyled type="checkbox" checked={isChecked} {...restProps} />
      {children}
    </LabelStyled>
  );
};
export default Checkbox;
