import { createAsyncThunk } from '@reduxjs/toolkit';

import advertise, {
  AdsType,
  AdvertiserType,
  PaginationType,
} from '@/apiConfigs/ads';
import requestAPI from '@/utils/requestAPI';

export const addAds = createAsyncThunk(
  'ads/addAds',
  async (ad: AdsType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(advertise.addAds(ad));

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

export const getAllAds = createAsyncThunk(
  'ads/getAllAds',
  async (params: PaginationType) => {
    const response = await requestAPI(advertise.getAllAds(params));

    return response;
  }
);

export const getSingleAds = createAsyncThunk(
  'documents/getSingleDocument',
  async (advertiseId: string) => {
    const response = await requestAPI(advertise.getSingleAds(advertiseId));
    console.log(response);

    return response;
  }
);

export const addAdvertiser = createAsyncThunk(
  'advertisers/addAdvertiser',
  async (advertiser: AdvertiserType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(advertise.addAdvertiser(advertiser));

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

export const getAllAdvertiser = createAsyncThunk(
  'advertisers/getAllAdvertiser',
  async (params: paginationType) => {
    const response = await requestAPI(advertise.getAllAdvertiser(params));

    return response;
  }
);
