import { createAsyncThunk } from '@reduxjs/toolkit';

import admins from '@/apiConfigs/admins';
import { PaginationType } from '@/apiConfigs/documents';
import requestAPI from '@/utils/requestAPI';

export const getAllAdmins = createAsyncThunk(
  'admins/getAllAdmins',
  async (params: PaginationType) => {
    const response = await requestAPI(admins.getAdmins(params));

    return response;
  }
);
