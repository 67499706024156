export const fontSize = {
  h0: '3em',
  h1: '2.5em',
  h2: '2em',
  h3: '1.5em',
  h4: '1.25em',
  h5: '1em',
  h6: '0.8em',
  regular: '1em',
  extraSmall: '6px',
  tiny: '8px',
  smallCaption: '9px',
  caption: '10px',
  smallSubtitle: '11px',
  subtitle: '12px',
  smallNormal: '13px',
  normal: '14px',
  largeNormal: '15px',
  title: '16px',
  largeTitle: '17px',
  highlight: '18px',
  subheading: '19px',
  heading: '20px',
  largeHeading: '22px',
  extraLargeHeading: '24px',
  smallDisplay: '26px',
  display: '28px',
  largeDisplay: '32px',
  extraLargeDisplay: '36px',
  jumboDisplay: '38px',
  superDisplay: '40px',
} as const;

export type FontSizeType = typeof fontSize;
