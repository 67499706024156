import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  position: yup
    .number()
    .required('Position is required')
    .integer('Position must be an integer'),
  buildingSteps: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required('Label is required'),
        value: yup.string().required('Value is required'),
      })
    )
    .required('Building steps are required')
    .min(1, 'At least one building step is required'),
});
