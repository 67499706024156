import { ConfigType } from '@/utils/requestAPI';

export type paginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
  type?: string;
  filter?: string;
  startDate?: string;
  endDate?: string;
};

const users = {
  getUsers: (params?: paginationType): ConfigType => {
    return {
      method: 'get',
      url: '/users',
      params,
    };
  },
  getUsersWithAnalytics: (params?: paginationType): ConfigType => {
    return {
      method: 'get',
      url: '/users/analytics',
      params,
    };
  },
  getUserDetails: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/user/${id}`,
    };
  },
  deleteUser: (id: string): ConfigType => {
    return {
      method: 'delete',
      url: `/user/${id}`,
    };
  },
};

export default users;
