import { IButtonComponentProps, IButtonProps, StyledButton } from './style';

const Button = ({
  variant = 'primary',
  radius = 'round',
  bgColor,
  size = 'md',
  icon,
  isLoading = false,
  isDisabled = false,
  onClick,
  className,
  style,
  children,
  ...rest
}: IButtonProps & IButtonComponentProps) => {
  return (
    <StyledButton
      variant={variant}
      radius={radius}
      size={size}
      bgColor={bgColor}
      $isDisabled={isDisabled}
      disabled={isDisabled}
      onClick={onClick}
      className={className}
      style={{ ...style }}
      {...rest}
    >
      {isLoading ? (
        <div className="lds-hourglass">
          <div />
        </div>
      ) : (
        <>
          {icon && icon}
          {children}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
