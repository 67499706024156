import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Typography from '@/designComponents/Typography';

import { InfoRowStyled } from '../Documents/style';
import { ForgotPasswordWrapper } from './style';

const ForgotPassword = () => {
  const theme = useTheme();
  const handleForm = (value: { email: string }) => {
    console.log(value);
  };
  return (
    <ForgotPasswordWrapper>
      <div className="banner-text">
        <Typography
          size="display"
          fontFamily="jost"
          fontWeight="medium"
          color="dark.neutral_60"
        >
          Forgot{' '}
          <span style={{ color: theme.colors.brandColorBlue }}>Password ?</span>
        </Typography>
        <Typography size="normal" fontWeight="normal" fontFamily="jost">
          Don’t worry! Fill in your email and we’ll sent you a link to reset
          your password.
        </Typography>
      </div>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleForm}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Please enter a valid email')
            .required('Email is required'),
        })}
      >
        {({ values, setFieldValue, errors, touched }) => {
          return (
            <Form>
              <div className="btns">
                <InfoRowStyled>
                  <Typography>
                    Email <span style={{ color: 'red' }}> * </span>
                  </Typography>
                  <Input
                    placeholder="alma.lawson@example.com"
                    style={{ height: '30px' }}
                    name="email"
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <Typography color="status.red" size="subtitle">
                      {errors.email}
                    </Typography>
                  ) : (
                    ''
                  )}
                </InfoRowStyled>
                <InfoRowStyled>
                  <Button
                    radius="normal"
                    style={{ height: '50px' }}
                    htmlType="submit"
                  >
                    Reset My Password
                  </Button>
                </InfoRowStyled>
                <InfoRowStyled>
                  <Link to="/" style={{ alignSelf: 'center' }}>
                    <Typography
                      size="title"
                      fontWeight="medium"
                      fontFamily="roboto"
                    >
                      Login
                    </Typography>
                  </Link>
                </InfoRowStyled>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ForgotPasswordWrapper>
  );
};

export default ForgotPassword;
