import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import videos, { addHowToVideoType, PaginationType } from '@/apiConfigs/videos';
import requestAPI from '@/utils/requestAPI';

export const addHowToVideos = createAsyncThunk(
  'videos/addHowToVideo',
  async (video: addHowToVideoType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(videos.addHowToVideo(video));
      toast.success('Added how to videos successfully');
      return response;
    } catch (error: any) {
      console.error(error.message, 'error from catch');
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getAllHowToVideos = createAsyncThunk(
  'videos/getAllHowToVideos',
  async (pagination: PaginationType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(videos.getAllHowToVideo(pagination));
      return response;
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
export const getSingleHowToVideos = createAsyncThunk(
  'videos/getSingleHowToVideos',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await requestAPI(videos.getSingleHowToVideo(id));
      return response;
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
