import styled from 'styled-components';

export const LoginWrapperStyled = styled.div`
  width: 100%;
  .sign-slogan {
    background-color: ${({ theme }) => theme.colors.soft.primary};
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.blue_ba};
    padding: 10px 14px;
    max-width: 420px;
    margin: 40px auto;

    & .main-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }
  }
`;

export const ContainerStyled = styled.div`
  max-width: 400px;
  margin: 50px auto;
  padding: 10px;

  & .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
  }

  & .form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }
`;
