import { ConfigType } from '@/utils/requestAPI';

export type SubscriptionData = {
  subscription: string;
  accountPlanType: string;
  duration: number;
};

export const subscriptionApi = {
  changeSubscription: (userId: string, data: SubscriptionData): ConfigType => {
    return {
      method: 'post',
      url: `/subscription/user/${userId}/create`,
      data,
    };
  },
};
