import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import cities from '@/apiConfigs/cities';
import CountrySelection from '@/components/CountrySelection';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Modal from '@/designComponents/Modal';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import Typography from '@/designComponents/Typography';
import { useAppDispatch } from '@/store';
import { getAllCities } from '@/store/cities/functions';
import requestAPI from '@/utils/requestAPI';

type SelectedCityType = {
  selectedCity?: CityValues | null;
};

type CityValues = {
  name: string;
  region: string;
  _id?: string;
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

const AddCity = ({ selectedCity }: SelectedCityType) => {
  const dispatch = useAppDispatch();
  const [isCityModalOpen, setIsCityModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('ie');
  const [initialValues, setInitialValues] = useState<CityValues>({
    name: '',
    region: selectedCountry,
  });

  useEffect(() => {
    if (selectedCity) {
      setInitialValues({
        name: selectedCity.name,
        region: selectedCity.region,
      });
      setSelectedCountry(selectedCity.region);
      setIsCityModalOpen(true);
    }
  }, [selectedCity]);

  const openCityModal = () => {
    setIsCityModalOpen(true);
  };

  const closeCityModal = () => {
    setIsCityModalOpen(false);
  };

  const handleCityAddOrUpdate = async (values: CityValues) => {
    try {
      setLoading(true);

      if (selectedCity) {
        await requestAPI(
          cities.updateCity(selectedCity._id, {
            name: values.name,
            region: selectedCountry,
          })
        );
        dispatch(getAllCities({ page: 1, limit: 10 }));
        toast.success('City updated successfully');
      } else {
        await requestAPI(
          cities.addCity({
            name: values.name,
            region: selectedCountry,
          })
        );
        toast.success('City added successfully');
      }
      setLoading(false);
      setIsCityModalOpen(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Button radius="md" onClick={openCityModal}>
        New City
      </Button>
      <Modal
        isOpen={isCityModalOpen}
        onClose={closeCityModal}
        title={selectedCity ? 'Edit City' : 'Add City'}
        halfWidth
      >
        <ModalContentWrapper>
          <Formik
            initialValues={initialValues}
            onSubmit={handleCityAddOrUpdate}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, touched }) => {
              return (
                <Form>
                  <div>
                    <div className="container">
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <CountrySelection
                          selectedCountry={selectedCountry}
                          setSelectedCountry={setSelectedCountry}
                        />
                      </div>
                      <Typography
                        fontWeight="medium"
                        style={{ marginBottom: '10px' }}
                      >
                        City Name
                      </Typography>
                      <Input
                        name="name"
                        value={values.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                      {errors.name && touched.name ? (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                          {errors.name}
                        </div>
                      ) : null}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '20px',
                      }}
                    >
                      <Button type="submit" isLoading={loading}>
                        {selectedCity ? 'Update' : 'Add'}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};

export default AddCity;
