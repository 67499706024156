import { TableData, TableRow, TableStyled } from './style';

export type TableProps = {
  tableData: {
    headings: Array<{ key: string; title: string }>;
    body: { [key: string]: number };
  };
};

const SubscribedUsers = ({ tableData }: TableProps) => {
  return (
    <div>
      <TableStyled>
        <tbody>
          {tableData.headings.map((item, idx) => (
            <TableRow key={idx}>
              <TableData>{item.title}</TableData>
              <TableData>{tableData.body[item.key]}</TableData>
            </TableRow>
          ))}
        </tbody>
      </TableStyled>
    </div>
  );
};

export default SubscribedUsers;
