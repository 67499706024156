/* eslint-disable */

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalTextEntity } from '@lexical/react/useLexicalTextEntity';
import type { TextNode } from 'lexical';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { $createKeywordNode, KeywordNode } from '../../nodes/KeywordNode';
import Popup from './Popupcomponent';

interface PopupState {
  show: boolean;
  content: string;
  position: { top?: number; left?: number; bottom?: number; right?: number };
}

export default function KeywordsPlugin({
  keywords,
  keywordInfos,
  popupComponent,
}: {
  keywords: string[];
  keywordInfos: Record<string, any>;
  popupComponent?: React.FC<any>;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const [popup, setPopup] = useState<PopupState>({
    show: false,
    content: '',
    position: {},
  });
  const mouseOutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (!editor.hasNodes([KeywordNode])) {
      throw new Error('KeywordsPlugin: KeywordNode not registered on editor');
    }
  }, [editor]);

  const $createKeywordNode_ = useCallback((textNode: TextNode): KeywordNode => {
    const info = keywordInfos[textNode.getTextContent()];
    console.log(info);

    return $createKeywordNode(textNode.getTextContent(), info);
  }, []);

  const getKeywordMatch = useCallback((text: string) => {
    for (const keyword of keywords) {
      const startIndex = text.toLowerCase().indexOf(keyword.toLowerCase());
      if (startIndex !== -1) {
        const endIndex = startIndex + keyword.length;
        const isWholeWord =
          (startIndex === 0 || /\W/.test(text[startIndex - 1])) &&
          (endIndex === text.length || /\W/.test(text[endIndex]));
        if (isWholeWord) {
          return { start: startIndex, end: endIndex };
        }
      }
    }
    return null;
  }, []);

  useLexicalTextEntity<KeywordNode>(
    getKeywordMatch,
    KeywordNode,
    $createKeywordNode_
  );

  const handleMouseOver = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const parent = target.closest('[data-lexical-text="true"]');

      if (parent && parent.classList.contains('keyword')) {
        const rect = parent.getBoundingClientRect();
        const parentWidth = editor._window?.innerWidth || window?.innerWidth;

        let position;
        if (rect.left < parentWidth / 2) {
          position = { left: rect.left, top: rect.top + rect.height };
        } else {
          position = {
            right: parentWidth - rect.right,
            top: rect.top + rect.height,
          };
        }
        const info = keywordInfos[target?.textContent?.toLowerCase() || ''];
        if (info) {
          setPopup({
            show: true,
            content: info,
            position,
          });
        }
      }
    },
    [keywordInfos]
  );

  const onClosePopup = () => {
    setPopup((prev) => ({ ...prev, show: false, content: '' }));
  };

  const handleMouseOut = useCallback(() => {
    if (mouseOutRef.current) {
      clearTimeout(mouseOutRef.current);
    }
    mouseOutRef.current = setTimeout(() => {
      onClosePopup();
    }, 4000);
  }, []);

  useEffect(() => {
    document.addEventListener('mouseover', handleMouseOver);
    document.addEventListener('mouseout', handleMouseOut);

    return () => {
      document.removeEventListener('mouseover', handleMouseOver);
      document.removeEventListener('mouseout', handleMouseOut);
      if (mouseOutRef.current) {
        clearTimeout(mouseOutRef.current);
      }
    };
  }, [editor]);

  if (!popup.show) {
    return null;
  }
  return (
    <Popup
      content={popup.content}
      position={popup.position}
      onClose={onClosePopup}
      component={popupComponent}
    />
  );
}
