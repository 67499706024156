import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import videos, { PaginationType } from '@/apiConfigs/videos';
import requestAPI from '@/utils/requestAPI';

export const addHowToVideosCategory = createAsyncThunk(
  'videos/addHowToVideoCategory',
  async (
    data: {
      name: string;
      contentSection: string[];
      icon: string;
      position: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await requestAPI(videos.addHowToVideoCategory(data));
      toast.success('Added how to videos category successfully');
      return response;
    } catch (error: any) {
      console.error(error.message, 'error from catch');
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getAllHowToVideosCategory = createAsyncThunk(
  'videos/getAllHowToVideosCategory',
  async (pagination: PaginationType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(
        videos.getAllHowToVideoCategory(pagination)
      );
      return response;
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
export const getSingleHowToVideosCategory = createAsyncThunk(
  'videos/getSingleHowToVideosCategory',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await requestAPI(videos.getSingleHowToVideoCategory(id));
      return response;
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
