import styled from 'styled-components';

export const LoginWrapper = styled.div`
  max-width: 1400px;
  justify-content: center;
  margin: 0 auto;
`;

export const Container = styled.div`
  max-width: 500px;
  padding: 30px;
  margin: 100px auto;
  border-top: 3px solid ${(props) => props.theme.colors.primary};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  & .forgot-password {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;
