import { NavigateFunction } from 'react-router-dom';

import Button from '@/designComponents/Button';

export type InitialValuesType = {
  documentTitle: string;
  documentType: string;
  parentTitle: string;
  icon: File[] | null;
  type: string;
  jurisdictions: string[];
};

export const cardData = [
  {
    title: 'Top Documents',
    key: 'top-document',
    count: 492,
  },
  {
    title: 'Go Green',
    key: 'go-green',
    count: 492,
  },
  {
    title: 'Building Guides',
    key: 'building-guide',
    count: 492,
  },
  {
    title: 'Building System',
    key: 'building-system',
    count: 492,
  },
  // {
  //   title: 'Download Center',
  //   key: 'download-center',
  //   count: 492,
  // },
  {
    title: 'Detailed Construction Drawing',
    key: 'construction-drawing',
    count: 492,
  },
];

export const breadCrumbData: Array<{ label: string; path: string }> = [
  {
    label: 'Go Green',
    path: '/',
  },
  {
    label: 'All Category',
    path: '/',
  },
  {
    label: 'Category Details',
    path: '/',
  },
];
export const dropDownOption: Array<{
  value: string;
  label: string;
}> = [
  {
    label: 'Go Green',
    value: 'go-green',
  },
  {
    label: 'Building Guides',
    value: 'building-guide',
  },
  {
    label: 'Building System',
    value: 'building-system',
  },
];

export const data = (navigate: NavigateFunction) => [
  {
    id: '1',
    professional: 'Damien Hannigan',
    professionalEmail: 'example_professional_78@gmail.com',
    name: 'Aj Browne',
    enquiryType: 'Professional',
    email: 'dev@intosoft.com',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '2',
    professional: 'Eoin Ryan',
    professionalEmail: 'example_professional_14@gmail.com',
    name: 'test',
    enquiryType: 'Professional',
    email: 'sab3ta@gmail.com',
    date: 'July 04, 2024 08:02',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '3',
    professional: 'Neala Dunne Keating',
    professionalEmail: 'example_third_party_email@example.com',
    name: 'Aj Browne',
    enquiryType: 'Professional',
    email: 'dev@intosoft.com',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '4',
    professional: 'Damien Hannigan',
    professionalEmail: 'example_profess_78@gmail.com',
    name: 'Aj Browne',
    enquiryType: 'Professional',
    email: 'dev@intosoft.com',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '5',
    professional: 'Breen Stone Products',
    professionalEmail: 'example_professional_186@gmail.com',
    name: 'Aj Browne',
    enquiryType: 'Professional',
    email: 'dev@intosoft.com',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '6',
    professional: 'new user',
    professionalEmail: 'newuser0000@gmail.com',
    name: 'Aj Browne',
    enquiryType: 'Professional',
    email: 'dev@intosoft.com',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '7',
    professional: 'Megan Ennis',
    professionalEmail: 'mennis0145@gmail.com',
    name: 'Testing Enquiry Functionality',
    enquiryType: 'Professional',
    email: 'megan@example.com',
    date: 'June 22, 2023 14:33',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '8',
    professional: 'Damien Hannigan',
    professionalEmail: 'example_profess_78@gmail.com',
    name: 'Aj Browne',
    enquiryType: 'Professional',
    email: 'dev@intosoft.com',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '9',
    professional: '	Ariel Ie Pro',
    professionalEmail: 'estrada.ariel.m@gmail.com',
    name: 'Ariel Estrada',
    enquiryType: 'Professional',
    email: 'dummy01@email.com',
    date: '	May 02, 2023 08:57',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '10',
    professional: '	Ariel Ie Pro',
    professionalEmail: 'estrada.ariel.m@gmail.com',
    name: 'Ariel Estrada',
    enquiryType: 'Professional',
    email: 'dummy01@email.com',
    date: '	May 02, 2023 08:57',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '11',
    professional: 'Damien Hannigan',
    professionalEmail: 'example_profess_78@gmail.com',
    name: 'Aj Browne',
    enquiryType: 'Professional',
    email: 'dev@intosoft.com',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '12',
    professional: 'Damien Hannigan',
    professionalEmail: 'example_profess_78@gmail.com',
    name: 'Aj Browne',
    enquiryType: 'Professional',
    email: 'dev@intosoft.com',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '13',
    professional: 'Damien Hannigan',
    professionalEmail: 'example_profess_78@gmail.com',
    name: 'Aj Browne',
    enquiryType: 'Professional',
    email: 'dev@intosoft.com',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '14',
    professional: 'Damien Hannigan',
    professionalEmail: 'example_profess_78@gmail.com',
    name: 'Aj Browne',
    enquiryType: 'Professional',
    email: 'dev@intosoft.com',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
  {
    id: '15',
    professional: 'Damien Hannigan',
    professionalEmail: 'example_profess_78@gmail.com',
    name: 'Aj Browne',
    enquiryType: 'Professional',
    email: 'dev@intosoft.com',
    date: '15 may 2020 11:00 pm',
    actions: (
      <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
        <Button variant="gray" radius="normal" onClick={() => navigate(`${1}`)}>
          View
        </Button>
        {/* <Button variant="gray" radius="normal">
          Edit
        </Button> */}
        <Button variant="gray" radius="normal">
          Delete
        </Button>
      </div>
    ),
  },
];
