import { createGlobalStyle } from 'styled-components';

import CSSReset from './cssResets';
import { scrollCss } from './reUsableStyles';

const styled = { createGlobalStyle };

const GlobalStyles = styled.createGlobalStyle`
  ${CSSReset};
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }
  body {
    background: ${({ theme }) => theme?.colors?.light?.white};
    color: ${({ theme }) => theme?.colors?.text};
    font-size: ${({ theme }) => theme?.fontSize?.normal};
    font-family: ${({ theme }) => theme?.fontFamily?.roboto}, monospace;

    ${scrollCss};
  }
  h1 {
    color: ${({ theme }) => theme?.colors?.text};
    font-size: ${({ theme }) => theme?.fontSize?.h1};
    font-weight: ${({ theme }) => theme?.fontWeights?.bold};
  }
  h2 {
    color: ${({ theme }) => theme?.colors?.text};
    font-size: ${({ theme }) => theme?.fontSize?.h2};
    font-weight: ${({ theme }) => theme?.fontWeights?.bold};
  }
  h3 {
    color: ${({ theme }) => theme?.colors?.text};
    font-size: ${({ theme }) => theme?.fontSize?.h3};
    font-weight: ${({ theme }) => theme?.fontWeights?.bold};
  }
  h4 {
    color: ${({ theme }) => theme?.colors?.text};
    font-size: ${({ theme }) => theme?.fontSize?.h4};
    font-weight: ${({ theme }) => theme?.fontWeights?.bold};
  }
  h5 {
    color: ${({ theme }) => theme?.colors?.text};
    font-size: ${({ theme }) => theme?.fontSize?.h5};
    font-weight: ${({ theme }) => theme?.fontWeights?.bold};
  }
  h6 {
    color: ${({ theme }) => theme?.colors?.text};
    font-size: ${({ theme }) => theme?.fontSize?.h6};
    font-weight: ${({ theme }) => theme?.fontWeights?.bold};
  }
  caption {
    color: ${({ theme }) => theme?.colors?.text};
    font-size: ${({ theme }) => theme?.fontSize?.caption};
    font-weight: ${({ theme }) => theme?.fontWeights?.light};
  }

  a,
  a:visited,
  a:focus,
  a:hover,
  a:active {
    color: #443bc7;
    text-decoration: none;
    background: none;
    border: none;
    outline: none;
  }

  .chart-node-wrapper {
    padding-left: 1px;
    padding-right: 1px;
    position: relative;

    .chart-dropdown-button {
      background-color: #898989;
      color: white;
      padding: 2px 4px;
      font-size: 12px;
      border-radius: 4px;
      border: none;
      cursor: pointer;
    }

    .chart-dropdown-content {
      position: absolute;
      right: 0;
      top: 20px;
      z-index: 9999;
      background-color: #f9f9f9;
      min-width: 100px;
      width: 140px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
      border-radius: 4px;
    }
    .chart-dropdown-action {
      color: black;
      padding: 5px 10px;
      text-decoration: none;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      text-align: left;
      width: 100%;
    }
  }

  .svg-chart-container {
    height: calc(100vh - 180px);
  }
`;

export default GlobalStyles;
