export const getRespectiveRegion = (shortRegion: string): string | null => {
  switch (shortRegion) {
    case 'ie':
      return 'Ireland';
    case 'uk':
      return 'United Kingdom';
    default:
      return null;
  }
};

export const getRespectiveCurrencySymbol = (
  currency: string
): string | null => {
  switch (currency.toLowerCase()) {
    case 'eur':
      return '€';
    case 'usd':
      return '$';
    case 'gbp':
      return '£';
    case 'jpy':
      return '¥';
    case 'inr':
      return '₹';
    case 'cny':
      return '¥';
    case 'krw':
      return '₩';
    case 'rub':
      return '₽';
    case 'try':
      return '₺';
    case 'brl':
      return 'R$';
    default:
      return null;
  }
};
