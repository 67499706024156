import styled from 'styled-components';

export const HeadingStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
export const SearchInputWrapperStyled = styled.div`
  width: 100%;
  max-width: 280px;
`;

export const HowToVideosFormWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

export const InfoRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & .text {
    font-family: ${({ theme }) => theme.fontFamily.roboto};
    font-size: ${({ theme }) => theme.fontSize.normal};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: #4c4c4c;
  }

  & .reactSelect {
    z-index: 999;
  }
`;

export const TextAreaStyled = styled.textarea`
  width: 100%;
  color: ${({ theme }) => theme.colors.dark.neutral_60};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  background-color: inherit;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  line-height: 18.75px;
  font-size: ${({ theme }) => theme.fontSize.title};
  border: none;

  outline: none;
  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.dark.neutral_40};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSize.normal};
    line-height: 16.41px;
  }
`;

export const DetailsPageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;

  .crumb-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;
