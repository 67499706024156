import styled from 'styled-components';

export const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const LabelStyled = styled.label`
  color: ${({ theme }) => theme.colors.dark.black};
  display: flex;
  gap: 6px;

  cursor: pointer;
`;

export const RadioButtonStyled = styled.input``;
