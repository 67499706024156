import { ReactComponent as LogoSvg } from '@/assets/logo.svg';

import { PublicHeaderStyled } from './styles';

const PublicHeader = () => {
  return (
    <PublicHeaderStyled>
      <LogoSvg />
    </PublicHeaderStyled>
  );
};

export default PublicHeader;
