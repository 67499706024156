import React from 'react';

import ProfessionalUsers from './ProfessionalUsers';

type Props = {};

const UsersDetails = (_props: Props) => {
  return (
    <div>
      <ProfessionalUsers />
    </div>
  );
};

export default UsersDetails;
