import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Typography from '@/designComponents/Typography';
import { RootState, useAppDispatch } from '@/store';
import { getSingleSupplier } from '@/store/suppliers/functions';
import theme from '@/theme';
import { humanReadableDate } from '@/utils/date';

import { SuppliersDetailWrapperStyled } from './style';

type Props = {};

const ShowroomsDetail = (_props: Props) => {
  const { showroomId } = useParams();
  const dispatch = useAppDispatch();
  const supplierDetails = useSelector(
    (state: RootState) => state.suppliers.singleSupplier
  );

  useEffect(() => {
    if (showroomId) {
      dispatch(getSingleSupplier(showroomId));
    }
  }, []);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
      <SuppliersDetailWrapperStyled>
        <div className="main-content">
          <div className="heading">
            <Typography
              fontFamily="jost"
              fontWeight="medium"
              size="heading"
              color="dark.neutral_60"
            >
              Suppliers Details
            </Typography>
            <hr
              style={{
                backgroundColor: theme.colors.dark.neutral_50,
                border: 'none',
                height: '1px',
              }}
            />
          </div>
          <div className="main-text">
            <div className="text">
              <Typography className="text-value">Supplier Name</Typography>
              <Typography className="text-value">
                {supplierDetails?.name}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Email</Typography>
              <Typography className="text-value">
                {supplierDetails?.email}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Avatar</Typography>
              <img src={supplierDetails?.avatar} height="80px" width="80px" />
            </div>

            <div className="text">
              <Typography className="text-value">Website</Typography>
              <Typography className="text-value">
                {supplierDetails?.website}
              </Typography>
            </div>
            <div className="text">
              <Typography className="text-value">Created At</Typography>
              <Typography className="text-value">
                {humanReadableDate(supplierDetails?.createdAt)}
              </Typography>
            </div>
          </div>
        </div>
      </SuppliersDetailWrapperStyled>
      {supplierDetails?.showroom?.title ||
      supplierDetails?.showroom?.description ? (
        <SuppliersDetailWrapperStyled>
          <div className="main-content">
            <div className="heading">
              <Typography
                fontFamily="jost"
                fontWeight="medium"
                size="heading"
                color="dark.neutral_60"
              >
                ShowRoom Details
              </Typography>
              <hr
                style={{
                  backgroundColor: theme.colors.dark.neutral_50,
                  border: 'none',
                  height: '1px',
                }}
              />
            </div>
            <div className="main-text">
              <div className="text">
                <Typography className="text-value">Showroom Title</Typography>
                <Typography className="text-value">
                  {supplierDetails?.showroom?.title}
                </Typography>
              </div>
              <div className="text">
                <Typography className="text-value">Description</Typography>
                <Typography>
                  {supplierDetails?.showroom?.description}
                </Typography>
              </div>
              <div className="text">
                <Typography className="text-value">Images</Typography>
                <img
                  src={supplierDetails?.showroom?.images}
                  height="80px"
                  width="80px"
                />
              </div>
            </div>
          </div>
        </SuppliersDetailWrapperStyled>
      ) : null}

      {supplierDetails?.showroom?.bottomBanner?.title ||
      supplierDetails?.showroom?.bottomBanner?.subtitle ? (
        <SuppliersDetailWrapperStyled>
          <div className="main-content">
            <div className="heading">
              <Typography
                fontFamily="jost"
                fontWeight="medium"
                size="h4"
                color="dark.neutral_60"
              >
                Bottom Banner Details
              </Typography>
              <hr
                style={{
                  backgroundColor: theme.colors.dark.neutral_50,
                  border: 'none',
                  height: '1px',
                }}
              />
            </div>
            <div className="main-text">
              <div className="text">
                <Typography className="text-value">Banner Title</Typography>
                <Typography>
                  {supplierDetails?.showroom?.bottomBanner?.title}
                </Typography>
              </div>
              <div className="text">
                <Typography className="text-value">Banner SubTitle</Typography>
                <Typography>
                  {supplierDetails?.showroom?.bottomBanner?.subtitle}
                </Typography>
              </div>
              <div className="text">
                <Typography className="text-value">Image 1</Typography>
                <img
                  src={supplierDetails?.showroom?.bottomBanner?.image1}
                  height="80px"
                  width="80px"
                />
              </div>
              <div className="text">
                <Typography className="text-value">Image 2</Typography>
                <img
                  src={supplierDetails?.showroom?.bottomBanner?.image2}
                  height="80px"
                  width="80px"
                />
              </div>
            </div>
          </div>
        </SuppliersDetailWrapperStyled>
      ) : null}
    </div>
  );
};

export default ShowroomsDetail;
