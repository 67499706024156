import { useEffect, useState } from 'react';
import { MdModeEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import downloadCenter from '@/apiConfigs/downloadCenter';
import DeleteModal from '@/components/DeleteModal';
import Button from '@/designComponents/Button';
import Typography from '@/designComponents/Typography';
import requestAPI from '@/utils/requestAPI';

import CreateResources from '../../DownloadResource/CreateResources';
import CreateFolders from '../CreateFolder';
import {
  FolderDetailContainerStyled,
  InfoRowStyled,
  MainContainer,
  TypeDetailContainerStyled,
} from './style';

type SingleGroupType = {
  title: string;
  position: string;
  slug: string;
  region: string;
};

const FolderDetails = () => {
  const { folderId } = useParams();
  const [singleGroup, setSingleGroup] = useState<SingleGroupType>({
    title: '',
    position: '',
    region: '',
    slug: '',
  });
  const [items, setItems] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isResourceEditModalOpen, setIsResourceEditModalOpen] = useState(false);
  const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState('');

  const navigate = useNavigate();

  const getSingleGroup = async (id: string) => {
    const data = await requestAPI(
      downloadCenter.getSingleDownloadCenterGroup(id)
    );
    setSingleGroup(data);
  };

  useEffect(() => {
    if (folderId) {
      getSingleGroup(folderId);
    }
  }, [folderId]);

  const getItems = async () => {
    const data = await requestAPI(
      downloadCenter.getDownloadCenterResources({
        filter: { downloadCenterGroupId: folderId },
      })
    );
    setItems(data.data);
  };

  useEffect(() => {
    if (folderId) {
      getItems();
    }
  }, [folderId]);

  const renderItemsByType = (type) => {
    const filteredItems = items?.filter((item) => item.type === type);
    if (!filteredItems || filteredItems.length === 0) {
      return <Typography>No {type.toLowerCase()}</Typography>;
    }
    return items
      ?.filter((item) => item.type === type)
      ?.map((item) => (
        <div
          key={item._id}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: '#F7FAFC',
            maxWidth: '280px',
            padding: '5px',
            justifyContent: 'space-between',
          }}
        >
          <a
            href={item.body.content}
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '220px' }}
          >
            <Typography>{item.title}</Typography>
          </a>
          <MdModeEdit
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSelectedItemId(item._id);
              setIsResourceEditModalOpen(true);
            }}
          />
        </div>
      ));
  };
  const region = singleGroup?.region === 'ie' ? 'Ireland' : 'UK';

  const handleDeleModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
    setDeleteError('');
  };

  const handleDeleteFolder = async () => {
    try {
      setIsDeleting(true);
      await requestAPI(downloadCenter.deleteDownloadCenterGroup(folderId));
      toast.success(`${singleGroup.title} has been successfully deleted`);
      navigate('/download/folders');
    } catch (error: any) {
      setDeleteError(error.message);
      console.error(error.message);
      toast.error(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <MainContainer>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          marginBottom: '20px',
        }}
      >
        <Typography className="heading">Download Folder Details</Typography>
        <Button variant="gray" onClick={() => setIsEditModalOpen(true)}>
          Edit Folder Detail
        </Button>
        <Button variant="danger" onClick={handleDeleModal}>
          Delete Folder
        </Button>

        <CreateFolders
          isOpenModal={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          initialValues={{
            name: singleGroup.title,
            region: singleGroup.region || 'ie',
          }}
          refreshData={getSingleGroup}
          folderId={folderId}
        />
      </div>
      <FolderDetailContainerStyled>
        <InfoRowStyled>
          <Typography className="title">Title: </Typography>
          <Typography>{singleGroup?.title}</Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="title">Position: </Typography>
          <Typography>{singleGroup?.position}</Typography>
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography className="title">Region: </Typography>
          <Typography>{region}</Typography>
        </InfoRowStyled>
      </FolderDetailContainerStyled>

      <Typography
        style={{ marginBottom: '20px', fontSize: '18px', fontWeight: '500' }}
      >
        {singleGroup?.slug}
      </Typography>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <TypeDetailContainerStyled>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h6"
              fontWeight="medium"
              size="h4"
              className="text"
            >
              Documents
            </Typography>
            {/* <FaPlus
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsResourceModalOpen(true);
              }}
            /> */}
          </div>
          {renderItemsByType('Document')}
        </TypeDetailContainerStyled>
        <TypeDetailContainerStyled>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h6"
              fontWeight="medium"
              size="h4"
              className="text"
            >
              Diagrams
            </Typography>
            {/* <FaPlus
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsResourceModalOpen(true);
              }}
            /> */}
          </div>
          {renderItemsByType('Diagram')}
        </TypeDetailContainerStyled>
        <TypeDetailContainerStyled>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h6"
              fontWeight="medium"
              size="h4"
              className="text"
            >
              Tables
            </Typography>
          </div>
          {renderItemsByType('Table')}
        </TypeDetailContainerStyled>
      </div>
      <CreateResources
        isOpenModal={isResourceModalOpen}
        onClose={() => setIsResourceModalOpen(false)}
      />

      <CreateResources
        isOpenModal={isResourceEditModalOpen}
        onClose={() => setIsResourceEditModalOpen(false)}
        resourcesId={selectedItemId}
      />
      {isDeleteModalOpen && (
        <DeleteModal
          title={`Delete ${singleGroup.title} Folder`}
          deleteText={`Are you sure want to delete " ${singleGroup.title} "`}
          isOpen={isDeleteModalOpen}
          onClose={handleDeleModal}
          onConfirmDelete={handleDeleteFolder}
          confirmBtnLoading={isDeleting}
          errorMessage={deleteError}
        />
      )}
    </MainContainer>
  );
};

export default FolderDetails;
