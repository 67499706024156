import React from 'react';

import {
  ADVERTISEMENT_ROUTE,
  BUILDING_ROUTE,
  COUPONS_ROUTE,
  DASHBOARD_ROUTE,
  DOCUMENTS_ROUTE,
  DOWNLOAD_CENTER_ROUTE,
  ECOMMERCE_ROUTE,
  EXTERNAL_PRODUCT_MAPPER_ROUTE,
  FORMS_ROUTE,
  // IDEAS_ROUTE,
  INTERACTIVE_ROUTE,
  JOBS_ROUTE,
  SETUP_ADMIN_USERS_ROUTE,
  SETUP_CITIES_ROUTE,
  SETUP_IDEA_CATEGORIES_ROUTE,
  SETUP_VIDEO_CATEGORIES_ROUTE,
  // PROFFESIONAL_ROUTE,
  // SETTING_ROUTE,
  SHOWROOM_ROUTE,
  STORIES_ROUTE,
  TAGS_ROUTE,
  USERS_ROUTE,
  VIDEOS_ROUTE,
} from '@/routes/routePaths';

import {
  AdvertiseSVG,
  BuildingJourneySVG,
  CouponSVG,
  DashboardSVG,
  DocumentSVG,
  ECommerceSVG,
  IdeaSVG,
  InteractiveSVG,
  ProductTagSVG,
  SettingSVG,
  // ProfessionalSVG,
  // SettingSVG,
  ShowroomSVG,
  UserSVG,
  VideoSVG,
} from './icons';

const menuList: MenuType = [
  {
    icon: DashboardSVG,
    key: 'dashboard',
    label: 'Dashboard',
    path: DASHBOARD_ROUTE,
  },
  {
    icon: DocumentSVG,
    key: 'leads',
    label: 'Documents',
    path: DOCUMENTS_ROUTE,
  },
  {
    icon: ProductTagSVG,
    key: 'tags',
    label: 'Tags',
    path: TAGS_ROUTE,
  },
  {
    icon: CouponSVG,
    key: 'coupons',
    label: 'Coupons',
    path: COUPONS_ROUTE,
  },
  {
    icon: VideoSVG,
    key: 'video',
    label: 'Videos',
    path: VIDEOS_ROUTE,
  },
  {
    icon: IdeaSVG,
    key: 'Story',
    label: 'Stories',
    path: STORIES_ROUTE,
  },
  {
    icon: DocumentSVG,
    key: 'downloadCenter',
    label: 'Download Center',
    path: DOWNLOAD_CENTER_ROUTE,
  },
  // {
  //   icon: VideoSVG,
  //   key: 'videos',
  //   label: 'Videos',
  //   path: VIDEOS_ROUTE,
  // },
  {
    icon: UserSVG,
    key: 'users',
    label: 'Users',
    path: USERS_ROUTE,
  },
  {
    icon: InteractiveSVG,
    key: 'forms',
    label: 'Forms',
    path: FORMS_ROUTE,
  },
  {
    icon: AdvertiseSVG,
    key: 'advertise',
    label: 'Advertisements',
    path: ADVERTISEMENT_ROUTE,
  },
  {
    icon: BuildingJourneySVG,
    key: 'building',
    label: 'Building Journey',
    path: BUILDING_ROUTE,
  },
  // {
  //   icon: IdeaSVG,
  //   key: 'ideas',
  //   label: 'Ideas and Stories',
  //   path: IDEAS_ROUTE,
  // },

  {
    icon: ECommerceSVG,
    key: 'ecommerce',
    label: 'Ecommerce',
    path: ECOMMERCE_ROUTE,
  },
  {
    icon: ShowroomSVG,
    key: 'job',
    label: 'Jobs',
    path: JOBS_ROUTE,
  },
  {
    icon: InteractiveSVG,
    key: 'interactive',
    label: 'Spreadsheets',
    path: INTERACTIVE_ROUTE,
  },
  // {
  //   icon: ProfessionalSVG,
  //   key: 'professionals',
  //   label: 'Professionals',
  //   path: PROFFESIONAL_ROUTE,
  // },
  {
    icon: ShowroomSVG,
    key: 'showrooms',
    label: 'Suppliers',
    path: SHOWROOM_ROUTE,
  },

  {
    icon: SettingSVG,
    key: 'setup',
    label: 'Setup',
    path: '/setup',
    children: [
      {
        icon: SettingSVG,
        key: 'Cities',
        label: 'Cities',
        path: SETUP_CITIES_ROUTE,
      },
      {
        icon: SettingSVG,
        key: 'admin-users',
        label: 'Admin Users',
        path: SETUP_ADMIN_USERS_ROUTE,
      },
      {
        icon: SettingSVG,
        key: 'external-product',
        label: 'External Product Mapper',
        path: EXTERNAL_PRODUCT_MAPPER_ROUTE,
      },
      {
        icon: SettingSVG,
        key: 'idea-categories',
        label: 'Idea categories',
        path: SETUP_IDEA_CATEGORIES_ROUTE,
      },
      {
        icon: SettingSVG,
        key: 'video-categories',
        label: 'Video Categories',
        path: SETUP_VIDEO_CATEGORIES_ROUTE,
      },
    ],
  },
];

export default menuList;

type MenuType = Array<MenuItemType>;

export type MenuItemType = {
  key: string;
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  path: string;

  children?: Omit<MenuItemType, 'order' | 'children'>[];
};
