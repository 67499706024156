import styled from 'styled-components';

export const UploadFileWrapper = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-width: 2px;
  border-color: ${({ theme }) => theme.colors.dark.neutral_20};
  padding: 30px 0px;
  cursor: pointer;
`;

export const ImagePreviewWrapper = styled.div`
  height: 112px;
  width: 126px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.dark.neutral_20};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .images {
    width: 100px;
  }

  .close {
    position: absolute;
    right: 0;
    top: -8px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 900px;
  margin: 0 auto;
  gap: 20px;
`;
