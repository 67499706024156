import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import downloadCenter from '@/apiConfigs/downloadCenter';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Modal from '@/designComponents/Modal';
import Typography from '@/designComponents/Typography';
import { InfoRowStyled, NewDocumentWrapper } from '@/pages/Documents/style';
import { RootState, useAppDispatch } from '@/store';
import { clearDownloadCenterError } from '@/store/downloadCenter';
import { addDownloadCenterCategory } from '@/store/downloadCenter/functions';
import requestAPI from '@/utils/requestAPI';

import { ModalContainerStyled } from '../style';

export type InitialValuesType = {
  name: string;
};

type Props = {
  isOpenModal: boolean;
  onClose: () => void;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

const CreateCategory = ({ isOpenModal, onClose }: Props) => {
  const { downloadId } = useParams();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValue, setInitialValue] = useState<InitialValuesType>({
    name: '',
  });

  const error = useSelector(
    (state: RootState) => state.downloadCenterCategory.error
  );

  const fetchCategory = async () => {
    if (downloadId) {
      const response = await requestAPI(
        downloadCenter.getSingleDownloadCenterCategory(downloadId)
      );
      setCategory(response);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, [downloadId]);

  useEffect(() => {
    if (error) {
      setTimeout(() => dispatch(clearDownloadCenterError()), 1000);
      toast.error(error);
    }
  }, [error]);

  const handleFormSubmit = async (val: InitialValuesType) => {
    setLoading(true);
    if (downloadId) {
      try {
        await requestAPI(
          downloadCenter.updateDownloadCenterCategory(downloadId, {
            title: val.name,
          })
        );
        setLoading(false);
        fetchCategory();
        toast.success('Category updated successfully');
        onClose();
      } catch (e) {
        console.error(e);
        setErrorMessage(e.message);
        setLoading(false);
      }
    } else {
      try {
        await dispatch(
          addDownloadCenterCategory({
            title: val.name,
          })
        );
        fetchCategory();
        toast.success('Category created successfully');
        setLoading(false);
        onClose();
      } catch (e) {
        console.log(e);
        setErrorMessage(e.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (downloadId) {
      setInitialValue({
        name: category?.title,
      });
    }
  });

  return (
    <ModalContainerStyled>
      <Modal
        isOpen={isOpenModal}
        onClose={onClose}
        title={downloadId ? 'Update Category' : 'create New Category'}
        halfWidth={true}
      >
        <Formik
          initialValues={initialValue}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <NewDocumentWrapper>
                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Name <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                    />
                    {errors.name && touched.name && (
                      <div style={{ color: 'red' }}>{errors.name}</div>
                    )}
                  </InfoRowStyled>
                </NewDocumentWrapper>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '50px',
                  }}
                >
                  <Button
                    radius="md"
                    variant="ghost"
                    style={{
                      border: `1px solid ${theme.colors.dark.neutral_250}`,
                      borderRadius: '10px',
                      color: theme.colors.text,
                      fontFamily: theme.fontFamily.roboto,
                      fontWeight: theme.fontWeights.medium,
                    }}
                    onClick={() => onClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    radius="normal"
                    isLoading={loading}
                    style={{ backgroundColor: theme.colors.brandColorBlue }}
                  >
                    {downloadId ? 'Update' : 'Save'}
                  </Button>
                </div>
                {errorMessage && (
                  <div style={{ color: 'red', marginTop: '10px' }}>
                    {errorMessage}
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </ModalContainerStyled>
  );
};

export default CreateCategory;
