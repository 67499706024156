import { createSlice } from '@reduxjs/toolkit';

import { getHashTags } from './function';

type HashTag = {
  _id: string;
  tag: string;
  prevId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

type InitialState = {
  hashTags: Array<HashTag> | null;
  error: string | null;
  loading: boolean;
  pagination: {
    total: number;
    limit: number;
    currentPage: number;
    totalPages: number;
  };
};

const initialState: InitialState = {
  hashTags: null,
  error: null,
  loading: false,
  pagination: {
    total: 0,
    limit: 11,
    currentPage: 0,
    totalPages: 1,
  },
};

const hashTagSlice = createSlice({
  initialState,
  name: 'hashTags',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHashTags.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getHashTags.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;

      state.hashTags = action.payload.data;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(getHashTags.rejected, (state, action) => {
      state.loading = false;

      state.error =
        (typeof action.payload === 'string' && action.payload) ||
        'Failed to fetch hash tags. Please, try again later.';
    });
  },
});

export const hashTagReducer = hashTagSlice.reducer;
