import { createSlice } from '@reduxjs/toolkit';

import { toKebabCase } from '@/utils/conversion';

import { getAllForms } from './functions';

const initialState = {
  formsData: [],
  analytics: {},
  pagination: {
    total: 0,
    limit: 10,
    currentPage: 1,
    totalPages: 1,
  },
  loading: false,
  error: null,
};

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllForms.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllForms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch forms.';
      })
      .addCase(getAllForms.fulfilled, (state, action) => {
        state.loading = false;
        state.formsData = action.payload.forms.data;
        state.pagination = action.payload.forms.pagination;
        state.analytics = action.payload.analytics
          ? Object.entries(action.payload.analytics).reduce(
              (acc, [key, value]) => {
                acc = { ...acc, [toKebabCase(key)]: { key, value } };
                return acc;
              },
              {}
            )
          : {};
      });
  },
});

export const formsReducer = formsSlice.reducer;
