import { ConfigType } from '@/utils/requestAPI';

export type AdsType = {
  name?: string;
  advertiser?: string;
  startDate?: Date;
  endDate?: Date;
  content?: string;
  contentType?: string;
  status?: string;
  type?: string;
  url?: string;
  region?: string[];
};
export type PaginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
};

export type AdvertiserType = {
  fullName?: string;
  email?: string;
  website?: string;
  phoneNumber?: string;
};

const advertise = {
  addAds: (data: AdsType): ConfigType => {
    return {
      method: 'post',
      url: '/ads',
      data,
    };
  },
  updateAds: (id: string, data: AdsType): ConfigType => {
    return {
      method: 'put',
      url: `/ads/${id}`,
      data,
    };
  },
  getAllAds: (params?: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/ads',
      params,
    };
  },
  getSingleAds: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/ads/${id}`,
    };
  },
  addAdvertiser: (data: AdvertiserType): ConfigType => {
    return {
      method: 'post',
      url: '/advertiser',
      data,
    };
  },
  updateAdvertiser: (id: string, data: AdvertiserType): ConfigType => {
    return {
      method: 'put',
      url: `/advertiser/${id}`,
      data,
    };
  },
  getAllAdvertiser: (params: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/advertiser',
      params,
    };
  },
  getSingleAdvertiser: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/advertiser/${id}`,
    };
  },
};

export default advertise;
