import * as yup from 'yup';

import { BUILDING_ROUTE } from '@/routes/routePaths';

export const BuildingJourneyBreadCrumbData = (
  label: string
): Array<{
  label: string;
  path: string;
}> => [
  {
    label: 'Building Journey',
    path: BUILDING_ROUTE,
  },
  {
    label,
    path: '#',
  },
];
export const BuildingJourneyStepBreadCrumbData = (
  label: string
): Array<{
  label: string;
  path: string;
}> => [
  {
    label: 'Building Journey Step',
    path: BUILDING_ROUTE,
  },
  {
    label,
    path: '#',
  },
];

export const tabsData: Array<{ label: string; value: string }> = [
  {
    label: 'Regulations',
    value: 'regulations',
  },
  {
    label: 'Trades',
    value: 'trades',
  },
  {
    label: 'Products',
    value: 'products',
  },
  {
    label: 'Building Systems',
    value: 'building systems',
  },
];
export const buildingStageData: Array<{ label: string; value: string }> = [
  {
    label: 'Wall Insulation',
    value: 'Wall Insulation',
  },
  {
    label: 'Roof Insulation',
    value: 'Roof Insulation',
  },
  {
    label: 'Floor Insulation',
    value: 'Floor Insulation',
  },
  {
    label: 'Fire Safety',
    value: 'Fire Safety',
  },
  {
    label: 'Roof Coverings',
    value: 'Roof Coverings',
  },
  {
    label: 'Blocks',
    value: 'Blocks',
  },
  {
    label: 'Wall Hangers/shoes',
    value: 'Wall Hangers/shoes',
  },
  {
    label: 'Lintels',
    value: 'Lintels',
  },
  {
    label: 'DPC',
    value: 'DPC',
  },
];

export const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  position: yup
    .number()
    .required('Position is required')
    .integer('Position must be an integer'),
  icon: yup.mixed().nullable().required('Icon is required'),
  region: yup
    .array()
    .of(yup.string())
    .min(1, 'At least one region is required')
    .required('Regions are required'),
  tabs: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required('Label is required'),
        value: yup.string().required('Value is required'),
      })
    )
    .min(1, 'At least one tab is required')
    .required('Tabs are required'),
  finance: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().required('Title is required'),
        subtitle: yup.string().required('Subtitle is required'),
        link: yup.string().required('Link is required'),
      })
    )
    .min(1, 'At least one Finance is required')
    .required('Finance is required'),
  documents: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required('Label is required'),
        value: yup.string().required('Value is required'),
      })
    )
    .min(1, 'At least one document is required')
    .required('Documents are required'),
  recommendedProfessionals: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required('Label is required'),
        value: yup.string().required('Value is required'),
      })
    )
    .min(1, 'At least one recommended professional is required')

    .required('Recommended professionals are required'),
  productSubCategory: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required('Label is required'),
        value: yup.string().required('Value is required'),
      })
    )
    .min(1, 'At least one product sub category is required')

    .required('Product subcategory is required'),
  buildingStage: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required('Label is required'),
        value: yup.string().required('Value is required'),
      })
    )
    .min(1, 'At least one building stage is required')
    .required('Building stage is required'),
  buildingSystems: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required('Label is required'),
        value: yup.string().required('Value is required'),
      })
    )
    .min(1, 'At least one building system is required')

    .required('Building systems are required'),
});

export const getRespectiveOptions = (
  savedData: any[],
  apiData: any[],
  id: string
) => {
  let newOptions: Array<{ label: string; value: string }> = [];

  const data =
    apiData?.map((item) => ({
      label: item.title || item.name || item.category || '',
      value: item._id,
    })) || [];
  const newSavedData =
    savedData?.map((item) => ({
      label: item.title || item.name || item.category || '',
      value: item._id,
    })) || [];

  if (id && savedData) {
    newOptions = [...newSavedData, ...data];
  } else {
    newOptions = [...data];
  }

  return newOptions;
};
