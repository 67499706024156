import Typography from '@/designComponents/Typography';

import Tag from '../../../../components/UserDetails/Tag';
import { InformationRowWrapper } from '../style';

type Props = {
  id: string;
  fullName: string;
  userName: string;
  userStatus: 'danger';
  userStatusTitle: string;
  email: string;
  reason: string;
  comment: string;
  paymentStatus: 'completed' | 'inComplete';
  paymentTitle: string;
  createdAt: string;
  updateAt: string;
};

const AccountDeletionUserInformation = ({
  createdAt,
  email,
  fullName,
  id,
  updateAt,
  userName,
  paymentStatus,
  paymentTitle,
  comment,
  reason,
  userStatus,
  userStatusTitle,
}: Props) => {
  return (
    <InformationRowWrapper>
      <>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            ID
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {id}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Full Name
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {fullName}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            User
          </Typography>
          <div style={{ display: 'flex', gap: '13px', alignItems: 'center' }}>
            <Typography
              fontFamily="roboto"
              fontWeight="normal"
              color="dark.neutral_450"
              size="normal"
            >
              {userName}
            </Typography>
            <Tag
              title={userStatusTitle}
              hidePoint
              style={{ borderRadius: '4px' }}
              variant={userStatus}
            />
          </div>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Email
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {email}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Reason
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {reason}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Additional Comments
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {comment}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Status
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tag title={paymentTitle} hidePoint variant={paymentStatus} />
          </div>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Created At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            {createdAt}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Update At
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            {updateAt}
          </Typography>
        </div>
      </>
    </InformationRowWrapper>
  );
};

export default AccountDeletionUserInformation;
