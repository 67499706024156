import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { couponPeriodType, couponSubscription } from '@/constants';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Modal from '@/designComponents/Modal';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';

const validationSchema = Yup.object().shape({
  subscription: Yup.string().required('Subscription is required'),
  accountPlan: Yup.string().required('Account Plan is required'),
  duration: Yup.number()
    .positive()
    .required('Duration is required')
    .max(12, 'Duration should not be greater than 12'),
});

export type InitialValue = {
  subscription: string;
  accountPlan: string;
  duration: number;
};

type Props = {
  openModal: boolean;
  onClose?: () => void;
  onSubmit: (values: InitialValue) => void;
  loading?: boolean;
};

export default function ChangeSubscriptionModal({
  openModal,
  onSubmit,
  onClose,
  loading,
}: Props) {
  return (
    <Modal
      isOpen={openModal}
      onClose={onClose}
      title="Change Subscription"
      halfWidth={true}
    >
      <ModalContentWrapper>
        <Formik
          initialValues={
            {
              accountPlan: '',
              subscription: '',
              duration: 1,
            } as InitialValue
          }
          onSubmit={(values) => {
            onSubmit(values);
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, setFieldValue }) => {
            return (
              <Form>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    gap: 100,
                    height: '100%',
                  }}
                >
                  <div>
                    <div style={{ marginBottom: '20px' }}>
                      <Typography
                        fontWeight="medium"
                        style={{ marginBottom: '10px' }}
                      >
                        Subscription
                      </Typography>
                      <Select
                        name="subscription"
                        selected={values.subscription}
                        onSelect={(value) => {
                          setFieldValue('subscription', value);
                        }}
                        options={couponSubscription}
                        placeholder="Select Subscription"
                      />
                      {errors.subscription && touched.subscription ? (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                          {errors.subscription}
                        </div>
                      ) : null}
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                      <Typography
                        fontWeight="medium"
                        style={{ marginBottom: '10px' }}
                      >
                        Account Plan Type
                      </Typography>
                      <Select
                        name="accountPlan"
                        selected={values.accountPlan}
                        onSelect={(value) => {
                          setFieldValue('accountPlan', value);
                        }}
                        options={couponPeriodType}
                        placeholder="Select Account Plan Type"
                      />
                      {errors.accountPlan && touched.accountPlan ? (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                          {errors.accountPlan}
                        </div>
                      ) : null}
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                      <Typography
                        fontWeight="medium"
                        style={{ marginBottom: '10px' }}
                      >
                        Duration(
                        {values.accountPlan === 'yearly'
                          ? 'In Years'
                          : 'In Months'}
                        )
                      </Typography>
                      <Input
                        title="title"
                        value={values.duration}
                        type="number"
                        onChange={(e) =>
                          setFieldValue('duration', e.target.value)
                        }
                      />
                      {errors.duration && touched.duration ? (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                          {errors.duration}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '20px',
                      gap: '20px',
                    }}
                  >
                    <Button
                      variant="gray"
                      radius="normal"
                      type="button"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" radius="normal" isLoading={loading}>
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalContentWrapper>
    </Modal>
  );
}
