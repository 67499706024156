/* eslint-disable */

import {
  DOMConversionMap,
  DOMExportOutput,
  LexicalNode,
  TextNode,
} from 'lexical';

class StyledTextNode extends TextNode {
  __fontFamily: string;

  __fontSize: string;

  __color: string;

  __backgroundColor: string;

  __className: string;

  constructor(
    text: string,
    fontFamily: string,
    fontSize: string,
    color: string,
    backgroundColor: string,
    className: string = ''
  ) {
    super(text);
    this.__fontFamily = fontFamily || '';
    this.__fontSize = fontSize || '';
    this.__color = color || '';
    this.__backgroundColor = backgroundColor || '';
    this.__className = className;
  }

  static getType(): string {
    return 'styled-text';
  }

  createDOM(config: any): HTMLElement {
    const dom = super.createDOM(config) as HTMLElement;
    dom.style.fontFamily = this.__fontFamily;
    dom.style.fontSize = this.__fontSize;
    dom.style.color = this.__color;
    dom.style.backgroundColor = this.__backgroundColor;
    if (this.__className) {
      dom.className = this.__className;
    }
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.style.fontFamily = this.__fontFamily;
    element.style.fontSize = this.__fontSize;
    element.style.color = this.__color;
    element.style.backgroundColor = this.__backgroundColor;
    if (this.__className) {
      element.className = this.__className;
    }
    element.textContent = this.getTextContent();
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (node: HTMLElement) => ({
        conversion: convertSpanElement,
        priority: 1,
      }),
      em: (node: HTMLElement) => ({
        conversion: convertSpanElement,
        priority: 1,
      }),
      strong: (node: HTMLElement) => ({
        conversion: convertSpanElement,
        priority: 1,
      }),
      sub: (node: HTMLElement) => ({
        conversion: convertSpanElement,
        priority: 1,
      }),
      sup: (node: HTMLElement) => ({
        conversion: convertSpanElement,
        priority: 1,
      }),
    };
  }

  static clone(node: StyledTextNode): StyledTextNode {
    return new StyledTextNode(
      node.__text,
      node.__fontFamily,
      node.__fontSize,
      node.__color,
      node.__backgroundColor,
      node.__className
    );
  }
}

function convertSpanElement(domNode: HTMLElement): { node: StyledTextNode } {
  const style = domNode.style;
  return {
    node: new StyledTextNode(
      domNode.textContent || '',
      style.fontFamily || '',
      style.fontSize || '',
      style.color || '',
      style.backgroundColor || '',
      domNode.className || ''
    ),
  };
}

export { StyledTextNode };
