const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 100,
  sidebarOverlay: 900,
  sidebar: 1000,
  dropdown: 2000,
  sticky: 300,
  banner: 4000,
  header: 5000,
  overlay: 6000,
  modal: 7000,
  popover: 8000,
  skipLink: 9000,
  toast: 10000,
  tooltip: 11000,
};

export default zIndices;

export type ZIndicesType = typeof zIndices;
