import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;

  .row-show {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  .pages {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    cursor: pointer;

    & .active-page {
      background-color: ${({ theme }) => theme.colors.dark.neutral_50};
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 12px;
      border-radius: 5px;
      border: transparent;
      cursor: pointer;
    }

    & .button {
      cursor: pointer;
      border: transparent;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.dark.neutral_450};
    }
  }
`;
